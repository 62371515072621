
export const IGNORED_EXCEPTION_MESSAGES: string[] = [];

export const environment = {
  production: true,
  tracking: {
    ignoredExceptionMessages: IGNORED_EXCEPTION_MESSAGES,
  }
};

export const SECRET_SEGMENT = 'ZSa9MO9nu8wj8DR45dyqxatwUJqTLdb-vByd79KD';
