import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// @ts-ignore
import moment from 'moment';
import get from 'lodash-es/get';

@Component({
  selector: 'app-event-time',
  templateUrl: './event-time.component.html',
  styleUrls: ['./event-time.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTimeComponent implements OnInit, OnChanges {
  @Input() openedAt;
  @Input() closedAt;
  @Input() graph;

  public duration;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'openedAt') && get(changes, 'closedAt')) {
      const momentDuration = moment.duration(moment(this.openedAt).diff(this.closedAt ? moment(this.closedAt) : moment.now()));
      this.duration = momentDuration.humanize();
      this.cdRef.markForCheck();
    }
  }

}
