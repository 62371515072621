import { Component, OnInit, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { OpsterMarkdownModule } from '../opster-markdowm/opster-markdown.module';

@Component({
  selector: 'code-viewer',
  templateUrl: './code-viewer.component.html',
  styleUrls: ['./code-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeViewerComponent implements OnInit {
  @Input() data: string;
  @Input() lineNumbers = true;
  @Input() clipboard = true;

  @Input() languages: 'typescript' | 'css' | 'xml' | 'bash' = 'typescript';

  constructor() {
  }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [CodeViewerComponent],
  imports: [
    OpsterMarkdownModule
  ],
  providers: [],
  exports: [CodeViewerComponent]
})
export class CodeViewerModule {
}
