import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import { TreeSelectComponent } from './tree-select.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonLoaderModule } from '../../skeleton-loader/skeleton-loader.module';

@NgModule({
  declarations: [
    TreeSelectComponent,
  ],
  imports: [
    CommonModule,
    TreeSelectModule,
    FormsModule,
    TooltipModule,
    SkeletonLoaderModule,

  ],
  providers: [],
  exports: [
    TreeSelectComponent
  ]
})
export class TreeSelectOpsterModule {
}
