import { Pipe, PipeTransform } from '@angular/core';
import size from 'lodash-es/size';
import { NumberFormatEnum } from '../../models/number-format.enum';

@Pipe({
  name: 'numberFormatPipe'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    const digits = args[1];
    if (isNaN(value)) {
      return '';
    }
    if (value === null) {
      return '';
    }

    if (args[0] === NumberFormatEnum.BYTES ||
      args[0] === NumberFormatEnum.BYTES_GB ||
      args[0] === NumberFormatEnum.BYTES_NONE ||
      args[0] === NumberFormatEnum.BYTES_SEC) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

      let symbol_b = '';
      if (args[0] === NumberFormatEnum.BYTES_SEC) {
        symbol_b = '/sec';
      }

      if(value === 0 && args[0] === NumberFormatEnum.BYTES_GB) {
        return '0 GB' + symbol_b;
      }
      if (value === 0) {
        return '0 Byte' + symbol_b;
      }
      let i = Math.floor(Math.log(value) / Math.log(1024));
      if (i === -1) {
        i = 0;
      }
      const round = Math.round(((value / Math.pow(1024, i))) * 100) / 100;
      if(args[0] === NumberFormatEnum.BYTES_GB) {
        return round.toFixed(2).replace(/[.,]00$/, '') + ' ' + 'GB' + symbol_b;
      }
      if (args[0] === NumberFormatEnum.BYTES_NONE) {
        return round.toFixed(1).replace(/[.,]00$/, '');

      } else {
        return round.toFixed(2).replace(/[.,]00$/, '') + ' ' + sizes[i] + symbol_b;
      }
    }


    let abs = Math.abs(value as number);
    const rounder = Math.pow(10, 2);
    const isNegative = value < 0;
    let key = '';

    if (args[0] === NumberFormatEnum.MS) {
      let symbol_ms = '';

      if (abs < 1000) {
        symbol_ms = ' ms';
      } else {
        abs = abs / 1000;
        symbol_ms = ' sec';

      }
      return (isNegative ? '-' : '') + abs.toFixed(2).replace(/[.,]00$/, '') + key + symbol_ms;


    }

    const powers = [
      {key: 'Q', value: Math.pow(10, 15)},
      {key: 'T', value: Math.pow(10, 12)},
      {key: 'B', value: Math.pow(10, 9)},
      {key: 'M', value: Math.pow(10, 6)},
      {key: 'K', value: 1000},
    ];

    for (let i = 0; i < size(powers); i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    let symbol = '';
    if (args[0] === NumberFormatEnum.PRECENT) {
      symbol = '%';
    }
    if (args[0] === NumberFormatEnum.SEC) {
      symbol = '/sec';
    }
    // if (!args[0] && value < 1) {
    //   symbol = 'B';
    // }
    return (isNegative ? '-' : '') + abs.toFixed(digits || 0) + key + symbol;
  }


}
