import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import get from 'lodash-es/get';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageErrorComponent implements OnInit {

  public reportError = '';

  constructor(private router: Router) {
    this.reportError = get(this.router.getCurrentNavigation(), 'extras.state.message');
  }

  ngOnInit(): void {
  }

}
