<div class="review">
  <div class="review-content">
    <p-image class="review-content-header" [src]="review?.companyImg" alt="Image" height="40px"></p-image>
    <div class="review-content-text" [innerHTML]="review?.content"></div>
  </div>

  <div class="review-footer">
    <div class="review-img">
      <p-avatar [image]="review?.img" [styleClass]="'review-avatar'"  shape="circle"></p-avatar>
    </div>
    <div class="review-name">
      {{review?.name}}
    </div>
    <div class="review-job">
      {{review?.job}}
    </div>
  </div>


</div>
