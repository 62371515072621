import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppRoutes} from './app-routing-data';
import {MasterPageComponent} from './layout/master-page/master-page.component';
import {ClearPageComponent} from './layout/clear-page/clear-page.component';
import {AuthGuard} from './core/auth/auth.guard';
import {PageErrorComponent} from './core/page-error/page-error.component';
import {DashboardSeo} from './shared/constants/seo.constant';

const routes: Routes = [
  {
    path: AppRoutes.login,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'd',
    component: ClearPageComponent,
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
      },
    ]
  },
  {
    path: 'error-page',
    component: PageErrorComponent,
  },
  {
    path: '',
    component: MasterPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: AppRoutes.dashboard,
        pathMatch: 'full'
      },
      {
        path: AppRoutes.settings,
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: AppRoutes.dashboard,
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          seo: {
            title: DashboardSeo.title,
            metaTags: [
              {name: DashboardSeo.metaTagName, content: DashboardSeo.metaTagContent},
            ]
          }
        }
      },
      {
        path: AppRoutes.clusterView,
        loadChildren: () => import('./features/cluster-view/cluster-view.module').then(m => m.ClusterViewModule),
      },
      {
        path: AppRoutes.ops_gpt,
        loadChildren: () => import('./features/ops-gpt/ops-gpt.module').then(m => m.OpsGptModule),
      },
      {
        path: AppRoutes.ops_gpt + '/conversation/:conversationId',
        loadChildren: () => import('./features/ops-gpt/ops-gpt.module').then(m => m.OpsGptModule),
      },
      {
        path: AppRoutes.indexView,
        loadChildren: () => import('./features/index-view/index-view.module').then(m => m.IndexViewModule)
      },
      {
        path: AppRoutes.nodeView,
        loadChildren: () => import('./features/node-view/node-view.module').then(m => m.NodeViewModule)
      },
      {
        path: AppRoutes.shardsView,
        loadChildren: () => import('./features/shards-view/shards-view.module').then(m => m.ShardsViewModule)
      },
      {
        path: AppRoutes.templateOptimizer,
        loadChildren: () => import('./features/template-optimizer/template-optimizer.module')
          .then(m => m.TemplateOptimizerModule)
      },
      {
        path: AppRoutes.slowLogs,
        loadChildren: () => import('./features/slow-logs/slow-logs.module').then(m => m.SlowLogsModule)
      },
      {
        path: AppRoutes.reports,
        loadChildren: () => import('./features/kpi/kpi.module').then(m => m.KpiModule)
      },
      {
        path: AppRoutes.notificationsHistory,
        loadChildren: () => import('./features/notifications-history/notifications-history.module').then(m => m.NotificationsHistoryModule),
      }
    ]
  },
  {path: '**', redirectTo: AppRoutes.dashboard},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
