import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SegmentTrackService } from '../../services/segment/segment.service';
import { ToastService } from '../../../store/toast/toast.service';

@Component({
  selector: 'opster-markdowm',
  templateUrl: './opster-markdowm.component.html',
  styleUrls: ['./opster-markdowm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpsterMarkdowmComponent implements OnInit {
  @Input() data;
  @Input() lineNumbers = true;
  @Input() clipboard = true;

  constructor(private toastService: ToastService,
              private segmentTrackService: SegmentTrackService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  copyToClipboard() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('recommendations.copy_clipboard'), 'success', 'copy_clipboard');

    this.segmentTrackService.setAnalyticsTrackToServer('AutOops command copied to clipboard', {
      email: this.segmentTrackService.getTrackEmail,
    });
  }

}
