import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { LoaderComponent } from './loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [],
  exports: [
    LoaderComponent
  ]
})
export class LoaderModule {
}
