<h1 mat-dialog-title> {{title}}
  <mat-icon mat-dialog-close (click)="onCancel(dialogButton.cancel)">close</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="content-wrapper" [class.with-icon]="icon">
    <mat-icon [svgIcon]="icon" class="dialog-icon" *ngIf="icon"></mat-icon>
    <p [innerHTML]="message"></p>
    <p [innerHTML]="message2" *ngIf="message2"></p>
    <p class="noteMessage">
      {{note}}
    </p>
  </div>
</div>
<div mat-dialog-actions [ngClass]="'space-between'">
  <button mat-stroked-button class="button-opster no-color dialog-action secondary" mat-dialog-close
          (click)="onCancel(dialogButton.cancel)">{{dialogButton.cancel.label | translate}}</button>

  <button mat-stroked-button class="button-opster yellow dialog-action medium-btn" [mat-dialog-close]="true"
          (click)="onOk(dialogButton.ok)">
    {{dialogButton.ok.label}}
    <mat-icon [svgIcon]="dialogButton.ok.icon" style="margin-left: 5px; width: 28px; height: 28px;"
              *ngIf="dialogButton.ok.icon"></mat-icon>
  </button>

</div>

