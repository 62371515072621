import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {GlobalService} from '../../store/global/global.service';
import {MainDashboardService} from '../../features/dashboard/service/main-dashboard.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private globalService: GlobalService,
    private dashboardService: MainDashboardService
  ) {
  }

  canActivate() {
    if (!this.globalService.getClusterListValue() || !this.dashboardService.getEventsListValue()) {
      this.globalService.setClusterList(false);
    }
    // // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return true;
  }
}
