const $mainDark = '#141414';
const $mainDark_opacity_60 = 'rgba(20,20,20,0.6)';

const $gray400 = '#272727';
const $gray400_opacity = 'rgba(39,39,39,0.35)';
const $gray800 = '#414A53';
const $gray900 = '#6B6B6B';
const $gray920 = '#6c757d';
const $gray930 = '#878787';
const $gray940 = '#989898';
const $gray945 = '#B7B7B7';
const $gray950 = '#E1E1E1';
const $gray970 = '#F1F1F1';
const $gray1000 = '#C3C3C3';
const $gray_border = '#707070';


const $mainWhite = '#fff';
const $mainWhiteOpacity30 = 'rgba(255, 255, 255, 0.3)';
const $mainWhiteOpacity50 = 'rgba(255, 255, 255, 0.5)';
const $mainWhiteOpacity60 = 'rgba(255, 255, 255, 0.6)';
const $mainWhiteOpacity70 = 'rgba(255, 255, 255, 0.7)';

const $mainBlue = '#140635';
const $mainGreen = '#35a899';
const $greenChart = '#53dcb0';

const $red100 = '#FE716F';
const $red200 = '#E14942';

const $main_yellow = '#fec400';
const $main_yellow_opacity = 'rgba(254, 196, 0, 0.3)';

const $yellow100 = '#edd5a4';
const $yellow200 = '#FFE799';
const $yellow400 = '#FFA923';
const $yellow800 = '#565000';
const $main_blue_50 = '#EBF5FD';
const $main_blue_90 = '#C7D8F9';
const $main_blue_90_opacity = '#C7D8F97F';
const $main_blue_100 = '#4072ee';
const $main_blue_120 = '#62ADFF';
const $main_blue_150 = '#729df0';
const $main_blue_150_opacity = '#729DF027';
const $main_blue_200 = '#527ff0';
const $main_blue_220 = '#007AFF';
const $main_blue_250 = '#4C6FE9';

const $main_blue_link = '#0000EE';
const $main_blue_link_light = '#2196F3';

const $dark_border_50 = 'rgba(255, 255, 255, 0.7)';
const $dark_border_200 = '#545f69';

const $light_border_100 = '#E0E0E0';

const $dark_tag = '#616161';

const $dark10 = '#FAFAFA';
const $dark20 = '#aeaeae';
const $dark50 = '#B3B3B3';
const $dark100 = '#3c5064';
const $dark150 = '#394048';
const $dark180_opacity = '#22282f80';
const $dark180 = '#22282f';
const $dark200 = '#292e33';
const $dark250 = '#31394D';
const $dark300 = '#1f2327';
const $dark300_opacity = 'rgba(31, 35, 39, 0.5)';
const $dark350 = '#4D4D4D';
const $dark355 = '#6B6E73';
const $dark360 = '#D9D9D9';
const $dark365 = '#626262';
const $dark370 = '#6e6e6e';
const $dark380 = '#606060';
const $dark385 = '#7E7E7E';
const $dark390 = '#808080';
const $dark400 = '#16191c';
const $dark410 = '#5A5C63';
const $dark415 = '#5F6368';
const $dark420 = '#495057';
const $dark450 = '#313131';
const $dark450_opacity = '#31313180';
const $dark460 = '#676767';
const $dark470 = '#474747';
const $dark480 = '#212121';
const $dark500 = '#000000';
const $dark500_opacity = '#000000e6';
const $dark500_opacity54 = '#0000008a';

const $dark_skeleton = '#394048';

const $light80 = '#dee2e6';
const $light100 = '#fff';
const $light110 = '#f9f9f9';
const $light120 = '#f7f7f7';
const $light130 = '#F0F5FA';
const $light140 = '#edf7ff';
const $light150 = '#e5e9f2';
const $light160 = '#E6E9F5';
const $light180 = '#e9f5ffcc';
const $light200 = '#ecf0f6';
const $light250 = '#eff0f1';
const $light300 = '#b8c5d3';
const $light350 = '#F4F4F4';
const $light350_opacity = 'rgba(244, 244, 244, 0.35)';
const $light400 = '#c3c9d0';
const $light_450 = '#CCCCCC';
const $light480 = '#F6F6F6';
const $light500_opacity = 'rgba(247, 247, 247, 0.5)';
const $light600_opacity = '#F4F4F450';
const $light580_opacity = '#F4F4F480';
const $light550_opacity = '#ffffffe5';
const $light600 = '#ffffff4d';
const $light650 = '#ffffffb3';
const $light700 = '#748aa1';
const $light750 = '#3131311a';
const $light800 = '#3131314D';
const $light850 = '#313131CC';
const $light900 = 'rgba(49,49,49,0.1)';


const $light_skeleton = 'rgba(96, 112, 130, 0.3)';
const $lightText = '#313131';
const $lightTextOpacity50 = 'rgba(49,49,49,0.50)';
const $lightTextOpacity30 = 'rgba(49,49,49,0.30)';
const $lightTextOpacity70 = 'rgba(49,49,49,0.70)';

const $light_input_disabled = 'rgba(0, 0, 0, 0.05)';
const $light_panel_text_disabled = 'rgba(49, 57, 77, 0.6)';
const $dark_input_disabled = 'rgba(255, 255, 255, 0.05)';

const $dark_shadows300 = 'rgba(0, 0, 0, 0.19)';
const $dark_shadows400 = '#22262a';

const $light_shadows50 = '#00000029';
const $light_shadows100 = 'rgba(229, 233, 242, 0.5)';
const $light_shadows200 = 'rgba(0, 0, 0, 0.39)';

const $light_tooltip = '#f2f7fe';
const $dark_tooltip = '#777777';

const $light_divider = 'rgba(0, 0, 0, 0.2)';
const $dark_divider = 'rgba(255, 255, 255, 0.2)';

const $blueActive = '#e3f3fd';
const $blueActiveOpacity = '#e3f3fd9e';

const $error = '#f9cbbe';
const $success = '#b5f0dd';

const $severity_high = '#FF6A6A';
const $severity_high_25 = 'rgba(255,106,106,0.25)';
const $severity_high_50 = 'rgba(255,106,106,0.5)';
const $severity_high_75 = 'rgba(255,106,106,0.75)';

const $severity_medium = '#FFC66A';
const $severity_medium_25 = 'rgba(255,198,106,0.25)';
const $severity_medium_50 = 'rgba(255,198,106,0.5)';
const $severity_medium_75 = 'rgba(255,198,106,0.75)';

const $severity_low = '#7CABEA';
const $severity_low_25 = 'rgba(124,171,234,0.25)';
const $severity_low_50 = 'rgba(124,171,234,0.5)';
const $severity_low_75 = 'rgba(124,171,234,0.75)';

const $severity_cost = '#2BC999';
const $severity_cost_25 = 'rgba(43,201,153,0.25)';
const $severity_cost_50 = 'rgba(43,201,153,0.5)';
const $severity_cost_75 = 'rgba(43,201,153,0.75)';

const $severity_high_ops = 'rgba(255,106,106,0.3)';
const $severity_medium_ops = 'rgba(255,198,106,0.3)';
const $severity_low_ops = 'rgba(124,171,234,0.3)';
const $severity_cost_ops = 'rgba(43,201,153,0.3)';

const $severity_close_dark_bg = 'rgba(244,244,244,0.33)';
const $severity_close_light_bg = '#3131311F';

const $severity_box_light = 'rgba(244,244,244,0.5)';
const $severity_box_dark = 'rgba(49,49,49,0.8)';

const $transparent = 'rgba(0,0,0,0)';

const $heat_map_1 = '#fefbec';
const $heat_map_2 = '#fef8da';
const $heat_map_3 = '#fdefbb';
const $heat_map_4 = '#fbe794';
const $heat_map_5 = '#fede73';
const $heat_map_6 = '#ffc1a5';
const $heat_map_7 = '#ffa5a5';
const $heat_map_8 = '#fd8383';
const $heat_map_9 = '#ff6767';
const $heat_map_10 = '#fb546a';

const $heat_map_1_ops = 'rgba(254,251,236,0.8)';
const $heat_map_2_ops = 'rgba(254,248,218,0.8)';
const $heat_map_3_ops = 'rgba(253,239,187,0.8)';
const $heat_map_4_ops = 'rgba(251,231,148,0.8)';
const $heat_map_5_ops = 'rgba(254,222,115,0.8)';
const $heat_map_6_ops = 'rgba(255,193,165,0.8)';
const $heat_map_7_ops = 'rgba(255,165,165,0.8)';
const $heat_map_8_ops = 'rgba(253,131,131,0.8)';
const $heat_map_9_ops = 'rgba(255,103,103,0.8)';
const $heat_map_10_ops = 'rgba(251,84,106,0.8)';

const $promoBg = '#d7dff4';

const $selectedBg1 = 'rgba(227, 236, 253, 0.5)';
const $selectedBg2 = 'rgb(83 101 124 / 50%)';

const $DebIcon = '#A80030';
const $helmIcon = '#0F1689';

// const $heat_map_dark_1 = 'rgba(229,254,242,0.9)';
// const $heat_map_dark_2 = 'rgba(254,241,190,0.9)';
// const $heat_map_dark_3 = 'rgba(255,200,154,0.9)';
// const $heat_map_dark_4 = 'rgba(255,133,112,0.9)';
// const $heat_map_dark_5 = 'rgba(255,95,95,0.9)';

export const themes = {
  lightTheme: {
    // main
    '--app-bg': $mainWhite,
    '--app-bg-opacity-60': $mainWhiteOpacity60,
    '--main-text': $lightText,
    '--main-text-opacity-50': $lightTextOpacity50,
    '--main-text-opacity-30': $lightTextOpacity30,
    '--main-sub-text': $gray900,
    '--main-sub-text-light': $dark385,
    '--main-sub-text-dark': $dark415,
    '--main-sub-text-link': $main_blue_220,
    '--main-success-text': $success,
    // tooltip
    '--tooltip-bg': $light100,
    '--tooltip-color': $dark500,
    // toggle
    '--slide-toggle': $light800,
    '--slide-toggle-opacity': $light750,
    // wizard
    '--wizard-bg': $dark10,
    // input
    '--input-label': $dark450_opacity,
    '--input-bg': $dark450,
    '--input-bg2': $light350,
    '--input-bg-disabled': $light_input_disabled,
    '--input-search-bg': $light350,
    '--input-distribution-bg': $mainWhite,
    '--input-dropdown-search-bg': $light_border_100,
    '--input-refresh-bg': $mainWhite,
    '--input-divider-text': $light350,
    '--input-refresh-border': $light350,
    '--input-search-border': $light300,
    '--input-search-icon': $gray920,
    '--space-border': $dark450,
    // dialog
    '--dialog-bg': $light100,
    '--dialog-key-text': $lightText,
    '--dialog-title-color': $lightText,
    '--dialog-action-bg': $light200,
    '--dialog-icon': $dark450,
    '--dialog-icon-close': $dark180_opacity,
    // divider
    '--divider-color': $light_divider,
    // chart
    '--grid-line': $light300,
    '--grid-line-opacity': $light200,
    '--chart-tooltip': $light_tooltip,
    '--chart-text-hover': $dark400,
    '--chart-gauge-color': $main_yellow,
    '--chart-color-gray': $gray800,
    '--chart-color-green': $mainGreen,
    '--chart-color-yellow': $yellow800,
    '--chart-unit': $dark250,
    '--chart-bg': $mainWhite,
    '--gauge-bg': $mainWhite,
    '--message-box': $main_blue_150_opacity,
    '--chart-overlay': $light550_opacity,
    // legend
    '--legend-item-hidden-color': $light_450,
    '--legend-navigation-inactive-color': $light_450,
    '--legend-navigation-active-color': $lightText,
    // crosshair
    '--crosshair-color': $light300,
    // button
    '--button-settings-bg': $mainWhite,
    '--button-settings-fill': $dark450,
    '--button-yellow-bg': $main_yellow,
    '--button-yellow-text': $dark450,
    '--button-text': $dark_shadows400,
    '--button-time-bg': $dark450,
    '--button-time-text': $severity_medium,
    '--button-gray-bg': $light350,
    '--button-light-bg': $light350,
    '--button-gray-text': $dark450,
    '--button-gray-border': $transparent,
    '--button-video-bg': $light110,
    '--button-video-play-bg': $mainWhite,
    '--button-video-play-line': $dark450,
    '--button-video-play-line-timestamp': $dark450,
    '--button-expand-chevron': $dark360,
    // border
    '--border': $light_border_100,
    '--border-input': $light_border_100,
    '--border-box': $light_border_100,
    // side-menu
    '--side-menu-bg': $dark450,
    '--side-menu-text': $light350,
    '--side-menu-selected': $dark480,
    '--side-menu-selected-border': $main_yellow,
    // menu
    '--menu-bg': $mainWhite,
    '--menu-text': $lightText,
    // menu-top
    '--menu-top-bg': $light100,
    '--menu-top-shadow': $light_shadows100,
    '--menu-top-text': $lightText,
    // panel
    '--panel-bg': $mainWhite,
    '--panel-table-bg': $light480,
    '--panel-shadow': $light_shadows50,
    '--panel-sub-bg': $light400,
    '--panel-icon-bg': $dark450,
    '--panel-expanded-bg': $mainWhite,
    '--panel-expanded-footer-bg': $light300,
    '--panel-expanded-footer-text': $lightText,
    '--panel-header': $light180,
    '--panel-header-icon': $dark470,
    '--panel-header-blue': $light140,
    '--panel-text': $mainWhite,
    '--panel-subtitle': $dark370,
    '--panel-open-close-bg': $light300,
    '--panel-open-close-text': $dark250,
    '--panel-open-close-arrow': $dark250,
    '--panel-open-close-disabled': $light_panel_text_disabled,
    // toolbar-header
    '--toolbar-header-bg': $mainWhite,
    '--toolbar-header-text': $dark450,
    // table
    '--table-header-text': $dark500_opacity54,
    '--table-header-bg': $light200,
    '--table-header-border': $light300,
    '--table-header-shadows': $light_shadows100,
    '--table-header-filter-active': $blueActive,
    '--table-button-icon-hover': $dark420,
    '--table-border-color': $light350,
    '--table-border-color-hover': $gray945,
    '--table-row-active-bg': $mainWhite,
    '--table-row-inactive-bg': $gray970,
    // chart-toolbar-header
    '--chart-toolbar-header-text': $mainBlue,
    '--chart-msg-box': $main_blue_150,
    // panel-content
    '--panel-content-bg': $light350,
    '--panel-content-bg-promo': $mainWhite,
    '--panel-content-text-promo': $dark450,
    '--panel-content-table': $light350,
    '--panel-content-item': $mainWhite,
    '--panel-content-item-card': $mainWhite,
    '--panel-content-item-card-benefits': $light350,
    '--panel-content-table-group': $light350,
    '--panel-content-table-group-opacity': $light350_opacity,
    '--panel-content-sidebar': $mainWhite,
    '--panel-content-hover': $light350,
    '--panel-content-shadows': $light_shadows200,
    '--panel-content-sub-bg': $mainWhite,
    '--panel-content-bg-icon': $light160,
    '--panel-content-sub-border': $transparent,
    '--panel-content-sub-curl': $lightText,
    '--panel-content-sub-curl-link': $main_blue_link,
    '--panel-content-sub-curl-text': $mainWhite,
    '--panel-content-tabs': $mainWhite,
    '--panel-content-tabs-settings': $light900,
    '--panel-content-tab-active': $light120,
    '--panel-content-sub-bg-opacity': $light500_opacity,
    '--panel-content-sub-time': $light700,
    '--panel-content-text': $lightText,
    '--panel-content-sub': $light350,
    '--panel-content-star': $main_blue_100,
    // dropdown
    '--dropdown-selected': $main_yellow,
    '--dropdown-hover': $main_yellow_opacity,
    // multi-select
    '--group-list-text': $lightTextOpacity70,
    // analytics
    '--analytics-reco': $light150,
    '--analytics-reco-text': $lightText,
    '--analytics-reco-gutters': $light300,
    '--analytics-expander-open': $main_blue_100,
    '--analytics-expander-close': $light700,
    // tags
    '--tag-bg': $light80,
    '--tag-text': $dark250,
    '--cluster-tag': $heat_map_5_ops,
    '--cluster-tag-text': $dark450,
    // gpt
    '--gpt-bg': $light350,
    '--gpt-name-bg': $dark450,
    '--gpt-name-text': $mainWhite,
    '--gpt-name-border': $dark450,
    '--gpt-arrow': $main_yellow,
    // checkbox
    '--checkbox-bg': $main_blue_200,
    '--checkbox-icon': $main_blue_200,
    '--checkbox-border': $light300,
    // snack-bar
    '--snack-bar-error-bg': $error,
    '--snack-bar-success-bg': $success,
    '--snack-bar-text': $dark200,
    // copy command
    '--copy-bg': $main_yellow,
    '--copy-text': $mainWhite,
    // Analytics severity
    '--severity-high': $severity_high,
    '--severity-medium': $severity_medium,
    '--severity-low': $severity_low,
    '--severity-cost': $severity_cost,
    '--severity-cost-ops': $severity_cost_ops,

    '--severity-high-fill': $severity_high,
    '--severity-high-fill-1': $severity_high_25,
    '--severity-high-fill-2': $severity_high_50,
    '--severity-high-fill-3': $severity_high_75,
    '--severity-high-fill-4': $severity_high,

    '--severity-medium-fill': $severity_medium,
    '--severity-medium-fill-1': $severity_medium_25,
    '--severity-medium-fill-2': $severity_medium_50,
    '--severity-medium-fill-3': $severity_medium_75,
    '--severity-medium-fill-4': $severity_medium,

    '--severity-low-fill': $severity_low,
    '--severity-low-fill-1': $severity_low_25,
    '--severity-low-fill-2': $severity_low_50,
    '--severity-low-fill-3': $severity_low_75,
    '--severity-low-fill-4': $severity_low,

    '--severity-cost-fill': $severity_cost,
    '--severity-cost-fill-1': $severity_cost_25,
    '--severity-cost-fill-2': $severity_cost_50,
    '--severity-cost-fill-3': $severity_cost_75,
    '--severity-cost-fill-4': $severity_cost,

    '--severity-box-empty': $severity_box_light,

    '--severity-high-ops': $severity_high_ops,
    '--severity-medium-ops': $severity_medium_ops,
    '--severity-low-ops': $severity_low_ops,
    '--severity-high-bg': $mainWhite,
    '--severity-medium-bg': $mainWhite,
    '--severity-low-bg': $mainWhite,
    '--severity-high-bg-open': $mainWhite,
    '--severity-medium-bg-open': $mainWhite,
    '--severity-low-bg-open': $mainWhite,
    '--severity-cost-bg-open': $mainWhite,
    '--severity-high-border': $severity_high,
    '--severity-medium-border': $severity_medium,
    '--severity-low-border': $severity_low,
    '--severity_close_bg': $severity_close_light_bg,
    // spinner
    '--spinner-color': $lightText,
    '--spinner-color-progress': $main_blue_100,
    // icon
    '--icon-color-shipping': $mainDark,
    '--icon-color-shipping-helm': $helmIcon,
    '--icon-color-shipping-deb': $DebIcon,
    '--icon-color': $dark450,
    '--icon-color-secondary': $dark180,
    '--icon-border': $transparent,
    '--icon-circle': $yellow200,
    // skeleton
    '--skeleton-color': $light_skeleton,
    // read more
    '--read-more-bg': $light250,
    // shard view colors
    '--shard-view-color-1': $heat_map_1,
    '--shard-view-color-2': $heat_map_2,
    '--shard-view-color-3': $heat_map_3,
    '--shard-view-color-4': $heat_map_4,
    '--shard-view-color-5': $heat_map_5,
    '--shard-view-color-6': $heat_map_6,
    '--shard-view-color-7': $heat_map_7,
    '--shard-view-color-8': $heat_map_8,
    '--shard-view-color-9': $heat_map_9,
    '--shard-view-color-10': $heat_map_10,
    // cluster card
    '--cluster-card-selected-background': $selectedBg1,
    '--chip-background': $light_border_100,
    '--cluster-chip-background': $light100,
    '--cluster-chip-text-color': $lightText,
    '--cluster-card-text-color': $lightTextOpacity70,
    // event card
    '--card-bg': $mainWhite,
    '--card-border': $gray950,
    '--card-text-secondary': $dark365,
    '--card-count-bg': $dark360,
    '--card-bg-gradient-1': $main_blue_90_opacity,
    '--card-bg-gradient-2': $light480,
    // info card
    '--info-card-main-blue': $main_blue_250,
    '--info-card-sub-blue': $main_blue_90_opacity,
    '--info-card-sub-blue-2': $main_blue_90,
    '--info-card-sub-gray': $gray940,
    '--info-card-sub-dark-gray': $dark365,
    '--info-card-text-link': $main_blue_220,
    '--info-card-text-note': $main_blue_250,
    '--info-card-copy-icon': $dark355,
    '--info-card-warning-icon': $red200,
    '--info-card-progress-border': $main_blue_250,
    '--info-card-progress-bg': $main_blue_50,
    '--info-card-progress-gradient-1': $main_blue_50,
    '--info-card-progress-gradient-2': $main_blue_50,
    // empty state box
    '--empty-state-box-bg': $light130,
    // radio
    '--radio-inner-color': $main_blue_200,
    '--radio-outer-color': $main_blue_200,
    '--radio-outer-color-unchecked': $main_blue_200,
  },
  darkTheme: {
    // main
    '--app-bg': $mainDark,
    '--app-bg-opacity-60': $mainDark_opacity_60,
    '--main-text': $mainWhite,
    '--main-text-opacity-50': $mainWhiteOpacity50,
    '--main-text-opacity-30': $mainWhiteOpacity30,
    '--main-sub-text': $light350,
    '--main-sub-text-light': $mainWhite,
    '--main-sub-text-dark': $mainWhite,
    '--main-sub-text-link': $mainWhite,
    // tooltip
    '--tooltip-bg': $dark_tooltip,
    '--tooltip-color': $light100,
    // toggle
    '--slide-toggle': $light600,
    '--slide-toggle-opacity': $light600,
    // wizard
    '--wizard-bg': $lightText,
    // input
    '--input-label': $light580_opacity,
    '--input-bg': $light100,
    '--input-bg2': $dark380,
    '--input-bg-disabled': $dark_input_disabled,
    '--input-search-bg': $dark450,
    '--input-distribution-bg': $dark390,
    '--input-dropdown-search-bg': $dark380,
    '--input-refresh-bg': $dark350,
    '--input-divider-text': $dark350,
    '--input-refresh-border': $dark350,
    '--input-search-border': $dark150,
    '--input-search-icon': $dark450,
    '--space-border': $light350,
    // dialog
    '--dialog-bg': $dark450,
    '--dialog-title-color': $mainWhite,
    '--dialog-key-text': $dark20,
    '--dialog-action-bg': $dark300,
    '--dialog-icon': $light350_opacity,
    '--dialog-icon-close': $mainWhite,
    // divider
    '--divider-color': $dark_divider,
    // chart
    '--grid-line': $dark100,
    '--grid-line-opacity': $dark150,
    '--chart-tooltip': $mainDark,
    '--chart-text-hover': $mainWhite,
    '--chart-gauge-color': $main_yellow,
    '--chart-color-gray': $light400,
    '--chart-color-green': $greenChart,
    '--chart-color-yellow': $yellow100,
    '--chart-unit': $light350,
    '--chart-bg': $dark450,
    '--gauge-bg': $dark500,
    '--message-box': $main_blue_150,
    '--chart-overlay': $dark500_opacity,
    // legend
    '--legend-item-hidden-color': $light_shadows100,
    '--legend-navigation-inactive-color': $light600,
    '--legend-navigation-active-color': $mainWhite,
    // crosshair
    '--crosshair-color': $mainWhite,
    // button
    '--button-settings-bg': $gray1000,
    '--button-settings-fill': $dark450,
    '--button-yellow-bg': $main_yellow,
    '--button-yellow-text': $dark450,
    '--button-text': $light350,
    '--button-time-bg': $severity_medium,
    '--button-time-text': $dark450,
    '--button-gray-bg': $dark450,
    '--button-light-bg': $light120,
    '--button-gray-text': $mainWhite,
    '--button-gray-border': $gray_border,
    '--button-video-bg': $mainDark,
    '--button-video-play-bg': $mainDark,
    '--button-video-play-line': $mainWhite,
    '--button-video-play-line-timestamp': $main_yellow,
    '--button-expand-chevron': $dark460,
    // border
    '--border': $dark450,
    '--border-input': $dark_border_200,
    '--border-box': $dark300,
    // side-menu
    '--side-menu-bg': $dark450,
    '--side-menu-text': $light350,
    '--side-menu-selected': $dark480,
    '--side-menu-selected-border': $main_yellow,
    // menu
    '--menu-bg': $dark200,
    '--menu-text': $mainWhite,
    '--main-success-text': $success,
    // menu-top
    '--menu-top-bg': $dark200,
    '--menu-top-shadow': $dark_shadows400,
    '--menu-top-text': $mainWhite,
    // panel
    '--panel-bg': $dark200,
    '--panel-table-bg': $dark450,
    '--panel-shadow': $dark_shadows400,
    '--panel-sub-bg': $dark150,
    '--panel-icon-bg': $dark350,
    '--panel-expanded-bg': $dark200,
    '--panel-expanded-footer-bg': $dark400,
    '--panel-expanded-footer-text': $dark50,
    '--panel-header': $light850,
    '--panel-header-icon': $mainWhite,
    '--panel-header-blue': $mainDark,
    '--panel-text': $dark200,
    '--panel-subtitle': $light100,
    '--panel-open-close-bg': $dark150,
    '--panel-open-close-text': $mainWhite,
    '--panel-open-close-arrow': $mainWhite,
    '--panel-open-close-disabled': $mainWhiteOpacity30,
    // dropdown
    '--dropdown-selected': $main_yellow,
    '--dropdown-hover': $main_yellow_opacity,
    // multi-select
    '--group-list-text': $mainWhiteOpacity70,
    // toolbar-header
    '--toolbar-header-bg': $dark200,
    '--toolbar-header-text': $light350,
    // table
    '--table-header-text': $light650,
    '--table-header-bg': $dark300,
    '--table-header-border': $dark_border_200,
    '--table-header-shadows': $dark_shadows400,
    '--table-header-filter-active': $blueActiveOpacity,
    '--table-button-icon-hover': $light350,
    '--table-border-color': $dark500,
    '--table-border-color-hover': $yellow400,
    '--table-row-active-bg': $dark500,
    '--table-row-inactive-bg': $dark450,
    // chart-toolbar-header
    '--chart-toolbar-header-text': $mainWhite,
    '--chart-msg-box': $main_blue_120,
    // panel-content
    '--panel-content-bg': $dark450,
    '--panel-content-bg-promo': $promoBg,
    '--panel-content-text-promo': $mainDark,
    '--panel-content-table': $dark450,
    '--panel-content-item': $dark450,
    '--panel-content-item-card': $mainDark,
    '--panel-content-item-card-benefits': $dark350,
    '--panel-content-table-group': $gray400,
    '--panel-content-table-group-opacity': $gray400_opacity,
    '--panel-content-sidebar': $dark450,
    '--panel-content-hover': $light600_opacity,
    '--panel-content-shadows': $dark_shadows300,
    '--panel-content-sub-bg': $mainDark,
    '--panel-content-bg-icon': $dark410,
    '--panel-content-sub-border': $light350,
    '--panel-content-sub-curl': $dark150,
    '--panel-content-sub-curl-link': $main_blue_link_light,
    '--panel-content-sub-curl-text': $mainWhite,
    '--panel-content-tabs': $dark300,
    '--panel-content-tabs-settings': $dark450,
    '--panel-content-tab-active': $dark450,
    '--panel-content-sub-bg-opacity': $dark300_opacity,
    '--panel-content-sub-time': $dark50,
    '--panel-content-text': $mainWhite,
    '--panel-content-sub': $mainDark,
    '--panel-content-star': $main_blue_100,
    // analytics
    '--analytics-reco': $gray400,
    '--analytics-reco-text': $mainWhite,
    '--analytics-reco-gutters': $dark150,
    '--analytics-expander-open': $main_yellow,
    '--analytics-expander-close': $dark_border_50,
    // tags
    '--tag-bg': $dark_tag,
    '--tag-text': $mainWhite,
    '--cluster-tag': $severity_medium_ops,
    '--cluster-tag-text': $main_yellow,
    // gpt
    '--gpt-bg': $dark450,
    '--gpt-name-bg': $dark450,
    '--gpt-name-text': $main_yellow,
    '--gpt-name-border': $main_yellow,
    '--gpt-arrow': $main_yellow,
    // checkbox
    '--checkbox-bg': $main_blue_200,
    '--checkbox-icon': $main_blue_200,
    '--checkbox-border': $dark_border_50,
    // snack-bar
    '--snack-bar-error-bg': $error,
    '--snack-bar-success-bg': $success,
    '--snack-bar-text': $dark200,
    // copy command
    '--copy-bg': $main_yellow,
    '--copy-text': $mainWhite,
    // Analytics severity
    '--severity-high': $severity_high_ops,
    '--severity-medium': $severity_medium_ops,
    '--severity-low': $severity_low_ops,
    '--severity-cost': $severity_cost_ops,
    '--severity-cost-ops': $severity_cost_ops,

    '--severity-high-fill': $severity_high,
    '--severity-high-fill-1': $severity_high_25,
    '--severity-high-fill-2': $severity_high_50,
    '--severity-high-fill-3': $severity_high_75,
    '--severity-high-fill-4': $severity_high,

    '--severity-medium-fill': $severity_medium,
    '--severity-medium-fill-1': $severity_medium_25,
    '--severity-medium-fill-2': $severity_medium_50,
    '--severity-medium-fill-3': $severity_medium_75,
    '--severity-medium-fill-4': $severity_medium,

    '--severity-low-fill': $severity_low,
    '--severity-low-fill-1': $severity_low_25,
    '--severity-low-fill-2': $severity_low_50,
    '--severity-low-fill-3': $severity_low_75,
    '--severity-low-fill-4': $severity_low,

    '--severity-cost-fill': $severity_cost,
    '--severity-cost-fill-1': $severity_cost_25,
    '--severity-cost-fill-2': $severity_cost_50,
    '--severity-cost-fill-3': $severity_cost_75,
    '--severity-cost-fill-4': $severity_cost,

    '--severity-box-empty': $severity_box_dark,

    '--severity-high-ops': $severity_high_ops,
    '--severity-medium-ops': $severity_medium_ops,
    '--severity-low-ops': $severity_low_ops,
    '--severity-high-bg': $gray400,
    '--severity-medium-bg': $gray400,
    '--severity-low-bg': $gray400,
    '--severity-high-bg-open': $severity_high,
    '--severity-medium-bg-open': $severity_medium,
    '--severity-low-bg-open': $severity_low,
    '--severity-cost-bg-open': $severity_cost,
    '--severity-high-border': $transparent,
    '--severity-medium-border': $transparent,
    '--severity-low-border': $transparent,
    '--severity_close_bg': $severity_close_dark_bg,
    // spinner
    '--spinner-color': $mainWhite,
    '--spinner-color-progress': $mainWhite,
    '--spinner-white-color': $light350,
    // icon
    '--icon-color-shipping': $mainWhite,
    '--icon-color-shipping-helm': $mainWhite,
    '--icon-color-shipping-deb': $mainWhite,
    '--icon-color': $light350,
    '--icon-color-secondary': $mainWhite,
    '--icon-border': $mainWhite,
    '--icon-circle': $transparent,
    // skeleton
    '--skeleton-color': $dark_skeleton,
    // read more
    '--read-more-bg': $dark150,
    // shard view colors
    // shard view colors
    '--shard-view-color-1': $heat_map_1_ops,
    '--shard-view-color-2': $heat_map_2_ops,
    '--shard-view-color-3': $heat_map_3_ops,
    '--shard-view-color-4': $heat_map_4_ops,
    '--shard-view-color-5': $heat_map_5_ops,
    '--shard-view-color-6': $heat_map_6_ops,
    '--shard-view-color-7': $heat_map_7_ops,
    '--shard-view-color-8': $heat_map_8_ops,
    '--shard-view-color-9': $heat_map_9_ops,
    '--shard-view-color-10': $heat_map_10_ops,
    // cluster card
    '--cluster-card-selected-background': $selectedBg2,
    '--chip-background': $severity_close_dark_bg,
    '--cluster-chip-background': $severity_close_dark_bg,
    '--cluster-chip-text-color': $light100,
    '--cluster-card-text-color': $lightTextOpacity70,
    // event card
    '--card-bg': $dark450,
    '--card-border': $dark450,
    '--card-text-secondary': $mainWhite,
    '--card-count-bg': $dark500,
    '--card-bg-gradient-1': $gray930,
    '--card-bg-gradient-2': $dark450,
    // info card
    '--info-card-main-blue': $main_blue_250,
    '--info-card-sub-blue': $dark410,
    '--info-card-sub-blue-2': $main_blue_90_opacity,
    '--info-card-sub-gray': $mainWhite,
    '--info-card-sub-dark-gray': $light350,
    '--info-card-text-link': $main_blue_120,
    '--info-card-text-note': $dark450,
    '--info-card-copy-icon': $light350,
    '--info-card-warning-icon': $red100,
    '--info-card-progress-border': $main_blue_120,
    '--info-card-progress-bg': $dark500,
    '--info-card-progress-gradient-1': $main_blue_50,
    '--info-card-progress-gradient-2': $main_blue_50,
    // empty state box
    '--empty-state-box-bg': $dark450,
    // radio
    '--radio-inner-color': $main_blue_200,
    '--radio-outer-color': $mainWhite,
    '--radio-outer-color-unchecked': $mainWhiteOpacity70,
  }
};
