import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import get from 'lodash-es/get';
import { GlobalService } from '../../../../../store/global/global.service';
import { GptPayload } from '../../../../../features/ops-gpt/ops-gpt.interface';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'gpt-section',
  templateUrl: './gpt-section.component.html',
  styleUrls: ['./gpt-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GptSectionComponent implements OnInit, OnChanges {
  @Input() eventName: string;
  @Input() eventType: string;
  @Input() cluster: string;
  @Input() analysisId: number;

  @Input() openedAt: number;
  @Input() closedAt: number;

  public gptAsks = [];
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private globalService: GlobalService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'eventName.currentValue')) {
      this.gptAsks.push(`What does "${this.eventName}" mean and what impact does it have?`);
      this.gptAsks.push(`How can I resolve "${this.eventName}"?`);
    }
  }

  askTemplate(ask) {
    const gptPayload: GptPayload = {
      display: true,
      typesToInclude: [this.eventType],
      prompt: ask,
      clusters: [this.cluster],
      closedAnalysisTimeRange: {from: this.openedAt, to: this.closedAt},
      analysisIds: [this.analysisId]
    };

    this.globalService.setQuickOpsGpt(gptPayload);
  }

}
