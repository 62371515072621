import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpsterMarkdowmComponent } from './opster-markdowm.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';




@NgModule({
  declarations: [
    OpsterMarkdowmComponent
  ],
  imports: [
    CommonModule,
    MarkdownModule.forChild(),
    MatIconModule,
    TooltipModule,
  ],
  providers: [],
  exports: [OpsterMarkdowmComponent]
})
export class OpsterMarkdownModule {
}
