import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SideMenuComponent} from './side-menu.component';
import {TranslateModule} from '@ngx-translate/core';

import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TooltipModule} from 'primeng/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {NewClusterConnectedModule} from '../../shared/components/new-cluster-connected/new-cluster-connected.component';
import {BadgeModule} from 'primeng/badge';
import {DialogModule} from 'primeng/dialog';

@NgModule({
  declarations: [
    SideMenuComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        TranslateModule,
        MatSlideToggleModule,
        TooltipModule,
        MatDividerModule,
        NewClusterConnectedModule,
        BadgeModule,
        DialogModule,
    ],
  providers: [],
  exports: [SideMenuComponent]
})
export class SideMenuModule {
}
