<h1 mat-dialog-title> {{title}}
  <mat-icon mat-dialog-close (click)="onCancel(this.dialogButton.cancel)">close</mat-icon>
</h1>
<div mat-dialog-content class="content">
  <div class="dismiss-form">
    <p *ngIf="message" [innerHTML]="message"></p>

    <div class="field-form small" *ngIf="!isReactive" style="margin: 20px 0 60px">
      <label>Clusters:</label>

      <p-multiSelect [styleClass]="'opster-multiSelect lg-width'" [options]="clusterList" #multiSelect
                     [panelStyleClass]="'opster-panel'" [formControl]="selectedCluster"
                     [showToggleAll]="false" [showHeader]="false">
        <ng-template pTemplate="selectedItems">
          {{selectedCluster?.value?.length === clusterList?.length ? 'All' : (selectedCluster?.value?.length ? selectedCluster.value[0].clusterName : 'No clusters')}}
          <span *ngIf="selectedCluster.value?.length > 1 && selectedCluster.value?.length !== clusterList?.length"
                class="example-additional-selection">
        (+{{selectedCluster.value.length - 1}} {{selectedCluster.value?.length === 2 ? 'other' : 'others'}})
      </span>
        </ng-template>
        <ng-template let-cluster pTemplate="item">
          <span class="wrapped-text">
            {{cluster?.clusterAlias}} ({{cluster?.cluster}})
          </span>
        </ng-template>
      </p-multiSelect>
    </div>

    <p class="noteMessage">
      {{note}}
    </p>

  </div>
</div>
<div mat-dialog-actions>
  <div>
    <button mat-button mat-dialog-close
            (click)="onCancel(this.dialogButton.cancel)">{{'confidence.dialog.delete.cancel' | translate}}</button>

    <button mat-stroked-button class="button-opster yellow" [mat-dialog-close]="true"
            (click)="onDismiss()">
      <mat-icon *ngIf="dialogButton.ok.icon" svgIcon="save_new_icon"></mat-icon>
      {{dialogButton.ok.label | translate}}
    </button>

  </div>

</div>
