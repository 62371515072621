<div class="sidebar-panel ops-gpt-section">
  <div class="panel-gpt">

    <p class="panel-title">
      <mat-icon class="gpt" svgIcon="ops_gpt"></mat-icon>
      <span>Chat with OpsGPT</span>
      <span  class="beta small">NEW</span>
    </p>

<!--    <p class="top-chat-gpt small" (click)="openOpsGpt()">-->
<!--      <span class="ops-gpt-text">OpsGPT</span>-->
<!--    </p>-->

  </div>

  <div class="panel-content gpt-templates">
    <div *ngFor="let ask of gptAsks; trackBy: PerformanceUtils.trackByIndex" class="gpt-ask" (click)="askTemplate(ask)">
      {{ask}}
      <mat-icon class="arrow_ask" [svgIcon]="'arrow_ask'"></mat-icon>
    </div>
  </div>
</div>
