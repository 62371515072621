import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import get from 'lodash-es/get';
import {TemplateResult, TypeTemplate} from '../../../../../features/template-optimizer/template-optimizer.interface';
import isEqual from 'lodash-es/isEqual';
import find from 'lodash-es/find';
import size from 'lodash-es/size';
import map from 'lodash-es/map';
import first from 'lodash-es/first';

@Component({
  selector: 'field-names',
  templateUrl: './field-names.component.html',
  styleUrls: ['./field-names.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldNamesComponent implements OnInit, OnChanges {
  @Input() item: TemplateResult;
  @Input() type: TypeTemplate;

  public settingNames: string;
  public fieldNames: string;
  public arrFieldNames;
  public selectionFieldNames;
  public selectionFieldNamesPosition = 0;

  @Output() setSelectionOnEditor = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.arrFieldNames = [];
    if (get(changes, 'item')) {
      this.fieldNames = get(this.item, 'fieldNames');
      this.settingNames = get(this.item, 'settingNames');

      map(this.fieldNames, (name, index) => {

        if (size(name)) {
          let rootPath;
          let namePath;

          if (isEqual(this.type, TypeTemplate.Index) || isEqual(this.type, TypeTemplate.Component)) {
            rootPath = 'template';
            namePath = 'mappings' + name;
          } else {
            rootPath = 'mappings';
            namePath = name;
          }

          this.arrFieldNames.push({id: index, name: namePath, root: rootPath});
        }
      });

      map(this.settingNames, (name, index) => {
        if (size(name)) {
          let rootPath;
          let namePath;

          if (isEqual(this.type, TypeTemplate.Index) || isEqual(this.type, TypeTemplate.Component)) {
            rootPath = 'template';
            namePath = 'settings/' + name;

          } else {
            rootPath = 'settings';
            namePath = name;
          }

          this.arrFieldNames.push({id: index, name: namePath, root: rootPath});
        }
      });

      this.selectionFieldNames = [first(this.arrFieldNames)];
      this.selectionChange(this.selectionFieldNames);
      this.cdRef.markForCheck();
    }
  }

  selectionChange(val) {
    this.selectionFieldNames = val;
    this.selectionFieldNamesPosition = get(first(this.selectionFieldNames), 'id');
    this.setSelectionOnEditor.emit(val);
    this.cdRef.markForCheck();
  }

  changeFieldNames(isNext) {
    const currentFieldNames = first(this.selectionFieldNames);
    const item = find(this.arrFieldNames, {id: (get(currentFieldNames, 'id') + (isNext ? 1 : (-1)))});
    if (item) {
      this.selectionFieldNames = [item];
      this.selectionChange(this.selectionFieldNames);
    }
    this.cdRef.markForCheck();
  }

}
