import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';
import split from 'lodash-es/split';

@Component({
  selector: 'app-supplementary-info',
  templateUrl: './supplementary-info.component.html',
  styleUrls: ['./supplementary-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplementaryInfoComponent implements OnInit {

  public rendered;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.rendered =  split(get(get(this.data, 'element'), 'rendered'), '<a').join('<a target="_blank"');
  }

}
