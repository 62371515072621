<div class="chart-wrapper" style="display: flex;height: 100%;position: relative">
  <div #container
       [attr.id]="containerId" style="  height: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
  width: 100%"
       [hidden]="!data || data.length < 1"></div>
  <div class="content-wrapper" style="display: flex;height: 100%;">
    <ng-content></ng-content>
  </div>
</div>
