import { Component, OnInit, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-external-title-panel',
  templateUrl: './external-title-panel.component.html',
  styleUrls: ['./external-title-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalTitlePanelComponent implements OnInit {
  @Input() className;
  @Input() minWidth;
  @Input() promo;

  constructor() {
  }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [ExternalTitlePanelComponent],
  imports: [
    MatCardModule,
    CommonModule
  ],
  exports: [ExternalTitlePanelComponent]
})
export class ExternalTitlePanelModule {
}
