export enum AppRoutes {
  login = 'login',
  dashboard = 'dashboard',
  clusterView = 'clusterView',
  ops_gpt  = 'ops_gpt',
  indexView = 'indexView',
  nodeView = 'nodeView',
  shardsView = 'shardsView',
  slowLogs = 'slowLogs',
  templateOptimizer = 'templateOptimizer',
  reports = 'reports',
  settings= 'settings',
  user = 'user',
  account = 'account',
  clusters = 'clusters',
  teamManagement = 'team_management',
  fetching = 'fetching',
  customizeEvents = 'customize_events',
  dismissEvents = 'dismiss_events',
  notifications = 'notifications',
  notificationsHistory = 'notifications',
  shipping = 'shipping',
  docker = 'docker',
  kubernetes = 'kubernetes',
  rpmPackage = 'rpm-package',
  debPackage = 'deb-package',
  dockerSwarm = 'docker-swarm',
  dockerCompose = 'docker-compose',
  windows = 'windows',
  tokens = 'tokens',
  helm = 'helm',
  installation = 'installation',
  installation_environment = 'installation/environment',
  installation_configuration = 'installation/configuration',
  environment = 'environment',
  configuration = 'configuration',
  command = 'command'
}
