import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Analysis,
  AnalysisClose,
  Health,
  HealthActivities,
  HealthDetails,
  Indications
} from '../../../shared/components/analytics/health.interface';
import { share, shareReplay } from 'rxjs/operators';
import { CostInsightSettings } from '../../../shared/constants/cost-insight.constant';
import { getHealthsMock2 } from './events-over-time.mock';
import { EVENTS_NUMBER_FORMAT, UNITS_NUMBER_FORMAT } from '../../node-view/eventsNumberFormat';
import map from 'lodash-es/map';
import get from 'lodash-es/get';
import split from 'lodash-es/split';
import capitalize from 'lodash-es/capitalize';
import join from 'lodash-es/join';
import includes from 'lodash-es/includes';

@Injectable({
  providedIn: 'root'
})
export class ClusterViewService {
  private groupedToggle$: BehaviorSubject<boolean>;
  private openCostEvent$: Subject<Health>;

  constructor(private httpClient: HttpClient) {
    this.groupedToggle$ = new BehaviorSubject<boolean>(false);
    this.openCostEvent$ = new Subject<Health>();
  }

  // Analyzer
  public getAnalysis(clusterId, indices, nodeIds, isOpen, offset, size, from, to, type?): Observable<AnalysisClose[]> {
    const body = {
      open: isOpen,
      nodeIds: nodeIds ? map(nodeIds, 'id') : null,
      indices: indices ? map(indices, 'id') : null,
      sortType: 'BY_TIME'
    };
    let url = `api/analyzer/clusters/${clusterId}/analysis`;
    url += `?offset=${offset || 0}&size=${size}`;
    if (type) {
      url += `&type=${type}`;
    }
    if (from) {
      url += `&from=${from}`;
    }
    if (to) {
      url += `&to=${to}`;
    }
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getAnalysisOpenEventsExpand(clusterId, offset, size, from, to, type?): Observable<AnalysisClose[]> {
    const body = {
      nodeIds: null,
      type,
      from,
      to,
      size,
      offset: offset || 0
    };
    const url = `api/analyzer/clusters/${clusterId}/open-events`;
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getAnalysisEventsHistoryExpand(clusterId, offset, size, from, to, type?): Observable<AnalysisClose[]> {
    const body = {
      nodeIds: null,
      type,
      from,
      to,
      size,
      offset: offset || 0
    };
    const url = `api/analyzer/clusters/${clusterId}/closed-events`;
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getEventsOverTime(isMock, clusterId, offset, size, from, to, type?): Observable<any> {
    if (isMock) {
      return of(getHealthsMock2);
    }
    const body = {};
    let url = `api/analyzer/clusters/${clusterId}/events-over-time?offset=${offset || 0}&size=${size}`;
    if (type) {
      url += `&type=${type}`;
    }
    if (from) {
      url += `&from=${from}`;
    }
    if (to) {
      url += `&to=${to}`;
    }
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getEventsOverTimeTest(clusterId, from, to, severities: string[], bucketSize): Observable<AnalysisClose[]> {
    const body = {
      from,
      to,
      bucketSize: bucketSize,
      severity: severities
    };
    const url = `api/analyzer/clusters/${clusterId}/v2/events-over-time`;

    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getAnalysisGrouping(clusterId, indices, nodeIds, isOpen, offset, sizeAnalysis, from?, to?): Observable<Analysis[]> {
    const body = {
      open: isOpen,
      nodeIds: nodeIds ? map(nodeIds, 'id') : null,
      indices: indices ? map(indices, 'id') : null,
      sortType: 'BY_SEVERITY'
    };
    let url = `api/analyzer/clusters/${clusterId}/analysis-type`;
    url += `?offset=${offset}&size=${sizeAnalysis}`;
    if (from) {
      url += `&from=${from}`;
    }
    if (to) {
      url += `&to=${to}`;
    }
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getAnalysisOpenEvents(clusterId, from?, to?): Observable<Analysis[]> {
    const body = {
      from,
      to
    };
    const url = `api/analyzer/clusters/${clusterId}/open-event-groups`;
    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public getAnalysisEventsHistory(clusterId, from?, to?): Observable<Analysis[]> {
    const body = {
      from,
      to
    };
    const url = `api/analyzer/clusters/${clusterId}/closed-event-groups`;

    return this.httpClient.post<any>(url, body).pipe(share());
  }

  public setGroupedToggle(val) {
    this.groupedToggle$.next(val);
  }

  public getGroupedToggle() {
    return this.groupedToggle$.value;
  }

  // Customize Events
  public getCustomizeEvents(includePattern, excludePattern, analysisType, patternId): Observable<any> {
    if (patternId) {
      return this.httpClient.get<any>(`api/analyzer/customize/pattern/${patternId}/${includePattern}/${excludePattern}/analysis/${analysisType}`)
        .pipe(share());
    } else {
      return this.httpClient.get<any>(`api/analyzer/customize/pattern/${includePattern}/${excludePattern}/analysis/${analysisType}`)
        .pipe(share());
    }

  }

  public getCustomizeEventsByCluster(clusterId, analysisType): Observable<any> {
    return this.httpClient.get<any>(`api/analyzer/customize/clusters/${clusterId}/analysis/${analysisType}`)
      .pipe(share());

  }

  public getAllCustomizedEvents(): Observable<any> {
    return this.httpClient.get<any>(`api/analyzer/customize`)
      .pipe(share());
  }

  public setCustomizeEvents(body): Observable<any> {
    return this.httpClient.post<any>(`api/analyzer/customize/account`, body)
      .pipe(share());

  }

  public resetToDefault(body): Observable<any> {
    return this.httpClient.post<any>(`api/analyzer/customize/account/reset`, body)
      .pipe(share());
  }

  // Dismiss Events
  public getDismissAbleForCluster(clusterId) {
    return this.httpClient.get<any>(`api/clusters/${clusterId}/dismiss-able`)
      .pipe(share());
  }

  public getDismissAbleForAccount() {
    return this.httpClient.get<any>(`api/analyzer/dismiss-able`)
      .pipe(share());
  }

  public setDismiss(body) {
    return this.httpClient.post<any>(`api/analyzer/dismiss`, body)
      .pipe(share());
  }

  // Cost Calculate
  public getCostCalculate(clusterId, from, to) {
    return this.httpClient.get<any>(`api/analyzer/insight/cluster/${clusterId}/cost/calculate?from=${from}&to=${to}`)
      .pipe(share());
  }

  // Analysis Details
  public getAnalysisDetails(clusterId, analysisId): Observable<HealthDetails> {
    return this.httpClient.get<any>(`api/analyzer/clusters/${clusterId}/analysis/${analysisId}`).pipe(share());
  }

  // cluster-health
  public getClusterHealth(clusterId, body): Observable<any> {
    return this.httpClient.post<any>(`api/analyzer/clusters/${clusterId}/score`, body).pipe(share());
  }

  // cluster-activity
  public getClusterActivity(clusterId, body): Observable<HealthActivities> {
    return this.httpClient.post<any>(`api/monitoring/clusters/${clusterId}/activity`, body).pipe(share());
  }

  // cluster-info
  public getClusterInfo(clusterId): Observable<any> {
    return this.httpClient.get<any>(`api/monitoring/clusters/${clusterId}/cluster_info`).pipe();
  }

  // cluster-indicators
  public getIndications(clusterId, size = 20, offset = 0, from, until = 0): Observable<Indications[]> {
    return this.httpClient.get<any>(`api/analyzer/clusters/${clusterId}/indications?size=${size}&offset=${offset}&from=${from}&until=${until}`).pipe(share());
  }

  // cost-insight
  public getDefaults(): Observable<any> {
    return this.httpClient.get<any>(`api/analyzer/insight/defaults`).pipe(share());
  }

  public getCostInsightSettings(clusterId): Observable<CostInsightSettings> {
    return this.httpClient.get<any>(`api/analyzer/insight/cluster/${clusterId}/cost/settings`).pipe(share());
  }

  public saveCostInsightSettings(body: CostInsightSettings, clusterId: string, updateAllClusters: boolean): Observable<any> {
    return this.httpClient.post<any>(`api/analyzer/insight/cluster/${clusterId}/cost/settings?submitToAllClusters=${updateAllClusters}`, body).pipe(share());
  }

  public updateCostInsightSettings(body: CostInsightSettings, clusterId: string, updateAllClusters: boolean): Observable<any> {
    return this.httpClient.put<any>(`api/analyzer/insight/cluster/${clusterId}/cost/settings?submitToAllClusters=${updateAllClusters}`, body).pipe(share());
  }

  public getVendorInstanceTypes(vendor: string): Observable<string[]> {
    return this.httpClient.get<any>(`api/analyzer/insight/instance?vendor=${vendor}`).pipe(share());
  }

  public setCostEventOpened(health: Health) {
    this.openCostEvent$.next(health);
  }

  public getCostEventOpened(): Observable<Health> {
    return this.openCostEvent$.asObservable().pipe(shareReplay());
  }

  public buildSidebarGraphData(graph) {
    try {
      if (!graph) {
        return null;
      }

      const metric = get(graph, 'metric');
      let numberFormat;
      const unit = get(metric, 'unit');
      numberFormat = UNITS_NUMBER_FORMAT(unit);
      if (!unit) {
        numberFormat = EVENTS_NUMBER_FORMAT(get(metric, 'metricName'));
      }

      let graphTitle = get(metric, 'graphTitle');
      if (!graphTitle) {
        const metricName = get(metric, 'metricName');
        if (includes(metricName, '_')) {
          graphTitle = join(map(split(metricName, '_'), x => capitalize(x)), ' ');
        } else {
          graphTitle = metricName;
        }
      }

      return {
        metrics: get(metric, 'metrics'),
        metricName: graphTitle,
        y: get(graph, 'line') ? [get(get(graph, 'line'), 'metrics')] : null,
        numberFormat: numberFormat
      };
    } catch (e) {
      console.log(e);
    }
  }

}
