export const GET_EVENT_TYPE_URL = (eventType) => {
  const urls: any = {
    STATUS_YELLOW:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-yellow-status/',
    STATUS_RED:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-red-status/',
    DISK_WATERMARK_LOW_THRESHOLD:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-low-disk-watermark/',
    DISK_WATERMARK_LOW:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-low-disk-watermark/',
    LOW_WATERMARK_REACHED:
      'https://opster.com/elasticsearch/elasticsearch-low-disk-watermark/',
    SHARD_TOO_SMALL:
      'https://opster.com/elasticsearch/elasticsearch-oversharding/',
    HEAP_SIZE:
      'https://opster.com/elasticsearch/elasticsearch-heap-size-usage/',
    CLUSTER_BLOCKS_READ_ONLY_ALLOW_DELETE:
      'https://opster.com/elasticsearch/cluster-blocks-read-only/',
    BLOCKS_READ_ONLY_ALLOW_DELETE:
      'https://opster.com/elasticsearch/cluster-blocks-read-only/',
    CLUSTER_BLOCKS_READ_ONLY:
      'https://opster.com/elasticsearch/cluster-blocks-read-only/',
    BLOCKS_READ_ONLY:
      'https://opster.com/elasticsearch/cluster-blocks-read-only/',
    MIN_MASTER_NODE_HIGHER_THAN:
      'https://opster.com/elasticsearch/elasiticsearch-minimum-master-nodes-quorum/',
    // tslint:disable-next-line:max-line-length
    MIN_MASTER_NODE_HIGHER_THAN_ELIGIBLE:
      'https://opster.com/elasticsearch/elasticsearch-minimum-master-nodes-quorum/',
    DISK_WATERMARK_FLOOD_STAGE:
      'https://opster.com/elasticsearch/elasticsearch-flood-stage-disk-watermark/',
    SHARD_TOO_LARGE:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-shards-too-large-shard-sizes/',
    DISK_WATERMARK_HIGH:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-high-disk-watermark/',
    HIGH_WATERMARK_REACHED:
      'https://opster.com/elasticsearch/elasticsearch-high-disk-watermark/',
    DEDICATED_MASTER_NODES:
      'https://opster.com/elasticsearch/elasticsearch-dedicated-master-node/',
    DEDICATED_CLIENT_NODES:
      'https://opster.com/elasticsearch/elasticsearch-dedicated-client-node/',
    MASTER_NOT_DISCOVERED:
      'https://opster.com/elasticsearch/elasticsearch-master-node-not-discovered/',
    DATA_PATH_IS_DEFAULT:
      'https://opster.com/elasticsearch/elasticsearch-dangerous-default-settings/',
    CLUSTER_NAME:
      'https://opster.com/elasticsearch/elasticsearch-dangerous-default-settings/',
    NODE_DISCONNECTED:
      'https://opster.com/elasticsearch/elasticsearch-node-disconnected/',
    INDEX_BURST:
      'https://opster.com/elasticsearch/elasticsearch-slow-indexing/',
    ZEN_DISCOVERY_SETTINGS_NOT_USED:
      'https://opster.com/elasticsearch/elasticsearch-zen-discovery-settings-not-used/',
    MAX_SHARD_PER_NODE:
      'https://opster.com/elasticsearch/elasticsearch-max-shards-per-node-exceeded/',
    ACTION_DESTRUCTIVE_REQUIRES_NAME:
      'https://opster.com/elasticsearch/best-practices/elasticsearch-misuse-of-wildcards/',
    MIN_MASTER_NODE_LESS_THAN_QUORUM:
      'https://opster.com/elasticsearch/elasticsearch-split-brain/',
    CLUSTER_CONCURRENT_REBALANCE_HIGH:
      'https://opster.com/elasticsearch/cluster-concurrent-rebalance-high-low/',
    CLUSTER_CONCURRENT_REBALANCE_LOW:
      'https://opster.com/elasticsearch/cluster-concurrent-rebalance-high-low/',
    SEARCH_BURST:
      'https://opster.com/elasticsearch/search-is-slow-in-nodesnames/',
    LOADED_MASTER_NODES:
      'https://opster.com/elasticsearch/elasticsearch-loaded-master-nodes/',
    REJECTED_INDEXING:
      'https://opster.com/blogs/improve-elasticsearch-indexing-rate/',
    INDEX_INDEXING_FAILED:
      'https://opster.com/guides/elasticsearch/operations/indexing-failure/',
    SEARCH_REJECTED_QUEUE:
      'https://opster.com/elasticsearch/elasticsearch-search-rejected-queue',
    FLOOD_STATE_REACHED:
      'https://opster.com/elasticsearch/elasticsearch-flood-stage-disk-watermark/',
    MAX_HEAP_SIZE_REACHED:
      'https://opster.com/elasticsearch/elasticsearch-heap-size-usage/',
    GC_RATIO_UNBALANCED:
      'https://opster.com/elasticsearch/elasticsearch-heap-size-usage/',
    ENABLE_ADAPTIVE_REPLICA_SELECTION:
      'https://opster.com/elasticsearch/enable-adaptive-replica-selection/',
    LOADED_CLIENT_NODE:
      'https://opster.com/elasticsearch/elasticsearch-loaded-client-nodes/',
    SHARD_REBALANCE_ENABLE_ALL:
      'https://opster.com/elasticsearch/elasticsearch-enable-shard-rebalance-and-allocation/',
    SHARD_ALLOCATION_ENABLE_ALL:
      'https://opster.com/elasticsearch/elasticsearch-enable-shard-rebalance-and-allocation/',
    REPOSITORY_SNAPSHOT:
      'https://opster.com/guides/elasticsearch/how-tos/elasticsearch-snapshot/',
    ENABLE_X_PACK_BASIC_SECURITY:
      'https://opster.com/elasticsearch/elasticsearch-x-pack-basic-security-is-off/',
    HIGH_CPU_UTILIZATION:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-high-cpu/',
    HEAVY_MERGES:
      'https://opster.com/elasticsearch/elasticsearch-heavy-merges-were-detected/',
    MANAGEMENT_QUEUE_SIZE:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-high-management-queue/',
    BOOTSTRAP_MEMORY_LOCK_IS_FALSE:
      'https://opster.com/elasticsearch/elasticsearch-bootstrap-memory-lock-is-false/',
    INDEX_QUEUE_SIZE:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-index-queue-size-is-high/',
    SCRIPT_ALLOWED_CONTEXTS:
      'https://opster.com/elasticsearch/elasticsearch-all-script-types-allowed/',
    SCRIPT_ALLOWED_TYPES:
      'https://opster.com/elasticsearch/elasticsearch-all-script-types-allowed/',
    UNBALACED_SHARDS:
      'https://opster.com/elasticsearch/elasticsearch-shard-allocation-is-unbalanced/',
    NUMBER_OF_MASTER_NODES:
      'https://opster.com/elasticsearch/elasticsearch-number-of-master-nodes/',
    SCRIPT_PAINLESS_REGEX_ENABLED:
      'https://opster.com/elasticsearch/elasticsearch-script-regex-enabled/',
    OPEN_FILE_DESCRIPTORS:
      'https://opster.com/elasticsearch/elasticsearch-file-descriptors/',
    NODE_CONCURRENT_RECOVERIES_HIGH:
      'https://opster.com/elasticsearch/elasticsearch-node-concurrent-recoveries/',
    NODE_CONCURRENT_RECOVERIES_LOW:
      'https://opster.com/elasticsearch/elasticsearch-node-concurrent-recoveries/',
    ALLOW_EXPENSIVE_QUERIES:
      'https://opster.com/elasticsearch/elasticsearch-allow-expensive-queries/',
    LOADED_DATA_NODES:
      'https://opster.com/elasticsearch/elasticsearch-loaded-data-nodes/',
    CIRCUIT_BREAKER:
      'https://opster.com/elasticsearch/elasticsearch-circuit-breakers/',
    CIRCUIT_BREAKER_USED_IS_HIGH:
      'https://opster.com/elasticsearch/elasticsearch-circuit-breakers/',
    LOADED_CLIENT_NODES:
      'https://opster.com/elasticsearch/elasticsearch-loaded-client-nodes/',
    SLOW_INDEXING:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-slow-indexing/',
    REJECTED_SEARCH:
      'https://opster.com/elasticsearch/elasticsearch-rejected-search-requests/',
    MIN_MASTER_NODE_HIGHER_THAN_QUORUM:
      'https://opster.com/elasticsearch/elasticsearch-minimum-master-nodes-quorum/',
    UNBALANCED_SHARDS:
      'https://opster.com/elasticsearch/elasticsearch-shard-allocation-is-unbalanced/',
    DATA_NODE_DISCONNECTED:
      'https://opster.com/elasticsearch/elasticsearch-node-disconnected/',
    COORDINATING_NODE_DISCONNECTED:
      'https://opster.com/elasticsearch/elasticsearch-node-disconnected/',
    NO_SHARDS_IN_DATA_NODE:
      'https://opster.com/elasticsearch/elasticsearch-shard-allocation-is-unbalanced/',
    COORDINATING_NODE_NOT_UTILIZED:
      'https://opster.com/guides/elasticsearch/high-availability/coordinating-only-dedicated-coordinating-node/',
    MASTER_NODE_DISCONNECTED:
      'https://opster.com/elasticsearch-glossary/elasticsearch-master-node-not-discovered/',
    INDEXING_THROTTLE_TIME:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-high-index-throttle-time/',
    INDEX_GET_DOC_MISSING:
      'https://opster.com/guides/elasticsearch/operations/elasticsearch-high-index-get-missing-total-count/',
    DISK_WATERMARKS_WRONG_CONFIGURATION:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-high-disk-watermark/',
    HEAVY_SEARCHES:
      'https://opster.com/guides/elasticsearch/operations/search-is-slow-in-nodesnames/',
    SEARCH_QUEUE_SIZE:
      'https://opster.com/guides/elasticsearch/glossary/elasticsearch-queue/',
    SLOW_SEARCH:
      'https://opster.com/guides/elasticsearch/operations/search-is-slow-in-nodesnames/',
    DETECT_EMPTY_SHARDS:
      'https://opster.com/guides/elasticsearch/capacity-planning/elasticsearch-oversharding/#eliminate-empty-shards',
    COST_UPGRADE_CLUSTER_VERSION:
      'https://opster.com/guides/elasticsearch/capacity-planning/how-to-reduce-elasticsearch-costs/',
  };

  return urls[eventType];
};
