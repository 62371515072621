export enum DialogSize {
  xSmall = '450px',
  small = '600px',
  medium = '650px',
  large = '700px',
  xLarge = '800px',
  xxLarge = '70vw',
  xxxLarge = '90vw',

}

export enum DialogScrollDir {
  top = 'top',
  bottom = 'bottom',
}

export enum VendorHelpUrl {
  AWS = 'https://opster.com/docs/autoops/how-tos/how-to-find-cloud-provider-instance-type/#AWS',
  GCP = 'https://opster.com/docs/autoops/how-tos/how-to-find-cloud-provider-instance-type/#Google-Cloud',
  AZURE = 'https://opster.com/docs/autoops/how-tos/how-to-find-cloud-provider-instance-type/#Azure'
}
