<div class="progress-bar-pop-up" [ngClass]="{'complete': status === slowLogStatus.COMPLETE}">
  <div class="upload-icon">
    <mat-icon class="progerss-bar-icon"
              [svgIcon]="status === slowLogStatus.FAILED ? 'progress_bar_error' : 'progress_bar_upload'"></mat-icon>
  </div>
  <div class="upload-text">

    <div class="close-icon" *ngIf="status === slowLogStatus.COMPLETE">
      <mat-icon mat-dialog-close (click)="close(true)">close</mat-icon>

    </div>


    <p class="file-name"
       [ngClass]="{'complete': status === slowLogStatus.COMPLETE,'failed': status === slowLogStatus.FAILED}">
      {{status !== slowLogStatus.FAILED ? fileName : 'Fail load ' + fileName}}</p>

    <p-progressBar [value]="value" [style]="{'height': '6px'}"
                   [showValue]="false"
                   *ngIf="status !== slowLogStatus.FAILED"
                   [ngClass]="{'complete': status === slowLogStatus.COMPLETE}"></p-progressBar>

    <p style="margin: 5px 0;color: var(--main-text);opacity: 0.5"
       *ngIf="status !== slowLogStatus.FAILED">{{value < 100 ? (slowLogStatus.ANALYZE && value > 99 ? 'Analyzing...' : 'Uploading…') : 'Complete'}} {{value}}
      %</p>

    <p class="read-more"
       *ngIf="status === slowLogStatus.FAILED">Read Why
      <mat-icon class="icon-panel arrow right">arrow_forward</mat-icon>
    </p>

  </div>


</div>
