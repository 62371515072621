<div class="app" [ngClass]="{'openMenu': isMenuOpen}">


  <div class="app-side-menu">
    <app-side-menu *ngIf="user && user !== {}" [currentUrl]="currentUrl" [user]="user"
                   (MenuChange)="onMenuOpen($event)"></app-side-menu>
  </div>

  <!--  <div class="app-content" *ngIf="!isQuickOpsGptOpen">-->

  <!--    <div class="app-top-content">-->
  <!--      <app-top-menu [currentUrl]="currentUrl"-->
  <!--                    *ngIf="currentUrl !== 'settings' && user && user !== {}"></app-top-menu>-->
  <!--      <div>-->
  <!--        <ng-content></ng-content>-->
  <!--      </div>-->
  <!--    </div>-->


  <!--  </div>-->

  <div class="app-content">
    <as-split [direction]="'horizontal'" style="overflow: hidden; height: 100%;z-index: 1001;" [useTransition]="true"
              [gutterSize]="12" [ngClass]="'split-area-gpt'">

      <as-split-area [size]="innerWidthBig? 75 :70" style="overflow: auto;" [style.position]="'relative'">

        <div class="app-top-content"    #appTopContent>
          <app-top-menu [currentUrl]="currentUrl"
                        *ngIf="currentUrl !== 'settings' && user && user !== {}"></app-top-menu>
          <div>
            <ng-content></ng-content>
          </div>
        </div>
      </as-split-area>

      <as-split-area [size]="innerWidthBig? 25: 30" [minSize]="innerWidthBig ? 25: 30" [maxSize]="50"
                     *ngIf="gptPayload?.display && showGpt" style="overflow: auto;    z-index: 1001;">
        <main-chat [user]="user" [isQuickOpsGpt]="true" [gptPayload]="gptPayload" [currentUrl]="currentUrl"></main-chat>
      </as-split-area>
    </as-split>

    <app-sidebar-panel-content
      *ngIf="sideBarObject && displaySidebarWithGpt"
      [withGpt]="gptPayload?.display"
      [display]="sideBarObject?.display"
      [eventTypeUrl]="sideBarObject?.eventTypeUrl"
      [analyticsGraph]="sideBarObject?.analyticsGraph"
      [opsterModal]="sideBarObject?.opsterModal"
      [customizeLoading]="sideBarObject?.customizeLoading"
      [renderGraph]="sideBarObject?.renderGraph"
      [filterEndpoints]="sideBarObject?.filterEndpoints"
      [customizeDisabled]="sideBarObject?.customizeDisabled"
      [customizeData]="sideBarObject?.customizeData"
      [displayHealth]="sideBarObject?.displayHealth"
      [appendTo]="gptPayload?.display ? appTopContent: 'body'"
      [cluster]="sideBarObject?.cluster"
      [detectedTime]="sideBarObject?.detectedTime"
      [health]="sideBarObject?.health"
      (supplementaryInfo)="onSupplementaryInfo($event)"
      (onMenuOpen)="onOnMenuOpen($event, sideBarObject?.displayHealth?.severity)"
      (onCustomize)="onOnCustomize()"
      (onDismiss)="onDismiss()"
      (onSidebarHide)="onSidebarHide()"
      (onMuteNotifications)="onOnMuteNotifications($event)"
    ></app-sidebar-panel-content>

  </div>




</div>

<app-progress-bar *ngIf="isAfterAnalyzeSlowLog"
                  [status]="analyzeSlowLogStatus"
                  [fileName]="analyzeSlowLogFileName"
                  [size]="analyzeSlowLogSize"
                  [progress]="analyzeSlowLogProgress"
                  (closeProgressBar)="onCloseProgressBar($event)"
></app-progress-bar>

<ng-container *ngIf="showTrialWelcomeDialog">
  <empty-state-trial [plan]="plan" [isTrialEnding]="isTrialEnding" (connectClusterClicked)="showTrialWelcomeDialog = false"></empty-state-trial>
</ng-container>

