import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService } from '../../store/theme/theme.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import get from 'lodash-es/get';
import split from 'lodash-es/split';
import first from 'lodash-es/first';

@Component({
  selector: 'app-clear-page',
  templateUrl: './clear-page.component.html',
  styleUrls: ['./clear-page.component.scss']
})
export class ClearPageComponent implements OnInit {
  public isDarkTheme$: Observable<boolean>;
  public currentUrl: string;

  constructor(private themeService: ThemeService,
              private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = split(first(split(get(event, 'urlAfterRedirects'), '?')) , ('/'))[1];
      });
  }

  ngOnInit(): void {
    this.isDarkTheme$ = this.themeService.getActiveTheme();
  }

}
