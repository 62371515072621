import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, Input, OnChanges,
  OnInit, ViewChild
} from '@angular/core';
import get from 'lodash-es/get';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescriptionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() healthDescription: string;
  public expandMore: boolean;
  public ifExpandMore = false;

  @ViewChild('description') description: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.healthDescription && this.description) {
      const element: any = get(this.description, 'nativeElement');
      this.ifExpandMore = get(element, 'offsetHeight') < get(element, 'scrollHeight') - 15;
        this.cdRef.markForCheck();
    }
    }, 0);
  }
}
