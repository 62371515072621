import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { OpsGptComponent } from './ops-gpt.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: OpsGptComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})

export class OpsGptRoutingModule {
}
