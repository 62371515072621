import {
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Directive,
  OnDestroy,
  ViewChild,
  ElementRef, HostListener
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Chart, Options } from 'highcharts';
import {
  ChartTypesEnum
} from './chart.enum';
import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import {
  ChartFormatterThis, ChartLegendAlignEnum, ChartLegendLayoutEnum,
  ChartLegendPositionEnum, ChartLegendVerticalAlignEnum,
} from './chart.interface';
// @ts-ignore
import moment from 'moment';
import { GET_RANGE } from '../../../store/global/helpers';


import noData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import xrange from 'highcharts/modules/xrange';
import customEvents from 'highcharts-custom-events';
import {ScreenSize} from '../../models/enum';
// import dumbbell from 'highcharts/modules/dumbbell';
// import highstock from 'highcharts/modules/stock';

customEvents(Highcharts);

const simpleUID = (
  prefix: string = '',
  length: number = 5,
  suffix: string = ''
): string => {
  return (
    prefix +
    Math.random()
      .toString(16)
      .substr(2, length) +
    suffix
  );
};

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ChartCoreOpster implements AfterViewInit, OnDestroy {
  Highchart: typeof Highcharts = Highcharts;

  type: ChartTypesEnum;
  highChartRef: Chart;
  containerId: string = simpleUID('bhc-', 7);
  options: Options;
  chartOptions: Options;
  isDarkTheme: boolean;
  datePickerMin;
  datePickerMax;
  private legendFontSize: string;


  // @Input() isDumbbell = false;
  @Input() animation = true;
  @Input() preTooltipValue = '';
  @Input() postTooltipValue = '';
  @Input() colorPalette = ['#769af3',
    '#945174',
    'var(--chart-color-green)',
    '#f07452',
    '#f3b076',
    'var(--chart-color-gray)',
    'var(--chart-color-yellow)',
    '#f990f0',
    '#1E8296',
    '#6B94B8',
    '#565000',
    '#2885AA',
    '#919665'];
  @Input() height;
  @Input() title: string = null;
  @Input() legend = false;
  @Input() showDataLabels = false;
  @Input() pointFormat = '{series.name}: <b>{point.percentage:.1f}%</b>';
  @Input() tooltipDateFormat = 'DD-MM-YYYY hh:mm:ss A';
  @Input() extraOptions: Options = {};
  @Output() legendChanged = new EventEmitter();
  @Input() legendPosition: ChartLegendPositionEnum = ChartLegendPositionEnum.BOTTOM_VERTICAL;
  @Input() globalDatePicker;
  @Input() globalDatePickerType: (rangeLabel) => any = GET_RANGE;
  @Input() tooltipValueFormatter = (val): false | string | Array<(string | null | undefined)> | null | undefined => val;
  @ViewChild('container', {static: true})
  chartContainer: ElementRef<HTMLElement>;

  protected constructor(public cdr: ChangeDetectorRef, public zone: NgZone) {
    window.Highcharts = Highcharts;

    // Boost(Highcharts);
    noData(this.Highchart);
    More(this.Highchart);
    HCSoldGauge(this.Highchart);
    // Exporting(Highcharts);
    xrange(this.Highchart);
    // dumbbell(this.Highchart);
    // highstock(this.Highchart);

    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const innerWidth = get(window, 'innerWidth');
    this.legendFontSize = innerWidth >= ScreenSize.xxl ? '14px' : '11px';
    this.applyOnChange();
  }

  tooltipFormatter(chartThis: ChartFormatterThis) {
    const date = moment(get(chartThis, 'key')).format(this.tooltipDateFormat);

    return `<div class="chart-tooltip">
      <div class="key">${date}</div>
    </div>`;
  }

  updateDatePicker(): void {
    if (this.globalDatePicker) {
      const range = get(this.globalDatePicker, 'label')
        ? (this.globalDatePickerType(get(this.globalDatePicker, 'label')))
        : get(this.globalDatePicker, 'range');


      if (range) {
        this.datePickerMin = get(range, 'startDate')?.valueOf();
        this.datePickerMax = get(range, 'endDate')?.valueOf();

        this.initialOptions();
      }
    }
  }


  initialOptions(): void {

    this.options = merge({
      colors: this.colorPalette,
      data: {
        dateFormat: '%l %p',
      },
      noData: {
        style: {
          fontSize: 14,
          fontWeight: 400,
          color: 'var(--panel-content-text)'
        }
      },
      accessibility: {
        enabled: false
      },
      chart: {
        zooming: {
          mouseWheel: {
            enabled: false
          }
        },
        renderTo: this.containerId,
        type: this.type,
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        borderRadius: 6,
        style: {
          'fontFamily': '"Poppins", sans-serif',
        }
      },
      title: {
        text: this.title,
        style: {
          fontSize: '16px',
          fontWeight: 500,
          color: 'var(--toolbar-header-text)'
        },
      },
      legend: merge({
        floating: false,
        align: 'center',
        verticalAlign: 'bottom',
        width: '100%',
        layout: 'vertical',
        itemMarginTop: 10,
        useHTML: false,
        navigation: {
          enabled: true,
          activeColor: 'var(--legend-navigation-active-color)',
          animation: true,
          arrowSize: 12,
          inactiveColor: 'var(--legend-navigation-inactive-color)',
          style: {
            fontWeight: 'bold',
            color: 'var(--toolbar-header-text)',
            fontSize: '12px'
          }
        },
        itemHiddenStyle: {
          color: 'var(--legend-item-hidden-color)',
        },
        itemHoverStyle: {
          color: 'var(--main-text)',
          opacity: 1,
        },
        itemStyle: {
          color: 'var(--main-text)',
          opacity: 0.8,
          fontSize: this.legendFontSize,
          cursor: 'pointer',
        }
      }, this.getLegendPositioning(this.legendPosition)),
      tooltip: {
        borderRadius: 10,
        borderWidth: 1,
        dateTimeLabelFormats: {
          minute: '%I:%M %P',
          hour: '%I:%M',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b \'%y',
          year: '%Y'
        },
        useHTML: true,
        zIndex: 999,
        backgroundColor: 'var(--chart-tooltip)',
        shadow: false,
        style: {
          textAlign: 'center',
          opacity: 1,
          fontSize: '13px',
          color: 'var(--toolbar-header-text)'
        },
      },
      plotOptions: {
        [this.type]: {
          events: {
            afterAnimate: undefined
          },
          showInLegend: this.legend,
          dataLabels: {
            enabled: this.showDataLabels
          },
        },
        series: {
          lineWidth: 1.5,
          animation: this.animation ? {duration: 1000} : false
        }
      },
      yAxis: {
        gridLineColor: 'var(--grid-line)',
        lineWidth: 0,
        title: {
          text: null
        },
        labels: {
          style: {
            color: 'var(--panel-content-text)',
            fontSize: '9px',
          },
        },
      },
      xAxis: {
        min: this.datePickerMin,
        max: this.datePickerMax,
        type: 'datetime',
        dateTimeLabelFormats: {
          minute: '%I:%M %P',
          hour: '%I:%M %P',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b \'%y',
          year: '%Y'
        },
        lineWidth: 0,
        labels: {
          style: {
            color: 'var(--panel-content-text)'
          },
        },
      },
      time: {
        getTimezoneOffset: () => new Date().getTimezoneOffset()
      },
      exporting: {
        enabled: false
      },
      responsive: {
        rules: [{
          condition: {
            minWidth: 400
          },
        }],
      },
      credits: {
        enabled: false
      },
      series: [],
    }, this.chartOptions);

  }

  ngAfterViewInit(): void {
    this.initialOptions();

    this.zone.runOutsideAngular(() => {
      this.highChartRef = Highcharts.chart(
        get(this.chartContainer, 'nativeElement'),
        this.options
      );
    });
  }

  applyOnChange(destroy = false) {
    if (this.highChartRef) {
      this.cdr.markForCheck();
      this.initialOptions();
      this.zone.runOutsideAngular(() => {
        if (destroy) {
          this.highChartRef.destroy();
          this.highChartRef = Highcharts.chart(
            get(this.chartContainer, 'nativeElement'),
            this.options
          );

        } else {
          this.highChartRef.update(this.options, true, true);
        }
      });
    }
  }


  private getLegendPositioning(
    position: ChartLegendPositionEnum,
    offset = {x: 0, y: 0}
  ) {
    const baseLegendPositionJson = {
      [ChartLegendPositionEnum.TOP]: {
        align: ChartLegendAlignEnum.CENTER,
        verticalAlign: ChartLegendVerticalAlignEnum.TOP,
        layout: ChartLegendLayoutEnum.HORIZONTAL,
      },
      [ChartLegendPositionEnum.BOTTOM_VERTICAL]: {
        align: ChartLegendAlignEnum.CENTER,
        verticalAlign: ChartLegendVerticalAlignEnum.BOTTOM,
        layout: ChartLegendLayoutEnum.VERTICAL,
      },
      [ChartLegendPositionEnum.BOTTOM_HORIZONTAL]: {
        align: ChartLegendAlignEnum.CENTER,
        verticalAlign: ChartLegendVerticalAlignEnum.BOTTOM,
        layout: ChartLegendLayoutEnum.HORIZONTAL,
      },
      [ChartLegendPositionEnum.RIGHT]: {
        align: ChartLegendAlignEnum.RIGHT,
        verticalAlign: ChartLegendVerticalAlignEnum.MIDDLE,
        layout: ChartLegendLayoutEnum.VERTICAL,
      },
      [ChartLegendPositionEnum.LEFT]: {
        align: ChartLegendAlignEnum.LEFT,
        verticalAlign: ChartLegendVerticalAlignEnum.MIDDLE,
        layout: ChartLegendLayoutEnum.VERTICAL,
      },
    };

    return {
      ...baseLegendPositionJson[position],
      x: offset.x,
      y: offset.y,
    };
  }

  ngOnDestroy() {
    if (this.highChartRef) {
      this.highChartRef.destroy();
      this.highChartRef = null;
    }
  }


}
