import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, retry} from 'rxjs';
import {Cluster, DatePicker} from './cluster.interface';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {first as rxFirst, share} from 'rxjs/operators';
import size from 'lodash-es/size';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import first from 'lodash-es/first';
import toString from 'lodash-es/toString';
import {SlowLogsService} from '../../features/slow-logs/services/slow-logs.service';
import {TemplateOptimizerService} from '../../features/template-optimizer/service/template-optimizer.service';
import {AppRoutes} from '../../app-routing-data';
import {GET_RANGE, GET_RANGE_NOW} from './helpers';
import moment, {Moment} from 'moment';
import {arraysEqual} from '../../shared/services/utils/functional-utils';
import orderBy from 'lodash-es/orderBy';
import split from 'lodash-es/split';
import {SideBarInterface} from './side-bar.interface';
import {GptPayload} from '../../features/ops-gpt/ops-gpt.interface';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import set from 'lodash-es/set';

const Refresh_Time_Default = '1m';
const Date_Picker_Default = 'Last 15 minutes';
const Date_Picker_Single_Default = 'Now';
const Date_Picker_Small_Default = 'Last 1 month';
const Date_Picker_Range_Events = 'Last 7 days';
const Date_Picker_Range_Notifications = 'Last 24 hours';
const Policy_Agreement_Default = false;
const Glossary_Pages = 2;

@UntilDestroy({checkProperties: true})
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private globalDatePicker$: BehaviorSubject<DatePicker>;
  private globalDatePickerSmall$: BehaviorSubject<DatePicker>;
  private singleDatePicker$: BehaviorSubject<DatePicker>;
  private eventsDatePicker$: BehaviorSubject<DatePicker>;
  private notificationsDatePicker$: BehaviorSubject<DatePicker>;

  private globalClusterList$: BehaviorSubject<Cluster[]>;
  // private globalClusterListFull$: BehaviorSubject<ClusterFull[]>;

  private selectedCluster$: BehaviorSubject<string>;
  private selectedRefreshTime$: BehaviorSubject<string>;
  private selectedRefreshNow$: BehaviorSubject<boolean>;
  private selectedFullScreen$: BehaviorSubject<any>;
  private selectedRunTemplateOptimizer$: BehaviorSubject<any>;
  private selectedRunSlowLogs$: BehaviorSubject<string>;
  private menuOpen$: BehaviorSubject<boolean>;
  private quickOpsGpt$: BehaviorSubject<GptPayload>;
  private policyOpsGpt$: BehaviorSubject<boolean>;
  private disableRefreshTime$: BehaviorSubject<boolean>;
  private glossaries$: BehaviorSubject<any>;
  private sideBar$: BehaviorSubject<SideBarInterface>;

  private selectedAnalysisId$: BehaviorSubject<string>;
  private loader$: BehaviorSubject<boolean>;

  // private onClusterListSubscription: boolean = false
  public lastRefreshTimeTo: Moment;

  constructor(private httpClient: HttpClient,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private slowLogsService: SlowLogsService,
              private templateOptimizerService: TemplateOptimizerService
  ) {
    this.loader$ = new BehaviorSubject<boolean>(false);
    this.resetAll();
  }

  // Global DatePicker
  public setGlobalDatePicker(data: DatePicker, addToUrl): void {
    if (addToUrl) {
      const queryParams = {
        from: get(data, 'label')
          ? split(data.label, ' ').join('_')
          : get(get(data, 'range'), 'startDate')?.valueOf(),
        to: get(data, 'label')
          ? Date_Picker_Single_Default
          : get(get(data, 'range'), 'endDate')?.valueOf()
      };
      // if (updateSingle) {
      //   const singleData = isCustom ? {label: null, range: {endDate: get(data, 'range.endDate')}} :
      //     {label: Date_Picker_Single_Default, range: null};
      //   queryParams['date'] = get(singleData, 'label')
      //     ? singleData.label.split(' ').join('_')
      //     : get(singleData, 'range.endDate').valueOf();
      //   this.singleDatePicker$.next(singleData);
      // }
      this.router.navigate(
        [],
        {
          queryParams,
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }

    if (!data) {
      data = {label: Date_Picker_Default, range: null};
    }
    // if (isEqual(data, get(this.globalDatePicker$, 'value'))) {
    //   return;
    // }
    this.globalDatePicker$.next(data);
  }

  public getGlobalDatePicker(): Observable<DatePicker> {
    return this.globalDatePicker$.asObservable();
  }

  // events DatePicker

  public setEventsDatePicker(data: DatePicker, addToUrl): void {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            from_c: get(data, 'label')
              ? split(data.label, ' ').join('_')
              : get(get(data, 'range'), 'startDate')?.valueOf(),
            to_c: get(data, 'label')
              ? Date_Picker_Single_Default
              : get(get(data, 'range'), 'endDate')?.valueOf()
          },
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }

    if (!data) {
      const activeRoute = get(this.activatedRoute, 'snapshot.url');
      const defaultLabel = activeRoute.indexOf(AppRoutes.dashboard) || activeRoute.indexOf(AppRoutes.clusterView);
      data = {label: defaultLabel ? Date_Picker_Range_Events : Date_Picker_Default, range: null};
    }

    this.eventsDatePicker$.next(data);
  }

  public getEventsDatePicker(): Observable<DatePicker> {
    return this.eventsDatePicker$.asObservable();
  }

  // Notifications DatePicker

  public setNotificationsDatePicker(data: DatePicker, addToUrl): void {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            from_n: get(data, 'label')
              ? split(data.label, ' ').join('_')
              : get(get(data, 'range'), 'startDate')?.valueOf(),
            to_n: get(data, 'label')
              ? Date_Picker_Single_Default
              : get(get(data, 'range'), 'endDate')?.valueOf()
          },
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }

    if (!data) {
      const activeRoute = get(this.activatedRoute, 'snapshot.url');
      const defaultLabel = activeRoute.indexOf(AppRoutes.notificationsHistory);
      data = {label: defaultLabel ? Date_Picker_Range_Notifications : Date_Picker_Default, range: null};
    }

    this.notificationsDatePicker$.next(data);
  }

  public getNotificationsDatePicker(): Observable<DatePicker> {
    return this.notificationsDatePicker$.asObservable();
  }

  // Single DatePicker
  public getSingleDatePicker(): Observable<DatePicker> {
    return this.singleDatePicker$.asObservable();
  }

  public setSingleDatePicker(data: DatePicker, addToUrl, updateGlobal?, isCustom?): void {
    if (addToUrl && data) {
      const queryParams = {
        date: get(data, 'label')
          ? split(data.label, ' ').join('_')
          : get(data, 'range.endDate')?.valueOf(),
      };
      if (updateGlobal) {
        const lastGlobalDatePicker = this.globalDatePicker$.value;
        const lastStartDate = get(lastGlobalDatePicker, 'range') ?
          get(lastGlobalDatePicker, 'range.startDate') :
          get(GET_RANGE(get(lastGlobalDatePicker, 'label')), 'startDate');
        const duration = !isCustom ? get(GET_RANGE_NOW(Date_Picker_Single_Default),
          'endDate').diff(get(GET_RANGE_NOW(get(data, 'label')), 'endDate')) : null;
        const endDate = isCustom && !duration ? get(data, 'range.endDate') :
          get(GET_RANGE_NOW(Date_Picker_Single_Default), 'endDate').subtract(duration);
        const startDate = moment(lastStartDate).isBefore(moment(endDate)) ?
          lastStartDate : moment(endDate).subtract(1, 'h');
        const globalData = {label: null, range: {startDate, endDate}};
        queryParams['from'] = get(globalData, 'range.startDate')?.valueOf();
        queryParams['to'] = get(globalData, 'range.endDate')?.valueOf();
        this.globalDatePicker$.next(globalData);
      }
      this.router.navigate(
        [],
        {
          queryParams,
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }
    if (!data) {
      data = {label: Date_Picker_Single_Default, range: null};
    }
    if (isEqual(data, get(this.singleDatePicker$, 'value'))) {
      return;
    }
    this.singleDatePicker$.next(data);
  }


  // DatePicker Small
  public setGlobalDatePickerSmall(data: DatePicker): void {
    if (!data) {
      data = {label: Date_Picker_Default, range: null};
    }
    if (isEqual(data, get(this.globalDatePickerSmall$, 'value'))) {
      return;
    }
    this.globalDatePickerSmall$.next(data);
  }

  public getGlobalDatePickerSmall(): Observable<DatePicker> {
    return this.globalDatePickerSmall$.asObservable();
  }

  // Cluster List For Top Menu
  public setClusterList(isUpdate: boolean) {

    this.clusterListFromServer(isUpdate)
      .pipe(rxFirst(), untilDestroyed(this))
      .subscribe((data: Cluster[]) => {

        if (!arraysEqual(data, this.globalClusterList$.value) || !size(data)) {
          this.globalClusterList$.next(data);

          const sortByLastMetric = first(orderBy(data, ['lastMetricTimestamp'], ['asc']));

          if (!isUpdate && !this.isLessThan5MinAgo(get(sortByLastMetric, 'lastMetricTimestamp'))) {
            const clustersTimestamp = JSON.parse(localStorage.getItem('clustersTimestamp'));

            if (!this.isLessThan5MinAgo(clustersTimestamp)) {
              this.setClusterList(true);
              localStorage.setItem('clustersTimestamp', JSON.stringify(Date.now()));
            }

          }
        }
      });


  }

  getFullClusterNameById(clusterId) {
    if (!size(this.getClusterListValue()) || !clusterId) {
      return null;
    }

    try {
      return this.getClusterListValue().find(cluster => {
        return isEqual(get(cluster, 'cluster'), clusterId);
      }).clusterAlias;
    } catch (e) {
      console.log(e);
    }
  }

  private isLessThan5MinAgo(date) {
    const hourInMilliseconds = 60 * 5 * 1000;

    const fiveMinAgo = Date.now() - hourInMilliseconds;

    return date > fiveMinAgo;
  }

  public getClusterList(): Observable<Cluster[]> {
    return this.globalClusterList$.asObservable();
  }

  public getClusterListValue(): Cluster[] {
    return this.globalClusterList$.value;
  }

  private clusterListFromServer(isUpdate: boolean = false, skipOpsterError: boolean = false): Observable<Cluster[]> {
    const skipOpsterErrorStr = toString(skipOpsterError);
    return this.httpClient.get<any>(`api/accounts/clusters`,
      {params: {update: isUpdate, skipOpsterError: skipOpsterErrorStr}}).pipe(rxFirst(), share());
  }

  // Cluster List Full For setting page
  public setClusterListFull(isUpdate: boolean) {
    this.clusterListFromServer(isUpdate)
      .pipe(rxFirst())
      .subscribe((data) => {
        this.globalClusterList$.next(data);
      });
  }

  // private clusterListFull(isUpdate: boolean = false): Observable<ClusterFull[]> {
  //   return this.httpClient.get<any>(`api/accounts/clusters?update=${isUpdate}`).pipe(share());
  // }

  public updateClusterName(body): Observable<any> {
    return this.httpClient.put<any>('api/accounts/cluster/update-alias', body);
  }

  // SelectedCluster
  public setSelectedCluster(clusterId: string, addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            cluster: clusterId
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        }).then(() => {
      });
    }

    if (isEqual(clusterId, this.selectedCluster$.getValue())) {
      return;
    }

    this.selectedCluster$.next(clusterId);
  }

  public getSelectedCluster(): Observable<string> {
    return this.selectedCluster$.asObservable();
  }

  // Selected Run Slow Logs
  public setSelectedRunSlowLogs(runId: any, addToUrl = true, setFirst = false) {
    const extras: NavigationExtras = {
      queryParams: {runSL: runId},
      queryParamsHandling: 'merge'
    };

    if (setFirst) {
      set(extras, 'replaceUrl', true);
    }

    if (isEqual(runId, this.selectedRunSlowLogs$.getValue())) {
      return;
    }

    if (addToUrl) {
      this.router.navigate(
        [],
        extras).then(() => {
      });
    }

    this.selectedRunSlowLogs$.next(runId);
  }

  public getSelectedRunSlowLogs(): Observable<string> {
    return this.selectedRunSlowLogs$.asObservable();
  }

  // Selected Run Template Optimizer
  public setSelectedRunTemplateOptimizer(val, addToUrl = true, setFirst = false) {
    const extras: NavigationExtras = {
      queryParams: {runTO: val},
      queryParamsHandling: 'merge',
    };

    if (setFirst) {
      set(extras, 'replaceUrl', true);
    }

    if (isEqual(val, this.selectedRunTemplateOptimizer$.getValue())) {
      return;
    }

    if (addToUrl) {
      this.router.navigate(
        [],
        extras).then(() => {
      });
    }

    this.selectedRunTemplateOptimizer$.next(val);
  }

  public getSelectedRunTemplateOptimizer() {
    return this.selectedRunTemplateOptimizer$.asObservable();
  }

  // Selected Analysis id
  public setSelectedAnalysisId(val, addToUrl = true) {
    if (isEqual(val, this.selectedAnalysisId$.getValue())) {
      return;
    }
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {eventId: val},
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }

    this.selectedAnalysisId$.next(val);
  }

  public getSelectedAnalysisId() {
    return this.selectedAnalysisId$.asObservable();
  }

  // RefreshTime
  public setSelectedRefreshTime(name: string, addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {refresh: name},
          queryParamsHandling: 'merge'
        }).then(() => {
      });

      localStorage.setItem('RefreshTime', JSON.stringify(name));
    }

    this.selectedRefreshTime$.next(name || this.selectedRefreshTime$.value);
  }

  public backRefreshTimeToDefault() {
    const refreshTime = localStorage.getItem('RefreshTime') ? JSON.parse(localStorage.getItem('RefreshTime')) : Refresh_Time_Default;
    this.setSelectedRefreshTime(refreshTime, false);
  }

  public getSelectedRefreshTime(): Observable<string> {
    return this.selectedRefreshTime$.asObservable();
  }

  // RefreshNow
  public setSelectedRefreshNow(isRefreshNow: boolean) {
    this.selectedRefreshNow$.next(isRefreshNow);
    this.selectedRefreshNow$.next(false);
  }

  public getSelectedRefreshNow(): Observable<boolean> {
    return this.selectedRefreshNow$.asObservable();
  }

  // FullScreen
  public setFullScreen(fullscreen: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: fullscreen ? fullscreen : {fullscreen: null, categoryId: null, metric: null},
        queryParamsHandling: 'merge'
      });
    this.selectedFullScreen$.next(fullscreen);
  }

  public getFullScreen(): Observable<any> {
    return this.selectedFullScreen$.asObservable();
  }

  public getGlossary() {
    return this.glossaries$.asObservable();
  }


  public setGlossary() {
    const glossaryList = [];
    let i = 0;
    while (i < Glossary_Pages) {
      i++;
      glossaryList.push(this.httpClient.get<any>('https://opster.com/wp-json/wp/v2/glossary?per_page=350&page=' + i, {params: {skipOpster: 'true'}}));
    }

    combineLatest(glossaryList).pipe(share(), retry(5)).subscribe({
      next: res => {
        const resultArray = [].concat(...res);
        this.glossaries$.next(resultArray);
      },
      error: (err) => {
        console.log(err);
        this.glossaries$.next([]);
      }
    });
  }

  // Menu Open
  public setMenuOpen(item) {
    this.menuOpen$.next(item);
  }

  public getMenuOpen(): Observable<boolean> {
    return this.menuOpen$.asObservable();
  }

  // Quick OpsGpt
  public setQuickOpsGpt(gptPayload: GptPayload) {
    this.quickOpsGpt$.next(gptPayload);
  }

  public getQuickOpsGpt(): Observable<GptPayload> {
    return this.quickOpsGpt$.asObservable();
  }

  // Policy Agreement
  public setPolicyOpsgpt(item: boolean) {
    localStorage.setItem('policyOpsgpt', JSON.stringify(item));
    this.policyOpsGpt$.next(item);
  }

  public getPolicyOpsgpt(): Observable<boolean> {
    return this.policyOpsGpt$.asObservable();
  }

  // Show Refresh Time
  public setPauseRefreshTime(val: boolean) {
    this.disableRefreshTime$.next(val);
  }

  public getPauseRefreshTime(): Observable<boolean> {
    return this.disableRefreshTime$.asObservable();
  }

  // loader
  public setLoader(loader) {
    this.loader$.next(loader);
  }

  public getLoader(): Observable<boolean> {
    return this.loader$.asObservable();
  }

  // Side Bar
  public setSideBar(item) {
    this.sideBar$.next(item);
  }

  public getSideBar(): Observable<SideBarInterface> {
    return this.sideBar$.asObservable();
  }

  public resetAll() {
    try {
      // tslint:disable-next-line:max-line-length
      const refreshTimeDefault = localStorage.getItem('RefreshTime') ? JSON.parse(localStorage.getItem('RefreshTime')) : Refresh_Time_Default;
      const policyFromStorage: boolean = localStorage.getItem('policyOpsgpt') ? JSON.parse(localStorage.getItem('policyOpsgpt')) : Policy_Agreement_Default;
      this.globalDatePicker$ = new BehaviorSubject<DatePicker>({label: Date_Picker_Default, range: null});
      this.globalDatePickerSmall$ = new BehaviorSubject<DatePicker>({label: Date_Picker_Small_Default, range: null});
      this.singleDatePicker$ = new BehaviorSubject<DatePicker>({label: Date_Picker_Single_Default, range: null});
      this.eventsDatePicker$ = new BehaviorSubject<DatePicker>({label: Date_Picker_Range_Events, range: null});
      this.notificationsDatePicker$ = new BehaviorSubject<DatePicker>({
        label: Date_Picker_Range_Notifications,
        range: null
      });

      this.selectedRunSlowLogs$ = new BehaviorSubject<string>(null);
      this.globalClusterList$ = new BehaviorSubject<Cluster[]>(null);
      this.selectedCluster$ = new BehaviorSubject<string>(null);
      this.selectedRefreshTime$ = new BehaviorSubject<string>(refreshTimeDefault);
      this.selectedFullScreen$ = new BehaviorSubject<any>(null);
      this.selectedRunTemplateOptimizer$ = new BehaviorSubject<any>(-1);
      this.selectedRefreshNow$ = new BehaviorSubject<boolean>(false);
      this.menuOpen$ = new BehaviorSubject<boolean>(false);
      this.sideBar$ = new BehaviorSubject<SideBarInterface>(null);
      this.quickOpsGpt$ = new BehaviorSubject<GptPayload>(null);
      this.policyOpsGpt$ = new BehaviorSubject<boolean>(policyFromStorage);
      this.disableRefreshTime$ = new BehaviorSubject<boolean>(false);
      this.glossaries$ = new BehaviorSubject<any>(null);
      this.selectedAnalysisId$ = new BehaviorSubject<string>(null);
      this.lastRefreshTimeTo = moment();

      this.slowLogsService.resetHistoryOnLogout();
      this.templateOptimizerService.resetHistoryOnLogout();
    } catch {

    }
  }
}
