import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PagesChat } from '../../../shared/models/enum';
import { ChatHistory } from '../ops-gpt.interface';
import { OpsterChatService } from '../service/opster-chat.service';
import { AuthService } from '../../../core/auth/auth.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter as rxFilter, first } from 'rxjs/operators';
import { SegmentTrackService } from '../../../shared/services/segment/segment.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../store/toast/toast.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfidenceDialogComponent
} from '../../../shared/components/popups/dialog/confidence-dialog/confidence-dialog.component';
import { DialogSize } from '../../../shared/components/popups/dialog/dialog.enum';
import { Cluster } from '../../../store/global/cluster.interface';
import { GlobalService } from '../../../store/global/global.service';

import set from 'lodash-es/set';
import isEmpty from 'lodash-es/isEmpty';
import size from 'lodash-es/size';
import take from 'lodash-es/take';
import map from 'lodash-es/map';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHistoryComponent implements OnInit, AfterViewInit {
  @Input() user;
  @Input() page: PagesChat;

  public chatHistory: ChatHistory[] = [];
  public loaderHistory = false;

  public clusterList: Cluster[];
  private readonly MAX_ITEMS_TOOLTIP = 10;

  // @Output() hasHistory = new EventEmitter();
  public showSlidersHoriz = [];
  public menuOpened;

  constructor(private opsterChatService: OpsterChatService,
              private authService: AuthService,
              private dialog: MatDialog,
              private router: Router,
              private globalService: GlobalService,
              private toastService: ToastService,
              private segmentTrackService: SegmentTrackService,
              private translateService: TranslateService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.clusterList = this.globalService.getClusterListValue();

    this.authService.getCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(r => {
        this.user = r;
        if (this.user) {
          this.getAllChats();
        } else {
          this.chatHistory = [];
          // this.hasHistory.emit(false);
        }
        this.ref.markForCheck();
      });

    this.opsterChatService.getAllChats()
      .pipe(untilDestroyed(this), rxFilter((value: any) => value))
      .subscribe(res => {
        if (this.user) {
          this.loaderHistory = false;
          this.chatHistory = filter(res, x => (get(x, 'source') !== 'INSTALLATION'));
          this.chatHistory.map(item => {
            set(item, 'tooltipText', this.buildClusterNamesTooltip(get(item, 'clusters')));
          });
          // this.hasHistory.emit(!!size(res));
          this.ref.markForCheck();
        }
      });
  }

  getAllChats() {
    this.loaderHistory = true;
    this.ref.markForCheck();

    this.opsterChatService.getAllChatsFromServer();
  }

  ngAfterViewInit() {
  }

  buildClusterNamesTooltip(clusters) {
    if (isEmpty(clusters)) {
      return '';
    }

    let tooltipText;
    const selectedLength = size(clusters);
    if (selectedLength > this.MAX_ITEMS_TOOLTIP) {
      const restItems = selectedLength - this.MAX_ITEMS_TOOLTIP;
      const selected = take(clusters, this.MAX_ITEMS_TOOLTIP);
      tooltipText = '<ul class="selectedItems-ul">' + map(selected, (item) => {
        const alias = get(item, 'clusterName') || null;
        const id = get(item, 'clusterId') || null;
        if (alias) {
          return `<li>${alias} - (${id}).</li>`;
        } else {
          return `<li>${id}.</li>`;
        }
      }).join('') + `<li>and ${restItems} more...</li>` + '</ul>';
    } else {
      tooltipText = '<ul class="selectedItems-ul">' + map(clusters, (item) => {
        const alias = get(item, 'clusterName') || null;
        const id = get(item, 'clusterId') || null;
        if (alias) {
          return `<li>${alias} - (${id}).</li>`;
        } else {
          return `<li>${id}.</li>`;
        }
      }).join('') + '</ul>';
    }
    return tooltipText;
  }

  goToChatGPT() {
    this.router.navigate([`/ops-gpt`], {
      replaceUrl: true,
      queryParamsHandling: 'merge'
    }).then(() => {
    });
  }

  deletePrompt(item) {
    this.opsterChatService.deleteConversation(get(item, 'userChatId'))
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {

        this.segmentTrackService.setAnalyticsTrackToServer('Autoops OpsGPT delete conversation', {
          email: this.segmentTrackService.getTrackEmail,
          userChatId: get(item, 'userChatId')
        });

        this.toastService.queueSnackBar(null,
          this.translateService.instant('success.chat_delete_success'), 'success', 'chat_delete_success');

        this.getAllChats();
      });
  }

  getHistoryItem(item: ChatHistory) {
    if (this.menuOpened) {
      return;
    }
    const conversationId = get(item, 'userChatId');

    this.opsterChatService.setConversationId(conversationId, true);
  }

  deleteConversion(item) {
    this.menuOpened = true;
    const title = 'Delete';
    const msg = this.translateService.instant('opsGpt.beforeDelete');
    const actionAfterConfidence = () => this.deletePrompt(item);
    const dialogButtonPipeConfig = {
      ok: {
        label: 'Delete',
        action: () => {
          actionAfterConfidence();
          this.menuOpened = false;
        }
      },
      cancel: {
        label: 'Cancel',
        action: () => {
          this.menuOpened = false;
        }
      },
    };

    this.openDialogConfidence(dialogButtonPipeConfig, msg, title);
  }


  openDialogConfidence(dialogButtonConfig, msg, title) {
    this.dialog.open(ConfidenceDialogComponent, {
      disableClose: true,
      width: DialogSize.small,
      data: {dialogButtonConfig: dialogButtonConfig, message: msg, title: title},
      id: 'confidence-dialog',
      autoFocus: false
    });
  }
}
