import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IndexView } from '../index.interface';
import { Router } from '@angular/router';
import { share } from 'rxjs/operators';
import { Shards } from '../../../shared/components/popups/dialog/dialog.interface';

@Injectable({
  providedIn: 'root'
})
export class IndexViewService {
  private selectedIndices$: BehaviorSubject<string>;
  private searchIndices$: BehaviorSubject<string>;

  constructor(public httpClient: HttpClient, private router: Router) {
    this.selectedIndices$ = new BehaviorSubject<string>(null);
    this.searchIndices$ = new BehaviorSubject<string>('');

  }

  public indicesListFromServer(clusterId: string,
                               searchIndices?: string,
                               offset?: string,
                               from?: number,
                               to?: number,
                               sort?: string,
                               sortOrder?: string,
                               sizeIndices?: number): Observable<IndexView[]> {
    let url = `api/monitoring/clusters/${clusterId}/indices?pagination=true&size=${sizeIndices}&from=${from}&to=${to}&sort=${sort}&sortOrder=${sortOrder}`;
    url = searchIndices ? url + `&search=${searchIndices}` : url;
    url = offset ? url + `&offset=${offset}` : url;
    return this.httpClient.get<any>(url).pipe(share());
  }

  public indicesListFromServerNoStats(clusterId, from?: number, to?: number) {
    return this.httpClient.get<any>(`api/monitoring/clusters/${clusterId}/indices?include_stats=false&from=${from}&to=${to}`).pipe(share());
  }

  // SelectedIndices
  public setSelectedIndices(indicesIds: string, addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {indices: indicesIds},
          queryParamsHandling: 'merge',
        }).then(() => {
      });
    }

    this.selectedIndices$.next(indicesIds);
  }

  public removeSelectedIndices(clusterId: string) {
    this.router.navigate(
      [],
      {
        queryParams: {cluster: clusterId, indices: null},
        queryParamsHandling: 'merge',
      }).then(() => {
    });

    this.selectedIndices$.next(null);
  }


  public getSelectedIndices(): Observable<string> {
    return this.selectedIndices$.asObservable();
  }

  // set searchIndices
  public setSearchIndices(search: string, addToUrl = true) {
    if (search === null) {
      return;
    }
    const text = search === '' ? null : search;
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {searchIndices: text},
          queryParamsHandling: 'merge',
        });
    }

    this.searchIndices$.next(search);

  }

  // get searchIndices
  public getSearchIndices(): Observable<string> {
    return this.searchIndices$.asObservable();
  }

  public getShards(clusterId, index, from, to): Observable<Shards> {
    const indexEncoded = encodeURIComponent(index);
    const urlEncoded = encodeURI(`api/monitoring/clusters/${clusterId}/indices/${indexEncoded}/shards?from=${from}&to=${to}`);

    return this.httpClient.get<any>(urlEncoded).pipe(share());
  }

}
