<div class="sidebar-panel">
  <p class="panel-title">
    <mat-icon svgIcon="processor"></mat-icon>
    <span>What was detected</span>
  </p>

  <!--  <div class="panel-content affected-section marked" innerHTML="{{affectedSection | marked}}">-->
  <!--  </div>-->

  <div class="panel-content affected-section marked">
    <opster-markdowm [data]="affectedSection"></opster-markdowm>
  </div>
</div>
