import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BarChartComponent } from './bar-chart.component';

@NgModule({
  declarations: [
    BarChartComponent
  ],
  exports: [
    BarChartComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  providers: []
})
export class BarChartModule {
}
