import {ChangeDetectionStrategy, Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Params, Router} from '@angular/router';
import {filter, first, map, mergeMap, skip} from 'rxjs/operators';
import {GlobalService} from './store/global/global.service';
import {NodeViewService} from './features/node-view/service/node-view.service';
import {IndexViewService} from './features/index-view/services/index-view.service';
import * as moment from 'moment';
import {isNumeric} from './shared/services/utils/functional-utils';
import get from 'lodash-es/get';
import * as FontFaceObserver from 'fontfaceobserver';
import {Observable} from 'rxjs';
import {ThemeService} from './store/theme/theme.service';
import toNumber from 'lodash-es/toNumber';
import has from 'lodash-es/has';
import {SeoService} from './store/seo/seo.service';
import {ShardsViewService} from './features/shards-view/service/shards-view.service';
import split from 'lodash-es/split';
import {MainDashboardService} from './features/dashboard/service/main-dashboard.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public isDarkTheme$: Observable<boolean>;

  constructor(private translate: TranslateService,
              private activatedRoute: ActivatedRoute,
              private globalService: GlobalService,
              private dashboardService: MainDashboardService,
              private nodeViewService: NodeViewService,
              private indexViewService: IndexViewService,
              private renderer: Renderer2,
              private themeService: ThemeService,
              private seoService: SeoService,
              private shardsViewService: ShardsViewService,
              private router: Router) {

    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    // sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml('<div>Safe HTML</div>');

    this.isDarkTheme$ = this.themeService.getActiveTheme();
    this.loadMaterial();
    this.getCurrentNavigation(1);

    this.router.events
      .pipe(
        filter(
          (event: ActivationStart) => {
            return (event instanceof NavigationStart && get(event, 'navigationTrigger') === 'popstate');
          }
        )
      )
      .subscribe(
        () => {
          this.getCurrentNavigation(1);
        });

    this.updateSeoData();

  }

  updateSeoData() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(_ => this.activatedRoute),
      map((route) => {

        while (get(route, 'firstChild')) {
          route = get(route, 'firstChild');
        }
        return route;
      }),
      filter((route) => get(route, 'outlet') === 'primary'),
      mergeMap((route) => get(route, 'data')),
      filter(data => data && has(data, 'seo')),
      first()
    ).subscribe(data => {


      const seoData = get(data, 'seo');
      if (get(seoData, 'title')) {
        this.seoService.updateTitle(get(seoData, 'title'));
      }
      if (get(seoData, 'metaTags')) {
        this.seoService.updateMetaTags(get(seoData, 'metaTags'));
      }
    });
  }


  getCurrentNavigation(skipNum) {
    this.activatedRoute.queryParams.pipe(skip(skipNum), first()).subscribe(
      (params: Params) => {
        if (params['new-version']) {
          this.router.navigate([], {
            queryParams: {
              'new-version': undefined,
            },
            queryParamsHandling: 'merge'
          }).then(() => {
            window.location.reload();
          });

        }
        if (params['cluster']) {
          this.globalService.setSelectedCluster(params['cluster'], false);
        }
        if (params['date']) {
          this.shardsViewService.setDate(params['date'], false);
        }
        if (params['clusters']) {
          this.dashboardService.setSelectedMultiCluster(split(params['clusters'], ','), false);
        }
        if (params['from'] && params['to']) {
          this.updateFromTo(params['from'], params['to'], true);
        } else {
          this.globalService.setGlobalDatePicker(null, false);
        }
        if (params['from_n'] && params['to_n']) {
          this.updateFromToNotifications(params['from_n'], params['to_n']);
        } else {
          this.globalService.setNotificationsDatePicker(null, false);
        }
        if (params['from_c'] && params['to_c']) {
          this.updateFromTo(params['from_c'], params['to_c'], false);
        } else {
          this.globalService.setEventsDatePicker(null, false);
        }
        if (params['refresh']) {
          this.globalService.setSelectedRefreshTime(params['refresh'], false);
        } else {
          this.globalService.setSelectedRefreshTime(null, false);
        }
        if (params['runSL']) {
          this.globalService.setSelectedRunSlowLogs(params['runSL'], false);
        } else {
          this.globalService.setSelectedRunSlowLogs(null, false);
        }
        if (params['nodes']) {
          this.nodeViewService.setSelectedNodes(params['nodes'], true);
        } else {
          this.nodeViewService.setSelectedNodes('', true);
        }

        if (params['indices']) {
          this.indexViewService.setSelectedIndices(params['indices'], false);
        } else {
          this.indexViewService.setSelectedIndices(null, false);
        }
        if (params['shardsSort']) {
          this.shardsViewService.setSortBy(params['shardsSort'], false);
        } else {
          this.shardsViewService.setSortBy(null, false);
        }

        if (params['searchIndices']) {
          this.indexViewService.setSearchIndices(params['searchIndices'], false);
        } else {
          this.indexViewService.setSearchIndices('', false);
        }
        if (params['runTO']) {
          this.globalService.setSelectedRunTemplateOptimizer(params['runTO'], false);
        } else {
          this.globalService.setSelectedRunTemplateOptimizer('', false);
        }
        if (params['eventId']) {
          this.globalService.setSelectedAnalysisId(toNumber(params['eventId']), false);
        } else {
          this.globalService.setSelectedAnalysisId('', false);
        }
        if (params['date']) {
          if (isNumeric(params['date'])) {
            this.globalService.setSingleDatePicker(
              {label: null, range: {endDate: moment.unix(params['date'] / 1000)}},
              false);
          } else {
            this.globalService.setSingleDatePicker({label: split(params['date'], '_').join(' '), range: null}, false);
          }
        } else {
          this.globalService.setSingleDatePicker(null, true);
        }

        if (params['fullscreen'] && params['categoryId'] && params['metric']) {

          const fullScreenParams = {
            fullscreen: params['fullscreen'],
            categoryId: params['categoryId'],
            metric: params['metric']
          };

          this.globalService.setFullScreen(fullScreenParams);
        }
      }
    );
  }


  updateFromTo(from, to, isGlobal) {
    if (isNumeric(from) && isNumeric(to)) {
      isGlobal ?
        this.globalService.setGlobalDatePicker(
          {
            label: null,
            range: {
              startDate: moment.unix(from / 1000),
              endDate: moment.unix(to / 1000)
            }
          }, false) :
        this.globalService.setEventsDatePicker(
          {
            label: null,
            range: {
              startDate: moment.unix(from / 1000),
              endDate: moment.unix(to / 1000)
            }
          }, false);
    } else if (from) {
      isGlobal ?
        this.globalService.setGlobalDatePicker({label: split(from, '_').join(' '), range: null}, false) :
        this.globalService.setEventsDatePicker({label: split(from, '_').join(' '), range: null}, false);
    }
  }

  updateFromToNotifications(from, to) {
    if (isNumeric(from) && isNumeric(to)) {

        this.globalService.setNotificationsDatePicker(
          {
            label: null,
            range: {
              startDate: moment.unix(from / 1000),
              endDate: moment.unix(to / 1000)
            }
          }, false);
    } else if (from) {
        this.globalService.setNotificationsDatePicker({label: split(from, '_').join(' '), range: null}, false);
    }
  }

  loadMaterial() {
    const materialIcons = new FontFaceObserver('Material Icons');
    materialIcons.load(null, 10000)
      .then(() => this.renderer.addClass(document.body, `loaded`))
      .catch(() => this.renderer.addClass(document.body, `error`)); // this line not necessary for simple example
  }


}
