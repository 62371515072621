import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'reviews-wizard',
  templateUrl: './reviews-wizard.component.html',
  styleUrls: ['./reviews-wizard.component.scss'],
})
export class ReviewsWizardComponent implements OnInit {
  @Input() autoplayInterval = 2000;

  public reviews1 = {
    companyImg: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:auto/h:auto/q:mauto/https://opster.com/wp-content/uploads/2021/03/Cybereason.svg',
    content: '"Using the platform and the \n' +
      'team’s support we’ve been able \n' +
      'to revamp our data structure, avoid incidents and improve performance.”',
    name: 'Eviatar Tenne',
    job: 'Data Platform Director',
    img: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:60/h:60/q:mauto/https://opster.com/wp-content/uploads/2021/06/Eviatar-Cybereason.jpeg',
  };

  public reviews2 = {
    companyImg: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:auto/h:auto/q:mauto/https://opster.com/wp-content/uploads/2021/04/Coupa-Logo.svg',
    content: '"Since working with Opster, we’ve been able to significantly improve our Elasticsearch performance and reduce our hardware costs by over $4 million.”',
    name: 'Arpit Ghiya',
    job: 'Senior Lead SRE',
    img: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:60/h:60/q:mauto/https://opster.com/wp-content/uploads/2022/04/arpit-ghiya.jpeg',
  };

  public reviews3 = {
    companyImg: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:auto/h:auto/q:mauto/https://opster.com/wp-content/uploads/2021/03/BMC.svg',
    content: '"Thanks to Opster we avoided production incidents, saved crucial time and we still benefit constantly from the AutoOps platform and their quick support.”',
    name: 'Nathan Amichay',
    job: 'Product Development Architect',
    img: 'https://mlbtihvv1ztx.i.optimole.com/d3LURAQ.nwrn~19701/w:60/h:60/q:mauto/https://opster.com/wp-content/uploads/2021/07/image-2.png',
  };


  public allReviews = [this.reviews1, this.reviews2, this.reviews3];
  private clickedOnReviews = false;

  constructor() {
  }

  onPage() {
    if (!this.clickedOnReviews) {
      this.clickedOnReviews = true;
    }
  }

  ngOnInit(): void {
  }


}
