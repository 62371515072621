<div id="container-loader">
  <div id="spinner">
    <mat-spinner  [strokeWidth]="2" [diameter]="70"></mat-spinner>
  </div>
  <div id="info">
    <div class="loader" [ngClass]="{'okta': okta}" >
      <mat-icon class="icon-opster" [svgIcon]="'opster_no_border'"></mat-icon>
      <div *ngIf="okta" class="logo-and-okta">+</div>
      <div *ngIf="okta" class="okta"></div>

    </div>
    <p>{{text}}...</p>
  </div>
</div>


