import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {TopMenuComponent} from './top-menu.component';

import {MatIconModule} from '@angular/material/icon';

import {TooltipModule} from 'primeng/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {InputMultiSelectModule} from '../../shared/components/inputs/input-multi-select/input-multi-select.module';
import {TimeRangeModule} from '../../shared/components/inputs/time-range/time-range.module';
import {InputSelectModule} from '../../shared/components/inputs/input-select/input-select.module';
import {ArrFilterModule} from '../../shared/pipe/arr-filter/arr-filter.pipe';
import {TreeSelectOpsterModule} from '../../shared/components/inputs/tree-select/tree-select.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    TopMenuComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        TranslateModule,
        TooltipModule,
        MatButtonModule,
        InputMultiSelectModule,
        TimeRangeModule,
        InputSelectModule,
        ArrFilterModule,
        TreeSelectOpsterModule,
        MatProgressSpinnerModule,
        DynamicDialogModule,
        BadgeModule,
    ],
  providers: [],
  exports: [TopMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TopMenuModule {
}
