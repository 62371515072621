import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { InputSelectTypes } from '../inputs.enum';
import { DatePipe } from '@angular/common';
import isEqual from 'lodash-es/isEqual';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';
import get from 'lodash-es/get';
import map from 'lodash-es/map';
import set from 'lodash-es/set';

const MANUAL = 'MANUAL';

@Component({
  selector: 'tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectComponent implements OnInit, OnChanges {
  @Input() items;
  @Input() selectedItem;
  @Input() loadingSelectedItem = false;
  @Input() type;

  @Output() selectionChange = new EventEmitter();

  public InputSelectTypes = InputSelectTypes;
  public nameItem = 'Templates';
  public placeholder;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'items.currentValue')) {
      switch (this.type) {
        case this.InputSelectTypes.TEMPLATE_OPTIMIZER:
          this.placeholder = 'No Previous Runs';
          // this.label = 'executionTime';
          const selectedTemplate = get(changes, 'selectedItem.currentValue') || this.selectedItem;
          this.selectedItem = find(flatten(map(this.items, 'children')), {'id': get(selectedTemplate, 'id')});

          map(this.items, item => {
            set(item, 'selectable', false);
            set(item, 'leaf', true);
            set(item, 'droppable', true);

            map(get(item, 'children'), value => {
              set(value, 'data', this.datePipe.transform(get(value, 'executionTime'), 'd MMM y hh:mm a'));
              set(value, 'label', this.datePipe.transform(get(value, 'executionTime'), 'd MMM y hh:mm a'));
              set(value, 'collapsedIcon', isEqual(get(value, 'source'), MANUAL) ? 'pi pi-file-edit' : 'pi pi-file');
              set(value, 'tooltip', this.datePipe.transform(get(value, 'executionTime'), 'd MMM y hh:mm a'));
            });
          });

          break;
      }
    }
  }

  onSelectionChange(event) {
    this.selectionChange.emit(get(event, 'node'));
  }

}
