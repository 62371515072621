import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LineChartComponent } from './line-chart.component';

@NgModule({
  declarations: [
    LineChartComponent
  ],
  exports: [
    LineChartComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  providers: [TitleCasePipe]
})
export class LineChartModule {
}
