import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastComponent } from './toast.component';
import { ToastModule } from 'primeng/toast';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    ToastComponent,

  ],
  imports: [
    CommonModule,
    ToastModule,
    MatIconModule,
  ],
  providers: [],
  exports: [
    ToastComponent
  ]
})
export class ToastOpsterModule {
}
