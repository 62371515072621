import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InstallationServiceService {
  private configuration$: BehaviorSubject<any>;

  constructor(private route: ActivatedRoute) {
    this.configuration$ = new BehaviorSubject<any>(null);

  }

  public setConfiguration(item) {
    this.configuration$.next(item);
  }

  public getConfiguration(): Observable<any> {
    return this.configuration$.asObservable();
  }

  public clearConfiguration() {
    this.configuration$.next(null);
  }

  public getEnvironment(): string {
    return this.route.snapshot.queryParams['environment'];
  }


}
