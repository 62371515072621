import { NgModule, Pipe, PipeTransform } from '@angular/core';
import indexOf from 'lodash-es/indexOf';
import lowerCase from 'lodash-es/lowerCase';
import get from 'lodash-es/get';

@Pipe({
  name: 'arrFilter',
  pure: false
})
export class ArrFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    const filterValue = lowerCase(filter);

    return indexOf(items.filter(item => lowerCase(get(item, 'name'))), filterValue) !== -1;
  }
}

@NgModule({
  declarations: [ArrFilterPipe],
  imports: [
  ],
  exports: [ArrFilterPipe]
})
export class ArrFilterModule {}
