import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import get from 'lodash-es/get';

export enum IntersectionStatus {
  Visible = 'Visible',
  // Pending = 'Pending',
  NotVisible = 'NotVisible'
}

export const RootMarginMultiplier = 1.5;

export const fromIntersectionObserver = (
  element: HTMLElement,
  config: IntersectionObserverInit,
  debounce = 0
) =>
  new Observable<IntersectionStatus>(subscriber => {
    const subject$ = new Subject<{
      entry: IntersectionObserverEntry;
      observer: IntersectionObserver;
    }>();

    const intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (isIntersecting(entry)) {
            subject$.next({entry, observer});
          } else {
            subject$.next({entry, observer});
          }
        });
      },
      config
    );

    subject$
      .pipe(
        debounceTime(debounce),
        filter(Boolean)
      )
      .subscribe(async ({entry}) => {
        const isEntryVisible = await isVisible(get(entry, 'target') as HTMLElement);

        if (isEntryVisible) {
          subscriber.next(get(IntersectionStatus, 'Visible'));
          // observer.unobserve(entry.target);
        } else {
          subscriber.next(get(IntersectionStatus, 'NotVisible'));
        }
      });

    intersectionObserver.observe(element);

    return {
      unsubscribe() {
        intersectionObserver.disconnect();
        subject$.unsubscribe();
      }
    };
  });

async function isVisible(element: HTMLElement) {
  return new Promise(resolve => {
    const observer = new IntersectionObserver(([entry]) => {
      const rootMargin = RootMarginMultiplier * innerHeight;
      resolve(get(entry, 'boundingClientRect.top') < (get(entry, 'rootBounds.bottom') + rootMargin)
        && get(entry, 'boundingClientRect.bottom') > (get(entry, 'rootBounds.top') - rootMargin));
      observer.disconnect();
    });

    observer.observe(element);
  });
}

function isIntersecting(entry: IntersectionObserverEntry) {
  return get(entry, 'isIntersecting');
}
