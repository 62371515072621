import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { InputSelectComponent } from './input-select.component';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { SkeletonLoaderModule } from '../../skeleton-loader/skeleton-loader.module';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [
    InputSelectComponent,
  ],
    imports: [
        CommonModule,
        DropdownModule,
        TooltipModule,
        FormsModule,
        SkeletonLoaderModule,
        TabViewModule,
    ],
  providers: [],
  exports: [
    InputSelectComponent
  ]
})
export class InputSelectModule {
}
