import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SegmentTrackService } from '../../../shared/services/segment/segment.service';
import { OpsterChatService } from '../service/opster-chat.service';
import { filter as rxFilter, first as rxFirst, map as rxMap, takeUntil } from 'rxjs/operators';
import { GlobalService } from '../../../store/global/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';

import get from 'lodash-es/get';
import random from 'lodash-es/random';
import set from 'lodash-es/set';
import size from 'lodash-es/size';
import upperCase from 'lodash-es/upperCase';
import concat from 'lodash-es/concat';
import isEqual from 'lodash-es/isEqual';
import findIndex from 'lodash-es/findIndex';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import filter from 'lodash-es/filter';
import groupBy from 'lodash-es/groupBy';
import forEach from 'lodash-es/forEach';
import first from 'lodash-es/first';

import { waitingScreenSentences } from '../../../../assets/sentences/waiting-screen-sentences';
import { interval, Subject } from 'rxjs';
import { ToastService } from '../../../store/toast/toast.service';
import { GIFS, GptPayload } from '../ops-gpt.interface';
import {
  ConfidenceDialogComponent
} from '../../../shared/components/popups/dialog/confidence-dialog/confidence-dialog.component';
import { DialogSize } from '../../../shared/components/popups/dialog/dialog.enum';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InstallationServiceService } from '../../settings/installation/service/installation-service.service';
import { MainDashboardService } from '../../dashboard/service/main-dashboard.service';
import includes from 'lodash-es/includes';
import { PerformanceUtils } from '../../../shared/services/utils/functional-utils';

const SHOW_MULTI_CLUSTERS = {'dashboard': true};
const INSTALLATION_PAGE = 'installation';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'main-chat',
  templateUrl: './main-chat.component.html',
  styleUrls: ['./main-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user;
  @Input() isQuickOpsGpt = false;
  @Input() gptPayload: GptPayload = {};
  @Input() currentUrl;

  private isOpsgptPolicyAgreed = false;

  public currentChat = [];
  public conversationId;

  public exampleInit =
    [
      'What are the most pressing issues in my cluster?',
      'Can I reduce resources and cost in the cluster and how?',
      'How is my cluster performing, in terms of search and indexing performance?'
    ];

  public exampleInitInstallation =
    [
      'I am unable to connect the cluster',
      'Why do I need to install Opster’s agent?',
      'Provide detailed guidance on how to install the AutoOps agent'
    ];

  public question: string;
  public userShortName: string;

  public chatLoading: boolean;
  public loadingHistory = false;
  public loadingSentence: string;

  public options: AnimationOptions = {};
  @ViewChild('sc') scrollPanel: ElementRef;
  @ViewChild('textAreaQuestion') textAreaQuestion: ElementRef;
  private intervalSubscription: Subject<boolean> = new Subject<boolean>();
  private destroyAskQustion$: Subject<boolean> = new Subject<boolean>();

  public answerAll = '';
  public answerError;
  public index = 0;
  public userChatId = null;
  public messageId;

  public allClusters;
  public multiClustersGroup;
  public loadingSelectedCluster;
  public multiClustersDisabledList;
  public selectedMultiCluster;
  public isMultiSelectPanelShowing;

  public afterFirstTime = false;
  public positionBottom = true;

  public showMultiClusters;

  public typesToInclude: string[];
  public closedAnalysisTimeRange;
  public analysisIds;

  public isInstallation;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private opsterChatService: OpsterChatService,
              private ref: ChangeDetectorRef,
              private globalService: GlobalService,
              private dialog: MatDialog,
              private router: Router,
              private toastService: ToastService,
              private translateService: TranslateService,
              private dashboardService: MainDashboardService,
              private installationServiceService: InstallationServiceService,
              private segmentTrackService: SegmentTrackService) {
    this.multiClustersDisabledList = new Map<string, boolean>();

  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler() {
    event.preventDefault();
    this.checkUserPolicyAgreement(this.question);
  }

  ngOnInit(): void {
    this.globalService.getPolicyOpsgpt()
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        this.isOpsgptPolicyAgreed = val;
        this.ref.markForCheck();
      });

    this.getClusterList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'user.currentValue')) {
      this.getConversationId();

      this.userShortName = get(this.user, 'fullName')
        ? upperCase(get(this.user, 'fullName')?.substring(0, 2))
        : upperCase(get(this.user, 'email')?.substring(0, 2));
    }
    if (get(changes, 'currentUrl.currentValue')) {
      this.isInstallation = includes(this.currentUrl, INSTALLATION_PAGE);
    }
    if (get(changes, 'gptPayload.currentValue')) {
      const prompt = get(this.gptPayload, 'prompt');
      this.typesToInclude = get(this.gptPayload, 'typesToInclude');
      this.closedAnalysisTimeRange = get(this.gptPayload, 'closedAnalysisTimeRange');

      if (!isEqual(this.analysisIds, get(this.gptPayload, 'analysisIds'))) {
        this.analysisIds = get(this.gptPayload, 'analysisIds');
        this.newChat(this.afterFirstTime);
      }


      if (prompt) {
        if (this.allClusters) {
          const clusters = get(this.gptPayload, 'clusters');
          this.buildMultiSelectedCluster(clusters);
        }
        this.question = prompt;
        this.askQuestion(prompt);
      }
    }
  }

  getClusterList() {
    this.showMultiClusters = this.currentUrl ? SHOW_MULTI_CLUSTERS[this.currentUrl] : true;

    this.globalService.getClusterList()
      .pipe(untilDestroyed(this), rxFirst())
      .subscribe(clusterList => {
        this.allClusters = filter(clusterList, x => get(x, 'clusterAlias') &&
          !isEqual(get(x, 'clusterAlias'), 'na') &&
          !isEqual(get(x, 'cluster'), 'na') &&
          get(x, 'enabled'));

        this.multiClustersGroup = this.buildMultiClusterGroup(this.allClusters);
        this.loadingSelectedCluster = false;

        if (this.showMultiClusters) {
          this.dashboardService.getSelectedMultiCluster()
            .pipe(rxFirst(), untilDestroyed(this))
            .subscribe(ids => {
              this.buildMultiSelectedCluster(ids);
            });
        } else {
          this.globalService.getSelectedCluster()
            .pipe(rxFirst(), untilDestroyed(this))
            .subscribe(id => {
              this.buildMultiSelectedCluster([id]);
            });
        }

      });
  }

  buildMultiClusterGroup(allClusters) {
    if (this.isMultiSelectPanelShowing) {
      return;
    }

    const clustersByActive = groupBy(allClusters, 'active');

    const clusterGroups = [];
    if (get(clustersByActive, 'true')) {
      clusterGroups.push(
        {
          label: 'Active',
          value: 'Active',
          items: map(get(clustersByActive, 'true'), (item) => ({
            label: get(item, 'clusterAlias') + ' (' + get(item, 'cluster') + ')',
            value: {...item, name: get(item, 'clusterAlias')},
            disabled: false
          }))
        });
    }
    if (get(clustersByActive, 'false')) {
      clusterGroups.push(
        {
          label: 'Inactive',
          value: 'Inactive',
          items: map(get(clustersByActive, 'false'), (item) => {
            this.multiClustersDisabledList.set(get(item, 'cluster'), true);
            return {
              label: get(item, 'clusterAlias') + ' (' + get(item, 'cluster') + ')',
              value: {...item, name: get(item, 'clusterAlias')},
              disabled: true
            };
          })
        });
    }

    return clusterGroups;

  }

  buildMultiSelectedCluster(ids) {
    const clusterIds = get(this.gptPayload, 'clusters') || ids;

    if (this.isMultiSelectPanelShowing) {
      return;
    }
    try {
      const idsMap = new Map<string, string>(map(clusterIds, (id) => [id, id]));
      const filterCluster = [];
      const activeItems = get(first(this.multiClustersGroup), 'items');
      forEach(activeItems, (cluster) => {
        const clusterValue = get(cluster, 'value');
        if (idsMap.has(get(clusterValue, 'cluster'))) {
          filterCluster.push({
            ...clusterValue,
            name: get(clusterValue, 'clusterAlias')
          });
        }
      });
      this.selectedMultiCluster = filterCluster;
    } catch (e) {
      console.log(e);
    }

    this.ref.markForCheck();
  }

  multiSelectPanelOpened() {
    this.isMultiSelectPanelShowing = true;
  }

  selectionMultiClusterChange(cluster) {
    this.isMultiSelectPanelShowing = false;
    if (isEqual(this.selectedMultiCluster, cluster)) {
      return;
    }
    const clusterIds = map(cluster, 'cluster');

    this.segmentTrackService.setAnalyticsTrackToServer(`Autoops OpsGPT Select cluster`, {
      email: this.segmentTrackService.getTrackEmail,
      numClusters: clusterIds?.length,
      isSideOpsGpt: this.isQuickOpsGpt
    });

    this.buildMultiSelectedCluster(clusterIds);
  }

  getConversationId() {
    this.opsterChatService.getConversationId()
      .pipe(untilDestroyed(this))
      .subscribe(id => {
        if (!isEqual(this.conversationId, id)) {
          this.conversationId = id;
          this.getConversation(this.conversationId);
        }
      });
  }

  getConversation(conversationId) {
    if (!this.conversationId) {
      return;
    }

    this.afterFirstTime = true;
    this.stopDestroyAskQustion();

    this.loadingHistory = true;
    this.ref.markForCheck();

    try {
      this.opsterChatService.getConversation(conversationId)
        .pipe(rxFirst(), untilDestroyed(this))
        .subscribe(conversation => {
          const clustersId = get(first(conversation), 'clustersId');
          if (clustersId) {
            set(this.gptPayload, 'clusters', clustersId);
          }

          this.getClusterList();

          this.currentChat = map(conversation, x => {

            set(x, 'error', isEmpty(get(x, 'answer')));

            set(x, 'answer', (!isEmpty(get(x, 'answer')) &&
              !isEqual(this.answerAll, get(x, 'answer'))) ? get(x, 'answer') :
              'We’re getting a high influx of requests at the moment, please try again in a few moments.\n' +
              'Should the issue persist, reach out to us using the' +
              ' Intercom button below or email us at info@opster.com');
            return x;
          });


          this.loadingHistory = false;
          this.ref.markForCheck();
        });
    } catch (e) {
      console.log(e);
    }
  }

  checkUserPolicyAgreement(question) {
    if (!this.isOpsgptPolicyAgreed) {
      this.openOpsgptPolicyDialog(question);
    } else {
      this.askQuestion(question);
    }
  }

  askQuestion(question) {
    if (!question || !size(question) || this.chatLoading) {
      return;
    }

    const randomId = random(0, 5);
    set(this.options, 'path', GIFS[randomId]);


    this.setLoadingSentence(true);

    this.scrollToBottom();
    this.afterFirstTime = true;

    this.ref.markForCheck();

    this.destroyAskQustion$ = new Subject<boolean>();

    this.opsterChatService.askQuestion(this.conversationId, question,
      map(this.selectedMultiCluster, 'cluster'), this.typesToInclude, this.closedAnalysisTimeRange, this.analysisIds, get(this.gptPayload, 'isInstallation'))
      .pipe(takeUntil(this.destroyAskQustion$), untilDestroyed(this),
        rxFilter((e: any) => get(e, 'type') === 3 && get(e, 'partialText')),
        rxMap(e => {

          this.chatLoading = false;
          this.ref.markForCheck();

          const partialText: string = get(e, 'partialText');
          return partialText ? partialText.trim().split('\r\n') : null;
        }))
      .subscribe(
        event => {
          try {
            // @ts-ignore
            event.slice(this.index).forEach((element, index) => {
              this.index++;
              const ans = JSON.parse(element);
              if (get(ans, 'answer')) {
                this.answerAll = this.answerAll.concat(get(ans, 'answer'));
                this.ref.markForCheck();
              }
              if (get(ans, 'message') && !isEqual(get(ans, 'code'), 200)) {
                this.askQuestionError(question, ans);
                this.answerError = get(ans, 'message');

                return;
              }
              if (get(ans, 'messageId')) {
                this.messageId = get(ans, 'messageId');
              }
              if (!this.userChatId) {
                this.userChatId = get(ans, 'userChatId');
              }

            });

            this.scrollToBottom();

          } catch (e) {

          }


        },
        error => {
          this.askQuestionError(question, error);
        },
        () => {
          if (!this.conversationId) {
            this.conversationId = this.userChatId;
            this.opsterChatService.setConversationId(this.conversationId, true, !this.isQuickOpsGpt);
            this.opsterChatService.getAllChatsFromServer();
          }

          if (!this.answerError) {
            const historyNum = this.opsterChatService.getAllChatsCount() || 1;
            const questionNum = size(this.currentChat) || 1;

            this.currentChat = concat(this.currentChat, {
              prompt: question,
              answer: (!isEmpty(this.answerAll) && !isEqual(this.answerAll, question)) ? this.answerAll :
                'We’re getting a high influx of requests at the moment, please try again in a few moments.\n' +
                'Should the issue persist, reach out to us using the Intercom button below or email us at info@opster.com',
              error: isEmpty(this.answerAll) || isEqual(this.answerAll, question),
              messageId: this.messageId
            });

            if (get(this.gptPayload, 'isInstallation')) {
              this.installationServiceService.getConfiguration()
                .pipe().subscribe(configuration => {
                this.segmentTrackService.setAnalyticsTrackToServer(
                  'Autoops OpsGPT installation ask question successfully',
                  {
                    isSideOpsGpt: this.isQuickOpsGpt,
                    email: this.segmentTrackService.getTrackEmail,
                    date: new Date().toLocaleString(),
                    messageId: this.messageId,
                    questionNum: questionNum,
                    historyNum: historyNum,
                    esClusterUrl: get(configuration, 'esClusterUrl'),
                    authenticationMethods: get(configuration, 'authenticationMethods'),
                    environment: this.installationServiceService.getEnvironment(),
                    question,
                  });
              });

            } else {
              this.segmentTrackService.setAnalyticsTrackToServer(
                'Autoops OpsGPT ask question successfully', {
                  isSideOpsGpt: this.isQuickOpsGpt,
                  email: this.segmentTrackService.getTrackEmail,
                  date: new Date().toLocaleString(),
                  messageId: this.messageId,
                  questionNum: questionNum,
                  historyNum: historyNum,
                  question,
                });
            }
          }

          this.messageId = null;
          this.answerError = null;
          this.answerAll = '';
          this.question = '';
          this.index = 0;
          this.userChatId = null;

          setTimeout(() => {
            get(this.textAreaQuestion, 'nativeElement').focus();
            this.scrollToBottom();
          }, 0);
          this.ref.markForCheck();
          this.setLoadingSentence(false);
        });

  }

  askQuestionError(question, error) {
    this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT ask question connection/server failed', {
      isSideOpsGpt: this.isQuickOpsGpt,
      email: this.segmentTrackService.getTrackEmail,
      question,
      error: get(error, 'message')
    });

    try {
      this.currentChat = concat(this.currentChat, {
        prompt: question,
        answer: get(error, 'message') || 'We’re getting a high influx of requests at the moment, please try again in a few moments.\n' +
          'Should the issue persist, reach out to us using the Intercom button below or email us at info@opster.com',
        error: true
      });

      this.chatLoading = false;

    } catch (e) {
      console.log(e);
    }

    this.scrollToBottom();
    this.ref.markForCheck();
  }

  onScroll() {
    const elm = get(this.scrollPanel, 'nativeElement');
    const scrollHeight = get(elm, 'scrollHeight');
    const clientHeight = get(elm, 'clientHeight');
    const scrollTop = get(elm, 'scrollTop');
    this.positionBottom = clientHeight >= (scrollHeight - scrollTop - 20);
  }

  scrollToBottom() {
    if (this.positionBottom && get(this.scrollPanel, 'nativeElement')) {
      get(this.scrollPanel, 'nativeElement').scrollTo(0, get(this.scrollPanel, 'nativeElement.scrollHeight'));
    }

  }

  copyToClipboard(answer) {
    this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT copy to clipboard answer', {
      email: this.segmentTrackService.getTrackEmail,
      answer
    });

    this.toastService.queueSnackBar(null,
      this.translateService.instant('recommendations.copy_clipboard'), 'success');
  }

  selectExample(example) {
    if (get(this.gptPayload, 'isInstallation')) {
      this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT installation clicked on example', {
        email: this.segmentTrackService.getTrackEmail,
        environment: this.installationServiceService.getEnvironment(),
        example
      });
    } else {
      this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT clicked on example', {
        email: this.segmentTrackService.getTrackEmail,
        example
      });
    }

    this.question = example;
    this.checkUserPolicyAgreement(example);
  }

  newChat(afterFirstTime: boolean = false) {
    set(this.gptPayload, 'isInstallation', this.isInstallation);

    if (afterFirstTime) {
      set(this.gptPayload, 'clusters', null);
    }

    this.getClusterList();
    this.stopDestroyAskQustion();

    this.conversationId = null;
    this.opsterChatService.setConversationId(null, true);

    this.currentChat = [];
    this.afterFirstTime = false;

    this.ref.markForCheck();
    // this.location.replaceState('/ops_gpt');
  }

  openFullPage() {
    this.router.navigate(['/ops_gpt']).then(() => {
    });

    this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT clicked on full page', {
      email: this.segmentTrackService.getTrackEmail,
    });
  }

  setFeedback(messageId: string, score: boolean) {
    if (!messageId || !this.conversationId) {
      return;
    }

    this.opsterChatService.setFeedback(this.conversationId, messageId, score)
      .pipe(rxFirst(), untilDestroyed(this))
      .subscribe(() => {

        const index = findIndex(this.currentChat, {messageId: messageId});
        const item = get(this.currentChat, index);

        const body = {
          prompt: get(item, 'prompt'),
          answer: get(item, 'answer'),
          messageId: get(item, 'messageId'),
          score
        };

        this.currentChat.splice(index, 1, body);
        this.ref.markForCheck();

        // this.notificationService.queueSnackBar(null, 'Saved successfully', 'success');

        this.segmentTrackService.setAnalyticsTrackToServer('Autoops opsGPT was this chat helpful', {
          isSideOpsGpt: this.isQuickOpsGpt,
          email: this.segmentTrackService.getTrackEmail,
          prompt: get(item, 'prompt'),
          messageId: get(item, 'messageId'),
          score,
          isInstallation: this.isInstallation
        });
      });
  }

  setLoadingSentence(val) {
    this.chatLoading = val;

    if (val) {
      let randomId = random(0, 262);

      this.loadingSentence = waitingScreenSentences[randomId];
      this.intervalSubscription = new Subject<boolean>();
      interval(2000)
        .pipe(takeUntil(this.intervalSubscription), untilDestroyed(this))
        .subscribe(() => {
          randomId = random(0, 262);

          this.loadingSentence = waitingScreenSentences[randomId];
          this.ref.markForCheck();
        });
    } else if (this.intervalSubscription) {
      this.intervalSubscription.next(null);
      this.intervalSubscription.unsubscribe();
    }

    this.ref.markForCheck();
  }

  stopDestroyAskQustion() {
    if (this.destroyAskQustion$ && !get(this.destroyAskQustion$, 'closed')) {
      this.destroyAskQustion$.next(null);
      this.destroyAskQustion$.unsubscribe();
    }
  }

  closeOpsGpt() {
    const gptPayload: GptPayload = {
      display: false
    };
    this.globalService.setQuickOpsGpt(gptPayload);
  }

  openOpsgptPolicyDialog(question) {
    const title = 'Introducing OpsGPT in AutoOps';
    const msg = `While OpsGPT aims for accuracy, it may occasionally yield misleading information. Verify answers with Opster’s expert support team.\n`;
    const msg2 = `By using OpsGPT, you acknowledge OpenAI as a sub-processor, processing the data you submit and some data collected & processed by AutoOps.`;
    const actionAfterConfidence = () => {
      this.globalService.setPolicyOpsgpt(true);
      this.askQuestion(question);
    };
    const dialogButtonPipeConfig = {
      ok: {
        label: 'Use OpsGPT',
        icon: 'ops_gpt_dark',
        action: () => {
          actionAfterConfidence();
        }
      },
      cancel: {
        label: 'Cancel',
        action: () => {
        }
      },
    };

    this.openDialogConfidence(dialogButtonPipeConfig, msg, msg2, title);
  }

  openDialogConfidence(dialogButtonConfig, msg, msg2, title) {
    this.dialog.open(ConfidenceDialogComponent, {
      disableClose: true,
      width: DialogSize.medium,
      data: {dialogButtonConfig: dialogButtonConfig, message: msg, message2: msg2, title: title},
      id: 'confidence-dialog',
      autoFocus: false
    });
  }

  ngOnDestroy() {
    this.stopDestroyAskQustion();
  }


}
