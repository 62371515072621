export const getHealthsMock = ({
  'bucketTimeInHour': 72,
  'response': {
    'COST_UPGRADE_CLUSTER_VERSION': [
      {
        'startTimestamp': 1689001000,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 0
      },
      {
        'startTimestamp': 1689001200,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 1
      }
    ],
    'NEW_TEMPLATE_DETECTED': [
      {
        'startTimestamp': 1688227200,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 6
      },
      {
        'startTimestamp': 1688227500,
        'endTimeStamp': 1689001700,
        'eventsCountOverTime': 0
      },
      {
        'startTimestamp': 1688227700,
        'endTimeStamp': 1689001900,
        'eventsCountOverTime': 0
      },
      {
        'startTimestamp': 1689001200,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 1
      },
      {
        'startTimestamp': 1688227700,
        'endTimeStamp': 1689001900,
        'eventsCountOverTime': 0
      },
    ],
    'LONG_RUNNING_SEARCH_TASK': [
      {
        'startTimestamp': 1688220000,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 1
      },
      {
        'startTimestamp': 1689001200,
        'endTimeStamp': 1689001200,
        'eventsCountOverTime': 1
      }
    ]
  }
});

export const getHealthsMock2 = ({
  'bucketTimeInHour': 76,
  'response': {
    'types': [
      'LONG_RUNNING_SEARCH_TASK',
      'COST_UPGRADE_CLUSTER_VERSION',
      'NEW_TEMPLATE_DETECTED'
    ],
    'events': [
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686386556000,
        'endTimestamp': 1686660156000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686660156000,
        'endTimestamp': 1686933756000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686933756000,
        'endTimestamp': 1687207356000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687207356000,
        'endTimestamp': 1687480956000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687480956000,
        'endTimestamp': 1687754556000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687754556000,
        'endTimestamp': 1688028156000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688028156000,
        'endTimestamp': 1688301756000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': 28181,
        'severity': 'MEDIUM',
        'startTimestamp': 1688301756000,
        'endTimestamp': 1688575356000,
        'eventsCountOverTime': 1,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688575356000,
        'endTimestamp': 1688848956000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688848956000,
        'endTimestamp': 1689122556000,
        'eventsCountOverTime': 0,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': 28210,
        'severity': 'MEDIUM',
        'startTimestamp': 1689122556000,
        'endTimestamp': 1689141555833,
        'eventsCountOverTime': 1,
        'type': 'LONG_RUNNING_SEARCH_TASK',
        'caption': 'Long Running Search Task'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1686386556000,
        'endTimestamp': 1686660156000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1686660156000,
        'endTimestamp': 1686933756000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1686933756000,
        'endTimestamp': 1687207356000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1687207356000,
        'endTimestamp': 1687480956000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1687480956000,
        'endTimestamp': 1687754556000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1687754556000,
        'endTimestamp': 1688028156000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1688028156000,
        'endTimestamp': 1688301756000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1688301756000,
        'endTimestamp': 1688575356000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1688575356000,
        'endTimestamp': 1688848956000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'LOW',
        'startTimestamp': 1688848956000,
        'endTimestamp': 1689122556000,
        'eventsCountOverTime': 0,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': 28211,
        'severity': 'LOW',
        'startTimestamp': 1689122556000,
        'endTimestamp': 1689141555833,
        'eventsCountOverTime': 1,
        'type': 'COST_UPGRADE_CLUSTER_VERSION',
        'caption': 'Upgrade Version'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686386556000,
        'endTimestamp': 1686660156000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686660156000,
        'endTimestamp': 1686933756000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1686933756000,
        'endTimestamp': 1687207356000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687207356000,
        'endTimestamp': 1687480956000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687480956000,
        'endTimestamp': 1687754556000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1687754556000,
        'endTimestamp': 1688028156000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688028156000,
        'endTimestamp': 1688301756000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': 28182,
        'severity': 'MEDIUM',
        'startTimestamp': 1688301756000,
        'endTimestamp': 1688575356000,
        'eventsCountOverTime': 6,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688575356000,
        'endTimestamp': 1688848956000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': -1,
        'severity': 'MEDIUM',
        'startTimestamp': 1688848956000,
        'endTimestamp': 1689122556000,
        'eventsCountOverTime': 0,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      },
      {
        'id': 28212,
        'severity': 'MEDIUM',
        'startTimestamp': 1689122556000,
        'endTimestamp': 1689141555833,
        'eventsCountOverTime': 1,
        'type': 'NEW_TEMPLATE_DETECTED',
        'caption': 'New template detected'
      }
    ]
  }
});
