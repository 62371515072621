import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IndexOnNodeDataResponse } from '../shards-view.interface';
import { Router } from '@angular/router';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShardsViewService {
  private sortBy$: BehaviorSubject<any>;
  private date$: BehaviorSubject<any>;

  constructor(private httpClient: HttpClient,
              private router: Router) {
    this.sortBy$ = new BehaviorSubject<any>({
      name: 'Indexing Rate/Sec',
      border: false,
      code: 'index_rate_per_second',
      asc: false,
      icon: 'arrow_downward'
    });

    this.date$ = new BehaviorSubject<any>(null);
  }

  public getIndexNodesRates(body, clusterId): Observable<any> {
    return this.httpClient.post<any>(`api/monitoring/clusters/${clusterId}/index_nodes_rates`, body,
      {params: {skipOpsterError: 'true'}}).pipe(share());
    //  return of(data_40);
  }

  public getIndexOnNodeData(body, clusterId): Observable<IndexOnNodeDataResponse> {
    return this.httpClient.post<any>(`api/monitoring/clusters/${clusterId}/index_on_node_data`,
      body, {params: {skipOpsterError: 'true'}}).pipe(share());
  }

  // sortBy
  public setSortBy(item: any, addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            shardsSort: item
          },
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }
    this.sortBy$.next(item);
  }

  public getSortBy(): Observable<any> {
    return this.sortBy$.asObservable();
  }

  // date
  public setDate(item: any, addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            date: item
          },
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }
    this.date$.next(item);
  }

  public getDate(): Observable<any> {
    return this.date$.asObservable();
  }

}
