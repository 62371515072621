<p-treeSelect [(ngModel)]="selectedItem" [options]="items"
              (onNodeSelect)="onSelectionChange($event)"
              [placeholder]="placeholder"
              placeholder="Select Item" [className]="'opster-tree-select'" [filter]="true">

  <ng-template pTemplate="value">
    <div class="item-value">

      <div>
        <span>{{nameItem}}</span>
        <span class="small-spacer-border"></span>
      </div>
      <span class="selected-item"
            [pTooltip]="selectedItem?.tooltip"
            [showDelay]="500"
            [innerHTML]="selectedItem?.label || 'No items'"></span>
    </div>
  </ng-template>

  <ng-template pTemplate="empty">
    <app-skeleton-loader *ngIf="loadingSelectedItem" [withItem]="false"></app-skeleton-loader>
  </ng-template>

</p-treeSelect>
