import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';
import { HttpClient } from '@angular/common/http';
import { AnalyticsTrackRequest } from './analytics-track-request';

import isEqual from 'lodash-es/isEqual';
import includes from 'lodash-es/includes';
import get from 'lodash-es/get';
import lowerCase from 'lodash-es/lowerCase';

import { first } from 'rxjs';
import { SegmentEnvKeys } from '../../../segment-env';
// import { environment } from '../../../../environments/environment';
// import { sha256 } from 'js-sha256';
// import { SECRET_SEGMENT } from '../../../../environments/environment.prod';

const BLACK_LIST = ['prod@opster.com', 'selenoid@opster.io', 'selenoid@opster.co', 'smokey+dev@opster.com', 'smokey+pro@opster.com', 'smokey@opster.com', 'smokey2@opster.com', 'smokeyfree@opster.com'];
const BLACK_LIST_COMPANY = ['gmail'];
const BLACK_LIST_DOMAIN = ['admin-prod.opster.internal', 'admin.opster.internal'];

// declare const gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class SegmentTrackService {
  private trackUserId: string;
  private email: string;
  private accountId: string;

  public afterFirstIdentify = false;
  private blackListErrors = [];

  constructor(private segment: SegmentService,
              private httpClient: HttpClient) {
  }

  init() {
    try {

      setTimeout(() => {
        this.segment.load(SegmentEnvKeys.env_prod);
        this.analyticsIdentify();
         this.analyticsTrack('load autoOps');
      }, 4000);

    } catch (e) {
      this.setAnalyticsTrackToServer('Error segment on client', {email: this.setTrackEmail});
      console.error(e);
    }
  }

  public get getBlackListErrors(): any {
    return this.blackListErrors;
  }


  public setBlackListErrors(val) {
    this.blackListErrors.push(val);
  }

  get getTrackAccountId(): string {
    return this.accountId;
  }

  set setTrackAccountId(val: string) {
    this.accountId = val;
  }

  get getTrackUserId(): string {
    return this.trackUserId;
  }

  set setTrackUserId(val: string) {
    if (val && this.trackUserId && !isEqual(this.trackUserId, val)) {
      this.analyticsAlias(val, this.trackUserId);
    }
    if (val && !isEqual(this.trackUserId, val)) {
      this.trackUserId = val;
      this.segment.setAnonymousId(val);
    }
  }

  get getTrackEmail(): string {
    return this.email;
  }

  set setTrackEmail(val) {
    this.email = val;
  }

  // @ts-ignore
  public analyticsIdentify(traits?: any, options?: any) {
    try {
      const existEmail = this.getTrackEmail || get(traits, 'email') || this.getSegmentUser();

      if (existEmail && this.checkEmail(existEmail)) {
        return;
      }
      if (this.checkDomain()) {
        return;
      }
      // if (existEmail && this.trackUserId) {
      //   this.segment.alias(existEmail, this.trackUserId).then(() => {
      //   });
      //
      // }

      this.segment.identify(this.trackUserId || null, traits, {
        integrations: {
          All: true,
          Intercom: false,
          // Intercom: !isEmpty(existEmail) ? {
          //   user_hash: this.createHmac(this.trackUserId)
          // } : true,
        }
      }).then(() => {
        const user = this.segment.user();
        this.setTrackUserId = user.id() || user.anonymousId();
      });
    } catch (e) {
      this.setAnalyticsTrackToServer('Error identify segment on client', {email: this.setTrackEmail});
      console.error(e);
    }
  }

  public analyticsTrack(eventName, properties?: any, options?: any) {
    const existEmail = this.getTrackEmail || get(properties, 'email') || this.getSegmentUser();
    if (existEmail && this.checkEmail(existEmail) || this.isBlackList(get(properties, 'inviteEmail'))) {
      return;
    }
    if (this.checkDomain()) {
      return;
    }

    // try {
    //   gtag('event', eventName, merge({
    //     'current_url': window.location.href,
    //   }, properties));
    // } catch (e) {
    //   console.log(e);
    // }

    try {
      this.segment.track(eventName, properties, options || {
        integrations: {
          Intercom: false,
          // Intercom: !isEmpty(existEmail) ? {
          //   user_hash: this.createHmac(this.trackUserId)
          // } : true
        }
      }).then(() => {
      });

    } catch (e) {
      console.log(e);
    }


  }

  public analyticsAlias(userId: string, previousId?: string, options?: any) {
    if (this.checkEmail(options)) {
      return;
    }
    if (this.checkDomain()) {
      return;
    }

    this.segment.alias(userId, previousId, options).then(() => {
    });
  }

  public analyticsPage(name?: string, properties?: any, options?: any) {
    const existEmail = this.getTrackEmail || get(properties, 'email') || this.getSegmentUser();

    if (!existEmail || this.checkEmail(existEmail)) {
      return;
    }
    if (this.checkDomain()) {
      return;
    }

    this.segment.page(name, properties, options).then(() => {
    });
  }

  public analyticsReset(): void {
    try {
      // this.setAnalyticsTrackToServer('Auto ops User did log out');
      this.setAnalyticsTrackToServer('Auto ops - User did log out');
      this.setTrackEmail = null;
      this.setTrackUserId = null;
      this.setTrackAccountId = null;
      this.segment.reset();
      localStorage.removeItem('ajs_user_id');
      localStorage.removeItem('ajs_anonymous_id');
      // localStorage.removeItem('_hjid');
      // localStorage.removeItem('ajs_group_properties');
    } catch (e) {
      console.error(e);
    }
  }

  public analyticsGroup(groupId: string, traits?: any): void {
    const existEmail = this.getTrackEmail || get(traits, 'email') || this.getSegmentUser();

    if (!existEmail || this.checkEmail(existEmail)) {
      return;
    }
    if (this.checkDomain()) {
      return;
    }
    if (this.companyInBlackList(groupId)) {
      return;
    }

    this.segment.group(groupId, traits).then(() => {
    });
  }

  public setAnalyticsTrackToServer(trackName: string, paramsTrack?: any) {
    const existEmail = this.getTrackEmail || get(paramsTrack, 'email') || this.getSegmentUser();

    if (!this.trackUserId || !existEmail || this.checkEmail(existEmail)) {
      return;
    }
    if (this.checkDomain()) {
      return;
    }

    // try {
    //   gtag('event', trackName, merge({
    //     'current_url': window.location.href,
    //   }, paramsTrack));
    //
    // } catch (e) {
    //   console.log(e);
    // }

    try {
      const body: AnalyticsTrackRequest = {
        trackName,
        params: paramsTrack || new Map(),
        userId: this.trackUserId
      };
      return this.httpClient.post<any>(`api/users/analytics-track`, body).pipe(first()).subscribe(() => {
      });
    } catch (e) {
      console.log(e);
    }

  }

  // private createHmac(userId) {
  //   try {
  //     if (!userId || !SECRET_SEGMENT) {
  //       return;
  //     }
  //     return sha256.hmac(SECRET_SEGMENT, userId);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  checkEmail(traits) {
    if (this.isBlackList(get(traits, 'email')) || this.isBlackList(this.getTrackEmail)) {
      return true;
    }
  }

  checkDomain() {
    const url = get(window, 'location.href');
    const {hostname} = new URL(url);
    return includes(BLACK_LIST_DOMAIN, hostname);
  }

  getSegmentUser() {
    try {
      return this.segment.user().traits().email;
    } catch (e) {
    }
  }

  private isBlackList(email) {
    return includes(BLACK_LIST, email);
  }

  companyInBlackList(company) {
    return includes(BLACK_LIST_COMPANY, lowerCase(company));
  }


}
