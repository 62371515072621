import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';

@Component({
  selector: 'app-no-internet-connection-dialog',
  templateUrl: './no-internet-connection-dialog.component.html',
  styleUrls: ['./no-internet-connection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoInternetConnectionDialogComponent implements OnInit {
  public dialogButton;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.action()).then(() => {
    });
  }

  ngOnInit(): void {
    this.dialogButton = get(this.data, 'dialogButtonConfig');
  }

}
