export enum NumberFormatEnum {
  NONE,
  NUMBER = 'number',
  BYTES = 'bytes',
  BYTES_GB = 'GB',
  BYTES_NONE = 'bytes none',
  PRECENT = 'percent',
  SEC = 'sec',
  BYTES_SEC = 'bytes_sec',
  MS = 'ms',
  ONLY_MS = 'ms'
}
