<p-sidebar #sidebar [visible]="display" position="right" [blockScroll]="true"
           [styleClass]="(appendTo === 'body' || withGpt)?  (analyticsGraph
           ? 'sidebar-big sidebar-left-severity' : 'sidebar-small sidebar-left-severity')
           : 'sidebar-append-to sidebar-left-severity'"
           [appendTo]="appendTo"
           [modal]="false"
           [style]="style"
           [closeOnEscape]="true"
           [showCloseIcon]="false" (onHide)="hide(false)">
  <div *ngIf="displayHealth;" class="left-severity {{(displayHealth.severity| uppercase)}}">
    <div class="col-icon"
         [ngClass]="[displayHealth.severity| uppercase]">
      <mat-icon
        [svgIcon]="('alert_'+  (displayHealth?.severity | lowercase) + '_open') "></mat-icon>
    </div>
  </div>

  <div *ngIf="displayIssue" class="left-severity {{displayIssue?.severity || 'HIGH'}}">
    <div class="col-icon" class="{{(displayIssue?.severity) || 'HIGH'}}"
         [pTooltip]="'Severity: High'"
         [tooltipPosition]="'right'">
      <mat-icon [svgIcon]="'alert_medium_open'"></mat-icon>
    </div>
  </div>

  <div style="overflow: auto">
    <div class="sidebar-top">
      <div class="actions-wrapper">
        <mat-icon [pTooltip]="'close'" [tooltipStyleClass]="'hovered-icon'" [showDelay]="500" (click)="closeSidebar()"
                  [svgIcon]="'close'"
                  class="close"></mat-icon>
      </div>

      <div class="sidebar-top-title" *ngIf="displayHealth">
        <div class="title marked" [pTooltip]="displayHealth?.title"
             [tooltipPosition]="'bottom'"
             [showDelay]="500">

          <opster-markdowm [lineNumbers]="false" [clipboard]="false"  [data]="displayHealth?.title">
          </opster-markdowm>

        </div>
        <div class="date-wrapper">
          <div class="date">
            {{detectedTime}}
          </div>
          <div class="sliders-horiz-btn" [class.disable]="!health?.type">

            <!--            <div class="top-chat-gpt small" >-->
            <!--              <mat-icon [svgIcon]="'chat_dark'" class="chat-icon" ></mat-icon>-->
            <!--            </div>-->

            <mat-icon [matMenuTriggerFor]="menu"
                      (menuOpened)="onOnMenuOpen(health?.type)" class="sliders-horiz"
                      svgIcon="sliders_horiz"></mat-icon>

            <mat-menu #menu="matMenu" [overlapTrigger]="false">
              <div *ngIf="!customizeLoading; else skeleton">
                <button mat-menu-item [disabled]="customizeDisabled || !customizeData" (click)="onOnCustomize()">
                  <mat-icon svgIcon="gear"></mat-icon>
                  <span>Customize</span>
                </button>
                <button mat-menu-item
                        (click)="onOnNotifications()">
                  <mat-icon svgIcon="notification"></mat-icon>
                  <span>Notifications</span>
                </button>
                <button mat-menu-item
                        (click)="onOnMuteNotifications({name: health?.title, type: health?.type})">
                  <mat-icon svgIcon="mute_notification"></mat-icon>
                  <span>Mute notification</span>
                </button>
                <button mat-menu-item [disabled]="!displayHealth?.dismissAble"
                        (click)="onOnDismiss(displayHealth?.type)">
                  <mat-icon svgIcon="dismiss"></mat-icon>
                  <span>Dismiss</span>
                </button>
                <button mat-menu-item
                        (click)="onShareEvent(displayHealth?.id)">
                  <mat-icon svgIcon="link"></mat-icon>
                  <span>Share event link</span>
                </button>
              </div>

              <ng-template #skeleton>
                <app-skeleton-loader></app-skeleton-loader>
              </ng-template>

            </mat-menu>

          </div>
        </div>

      </div>
      <div class="sidebar-top-title" *ngIf="displayIssue">
        <div class="title" [pTooltip]="displayIssue?.name" [showDelay]="500">
          {{displayIssue?.name}}
        </div>
        <div class="category">
          <div>
            {{displayIssue?.category}}
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="displayIssue" class="analytics analytics-no-graph">
      <div class="left">
        <app-description [healthDescription]="displayIssue?.description"></app-description>

        <field-names *ngIf="displayIssue?.fieldNames?.length"
                     [item]="displayIssue"
                     [type]="templateType"
                     (setSelectionOnEditor)="selectionOnEditor($event)"></field-names>
      </div>

    </div>


    <div *ngIf="health && analyticsGraph" class="analytics analytics-graph">
      <div class="left">
        <app-affected-section *ngIf="health?.affectedContext?.length"
                              [affectedSection]="health?.affectedContext"></app-affected-section>
        <gpt-section [eventName]="displayHealth?.title" [eventType]="displayHealth?.type"
                     [cluster]="cluster"
                     [analysisId]="displayHealth?.id"
                     [openedAt]="health?.startTime" [closedAt]="health?.endTime"></gpt-section>
        <field-names *ngIf="displayIssue?.fieldNames?.length"
                     [item]="displayIssue?.fieldNames"
                     (setSelectionOnEditor)="selectionOnEditor($event)"></field-names>
        <recommendations *ngIf="health?.recommendations?.length" [type]="health?.type"
                         [recommendations]="health?.recommendations"></recommendations>
        <app-description [healthDescription]="health?.description"></app-description>

      </div>
      <div class="right">
        <app-notifications (emitSidebarHide)="hide(false)" [filterEndpoints]="filterEndpoints"
                           [status]="health?.notificationStatus"></app-notifications>
        <app-event-time [openedAt]="health?.startTime" [closedAt]="health.endTime" [graph]="true"></app-event-time>
        <app-event-overview [renderGraph]="renderGraph" [analyticsGraph]="analyticsGraph"></app-event-overview>
        <app-read-more *ngIf="health?.guide || eventTypeUrl" [eventTypeUrl]="health?.guide || eventTypeUrl"
                       [type]="health?.type"></app-read-more>
      </div>
    </div>

    <div *ngIf="health && !analyticsGraph" class="analytics analytics-no-graph">
      <app-notifications (emitSidebarHide)="hide(false)" [filterEndpoints]="filterEndpoints" [status]="health?.notificationStatus"></app-notifications>
      <app-event-time [openedAt]="health?.startTime" [closedAt]="health?.endTime" [graph]="false"></app-event-time>
      <app-affected-section *ngIf="health?.affectedContext?.length"
                            [affectedSection]="health?.affectedContext"></app-affected-section>
      <gpt-section [eventName]="displayHealth?.title" [eventType]="displayHealth?.type"
                   [cluster]="cluster"
                   [analysisId]="displayHealth?.id"
                   [openedAt]="health?.startTime" [closedAt]="health?.endTime"></gpt-section>
      <field-names *ngIf="displayIssue?.fieldNames?.length"
                   [item]="displayIssue?.fieldNames"
                   (setSelectionOnEditor)="selectionOnEditor($event)"></field-names>
      <recommendations *ngIf="health?.recommendations?.length" [type]="health?.type"
                       [recommendations]="health?.recommendations"></recommendations>
      <app-description [healthDescription]="health?.description"></app-description>

      <app-read-more *ngIf="health?.guide || eventTypeUrl" [eventTypeUrl]="health?.guide || eventTypeUrl"
                     [type]="health?.type"></app-read-more>

    </div>

    <div *ngIf="!health && !displayIssue"
         style="display: grid;grid-template-rows: auto; grid-gap: 10px;padding-top: 10px">
      <app-skeleton-loader [withItem]="false"></app-skeleton-loader>
      <app-skeleton-loader [withItem]="false"></app-skeleton-loader>
    </div>

  </div>


</p-sidebar>

<div class="p-component-overlay p-sidebar-mask" style="z-index: 1000;" *ngIf="opsterModal && display"
     (click)="hide(false)"></div>
