export enum ChartTypesEnum {
  Abands = 'abands',
  Ad = 'ad',
  Ao = 'ao',
  Apo = 'apo',
  Area = 'area',
  Arearange = 'arearange',
  Areaspline = 'areaspline',
  Areasplinerange = 'areasplinerange',
  Aroon = 'aroon',
  Aroonoscillator = 'aroonoscillator',
  Atr = 'atr',
  Bar = 'bar',
  Bb = 'bb',
  Bellcurve = 'bellcurve',
  Boxplot = 'boxplot',
  Bubble = 'bubble',
  Bullet = 'bullet',
  Candlestick = 'candlestick',
  Cci = 'cci',
  Chaikin = 'chaikin',
  Cmf = 'cmf',
  Column = 'column',
  Columnpyramid = 'columnpyramid',
  Columnrange = 'columnrange',
  Cylinder = 'cylinder',
  Dema = 'dema',
  Dependencywheel = 'dependencywheel',
  Dumbbell = 'dumbbell',
  Dpo = 'dpo',
  Ema = 'ema',
  Errorbar = 'errorbar',
  Flags = 'flags',
  Funnel = 'funnel',
  Funnel3d = 'funnel3d',
  Gantt = 'gantt',
  Gauge = 'gauge',
  Heatmap = 'heatmap',
  Histogram = 'histogram',
  Ikh = 'ikh',
  Item = 'item',
  Keltnerchannels = 'keltnerchannels',
  Line = 'line',
  Linearregression = 'linearregression',
  Linearregressionangle = 'linearregressionangle',
  Linearregressionintercept = 'linearregressionintercept',
  Linearregressionslope = 'linearregressionslope',
  Macd = 'macd',
  Map = 'map',
  Mapbubble = 'mapbubble',
  Mapline = 'mapline',
  Mappoint = 'mappoint',
  Mfi = 'mfi',
  Momentum = 'momentum',
  Natr = 'natr',
  Networkgraph = 'networkgraph',
  Ohlc = 'ohlc',
  Organization = 'organization',
  Packedbubble = 'packedbubble',
  Pareto = 'pareto',
  Pc = 'pc',
  Pie = 'pie',
  Pivotpoints = 'pivotpoints',
  Polygon = 'polygon',
  Ppo = 'ppo',
  Priceenvelopes = 'priceenvelopes',
  Psar = 'psar',
  Pyramid = 'pyramid',
  Pyramid3d = 'pyramid3d',
  Roc = 'roc',
  Rsi = 'rsi',
  Sankey = 'sankey',
  Scatter = 'scatter',
  Scatter3d = 'scatter3d',
  Series = 'series',
  Sma = 'sma',
  Solidgauge = 'solidgauge',
  Spline = 'spline',
  Stochastic = 'stochastic',
  Streamgraph = 'streamgraph',
  Sunburst = 'sunburst',
  Supertrend = 'supertrend',
  Tema = 'tema',
  Tilemap = 'tilemap',
  Timeline = 'timeline',
  Treemap = 'treemap',
  Trix = 'trix',
  Variablepie = 'variablepie',
  Variwide = 'variwide',
  Vbp = 'vbp',
  Vector = 'vector',
  Venn = 'venn',
  Vwap = 'vwap',
  Waterfall = 'waterfall',
  Williamsr = 'williamsr',
  Windbarb = 'windbarb',
  Wma = 'wma',
  Wordcloud = 'wordcloud',
  Xrange = 'xrange',
  Zigzag = 'zigzag',
}
