<div>
  <h1 mat-dialog-title>Opster Knowledge Center
    <mat-icon mat-dialog-close>close</mat-icon>
  </h1>
  <div mat-dialog-content >
    <div class="col-content markdown supplementary marked">
<!--      <p innerHTML="{{rendered | marked}}"></p>-->

      <opster-markdowm  [lineNumbers]="false" [clipboard]="false"  [data]="rendered">
      </opster-markdowm>


    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button class="button-opster gray" [mat-dialog-close]="true"
            cdkFocusInitial>Close
    </button>
  </div>
</div>


