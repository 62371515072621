import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {GlobalService} from '../../store/global/global.service';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import get from 'lodash-es/get';
import first from 'lodash-es/first';
import {SegmentTrackService} from '../../shared/services/segment/segment.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import split from 'lodash-es/split';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterPageComponent implements OnInit {
  public clusterList$;
  public currentUrl: string = null;
  public loader: boolean;

  constructor(private globalService: GlobalService,
              private segmentTrackService: SegmentTrackService,
              private ref: ChangeDetectorRef,
              private router: Router) {

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), untilDestroyed(this))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = first(split(get(event, 'urlAfterRedirects'), '?'))?.substring(1);

        this.ref.markForCheck();

        this.segmentTrackService.analyticsPage(this.currentUrl, {
          url: get(event, 'urlAfterRedirects'),
          email: this.segmentTrackService.getTrackEmail
        });

      });
  }

  ngOnInit() {
    this.clusterList$ = this.globalService.getClusterList();
    // this.eventsList$ = this.dashboardService.getEventsList();

    this.globalService.getLoader()
      .pipe(untilDestroyed(this)).subscribe(res => {
      this.loader = res;
      this.ref.markForCheck();
    });
  }
}
