<div>
  <h1 mat-dialog-title> No Internet Connection</h1>
  <div mat-dialog-content class="no-connection-content">
    <p>Whoops !</p>
    <img src="https://opster.com/wp-content/uploads/2022/05/no_connection.svg" alt="no-connection"/>
    <p>Please check your internet</p>
    <p> connection and try again </p>
  </div>
  <div mat-dialog-actions style="justify-content: end">
    <button  mat-stroked-button class="button-opster yellow" [mat-dialog-close]="true"
            (click)="onOk(this.dialogButton.ok)"
            cdkFocusInitial>Try Again
    </button>
  </div>
</div>

