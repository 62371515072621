import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SegmentTrackService } from '../segment/segment.service';
import { HttpClient } from '@angular/common/http';
import get from 'lodash-es/get';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AwsUploadService {
  private uploadFileSlowLog$: Subject<any>;
  private uploadFileTemplate$: Subject<any>;

  constructor(private segmentTrackService: SegmentTrackService,
              private httpClient: HttpClient) {
    this.uploadFileSlowLog$ = new Subject<any>();
    this.uploadFileTemplate$ = new Subject<any>();

  }

  public uploadFileToS3(url, file): Observable<any> {
    return this.httpClient.put<any>(url, file);
  }


  // Slow Log
  uploadFileSlowLog(file, path) {
    this.getS3PreSignedSlowLogs(path)
      .pipe(first())
      .subscribe(url => {
        this.uploadFileToS3(get(url, 'preSignedUrl'), file)
          .pipe(first())
          .subscribe(() => {

            setTimeout(() => {
              this.uploadFileSlowLog$.next(true);
            }, 200);

          }, err => {
            this.segmentTrackService.setAnalyticsTrackToServer('There was an error uploading your file',
              {error: err, email: this.segmentTrackService.getTrackEmail});
          });

      });

  }

  public getS3PreSignedSlowLogs(path): Observable<any> {
    const body = {
      's3BucketPath': path
    };
    return this.httpClient.post<any>(`api/analyzer/slowlogs/s3-pre-signed`, body);
  }

  getUploadFileSlowLog(): Observable<any> {
    return this.uploadFileSlowLog$.asObservable();
  }

  // Template
  uploadFileTemplate(file, path) {
    this.getS3PreSignedTemplate(path)
      .pipe(first())
      .subscribe(url => {
        this.uploadFileToS3(get(url, 'preSignedUrl'), file)
          .pipe(first())
          .subscribe(() => {

            setTimeout(() => {
              this.uploadFileTemplate$.next(true);
            }, 200);

          }, err => {
            this.segmentTrackService.setAnalyticsTrackToServer('There was an error uploading your file',
              {error: err, email: this.segmentTrackService.getTrackEmail});
          });

      });

  }

  public getS3PreSignedTemplate(path): Observable<any> {
    const body = {
      's3BucketPath': path
    };
    return this.httpClient.post<any>(`api/analyzer/template-optimizer/s3-pre-signed`, body);
  }

  getUploadFileTemplate(): Observable<any> {
    return this.uploadFileTemplate$.asObservable();
  }


}
