import { NumberFormatEnum } from '../../shared/models/number-format.enum';

export const EVENTS_NUMBER_FORMAT = (eventType) => {
  const numberFormat: any = {
    // 'FS_USED_IN_BYTES': NumberFormatEnum.BYTES,
    // 'QUERY_LATENCY_IN_MILLIS': NumberFormatEnum.MS,
    // 'INDEX_LATENCY_IN_MILLIS': NumberFormatEnum.MS,
    // 'HEAP_USED_IN_BYTES': NumberFormatEnum.BYTES,
    'Indexing latency': NumberFormatEnum.MS,
    'Search latency': NumberFormatEnum.MS,
    'Merge latency': NumberFormatEnum.MS,
    'GC time spent in %': NumberFormatEnum.PRECENT,
    'Indexing throttle time': NumberFormatEnum.MS,
    'CPU Usage': NumberFormatEnum.PRECENT,
    'FS_USED_IN_BYTES': NumberFormatEnum.BYTES,
    'HEAP_USED_IN_BYTES': NumberFormatEnum.BYTES,
    'BREAKERS_REQUEST_USED': NumberFormatEnum.PRECENT,
    'BREAKERS_FIELD_DATA_USED': NumberFormatEnum.PRECENT,
    'BREAKERS_PARENT_USED': NumberFormatEnum.PRECENT
  };

  return numberFormat[eventType];
};

export const UNITS_NUMBER_FORMAT = (unit) => {
  const numberFormat: any = {
    'COUNT': NumberFormatEnum.NUMBER,
    'MILLIS': NumberFormatEnum.MS,
    'BYTES': NumberFormatEnum.BYTES,
    'KILOBYTES': NumberFormatEnum.BYTES,
    'PERCENT': NumberFormatEnum.PRECENT,
    'COUNT_RATE_PER_SECOND': NumberFormatEnum.SEC,
    'BYTES_RATE_PER_SECOND': NumberFormatEnum.BYTES_SEC
  };

  return numberFormat[unit];
};
