import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';

@Component({
  selector: 'app-confidence-dialog',
  templateUrl: './confidence-dialog.component.html',
  styleUrls: ['./confidence-dialog.component.scss']
})
export class ConfidenceDialogComponent implements OnInit {
  public dialogButton;
  public message: string;
  public message2: string;
  public note: string;
  public icon: string;
  public title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    this.dialogButton = get(this.data, 'dialogButtonConfig');
    this.message = get(this.data, 'message');
    this.message2 = get(this.data, 'message2');
    this.note = get(this.data, 'note');
    this.title = get(this.data, 'title');
    this.icon = get(this.data, 'icon');
  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.action()).then(() => {
    });
  }

  onCancel(dialogButton): void {
    Promise.resolve(dialogButton.action()).then(() => {
    });
  }
}
