export interface ChatHistory {
  userChatId: number;
  prompt: string;
  answer?: string;
  updatedAt: number;
  clusters: any;
}

export interface GptPayload {
  display?: boolean;
  typesToInclude?: string[];
  prompt?: string;
  clusters?: string[];
  closedAnalysisTimeRange?: {from: number, to: number};
  analysisIds?: number[];
  isInstallation?: boolean;
}


export const GIFS = [
  'https://assets8.lottiefiles.com/packages/lf20_ka69hw25.json',
  'https://assets4.lottiefiles.com/datafiles/40aX5db74VvGPWw/data.json',
  'https://assets10.lottiefiles.com/private_files/lf30_yuvwrfd2.json',
  'https://assets5.lottiefiles.com/temp/lf20_XgycDo.json',
  'https://assets2.lottiefiles.com/packages/lf20_8TYQ3W.json',
  'https://lottie.host/b9b6a304-42b7-4bcc-8716-a7334d501463/78f8SRKqmm.json'
];
