import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { OpsGptComponent } from './ops-gpt.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { PanelModule } from '../../shared/components/panel/panel.component';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'primeng/button';
import { MatMenuModule } from '@angular/material/menu';
import { LoaderModule } from '../../shared/components/loader/loader.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OpsterMarkdownModule } from '../../shared/components/opster-markdowm/opster-markdown.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SplitterModule } from 'primeng/splitter';
import { LottieComponent } from 'ngx-lottie';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { MainChatComponent } from './main-chat/main-chat.component';
import { OpsGptRoutingModule } from './ops-gpt-routing.module';
import { AnswerComponent } from './answer/answer.component';
import { InputMultiSelectModule } from '../../shared/components/inputs/input-multi-select/input-multi-select.module';
import { SkeletonLoaderModule } from '../../shared/components/skeleton-loader/skeleton-loader.module';
import { TableModule } from 'primeng/table';
import { ExternalTitlePanelModule } from '../../shared/components/external-title-panel/external-title-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    OpsGptComponent,
    ChatHistoryComponent,
    MainChatComponent,
    AnswerComponent,
  ],
  imports: [
    CommonModule,
    OpsGptRoutingModule,
    PanelModule,
    MatIconModule,
    ButtonModule,
    MatMenuModule,
    LoaderModule,
    MatProgressSpinnerModule,
    OpsterMarkdownModule,
    ClipboardModule,
    SplitterModule,
    LottieComponent,
    InputTextareaModule,
    FormsModule,
    InputMultiSelectModule,
    SkeletonLoaderModule,
    TableModule,
    ExternalTitlePanelModule,
    MatButtonModule,
    TooltipModule,
  ],
  exports: [
    MainChatComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class OpsGptModule {
}
