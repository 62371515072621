import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DashboardData, DashboardDataEvents, IEventResponse} from '../dashboard.interface';
import {first} from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';
import {Router} from '@angular/router';

const DEFAULT_NUMBER_EVENT_CARDS = 10;

@Injectable({
  providedIn: 'root'
})
export class MainDashboardService {

  private openProductTour$: Subject<string>;
  private eventsList$: BehaviorSubject<IEventResponse[]>;
  private selectedEvents$: BehaviorSubject<string[]>;
  private selectedMultiCluster$: BehaviorSubject<string[] | null>;
  private openEventsReceived$: BehaviorSubject<any>;

  constructor(private httpClient: HttpClient,
              private router: Router) {
    this.resetAll();
  }

  getDashboardDataFromServer(from: number): Observable<DashboardData> {
    const now = new Date().getTime();

    const body = {
      timeRange: {from: from, to: now}
    };

    return this.httpClient.post<any>(`api/analyzer/dashboard`, body);
  }

  getDashboardEventDataFromServer(from: number, severityList, eventTypes, clusterIds): Observable<DashboardDataEvents> {
    const now = new Date().getTime();

    const body = {
      severityList: severityList,
      eventTypes: eventTypes,
      clusterIds: clusterIds,
      timeRange: {from: from, to: now},
      numberOfCards: DEFAULT_NUMBER_EVENT_CARDS
    };

    return this.httpClient.post<any>(`api/analyzer/dashboard/events`, body);
  }

  // EVENTS

  getEventsFromServer() {
    this.httpClient.get<IEventResponse[]>('api/analyzer/analyses-types')
      .pipe(first()).subscribe((data) => {
      this.eventsList$.next(data);
    });
  }

  getEventsList(): Observable<IEventResponse[]> {
    return this.eventsList$.asObservable();
  }

  getEventsListValue() {
    return this.eventsList$.value;
  }

  public setSelectedEvents(eventType: string[]) {
    const arr: any = this.selectedEvents$.value;

    if (!isEqual(eventType.sort(), arr.sort())) {
      this.selectedEvents$.next(eventType);
    }
  }

  getSelectedEvents() {
    return this.selectedEvents$.asObservable();
  }

  // CLUSTERS
  public setSelectedMultiCluster(clusterIds: string[], addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            clusters: clusterIds.join(',')
          },
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }

    if (!isEqual(this.getSelectedMultiClusterValue(), clusterIds)) {
      this.selectedMultiCluster$.next(clusterIds);
    }
  }

  getSelectedMultiCluster() {
    return this.selectedMultiCluster$.asObservable();
  }

  getSelectedMultiClusterValue() {
    return this.selectedMultiCluster$.value;
  }

  // PRODUCT TOUR
  setOpenProductTour(val?) {
    this.openProductTour$.next(val);
  }

  getOpenProductTour(): Observable<any> {
    return this.openProductTour$.asObservable();
  }

  // EVENTS RECEIVED
  setReceivedEvents(val) {
    this.openEventsReceived$.next(val);
  }

  getReceivedEvents(): Observable<any> {
    return this.openEventsReceived$.asObservable();
  }

  resetAll() {
    this.openProductTour$ = new Subject<string>();
    this.eventsList$ = new BehaviorSubject<IEventResponse[]>([]);
    this.selectedEvents$ = new BehaviorSubject<string[]>([]);
    this.selectedMultiCluster$ = new BehaviorSubject<string[] | null>(null);
    this.openEventsReceived$ = new BehaviorSubject<any>(null);
  }

}
