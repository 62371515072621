import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import size from 'lodash-es/size';
import { isStringNumber } from '../../../shared/services/utils/functional-utils';
import { GIFS } from '../ops-gpt.interface';
import get from 'lodash-es/get';

@Injectable({
  providedIn: 'root'
})
export class OpsterChatService {
  private apiBaseUrl = 'api';
  public conversationId: BehaviorSubject<number>;
  public allChats: BehaviorSubject<[]>;

  constructor(private http: HttpClient,
              private location: Location,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.reset();

    this.getJsonData();
  }

  // @ts-ignore
  public askQuestion(conversationId, question, clusters, typesToInclude, closedAnalysisTimeRange?, analysisIds?, isInstallation?) {
    let body;

    if (!isInstallation) {
      const currentDate = new Date();
      const today = currentDate.getTime();
      const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).getTime();
      const oneMonthAgo = new Date(currentDate);
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
      const oneMonthAgoTimestamp = oneMonthAgo.getTime();

      body = {
        prompt: question,
        clusterIds: clusters,
        typesToInclude: typesToInclude,
        closedAnalysisTimeRange: {from: typesToInclude ? oneMonthAgoTimestamp : yesterday, to: today},
        analysisIds: analysisIds,
        sourcePage: 'AUTO_OPS_GENERAL'
      };
    } else {

      body = {
        prompt: question,
      };
    }


    let url: string;
    if (conversationId) {
      url = `${this.apiBaseUrl}/chat/v1/chats/${isInstallation ? 'installation' : 'classify'}/${conversationId}`;
    } else {
      url = `${this.apiBaseUrl}/chat/v1/chats/${isInstallation ? 'installation' : 'classify'}`;
    }

    const headers = new HttpHeaders().set('Stream', `true`);
    headers.set('Transfer-Encoding', 'chunked');
    headers.set('Accept-Encoding', 'chunked');
    headers.set('Cache-Control', 'no-cache');

    return this.http.post(url, body, {
      observe: 'events', reportProgress: true, responseType: 'text',
      headers
    });
  }

  public getConversation(conversationId): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/chat/v1/chats/${conversationId}`).pipe(share()) as Observable<any>;
  }

  public setFeedback(conversationId, messageId, score): Observable<any> {
    const body = {score};
    return this.http.post(`${this.apiBaseUrl}/chat/v1/chats/${conversationId}/feedback/${messageId}`, body)
      .pipe(share()) as Observable<any>;
  }

  public getAllChatsFromServer() {
    this.http.get(`${this.apiBaseUrl}/chat/v1/chats/all`).pipe(share())
      .subscribe(res => {
        this.setAllChats(res);
      });
  }

  public deleteConversation(conversationId) {
    return this.http.delete(`${this.apiBaseUrl}/chat/v1/chats/${conversationId}`);
  }

  public setAllChats(allChats) {
    this.allChats.next(allChats);
  }

  public getAllChats(): any {
    return this.allChats.asObservable();
  }

  public getAllChatsCount(): any {
    return size(this.allChats.getValue());
  }

  // conversationId.
  public setConversationId(conversationId, navigate = false, state = false) {
    if (!isStringNumber(conversationId) && conversationId) {
      return;
    }

    if (navigate) {
      this.router.navigate([], {
        queryParams: {conversationId: conversationId},
        queryParamsHandling: 'merge'
      }).then(() => {
      });
    } else if (state) {
      this.location.replaceState(`/ops_gpt/conversation/${conversationId}`);
    }
    this.conversationId.next(conversationId);
  }

  public getConversationId(): Observable<number> {
    return this.conversationId.asObservable();
  }

  getJsonData(): any {
    GIFS.forEach(element => this.http.get<any[]>(element).toPromise());
  }

  reset() {
    const params = get(this.activatedRoute, 'snapshot.queryParams');
    const conversationIdFromUrl = params['conversationId'];
    this.conversationId = new BehaviorSubject(conversationIdFromUrl);
    this.allChats = new BehaviorSubject(null);
  }
}
