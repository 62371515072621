<div [ngClass]="{'full-screen': isFullScreen}" *ngIf="data" class="panel-line-crosshair-chart">
  <div class="chart-header" [ngClass]="data?.activity || data?.groups ? 'multi' : 'single'">

    <div class="chart-name">
      <span>{{metricNameFromServe ? data?.metricName : ('charts.' + data?.metricName | translate)}}</span>
      <span class="chart-header-spinner">
          <mat-spinner *ngIf="loading" [strokeWidth]="1"
                       [diameter]="15"></mat-spinner>
      </span>
    </div>

    <div [pTooltip]="selectedPercentilesForm?.value" [tooltipPosition]="'left'" class="chart-dropdown-wrapper" *ngIf="withPercentiles && PercentilesList[data?.metricName]">
      <p-dropdown [options]="selectPercentilesList"
                  [formControl]="selectedPercentilesForm"
                  [dropdownIcon]="'arrow_down'"
                  [styleClass]="'opster-dropdown-top sm-10-width rounded-border-radius'"
                  (onHide)="onSelectionPercentileChange(selectedPercentilesForm?.value, data?.metricName)"
                  [scrollHeight]="'300px'">
        <ng-template let-value pTemplate="selectedItem">
          {{value?.replace('_', '').replace('PERCENTILE', 'p') | titlecase}}
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="sort-by-item">
            {{item?.replace('_', '').replace('PERCENTILE', 'p') | titlecase}}
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div [pTooltip]="selectedForm?.value" [tooltipPosition]="'left'" class="chart-dropdown-wrapper" *ngIf="data.groups">

      <p-multiSelect *ngIf="data?.multi"
                     [styleClass]="'p-multiSelect-group dashboard-multiselect autosize'"
                     [dropdownIcon]="'arrow_down'" [formControl]="selectedForm"
                     [options]="selectList" [filter]="false" [showToggleAll]="false" [showHeader]="false"
                     (onPanelHide)="onSelectionChange(selectedForm?.value, data?.metricName)"
                     [panelStyleClass]="'opster-multiSelect-panel'" >
        <ng-template pTemplate="selectedItems" let-selected>
            <span class="selected-item-wrapper" *ngFor="let val of selected; trackBy: PerformanceUtils.trackByIndex">
              {{data.groups ? ('chartSelect.' + val | translate) : val}}
            </span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="list-item-wrapper">
            {{data.groups ? ('chartSelect.' + item | translate) : item}}
          </div>
        </ng-template>
      </p-multiSelect>

      <p-dropdown *ngIf="!data.multi"
                  [options]="selectList"
                  [formControl]="selectedForm"
                  [dropdownIcon]="'arrow_down'"
                  [styleClass]="'opster-dropdown-top sm-width rounded-border-radius'"
                  (onHide)="onSelectionChange(selectedForm?.value, data?.metricName)"
                  [scrollHeight]="'300px'">
        <ng-template let-value pTemplate="selectedItem">
          {{data.groups ? ('chartSelect.' + value | translate) : value}}
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="sort-by-item">
            {{data.groups ? ('chartSelect.' + item | translate) : item}}
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <mat-icon class="chart-header-icon" (click)="onFullScreen(data)"
              *ngIf="!loading && !isFullScreen && expandIcon"
              svgIcon="expand_snippet"></mat-icon>
    <mat-icon class="chart-header-icon" (click)="onFullScreenExit()"
              *ngIf="isFullScreen" svgIcon="collapse_snippet">
    </mat-icon>

  </div>
  <div [style.height]="height" class="line-crosshair-chart-wrapper">
    <line-crosshair-chart
      [data]="data"
      [numberFormat]="numberFormat"
      [digits]="digits"
      [cursor]="cursor"
      [title]="data?.metricName"
      (selectionChanged)="onSelectionChanged($event)"
      [selection]="selection"
      [height]="data.newLine ? '100%': height"
      [legend]="legend"
      [legendRight]="data.newLine || false"
      [crosshair]="crosshair"
      [animationCharts]="animationCharts"
      [globalDatePicker]="globalDatePicker"
      [yLineFullName]="yLineFullName"
      [isFullScreen]="isFullScreen"
      [clusterId]="clusterId"
      (emitLoaderTimeout)="showChartHeaderLoaderTimeout()"
    ></line-crosshair-chart>
  </div>

</div>

