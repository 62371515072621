/**
 * This is used for providing a custom filtering mdoe in the
 * PrimeNg p-multiSelect component. If only one word is provided,
 *
 * Behavior:
 * 1. If only one word is provided, it checks for a substring match.
 * 2. If multiple words are provided, it will first check if all the words
 * match, and then check if the last word is partially matched.
 */
export function everyWordMatches(
  searchValue?: string,
  filterValue?: string
): boolean {
  // undefined or empty (trimmed) string will match everything
  if (!filterValue?.trim()) {
    return true;
  }

  // undefined or empty string will not match
  if (!searchValue) {
    return false;
  }

  // lowercase everything to make it case insensitive
  searchValue = searchValue.toLocaleLowerCase();
  filterValue = filterValue.toLocaleLowerCase();

  // split on spaces to get the words
  const filterWords = filterValue.split(" ");

  // if there is only one word, check if it is included in the value
  if (filterWords.length === 1) {
    return searchValue.includes(filterValue);
  }

  // check if all the words match
  const matched = matchEveryWord(filterWords, searchValue);
  if (matched) return true;

  // check if the last word is partially matched
  const lastWord = filterWords.pop();
  if (searchValue.includes(lastWord)) {
    return matchEveryWord(filterWords, searchValue);
  }
  return false;
}

const matchEveryWord = (filterWords: string[], value: string): boolean =>
  filterWords.every((word) => value.includes(word));
