import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const DARK_THEME = false;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private isDarkTheme$: BehaviorSubject<boolean>;
  constructor() {
    const value = localStorage.getItem('isDarkTheme') === null
      ? DARK_THEME : (localStorage.getItem('isDarkTheme') === 'true');
    this.isDarkTheme$ = new BehaviorSubject<boolean>(value);
  }

  setActiveThem(isDarkTheme: boolean) {
    const data = isDarkTheme;
    localStorage.setItem('isDarkTheme', JSON.stringify(data));
    this.isDarkTheme$.next(isDarkTheme);
  }

  public getActiveTheme(): Observable<boolean> {
    return this.isDarkTheme$.asObservable();
  }

}
