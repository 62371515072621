import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'primeng/avatar';
import { ReviewsWizardComponent } from './reviews-wizard.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';


@NgModule({
  declarations: [
    ReviewsWizardComponent,
    ReviewsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    AvatarModule,
    CarouselModule,
    ImageModule,
  ],
  providers: [],
  exports: [
    ReviewsWizardComponent,
    ReviewsComponent
  ]
})
export class ReviewsWizardModule {
}
