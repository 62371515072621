import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SupplementaryInfoComponent} from '../../../popups/dialog/supplementary-info/supplementary-info.component';
import {GlobalService} from '../../../../../store/global/global.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogSize} from '../../../popups/dialog/dialog.enum';
import {SegmentTrackService} from '../../../../services/segment/segment.service';

import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import find from 'lodash-es/find';
import size from 'lodash-es/size';
import {filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreComponent implements OnInit {
  @Input() eventTypeUrl;
  @Input() type;

  public showLoader = false;
  public glossaryLoaded = false;
  public shouldOpenDialogAfterGlossaryLoaded = false;
  public glossaryList: any[];
  public eventGlossaryContent;

  constructor(private globalService: GlobalService,
              private segmentTrackService: SegmentTrackService,
              private dialog: MatDialog,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.getGlossaryList();
  }

  getGlossaryList() {
    this.showLoader = true;
    this.globalService.getGlossary()
      .pipe(
        filter(value => value),
        untilDestroyed(this))
      .subscribe(res => {
        this.glossaryList = res;
        this.showLoader = false;
        this.glossaryLoaded = true;
        if (this.shouldOpenDialogAfterGlossaryLoaded) {
          this.openDialog();
        }
      });
  }

  linkClicked() {
    if (this.glossaryLoaded) {
      this.openDialog();
    } else {
      this.shouldOpenDialogAfterGlossaryLoaded = true;
      if (!this.showLoader) {
        this.getGlossaryList();
      }
    }
  }

  openDialog() {
    // glossary received successfully
    if (size(this.glossaryList)) {

      // find event glossary content once
      if (!this.eventGlossaryContent) {
        this.eventGlossaryContent = find(this.glossaryList, (res) =>
          isEqual(this.eventTypeUrl, get(res, 'link'))
        );

        // url not found, open in new tab
        if (!this.eventGlossaryContent) {
          this.showLoader = false;
          this.ref.markForCheck();
          this.segmentTrackService.setAnalyticsTrackToServer('Clicked in glossary info - link',
            {
              email: this.segmentTrackService.getTrackEmail,
              info: this.eventTypeUrl
            });

          window.open(this.eventTypeUrl, '_blank');
          return;
        }

        // url found, open dialog
        this.showLoader = false;
        this.ref.markForCheck();
      }

      this.segmentTrackService.setAnalyticsTrackToServer('Clicked in glossary info popup',
        {
          email: this.segmentTrackService.getTrackEmail,
          info: get(this.eventTypeUrl, 'slug'),
          type: this.type
        });

      this.dialog.open(SupplementaryInfoComponent, {
        width: DialogSize.medium,
        data: {element: get(this.eventGlossaryContent, 'content')},
        id: 'supplementary-info-dialog',
        autoFocus: false,
      });
    } else {
      // glossary loading error, open in new tab
      this.showLoader = false;
      this.ref.markForCheck();
      window.open(this.eventTypeUrl, '_blank');
    }
  }
}
