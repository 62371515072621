import { RefreshTime } from '../../store/global/cluster.interface';

export const RefreshTimes: RefreshTime[] = [
  {name: 'Off', value: null},
  {name: '10s', value: 10000},
  {name: '30s', value: 30000},
  {name: '1m', value: 60000},
  {name: '15m', value: 900000},
  {name: '30m', value: 1800000},
  {name: '1h', value: 3600000}];
