<div class="sidebar-panel">
  <p class="panel-title">
    <mat-icon svgIcon="activity_chart"></mat-icon>
    <span>Event timeline</span>
  </p>
  <div class="sidebar-chart-wrapper">
    <div class="sidebar-chart" *ngIf="!loading; else loadingUI">
      <panel-line-crosshair-chart [data]="analyticsGraph"
                                  [selection]="false"
                                  [metricNameFromServe]="true"
                                  [loading]="loading"
                                  [legend]="false"
                                  [cursor]="true"
                                  [yLineFullName]="false"
                                  [height]="height"
                                  [isFullScreen]="false"
                                  [expandIcon]="false"
                                  [crosshair]="false"
                                  [globalDatePicker]="null"
                                  [animationCharts]="true"
                                  [isSidebar]="true"
      ></panel-line-crosshair-chart>

    </div>
  </div>

  <ng-template #loadingUI>
    <div *ngIf="loading" class="wrapper-spinner">
      <mat-spinner [strokeWidth]="2" [diameter]="40"></mat-spinner>
    </div>
  </ng-template>

</div>

