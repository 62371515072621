export interface SettingRecommendation {
  name: string;
  description: string;
  severity: EnumSeverity | string;
  tags: string[];
  type: string;
  actions: any[];
  readMore?: any;
}

export interface SearchPerformance {
  good: number;
  average: number;
  slow: number;
}

export interface Summary {
  amountOfQueries: number;
  searchPerformance: SearchPerformance;
  slowestSearchId: number;
}

export interface TopSuspect {
  queryId: number;
  searchOrder: number;
  toolResultId: number;
  queryTimestamp: number;
  indexName: string;
  totalShards: number;
  tookTime: any;
  score: number;
}

export interface TopLatency {
  id: number;
  tookTimeInMillis: number;
}

export interface TopReoccurringQuery {
  pattern: string;
  count: number;
}

export interface SlowLogReport {
  id?: number;
  summary?: Summary;
  topSuspects?: TopSuspect[];
  topLatencies?: TopLatency[];
  topReoccurringQuery?: TopReoccurringQuery[];
  searchesTimeOverTime?: number[][];
  searchesCountOverTime?: number[][];
}

export interface PreviousRunsSlowLogs {
  id?: string;
  executionTime?: number;
  distribution?: string;
  cluster?: string;
  result?: any;
  summary?: any;
  type?: string;
}

export interface SlowLogStatusData {
  status: SlowLogStatus;
  result?: SlowLogReport;
  fileName?: string;
  size?: number;
  progress?: number;
}

export enum SlowLogStatus {
  START = 'START',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  UPLOAD = 'UPLOAD',
  ANALYZE = 'ANALYZE'
}

export enum EnumSeverity {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}
