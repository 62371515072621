import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { InputSelectTypes } from '../inputs.enum';
import { Dropdown } from 'primeng/dropdown';
import { DatePipe } from '@angular/common';
import isEqual from 'lodash-es/isEqual';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';
import get from 'lodash-es/get';
import map from 'lodash-es/map';
import set from 'lodash-es/set';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent implements OnInit, OnChanges {
  @Input() items;
  @Input() selectedItem;
  @Input() loadingSelectedItem = false;
  @Input() isGroup = false;
  @Input() type;
  @Input() disabled = false;
  @Input() tooltipPosition = 'top';

  // max 2 tabs
  @Input() tabViewNames = [];
  @Input() isSecondTabSelected = false;

  @ViewChild('dropdown') dropdown: Dropdown;

  public InputSelectTypes = InputSelectTypes;

  public nameItem: string;
  public placeholder;
  public label;
  public styleClass;

  @Output() selectionChange = new EventEmitter();
  @Output() tabChange = new EventEmitter();
  @Output() loadItems = new EventEmitter();
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private ref: ElementRef,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event.target'])
  pageClicked() {
    if (!get(this.ref, 'nativeElement').contains(get(event, 'target') as HTMLElement)) {
      this.dropdown.hide();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'items.currentValue') || get(changes, 'selectedItem.currentValue')) {
      switch (this.type) {
        case this.InputSelectTypes.CLUSTERS:
          this.nameItem = 'Cluster';
          this.placeholder = 'No Clusters';
          this.label = 'clusterAlias';
          this.styleClass = 'opster-dropdown-top lg-width';
          const items = this.items;

          if (get(changes, 'selectedItem.currentValue')) {
            const selectedCluster = this.selectedItem;
            this.selectedItem = find(flatten(map(items, 'items')),
              {'cluster': get(selectedCluster, 'cluster')});
          }

          map(items, item => {
            map(get(item, 'items'), value => {
              set(value, 'filteredFields', get(value, 'clusterAlias') + ',' + get(value, 'cluster'));
              set(value, 'displayedName', get(value, 'clusterAlias') + ' (' + get(value, 'cluster') + ')');
              set(value, 'tooltip', get(value, 'clusterAlias') + ' (' + get(value, 'cluster') + ')');
            });
          });
          break;
        case this.InputSelectTypes.SLOW_LOGS:
          this.nameItem = 'Runs';
          this.placeholder = 'No Previous Runs';
          this.label = 'executionTime';
          this.styleClass = 'opster-dropdown-top md-width';
          map(this.items, item => {
            set(item, 'filteredFields', this.datePipe.transform(get(item, 'executionTime'), 'd MMM y')
              + ',' + get(item, 'cluster'));
            set(item, 'displayedName', `${this.datePipe.transform(get(item, 'executionTime'), 'd MMM y')} &bull; ${get(item, 'cluster')}`);
            set(item, 'tooltip', this.datePipe.transform(get(item, 'executionTime'), 'd MMM y') + ' ' + get(item, 'cluster'));
          });
          break;
        case this.InputSelectTypes.REFRESH:
          this.nameItem = '';
          this.placeholder = 'No';
          this.label = 'name';
          this.styleClass = 'opster-dropdown-top sm-width';
          map(this.items, item => {
            set(item, 'displayedName', get(item, 'name'));
          });
          break;
        default:
          this.nameItem = '';
          this.placeholder = 'No Data';
          break;
      }
    }
  }

  // private getClusterName(item) {
  //   return get(find(this.clusterList, {cluster: get(item, 'cluster')}), 'clusterAlias') || get(item, 'cluster');
  // }

  onPanelShow() {
    this.loadItems.emit();
  }


  onSelectionChange(event) {
    this.selectionChange.emit(get(event, 'value'));
  }

  onChangeTabView(val) {
    event.stopPropagation();

    this.isSecondTabSelected = isEqual(get(val, 'index'), 1);
    this.tabChange.emit(this.isSecondTabSelected);
  }
}

