import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { SkeletonLoaderComponent } from './skeleton-loader.component';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    SkeletonLoaderComponent,
  ],
  imports: [
    CommonModule,
    SkeletonModule,
  ],
  providers: [],
  exports: [
    SkeletonLoaderComponent
  ]
})
export class SkeletonLoaderModule {
}
