import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';
import { first as rxFirst } from 'rxjs/operators';
import { GlobalService } from '../../../../../store/global/global.service';
import { FormControl } from '@angular/forms';
import size from 'lodash-es/size';
import difference from 'lodash-es/difference';
import filter from 'lodash-es/filter';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';

import { ClusterViewService } from '../../../../../features/cluster-view/service/cluster-view.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../../store/toast/toast.service';
import { Cluster } from '../../../../../store/global/cluster.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dismiss-event-dialog',
  templateUrl: './dismiss-event-dialog.component.html',
  styleUrls: ['./dismiss-event-dialog.component.scss']
})
export class DismissEventDialogComponent implements OnInit {
  public dialogButton;
  public message: string;
  public note: string;
  public title: string;
  public eventName: string;
  public editMode;
  public isReactive;

  public clusterList: Cluster[];
  public selectedCluster = new FormControl();
  public selectedClusterIds;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private clusterViewService: ClusterViewService,
              private translateService: TranslateService,
              private toastService: ToastService,
              private globalService: GlobalService) {
  }

  ngOnInit(): void {
    this.dialogButton = get(this.data, 'dialogButtonConfig');
    this.message = get(this.data, 'message');
    this.eventName = get(this.data, 'eventName');
    this.editMode = get(this.data, 'editMode');
    this.isReactive = get(this.data, 'isReactive');
    this.selectedClusterIds = get(this.data, 'selectedCluster');
    this.note = get(this.data, 'note');
    this.title = get(this.data, 'title');
    this.getClusterList();

  }

  getClusterList() {
    this.globalService.getClusterList()
      .pipe(rxFirst(), untilDestroyed(this)).subscribe(res => {
      this.clusterList = res;
      if (this.editMode) {
        const clusters = filter(res, x => includes(this.selectedClusterIds, get(x, 'cluster')));
        this.selectedCluster.setValue(clusters);
      } else {
        this.selectedCluster.setValue(res);
      }
    });
  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.ok.action()).then(() => {
    });
  }

  onDismiss() {
    const body = [];

    if (this.editMode) {
      const addDismissClusters = difference(map(get(this.selectedCluster, 'value'), 'cluster'),
        map(this.selectedClusterIds));
      const removeDismissClusters = difference(map(this.selectedClusterIds), map(get(this.selectedCluster, 'value'), 'cluster'));

      if (size(addDismissClusters)) {
        body.push({
          'analysisType': this.eventName,
          'clusters': addDismissClusters,
          'enabled': false
        });
      }
      if (size(removeDismissClusters)) {
        body.push({
          'analysisType': this.eventName,
          'clusters': removeDismissClusters,
          'enabled': true
        });
      }
    } else {
      body.push({
        'analysisType': this.eventName,
        'clusters': map(get(this.selectedCluster, 'value'), 'cluster'),
        'enabled': this.isReactive
      });
    }


    this.clusterViewService.setDismiss(body)
      .pipe(rxFirst(), untilDestroyed(this)).subscribe(() => {

      this.toastService.queueSnackBar(null,
        this.translateService.instant(this.isReactive ? 'settings.dismiss_events.savedReactive' : 'settings.dismiss_events.savedDismiss'), 'success');

      this.onOk(this.dialogButton);
    });
  }

  onCancel(dialogButton): void {
    Promise.resolve(dialogButton.cancel.action()).then(() => {
    });
  }

}
