<div class="sidebar-panel notifications">
  <p class="panel-title">
    <mat-icon svgIcon="notification"></mat-icon>
    <span>Notifications</span>
    <!--    <span style="font-weight: normal;margin-left: 10px;min-width: 55px">Sent to: {{filterEndpoints && !filterEndpoints?.length ? 'None' : ''}}</span>-->
  </p>
  <div class="endpoints" *ngIf="filterEndpoints?.length">
    <!--        <span class="divider" *ngIf="i !==0"></span>-->
    <mat-icon *ngFor="let item of filterEndpointsDisplay; let i = index; trackBy: PerformanceUtils.trackByIndex"
              [svgIcon]="item?.key" [pTooltip]="item?.names" [tooltipPosition]="'bottom'"></mat-icon>
    <!--        <span class="name">{{item.name}} </span>-->
  </div>

  <p-divider layout="vertical"></p-divider>

  <span class="item-none" *ngIf="filterEndpoints">
      <a (click)="notificationsNav()" class="link">
        {{filterEndpoints && !filterEndpoints?.length ? 'Setup' : 'Edit'}}</a>
    </span>

  <div class="box-spinner" *ngIf="!filterEndpoints">
    <mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
  </div>

  <span class="notifications-status-text">{{NotificationStatus[status]}}</span>

</div>
