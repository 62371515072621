import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import get from 'lodash-es/get';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'tour-dialog',
  templateUrl: './tour-dialog.component.html',
  styleUrls: ['./tour-dialog.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourDialogComponent implements OnInit {
  public url: SafeResourceUrl;
  public isShardsView: boolean;

  constructor(public config: DynamicDialogConfig, private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    const data = get(this.config, 'data');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(get(data, 'url'));
    this.isShardsView = get(data, 'isShardsView');
  }

}
