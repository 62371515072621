<div class="side-menu-wrapper">
  <div class="side-menu-scroll-wrapper">
    <div class="side-menu" [class.open]="isMenuOpen">

      <a [routerLink]="AppRoutes.dashboard"
         [replaceUrl]="true"
         [queryParamsHandling]="'merge'"
         class="opster-logo">
        <mat-icon [svgIcon]="isMenuOpen? 'AutoOps_logo': 'opster_close'"></mat-icon>
      </a>

      <div class="tab-menu"
           [pTooltip]="(isClusterConnectedSuccess === false || !clusterList?.length || isTrialEnding) ? 'Connect a cluster first in order to use AutoOps' : ''">

        <ng-container *ngFor="let tab of tabs;trackBy: PerformanceUtils.trackByIndex;" class="tab">
          <mat-divider *ngIf="tab?.route === AppRoutes.reports && tab?.permission"></mat-divider>
          <a class="tab-a" id="{{tab.id| translate}}"
             [class.disabled]="!clusterList?.length || isTrialEnding"
             [pTooltip]="tab.label | translate"
             [tooltipPosition]="'right'"
             [showDelay]="300"
             *ngIf="tab?.permission"
             [class.selected]="tab.label==selectedTab?.label"
             [routerLink]="tab.link | async"
             [queryParamsHandling]="'merge'"
             [replaceUrl]="true"
             (click)="onTabClick(tab)">
            <div class="wrapper">
              <span *ngIf="tab?.isNew" pBadge severity="warning" value="NEW">
                  <mat-icon class="side-menu-icon" [svgIcon]="tab.icon" [style.height]="tab.sizeIcon"
                            [style.width]="tab.sizeIcon"></mat-icon>
              </span>
              <mat-icon *ngIf="!tab?.isNew" class="side-menu-icon" [style.height]="tab.sizeIcon"
                        [style.width]="tab.sizeIcon" [svgIcon]="tab.icon"></mat-icon>
              <span class="wrapper-label">{{tab?.label | translate}}</span>
              <mat-icon *ngIf="tab.label==selectedTab?.label" svgIcon="chevron_right"
                        class="chevron_right menu"></mat-icon>
            </div>
          </a>
        </ng-container>

      </div>

      <div class="btn-bottom">

        <div class="btn-menu" (click)="openContactUs()" [pTooltip]="'Contact Us'"
             *ngIf="showContactUs"
             [tooltipPosition]="'right'">
          <mat-icon svgIcon="phone"></mat-icon>
          <span style="padding-left: 13px">Contact Us</span>
        </div>

        <div class="btn-menu" [routerLink]="settingsLink.link | async"
             [replaceUrl]="true"
             [pTooltip]="accountTooltip"
             [escape]="false"
             [tooltipStyleClass]="'account-tooltip'"
             (click)="selectedAccount = true"
             [class.selected]="selectedAccount"
             [queryParamsHandling]="'merge'">
          <!--        <div class="side-menu-icon short-name">-->
          <!--          {{userShortName | uppercase}}-->
          <!--        </div>-->
          <mat-icon [svgIcon]="'gear_white'"></mat-icon>

          <span style="margin-left: -3px;">Settings</span>
        </div>

        <div class="btn-menu" style="display: flex " [pTooltip]="'Theme'"
             [tooltipPosition]="'right'">

          <mat-icon *ngIf="!isMenuOpen" class="side-menu-icon" (click)="toggleThemeMenu()"
                    [svgIcon]="!isDarkTheme ? 'moon' : 'sun'"></mat-icon>

          <span style="    padding: 0;">

      <mat-icon class="side-menu-icon" style="margin-right: 10px" (click)="toggleThemeMenu()" svgIcon="sun"></mat-icon>


      <mat-slide-toggle [checked]="isDarkTheme"
                        labelPosition="before"
                        class="toggle-bar-menu"
                        [ngClass]="'toggle-sidemenu'"
                        (change)="toggleTheme($event.checked)">
      </mat-slide-toggle>

      <mat-icon style="    margin-left: 25px;" svgIcon="moon"></mat-icon>
      </span>
        </div>


        <div class="btn-menu" (click)="logOut()" [pTooltip]="'Log Out'"
             [tooltipPosition]="'right'">
          <mat-icon id="log-out-icon" class="side-menu-icon" svgIcon="log_out"></mat-icon>
          <span>Log out</span>
        </div>

        <div class="btn-menu" (click)="openMenu()"
             [class.disabled]="((clusterList && !clusterList?.length) || isTrialEnding)">
          <mat-icon class="side-menu-icon" svgIcon="menu_icon"></mat-icon>
          <span>Collapse</span>
        </div>

      </div>

      <div class="empty-menu">

      </div>


    </div>
  </div>
</div>

<new-cluster-connected *ngIf="isClusterConnectedSuccess"
                       (goToDashboard)="closePopup()"
                       [eventsReceived]="openEventsReceived">
</new-cluster-connected>

<p-dialog [styleClass]="'contact-us'" [draggable]="false" [resizable]="false" [(visible)]="displayContactUs">
  <iframe width="400px" height="490px" frameBorder="0" [src]="urlSafe"></iframe>
</p-dialog>

<!--<ng-template #HtmlContentAccount>-->
<!--  <div class="account-tooltip">-->
<!--    <div class="side-menu-icon short-name">-->
<!--      {{userShortName | uppercase}}-->
<!--    </div>-->
<!--    <div class="right">-->
<!--      <div class="email">-->
<!--        {{user?.email}}-->
<!--      </div>-->
<!--      <div>-->
<!--        {{user?.fullName}}-->
<!--        •-->
<!--        {{user?.company}}-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
<!--<div class="no-cluster-disabled" *ngIf="!isClusterConnectedSuccess"-->
<!--     [pTooltip]="!isClusterConnectedSuccess? 'Connect a cluster first in order to use AutoOps': ''">-->

<!--</div>-->
