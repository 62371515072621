// import { TagColor } from '../tags/tag.interface';

// import { TagColor } from '../tags/tag.interface';

export interface Health {
  id: number;
  type: string;
  title: string;
  description: string;
  affectedContext: string;
  severity: string;
  tags: string[];
  recommendations: Recommendation[];
  context: Context;
  startTime: number;
  endTime?: any;
  prevent?: any;
  notificationStatus: string;
  dismissAble: boolean;
  customizeAble: boolean;
  analysisClassification: string;
  guide: string;
}

export interface Analysis {
  id: number;
  type: string;
  caption: string;
  title: string;
  description: string;
  severity: string;
  tags: string[];
  lastTime: any;
  count: number;
  dismissAble: boolean;
  customizeAble: boolean;
  analysisClassification: ANALYSIS_CLASSIFICATION;
}

export enum ANALYSIS_CLASSIFICATION {
  EVENT = 'EVENT',
  COST = 'COST'
}

export interface Recommendation {
  id: number;
  type: string;
  title: string;
  description: string;
  command?: any;
}

export interface Metric2 {
  data: any[][];
  name: string;
}

export interface Metric {
  metrics: Metric2[];
  metricName: string;
}

export interface Graph {
  metric: Metric;
}

export interface Context {
  graph: Graph;
  esHost: string;
  status: string;
  indices: string[];
  reasons: string[];
  clusterId: string;
  timeRange: TimeRange;
  clusterHealth: ClusterHealth;
  indicesSample: string;
  reasonToIndex: any[];
  indicesIsSample: boolean;
  isIndicesPresent: boolean;
  noneSystemIndices: string[];
  sampleIndicesLink: string;
  totalIndicesFound: number;
  sampleIndicesLinks: string[];
  noneSystemIndicesSample: string;
  amountOfUnassignedShards: number;
  noneSystemSampleIndicesLink: string;
  noneSystemSampleIndicesLinks: string[];
  amountOfUnassignedReplicaShards: number;
  index_shard_allocation_stats_list: IndexShardAllocationStatsList[];
}

export interface TimeRange {
  to: number;
  from: number;
}

export interface ClusterHealth {
  activeShards: number;
  clusterStatus: string;
  metricsetType: string;
  numberOfNodes: number;
  transactionId: string;
  relocatingShards: number;
  unassignedShards: number;
  metricbeatVersion: string;
  numberOfDataNodes: number;
  initializingShards: number;
  activePrimaryShards: number;
  numberOfPendingTasks: number;
  numberOfInFlightFetch: number;
  delayedUnassignedShards: number;
  activeShardsPercentAsNumber: number;
  taskMaxWaitingInQueueMillis: number;
}

export interface IndexShardAllocationStatsList {
  index: string;
  nodeId: string[];
  nodeName: string[];
  indexStatus: string;
  sizeInBytes: number;
  totalShards: number;
  assignShards: AssignShard[];
  nodeIdToName: any;
  metricsetType: string;
  readOnlyIndex: boolean;
  transactionId: string;
  totalDocuments: number;
  unAssignShards: UnAssignShards;
  totalPrimaryShards: number;
  totalAssignedShards: number;
  totalReplicasShards: number;
  totalUnAssignedShards: number;
  unAssignShardsReasons: string[];
  totalAssignedPrimaryShards: number;
  totalAssignedReplicasShards: number;
  totalUnAssignedPrimaryShards: number;
  totalUnAssignedReplicasShards: number;
}

export interface AssignShard {
  index: string;
  state: string;
  nodeId: string;
  primary: boolean;
  shardId: string;
  shardNum: string;
  docsCount: number;
  sizeInBytes: number;
  segmentsCount: number;
}

export interface UnAssignShards {
  INDEX_CREATED: INDEXCREATED[];
}

export interface INDEXCREATED {
  index: string;
  state: string;
  primary: boolean;
  shardNum: string;
  unassignedReason: string;
}

export interface AnalysisAll {
  analyses?: Analysis[];
}

export interface AnalysisClose {
  type: string;
  title: string;
  description: string;
  severity: string;
  tags: string[];
  lastTime: any;
  count: number;
}


export interface HealthDetails {
  id: number;
  title: string;
  description: string;
  severity: string;
  tags: any[];
  recommendations: any[];
  startTime: number;
  endTime?: any;
}

export enum AnalyticsTypeEnum {
  ALL = 'all',
  NODE = 'node',
  INDEX = 'index'
}

export enum SeverityEnum {
  'LOW' = 1,
  'MEDIUM' = 2,
  'HIGH' = 3
}

export interface Datum {
  name: string;
  data: number[][];
}

export interface Metric {
  name: string;
  data: Datum[];
}

export interface Activities {
  metricName: string;
  metrics: Metric[];
}

export interface HealthActivities {
  activities: Activities;
}

export interface Metric2 {
  data: any[][];
  name: string;
}

export interface Metric {
  metrics: Metric2[];
  metricName: string;
}

export interface Metric3 {
  data: any[][];
  name: string;
}

export interface Line {
  metrics: Metric3[];
  metricName: string;
}

export interface Graph {
  metric: Metric;
}

export interface Context {
  graph: Graph;
  nodes: string[];
  nodeRoles: string[];
  nodesLinks: string[];
  nodesNames: string;
  allNodesLink: string;
  metricsMaxValuePoint: number;
  maxValue?: number;
  threshold?: number;
  crossedLimit?: boolean;
  currentNodeId: string;
  usageInPercentage: string;
}

export interface Indications {
  id: number;
  clusterId: string;
  context: Context;
  type: string;
  startTime: any;
  endTime: any;
  description: string;
}

export enum NOTIFICATION_STATUS {
  SENT = 'Notification sent',
  NO_ENDPOINT = 'Endpoint not defined',
  MUTED = 'Notification muted',
  SENDING = 'Sending notification',
  CANCELED = 'Notification failed to send',
  // NOT_AVAILABLE = 'Notification status not available',
  NOT_SENT = 'Event closed before notification sent',
  // FAILED = 'Failed to retrieve notification status',
}

