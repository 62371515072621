import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import get from 'lodash-es/get';
import { Sidebar } from 'primeng/sidebar';
import { Analysis, ANALYSIS_CLASSIFICATION, Health } from '../health.interface';
import { GET_EVENT_TYPE_URL } from '../event-type-url';
import { DismissEventDialogComponent } from '../../popups/dialog/dismiss-event-dialog/dismiss-event-dialog.component';
import { DialogSize } from '../../popups/dialog/dialog.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '../../../../store/toast/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { MuteEventDialogComponent } from '../../popups/dialog/mute-event-dialog/mute-event-dialog.component';
import { GlobalService } from '../../../../store/global/global.service';
import { EventType, MuteAnalysisBody, NotificationMuteSetting } from '../../../../features/settings/settings.interface';
import { SettingsServiceService } from '../../../../features/settings/service/settings-service.service';

@Component({
  selector: 'app-sidebar-panel-content',
  templateUrl: './sidebar-panel-content.component.html',
  styleUrls: ['./sidebar-panel-content.component.scss',
    '../analytics-open-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarPanelContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() display;
  @Input() health: Health;
  @Input() displayHealth: Analysis;
  @Input() cluster;
  @Input() detectedTime;
  @Input() eventTypeUrl;
  @Input() analyticsGraph = null;
  @Input() appendTo = null;
  @Input() opsterModal = false;
  @Input() displayIssue: any;
  @Input() templateType;
  @Input() style;

  @Input() customizeLoading = false;
  @Input() customizeDisabled = false;
  @Input() customizeData = null;
  @Input() renderGraph;

  @Input() filterEndpoints;

  @Input() withGpt = false;

  @Output() supplementaryInfo = new EventEmitter();
  @Output() onCustomize = new EventEmitter();
  @Output() onMenuOpen = new EventEmitter();
  @Output() onMuteNotifications = new EventEmitter();
  @Output() onSidebarHide = new EventEmitter();
  @Output() setSelectionOnEditor = new EventEmitter();
  @Output() onDismiss = new EventEmitter();

  public dialogButtonConfig;
  public status: string;
  public AnalysisClassificatio = ANALYSIS_CLASSIFICATION;

  @ViewChild('sidebar') sidebar: Sidebar;


  constructor(private translateService: TranslateService,
              private toastService: ToastService,
              private clipboard: Clipboard,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private globalService: GlobalService,
              private settingsServiceService: SettingsServiceService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'display.currentValue')) {
      if (get(changes, 'health.currentValue')) {
        this.eventTypeUrl = GET_EVENT_TYPE_URL(get(this.health, 'type'));
      }

      if (get(changes, 'item.currentValue')) {
        this.eventTypeUrl = GET_EVENT_TYPE_URL(get(this.displayIssue, 'type'));
      }
    }
  }


  hide(event) {
    if (this.display !== event) {
      this.display = event;
      this.globalService.setSideBar(null);
      this.onSidebarHide.emit();
    }
  }

  closeSidebar() {
    this.sidebar.hide();
  }

  onOnMenuOpen(type) {
    this.onMenuOpen.emit(type);
    // event.stopPropagation();
  }

  onOnDismiss(eventName) {
    this.openDialogConfidence(eventName);
  }

  openDialogConfidence(eventName) {
    this.dialogButtonConfig = {
      ok: {
        label: 'Dismiss',
        icon: false,
        action: () => {
          this.onDismiss.emit();
          this.closeSidebar();
        }
      },
      cancel: {
        label: 'Cancel',
        icon: false,
        action: () => {
        }
      },
    };

    const msg = this.translateService.instant('settings.dismiss_events.beforeDismiss',
      {eventName: eventName});
    const note = this.translateService.instant('settings.dismiss_events.noteDismiss');
    const title = this.translateService.instant('settings.dismiss_events.titleDismiss');

    this.dialog.open(DismissEventDialogComponent, {
      width: DialogSize.xLarge,
      id: 'confidence-dialog',
      autoFocus: false,
      data: {
        dialogButtonConfig: this.dialogButtonConfig,
        message: msg,
        eventName,
        note,
        title,
      },
    });

  }

  onOnCustomize() {
    this.onCustomize.emit();
  }

  onOnNotifications() {
    this.closeSidebar();

    this.router.navigate(['/settings/notifications'],
      {
        queryParamsHandling: 'merge'
      }).then(() => {
    });
  }

  onOnMuteNotifications(eventType: EventType) {
    const clusterAlias = this.globalService.getFullClusterNameById(this.cluster);
    const analysisBody: MuteAnalysisBody = {clusterAlias: clusterAlias, eventType: get(eventType, 'type')};
    this.settingsServiceService.getMuteAnalysis(analysisBody).subscribe(muteSetting => {
      this.openMuteNotificationDialog(eventType, muteSetting);
    });
  }

  openMuteNotificationDialog(eventType: EventType, muteSetting: NotificationMuteSetting) {
    this.dialog.open(MuteEventDialogComponent, {
      width: DialogSize.large,
      id: 'mute-dialog',
      autoFocus: false,
      data: {
        type: eventType,
        muteSetting: muteSetting,
        page: 'sidebar'
      }
    });
  }

  selectionOnEditor(val) {
    this.setSelectionOnEditor.emit(val);
  }

  onShareEvent(eventId) {
    const origin = window.location.origin;
    let currentUrl;

    this.activatedRoute.queryParams.subscribe(params => {
      currentUrl = `${origin}/clusterView?eventId=${eventId}&cluster=${this.cluster || params['cluster']}`;
      if (params['from_c'] && params['to_c']) {
        currentUrl += `&from_c=${params['from_c']}&to_c=${params['to_c']}`;
      }
    });

    this.clipboard.copy(currentUrl);

    this.toastService.queueSnackBar(null,
      this.translateService.instant('success.copy_event_link'), 'success');
  }

  ngOnDestroy() {
  }


}
