export interface Template {
  id: number;
  executionTime: number;
  type: TypeTemplate;
  source: Source;
}

export interface TemplateToResultsMap {
  [key: string]: Template[];
}

export interface TemplateToResults {
  templateToResultsMap: TemplateToResultsMap;
}

export enum Source {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum TypeTemplate {
  Component = 'COMPONENT',
  Index = 'INDEX',
  Legacy = 'LEGACY',
}


export interface TemplateResult {
  id:             number;
  accountId:      number;
  cluster:        string;
  name:           string;
  inputId:        number;
  summary:        string;
  result:         Result;
  executionTime:  number;
  distribution:   string;
  type:           TypeTemplate;
  templateSource: null;
}

export interface Result {
  id:                number;
  executionTime:     number;
  esVersion:         string;
  optimizerResponse: OptimizerResponse;
  summary:           Summary;
  templates:         any;
  name:              string;
}

export interface OptimizerResponse {
  optimizerResponseMap: any;
}

export interface Summary {
  issuesOpened:        number;
  totalChecksExecuted: number;
  majorIssues:         number;
}
