import {throwError as observableThrowError, Observable, of, merge, fromEvent, Observer} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import isEmpty from 'lodash-es/isEmpty';
import includes from 'lodash-es/includes';
import has from 'lodash-es/has';
import invoke from 'lodash-es/invoke';
import startsWith from 'lodash-es/startsWith';
import get from 'lodash-es/get';

import {MatDialog} from '@angular/material/dialog';
import {
  NoInternetConnectionDialogComponent
} from '../components/popups/dialog/no-internet-connection-dialog/no-internet-connection-dialog.component';
import {GlobalService} from '../../store/global/global.service';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth/auth.service';
import {ToastService} from '../../store/toast/toast.service';
import {DialogSize} from '../components/popups/dialog/dialog.enum';
import {SegmentTrackService} from '../services/segment/segment.service';
import {TranslateService} from '@ngx-translate/core';
import set from 'lodash-es/set';
import isEqual from 'lodash-es/isEqual';

const CODE_OKTA = 'A412';
const CODE_LOGIN = 'A4013';
const CODE_LOGIN_WITH_GOOGLE = 'A420';

// const CODE_LOGIN2 = 'A401';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  public dialogButtonConfig: any;
  public isOnline = true;
  public dialogRef;
  private authReq;


  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private segmentTrackService: SegmentTrackService,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService) {

    this.createOnline$().subscribe((isOnline: boolean) => {
      this.isOnline = isOnline;
    });
  }

  createOnline$() {
    return merge<any>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
      }));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const clonedRequest = req.clone({ headers: req.headers.append('Referrer-Policy', 'origin') });

    if (!includes(req.url, 'glossary')) {
      this.authReq = req.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=31536000',
          Pragma: 'no-cache'
        }
      });
    } else {
      this.authReq = req;
    }

    if (!this.isOnline) {
      this.handleErrorNoInternet();
      return;
    } else {
      this.dialogRef ? this.dialogRef.close() : null;
    }

    if (this.authReq.params.get('skipOpster') === 'true') {
      return next.handle(this.authReq);
    }


    return next.handle(this.authReq).pipe(
      catchError((error) => {


        if (error instanceof HttpErrorResponse) {
          const code = get((error as HttpErrorResponse), 'error.code');

          if (code === CODE_OKTA) {
            const message = get((error as HttpErrorResponse), 'error.message');
            this.router.navigate(['/error-page'], {state: {message: message}});
            return;
          }

          const statusCode = get(error, 'status');
          const status = (startsWith(`${statusCode}`, '5') && statusCode !== 504) ? '500' : `${statusCode}`;

          const errorHandlers = {
            '401': () => this.handle401Error(error),
            '431': () => this.handle431Error(error, this.authReq),
            '403': () => this.handle401Error(error),
            '504': () => this.handle504Error(error),
            '500': () => this.handleServerError(error),
            default: () => this.handleGeneralError(error)
          };

          return invoke(errorHandlers, has(errorHandlers, status) ? status : 'default');
        }
        return observableThrowError(error);
      })) as any;
  }

  private handleErrorNoInternet() {
    if (this.dialogRef) {
      return;
    }
    this.dialogButtonConfig = {
      ok: {
        label: 'Add',
        action: () => {
        }
      },
    };
    this.dialogRef = this.dialog.open(NoInternetConnectionDialogComponent, {
      width: DialogSize.small,
      data: {dialogButtonConfig: this.dialogButtonConfig},
      id: 'no-internet-dialog'
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.globalService.setSelectedRefreshNow(true);
      this.dialogRef = null;
    });
  }

  private handle401Error(error: HttpErrorResponse) {
    // window.location.href = '/login?reason=expiry';
    console.log(error);

    if (get(error, 'error.code') === CODE_LOGIN || get(error, 'error.code') === CODE_LOGIN_WITH_GOOGLE) {
      this.queueSnackBar('Access denied', get(error.error, 'message'), 'error',
        'access_denied', 10000);
      return;
    }

    if (!isEmpty(get(this.authService, 'currentUserValue'))) {
      this.queueSnackBar('Access denied', get(error, 'error.message', 'please log in to view this page'), 'error',
        'access_denied', 10000);
      this.authService.removeUser();
    }
    this.router.navigate(['/login/sign-in']).then(() => {
    });

    return observableThrowError(error);
  }

  private handle504Error(error: HttpErrorResponse) {
    window.location.reload();
    return observableThrowError(error);
  }

  private queueSnackBar(summary: string, message: string, severity, key?: string, duration?: number) {
    if (!isEqual(this.authReq.params.get('skipOpsterError'), 'true')) {
      this.toastService.queueSnackBar(summary, message, severity, key, duration);
    }
  }

  private handleServerError(error: HttpErrorResponse) {
    if (error.status === 401) {
      return;
    }
    const errorText: string = get(error, 'error.error', 'errors.unexpected_error');
    this.queueSnackBar('Error', this.translateService.instant(errorText), 'error', 'server_error');

    this.buildSegmentTrack(error);
    return of();
  }

  private handle431Error(error: HttpErrorResponse, authReq) {
    this.buildSegmentTrack(error, authReq);

    return observableThrowError(error);
  }


  private handleGeneralError(error: HttpErrorResponse) {
    const errorText: string = get(error, 'error.error', 'errors.unexpected_error');
    this.queueSnackBar('Error', this.translateService.instant(errorText), 'error', 'server_error');

    this.buildSegmentTrack(error);

    return observableThrowError(error);
  }

  buildSegmentTrack(error, response?) {
    if (get(error, 'status') === 0) {
      window.location.reload();
      return;
    }

    this.authService.getCurrentUser().pipe(first())
      .subscribe(res => {
        const accountId = get(res, 'id');
        const email = get(res, 'email');
        const errorText: string = get(error, 'error.error', 'errors.unexpected_error');

        const body = {
          status: get(error, 'status'),
          url: get(error, 'url'),
          domain: get(window, 'location.origin'),
          accountId,
          email
        };

        if (response) {
          const keys = response.headers.keys();
          const headers = keys.map(key =>
            `${key}: ${response.headers.get(key)}`);

          set(body, 'headers', headers);
        }


        if (!includes(this.segmentTrackService.getBlackListErrors, body)) {
          this.segmentTrackService.setAnalyticsTrackToServer(this.translateService.instant(errorText), body);
          this.segmentTrackService.setBlackListErrors(body);
        }
      });
  }
}
