import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { SideMenuModule } from './side-menu/side-menu.module';
import { TopMenuModule } from './top-menu/top-menu.module';
import { ThemeModule } from '../store/theme/theme.module';
import { MenuService } from './menu-service/menu.service';
import { ClearPageComponent } from './clear-page/clear-page.component';
import { MatIconModule } from '@angular/material/icon';
import { LoaderModule } from '../shared/components/loader/loader.module';
import { ProgressBarOpsterModule } from '../shared/components/progress-bar/progress-bar.component';
import { MessageService } from 'primeng/api';
import { SharedModule } from '../shared/shared.module';
import { SplitterModule } from 'primeng/splitter';
import { OpsGptModule } from '../features/ops-gpt/ops-gpt.module';
import { AngularSplitModule } from 'angular-split';
import { SidebarPanelContentModule } from '../shared/components/analytics/sidebar-panel-content/sidebar-panel-content.module';
import {EmptyStateTrialModule} from '../shared/components/empty-state-trail/empty-state-trial.module';

@NgModule({
  declarations: [
    MasterPageComponent,
    AppLayoutComponent,
    ClearPageComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        SideMenuModule,
        TopMenuModule,
        ThemeModule,
        MatIconModule,
        LoaderModule,
        ProgressBarOpsterModule,
        SharedModule,
        SplitterModule,
        OpsGptModule,
        AngularSplitModule,
        SidebarPanelContentModule,
        EmptyStateTrialModule,
    ],
  providers: [MenuService, MessageService ],
  exports: [AppLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLayoutModule {
}
