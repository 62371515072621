<p-toast [preventOpenDuplicates]="true" [styleClass]="'global-toast'"></p-toast>

<div class="new-version">
  <p-toast position="top-right" key="new_version" [preventOpenDuplicates]="true">
    <ng-template let-message pTemplate="message">
      <div class="message">
        <mat-icon [svgIcon]="'new_release'" style="font-size: 1.5rem"></mat-icon>
        <span>{{message.summary}}
          <a (click)="refresh()">Refresh</a>
     </span>
      </div>
    </ng-template>

  </p-toast>
</div>



