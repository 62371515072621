<div class="chat-history">
  <div class="chat-table" [class.empty-state]="!chatHistory?.length" *ngIf="!loaderHistory; else loadingUI">

    <p-table [value]="chatHistory" *ngIf="chatHistory?.length; else emptyMsg"
             [responsive]="true"
             styleClass="chat-history-table">
      <ng-template pTemplate="header">
        <tr>
          <th style="width:40%">Conversation
          </th>
          <th style="width:30%">Clusters
          </th>
          <th style="width:20%">Date
          </th>
          <th style="width: 10%">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history let-rowIndex="rowIndex">
        <tr style="height: 53px" (click)="getHistoryItem(history)">
          <td class="prompt-name" [pTooltip]="history?.prompt"
              [tooltipStyleClass]="'hovered-icon'">{{history?.prompt}}</td>
          <td class="prompt-clusters" [pTooltip]="history?.tooltipText" [escape]="false"
              [tooltipStyleClass]="'multiselect-tooltip auto-width'" [tooltipPosition]="'left'">
            <div class="cluster-tag" *ngIf="history?.clusters?.length >= 1 && history?.clusters.length !== clusterList.length">
              {{history?.clusters[0]?.clusterName}}
            </div>
              <div *ngIf="!history?.clusters.length" class="cluster-tag">--</div>
              <div *ngIf="history?.clusters.length === clusterList.length" class="cluster-tag">All clusters</div>
              <div style="margin-left: 5px" *ngIf="history?.clusters?.length > 1 && history?.clusters.length !== clusterList.length">
              +{{history?.clusters?.length - 1}}
            </div>

          </td>
          <td class="prompt-date-menu">{{history?.updatedAt ? (history?.updatedAt | date: 'MMM d h:mm a') : '--- ---'}}
            <!--            <mat-menu (closed)="showSlidersHoriz = []; menuOpened = false" #menu="matMenu" [overlapTrigger]="false">-->
            <!--              <div mat-menu-item (click)="dialogConfidenceMuteConfig(history)">-->
            <!--                <mat-icon class="chat-trash" [svgIcon]="'trash'"></mat-icon>-->
            <!--                <span>Delete</span>-->
            <!--              </div>-->
            <!--            </mat-menu>-->

            <!--              (menuOpened)="this.menuOpened = true; showSlidersHoriz[rowIndex] = true"-->
            <!--              [matMenuTriggerFor]="menu"-->

          </td>
          <td>
            <mat-icon class="icon-delete"
                      (click)="deleteConversion(history)"
                      [style.display]="showSlidersHoriz[rowIndex] ? 'block': 'none'"
                      [pTooltip]="'Delete Conversion'"
                      [tooltipPosition]="'left'"
                      svgIcon="trash">
            </mat-icon>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>

  <ng-template #loadingUI>
    <app-skeleton-loader></app-skeleton-loader>

  </ng-template>

  <ng-template #emptyMsg>
    <div class="empty-state">
      <mat-icon svgIcon="empty_state_ghost"></mat-icon>
      <div class="empty-state-text">
        <span>No conversations yet</span>
      </div>
    </div>
  </ng-template>

</div>
