export const LoginSeo = {
  title: '',
  metaTagName: 'description',
  metaTagContent: 'Sign in to Opster AutoOps. Improve your Elasticsearch performance, ensure stability and reduce costs.',
};

export const DashboardSeo = {
  title: '',
  metaTagName: 'description',
  metaTagContent: 'Opster AutoOps Dashboard - everything you need to improve your Elasticsearch performance, ensure stability and reduce costs.',
};

