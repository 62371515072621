import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MenuTab } from '../menu-service/menu.interface';
import { MenuService } from '../menu-service/menu.service';
import { AppRoutes } from '../../app-routing-data';
import { ThemeService } from '../../store/theme/theme.service';
import { filter, first as rxFirst, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { GlobalService } from '../../store/global/global.service';
import { Router } from '@angular/router';
import { User } from '../../core/auth/auth.interface';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { Cluster } from '../../store/global/cluster.interface';
import { SegmentTrackService } from '../../shared/services/segment/segment.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccountPlan } from '../../shared/models/enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainDashboardService } from '../../features/dashboard/service/main-dashboard.service';

// lodash-es
import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import upperCase from 'lodash-es/upperCase';
import size from 'lodash-es/size';
import first from 'lodash-es/first';
import split from 'lodash-es/split';
import find from 'lodash-es/find';
import { PerformanceUtils } from '../../shared/services/utils/functional-utils';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentUrl: string;
  @Input() user: User;
  @Output() MenuChange = new EventEmitter();

  public tabs: MenuTab[];
  public settingsLink: MenuTab;

  public selectedTab: MenuTab;
  public isMenuOpen = false;
  // public isMenuHover = false;

  public AppRoutes = AppRoutes;
  public isDarkTheme: boolean;
  public userShortName;

  public selectedAccount;
  public clusterList: Cluster[] = null;

  public accountTooltip;

  public isClusterConnectedSuccess = null;
  public openEventsReceived;
  public isTrialEnding;
  public isMenuDisable = false;
  public showContactUs = false;
  public displayContactUs;
  public urlSafe: SafeResourceUrl;

  private destroyCluster$: Subject<boolean> = new Subject<boolean>();
  // private destroyReceivedEvents$: Subject<boolean> = new Subject<boolean>();

  private clusterViewInterval: Subscription;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private menuService: MenuService,
              private authService: AuthService,
              private globalService: GlobalService,
              private dashboardService: MainDashboardService,
              private router: Router,
              private ref: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private segmentTrackService: SegmentTrackService,
              private themeService: ThemeService) {
  }

  ngOnInit() {
    this.tabs = this.menuService.getSideMenuTabs();
    this.settingsLink = this.menuService.getSettingsLink();
    this.indicateSelectedTab(this.currentUrl);

    this.themeService.getActiveTheme().subscribe(res => this.isDarkTheme = res);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://opster.com/contact-frame/autoops-contact-us-opster-message/');

    this.authService.getAccount()
      .pipe(filter(x => x), rxFirst(), untilDestroyed(this))
      .subscribe(accountUser => {
        this.showContactUs = isEqual(get(accountUser, 'account.plan'), AccountPlan.TRIAL)
          || isEqual(get(accountUser, 'account.plan'), AccountPlan.FREE);
        this.ref.markForCheck();
      });


    const combineLatestList = [];
    const clusterList$ = this.globalService.getClusterList();
    const eventsReceived$ = this.dashboardService.getReceivedEvents();
    combineLatestList.push(clusterList$, eventsReceived$);

    combineLatest(combineLatestList)
      .pipe(untilDestroyed(this),
        takeUntil(this.destroyCluster$))
      .subscribe(([clusterList, events]: any) => {

        if (this.clusterList && clusterList && !events) {
          if (isEqual(size(this.clusterList), 0) && isEqual(size(clusterList), 1)) {
            this.isClusterConnectedSuccess = true;
          }
        }

        if (clusterList && events) {
          if (!size(get(events, 'eventsSection.eventCards')) && size(clusterList) === 1) { // cluster already connected
            this.isClusterConnectedSuccess = true;
          }
        }

        this.clusterList = clusterList;
        this.openEventsReceived = !!size(get(events, 'eventsSection.eventCards'));
        if (this.openEventsReceived) {
          this.destroyCluster$.next(null);
          this.destroyCluster$.unsubscribe();
        }

        this.ref.markForCheck();
      });

    // this.getClusterList();
  }

  onTabClick(tab: MenuTab): void {
    if (size(this.clusterList)) {
      this.selectedTab = tab;
      this.ref.markForCheck();
    }
  }

  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.MenuChange.emit(this.isMenuOpen);
    this.ref.markForCheck();
  }

  closePopup() {
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked on Go to Dashboard - closed new cluster connected popup',
      {email: this.segmentTrackService.getTrackEmail});

    if (this.showContactUs) {
      this.openMenu();
    }

    this.isClusterConnectedSuccess = false;
    this.ref.markForCheck();
  }

  private indicateSelectedTab(currentUrl: string): void {
    if (!this.tabs) {
      return;
    }
    this.selectedTab = find(this.tabs, (tab) =>
      get(tab, 'route') === first(split(currentUrl, '/'))
    );

    this.ref.markForCheck();

  }

  // THEME
  toggleThemeMenu() {
    this.themeService.setActiveThem(!this.isDarkTheme);
  }

  toggleTheme(val: boolean) {
    this.themeService.setActiveThem(val);
  }

  logOut() {
    this.globalService.setLoader(true);
    this.authService.logout().pipe(rxFirst())
      .subscribe(
        () => {
          this.globalService.resetAll();
          this.dashboardService.resetAll();
          this.globalService.setLoader(false);

          this.router.navigate(['/login']).then(() => {
          });
        },
        () => {
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'currentUrl')) {
      this.indicateSelectedTab(this.currentUrl);
    }
    if (get(changes, 'user')) {
      try {
        this.userShortName = get(this.user, 'fullName')
          ? upperCase(get(this.user, 'fullName')?.substring(0, 2))
          : upperCase(get(this.user, 'email')?.substring(0, 2));

        this.accountTooltip = `
  <div class="account-tooltip-body">
    <div class="side-menu-icon short-name">
      ${this.userShortName}
    </div>
    <div class="right">
      <div class="email">
        ${this.user?.email}
      </div>
      <div class="company">
        ${this.user?.fullName}
        •
        ${this.user?.company}
      </div>
    </div>
  </div>`;

      } catch (e) {
        this.userShortName = 'op';
      }
    }
  }

  openContactUs() {
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked on Contact Us side menu AutoOps',
      {email: this.segmentTrackService.getTrackEmail});

    this.displayContactUs = true;
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    if (this.destroyCluster$ && !this.destroyCluster$.closed) {
      this.destroyCluster$.next(null);
      this.destroyCluster$.unsubscribe();
    }

    if (this.clusterViewInterval) {
      this.clusterViewInterval.unsubscribe();
    }

  }
}
