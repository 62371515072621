import {Component, OnInit, ChangeDetectionStrategy, Input, NgModule} from '@angular/core';

@Component({
  selector: 'loader-box',
  templateUrl: './loader-box.component.html',
  styleUrls: ['./loader-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderBoxComponent implements OnInit {
  @Input() strokeWidth = 1;
  @Input() diameter = 20;
  @Input() relative = false;

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [LoaderBoxComponent],
  imports: [
  ],
  exports: [LoaderBoxComponent]
})
export class LoaderBoxModule {}
