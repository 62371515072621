<div class="new-cluster-connected">
  <div class="box-new-cluster-connected">

    <div class="background-wrapper">
      <div class="background-circle right-aligned"></div>
      <div class="background-circle right-aligned second"></div>
    </div>

    <div class="left">
      <ng-container *ngIf="!eventsReceived">
        <h1 class="header">Cluster<br> connected 🎉</h1>
        <h3 class="subheader">Your data is being analyzed now...</h3>
        <p>This process usually takes up to 15 min.<br>The analysis will continue even if you leave this page.</p>
      </ng-container>

      <ng-container *ngIf="eventsReceived">
        <h1 class="header">You're<br> all set 🎉</h1>
        <h3 class="subheader">Operate, optimize and reduce<br> the cost of your deployment.</h3>
        <button class="button-opster yellow" (click)="closeDialog()">Go to Dashboard ></button>
      </ng-container>
    </div>

    <div class="right">
      <div class="product-tour-wrapper">
        <iframe width="100%" height="100%" frameBorder="0" [src]="url"></iframe>
      </div>
      <mat-icon class="marketing-icon top" [svgIcon]="'marketing_bg_top'"></mat-icon>
      <mat-icon class="marketing-icon bottom" [svgIcon]="'marketing_bg_bottom'"></mat-icon>
    </div>

    <div class="footer">
      <span class="step-wrapper">
        <mat-icon class="bulb-idea" [svgIcon]="progressbarValue === 100 ? 'checkbox_circle' : 'bulb_idea'"></mat-icon>
        <span class="stepName">{{stepsNames[currentStep]}}... </span>
        <span class="stepPercentage">{{progressbarValue}}%</span>
      </span>
      <p-progressBar [value]="progressbarValue" [showValue]="false"
                     [style]="{ height: '10px', borderRadius: '10px' }"></p-progressBar>
    </div>

  </div>

</div>
