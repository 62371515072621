<div class="opster-chat">

  <div class="main-chat">
    <main-chat [user]="user" [currentUrl]="null"></main-chat>
  </div>

  <div class="main-history">

    <div class="opster-chat-history">

      <app-external-title-panel>
        <div title class="title">Chat History</div>
        <chat-history body [user]="user"></chat-history>
      </app-external-title-panel>

    </div>

  </div>
</div>
