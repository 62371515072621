import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InputMultiSelectComponent} from './input-multi-select.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {CheckboxModule} from 'primeng/checkbox';
import {FormsModule} from '@angular/forms';
import {SkeletonLoaderModule} from '../../skeleton-loader/skeleton-loader.module';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    InputMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    TooltipModule,
    CheckboxModule,
    FormsModule,
    SkeletonLoaderModule,
    MatIconModule,
  ],
  providers: [],
  exports: [
    InputMultiSelectComponent
  ]
})
export class InputMultiSelectModule {
}
