import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {
  @Input() theHtmlString: string;
  @Input() withHeaderSub: string;
  value = '';

  constructor() {
  }

  ngOnInit() {
  }

}

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    MatToolbarModule,
    CommonModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
