import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

// material
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';


// components
import {DialogComponent} from './components/popups/dialog/dialog.component';
import {AddFetcherComponent} from './components/popups/dialog/add-fetcher/add-fetcher.component';
import {
  CustomizeEventDialogComponent
} from './components/popups/dialog/customize-event-dialog/customize-event-dialog.component';
import {
  NoInternetConnectionDialogComponent
} from './components/popups/dialog/no-internet-connection-dialog/no-internet-connection-dialog.component';
import {
  AddNotificationPipeComponent
} from './components/popups/dialog/add-notification-pipe/add-notification-pipe.component';
import {ConfidenceDialogComponent} from './components/popups/dialog/confidence-dialog/confidence-dialog.component';
import {ShardsDialogComponent} from './components/popups/dialog/shards-dialog/shards-dialog.component';
import {SupplementaryInfoComponent} from './components/popups/dialog/supplementary-info/supplementary-info.component';
import {RedirectToCheckupComponent} from './components/popups/dialog/redirect-to-checkup/redirect-to-checkup.component';
import {EditClusterComponent} from './components/popups/dialog/edit-cluster/edit-cluster.component';
import {MultipleAccountsComponent} from './components/popups/dialog/multiple-accounts/multiple-accounts.component';
import {
  DismissEventDialogComponent
} from './components/popups/dialog/dismiss-event-dialog/dismiss-event-dialog.component';
import {PartialResultsComponent} from './components/partial-results/partial-results.component';
import {FailedUploadComponent} from './components/popups/dialog/failed-upload/failed-upload.component';
import {AddMemberComponent} from './components/popups/dialog/add-member/add-member.component';
import {AddSsoAppComponent} from './components/popups/dialog/add-sso-app/add-sso-app.component';
import {GetTokenComponent} from './components/popups/dialog/get-token/get-token.component';
import {MuteEventDialogComponent} from './components/popups/dialog/mute-event-dialog/mute-event-dialog.component';
import {ChartDialogComponent} from './components/popups/dialog/chart-dialog/chart-dialog.component';
import {CopyBtnComponent} from './components/buttons/copy-btn/copy-btn.component';
import {InfoDialogComponent} from './components/popups/dialog/info-dialog/info-dialog.component';
import {TourDialogComponent} from './components/popups/dialog/tour-dialog/tour-dialog.component';
import {AddMuteSettingComponent} from './components/popups/dialog/add-mute-setting/add-mute-setting.component';
import {CalculatorDialogComponent} from './components/popups/dialog/calculator-dialog/calculator-dialog.component';

// all
import {ClipboardModule} from '@angular/cdk/clipboard';

// primeng
import {MessageService} from 'primeng/api';
import {DividerModule} from 'primeng/divider';
import {PickListModule} from 'primeng/picklist';
import {ListboxModule} from 'primeng/listbox';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {MultiSelectModule} from 'primeng/multiselect';
import {ChipModule} from 'primeng/chip';

// pipe
import {ObjNgForPipeModule} from './pipe/obj-ng-for/obj-ng-for.pipe';
import {DateSuffixPipe} from './pipe/date-suffix/date-suffix.pipe';
import {DndDirectiveModule} from './directives/dnd.directive';

// Module
import { LoaderBoxModule } from './components/loader-box/loader-box.component';
import { SkeletonLoaderModule } from './components/skeleton-loader/skeleton-loader.module';
import { OpsterMarkdownModule } from './components/opster-markdowm/opster-markdown.module';
import { BarChartModule } from './charts/bar-chart/bar-chart.module';
import { LineChartModule } from './charts/line-chart/line-chart.module';
import { CodeViewerModule } from './components/code-viewer/code-viewer.component';
import { NumberFormatModule } from './pipe/number-format/number-format.module';
import { PaginatorModule } from 'primeng/paginator';
import {InputNumberModule} from 'primeng/inputnumber';

@NgModule({
  declarations: [
    DialogComponent,
    ConfidenceDialogComponent,
    AddFetcherComponent,
    NoInternetConnectionDialogComponent,
    CustomizeEventDialogComponent,
    AddNotificationPipeComponent,
    ShardsDialogComponent,
    SupplementaryInfoComponent,
    RedirectToCheckupComponent,
    AddMemberComponent,
    EditClusterComponent,
    MultipleAccountsComponent,
    DismissEventDialogComponent,
    PartialResultsComponent,
    FailedUploadComponent,
    AddSsoAppComponent,
    GetTokenComponent,
    DateSuffixPipe,
    AddMuteSettingComponent,
    MuteEventDialogComponent,
    CalculatorDialogComponent,
    InfoDialogComponent,
    TourDialogComponent,
    CopyBtnComponent,
    ChartDialogComponent,
  ],
  exports: [
    DialogComponent,
    DateSuffixPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCardModule,
    LoaderBoxModule,
    OpsterMarkdownModule,
    MultiSelectModule,
    PickListModule,
    DropdownModule,
    ObjNgForPipeModule,
    ChipsModule,
    FormsModule,
    DividerModule,
    CheckboxModule,
    ListboxModule,
    TooltipModule,
    SkeletonLoaderModule,
    CodeViewerModule,
    MatButtonModule,
    ClipboardModule,
    DndDirectiveModule,
    ChipModule,
    BarChartModule,
    LineChartModule,
    NumberFormatModule,
    InputNumberModule,
    PaginatorModule
  ],
  providers: [MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
