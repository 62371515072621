<div class="top-menu">
  <div class="header" [class.fixed]="isSettings">
    <div class="header-url">{{('topMenu.' + currentUrl.split('/')[0]) | translate}}
      <span *ngIf="showBeta" class="beta">BETA</span>
    </div>
    <div class="cluster-name-wrapper" *ngIf="showClusterName">
      <span class="cluster-name">Cluster: {{selectedCluster?.clusterAlias}} ({{selectedCluster?.cluster}})</span>
    </div>

    <!--    <div class="sub-header" *ngIf="showSubHeader">{{('topMenu.sub.' + currentUrl) | translate}}</div>-->
    <div class="tour-wrapper" *ngIf="showProductTour" (click)="openTour()">
      <mat-icon class="play-icon" [svgIcon]="'play_tour'"></mat-icon>
      <div class="play-text">
        <span
          class="first-line">{{currentUrl === 'shardsView' ? ('topMenu.firstLineShardsView' | translate) : ('topMenu.firstLine' | translate)}}</span>
        <span
          class="second-line">{{currentUrl === 'shardsView' ? ('topMenu.secondLineShardsView' | translate) : ('topMenu.secondLine' | translate)}}</span>
      </div>
    </div>
  </div>
  <ng-container>
<!--    <div class="spacer" *ngIf="showWelcomeMessage"></div>-->
<!--    <ng-container *ngIf="showWelcomeMessage">-->
<!--      <div class="welcome-msg" [class.hiding]="welcomeMessageHide">{{welcomeMessage}}</div>-->
<!--    </ng-container>-->
    <span class="spacer"></span>

    <ng-container *ngIf="showRunsHistorySlowLogs && previousRunsSlowLogs">
      <input-select [type]="InputSelectTypes.SLOW_LOGS"
                    [items]="previousRunsSlowLogs"
                    [selectedItem]="selectedRunSlowLogs"
                    (selectionChange)="selectionRunChangeSL($event)"
                    [isGroup]="false"
      ></input-select>
    </ng-container>

    <span class="small-spacer" *ngIf="showRunsHistorySlowLogs"></span>

    <button *ngIf="showRunsHistorySlowLogs" mat-stroked-button class="button-opster action small-btn"
            (click)="newRunSL()">
      <mat-icon style="margin-right: 5px" [svgIcon]="'plus_color'"></mat-icon>
      New Run
    </button>

    <span class="small-spacer" *ngIf="showRunsHistorySlowLogs"></span>

<!--    <ng-container *ngIf="showEvents">-->
<!--      <input-multi-select-->
<!--        [listGrouping]="eventsList"-->
<!--        [sizeOfItems]="allEventsList?.length"-->
<!--        [selectedAllLabel]="true"-->
<!--        [selectedItems]="selectedEvents"-->
<!--        [selectedGroups]="selectedEventsGroups"-->
<!--        [selectionLimit]="selectionLimit"-->
<!--        [disabledItemList]="eventsDisabledList"-->
<!--        [isGroup]="true"-->
<!--        [isSelectingGroup]="true"-->
<!--        (groupSelectionChange)="eventsGroupSelectionChange($event)"-->
<!--        (itemSelectionChange)="eventsItemSelectionChange($event)"-->
<!--        [nameItem]="'Events'"-->
<!--        (loadItems)="eventsPanelOpened()"-->
<!--        (selectionChange)="selectionEventChange($event)">-->
<!--      </input-multi-select>-->
<!--    </ng-container>-->

<!--    <span class="small-spacer" *ngIf="showEvents && showMultiClusters"></span>-->

    <ng-container *ngIf="showMultiClusters">
      <input-multi-select
        [listGrouping]="multiClustersGroup"
        [sizeOfItems]="multiClustersGroup[0]?.items?.length"
        [selectedAllLabel]="true"
        [selectedItems]="selectedMultiCluster"
        [selectionLimit]="selectionLimit"
        [disabledItemList]="multiClustersDisabledList"
        [isGroup]="true"
        [isSelectingGroup]="false"
        [nameItem]="'Clusters'"
        (loadItems)="multiSelectPanelOpened()"
        (selectionChange)="selectionMultiClusterChange($event)">
      </input-multi-select>
    </ng-container>

    <ng-container *ngIf="showClusters">
      <input-select [type]="InputSelectTypes.CLUSTERS"
                    [items]="showRunsHistoryTemplateOptimizer? (isSecondTabSelected ? manualClusterItemsDisplay : allClustersGroup): allClustersGroup"
                    [selectedItem]="showRunsHistoryTemplateOptimizer? (isSecondTabSelected ? selectedManualCluster : selectedCluster): selectedCluster "
                    (selectionChange)="selectionClusterChange($event)"
                    [isGroup]="true"
                    [isSecondTabSelected]="isSecondTabSelected"
                    [tabViewNames]="showRunsHistoryTemplateOptimizer ? ['Connected', 'Manual'] : []"
                    (loadItems)="onLoadClusters()"
                    (tabChange)="selectionManualClusterChange($event)"
      ></input-select>
    </ng-container>

    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <span class="small-spacer" *ngIf="showRunsHistoryTemplateOptimizer"></span>

    <ng-container *ngIf="showRunsHistoryTemplateOptimizer">
      <tree-select [type]="InputSelectTypes.TEMPLATE_OPTIMIZER"
                   [items]="previousRunsTemplateOptimizer"
                   (selectionChange)="selectionRunChangeTO($event)"
                   [selectedItem]="selectedRunTemplateOptimizer"
      ></tree-select>

    </ng-container>

    <span class="small-spacer" *ngIf="showRunsHistoryTemplateOptimizer"></span>

    <button *ngIf="showRunsHistoryTemplateOptimizer" mat-stroked-button class="button-opster action small-btn"
            (click)="newRunTO()">
      <mat-icon style="margin-right: 5px" [svgIcon]="'plus_color'"></mat-icon>
      New Template
    </button>

    <span class="small-spacer" *ngIf="showRunsHistoryTemplateOptimizer"></span>


    <div style="line-height: 0;" *ngIf="showNodes">
      <input-multi-select
        [listGrouping]="nodesListGrouping"
        [selectedItems]="selectedNodes"
        [selectedGroups]="selectedGroups"
        [selectionLimit]="selectionLimit"
        [disabledItemList]="nodesDisabledList"
        [isGroup]="true"
        [isSelectingGroup]="true"
        (selectionChange)="nodesSelectionChange($event)"
        (groupSelectionChange)="nodesGroupSelectionChange($event)"
        (itemSelectionChange)="nodesItemSelectionChange($event)"
        [nameItem]="'Nodes'">
      </input-multi-select>
    </div>

    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <time-range *ngIf="showDatePicker" [timeType]="datePickerType"></time-range>

    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <div class="icon-refresh" *ngIf="showRefresh" [class.disabled]="disableRefreshTime || disableRefreshTimeURL">
      <mat-icon (click)="refreshView()" *ngIf="!loadingRefreshView"
                [pTooltip]="'Refresh Now'" [tooltipPosition]="'bottom'"
                [tooltipStyleClass]="'hovered-icon'"
                [showDelay]="500"
                [svgIcon]="'refresh'"></mat-icon>

      <mat-icon matSuffix *ngIf="loadingRefreshView">
        <mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
      </mat-icon>

    </div>


    <ng-container *ngIf="showRefresh">
      <input-select [type]="InputSelectTypes.REFRESH"
                    [items]="refreshTimesList"
                    [selectedItem]="selectedRefreshTime"
                    [disabled]="disableRefreshTime || disableRefreshTimeURL"
                    (selectionChange)="selectionRefreshChange($event)">
      </input-select>
    </ng-container>
    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

    <div pBadge severity="info" value="NEW" iconPos="right" class="top-chat-gpt" (click)="openOpsGpt()"
         *ngIf="!gptPayload?.display && !disableQuickOpsGpt && !isSettings">

      <mat-icon [pTooltip]="'OpsGPT'" [tooltipPosition]="'bottom'"
                [tooltipStyleClass]="'hovered-icon'"
                [showDelay]="500"
                class="chat-icon"
                [svgIcon]="'ops_gpt_dark'"></mat-icon>

      <span class="ops-gpt-text">OpsGPT</span>
    </div>


    <span class="small-spacer" *ngIf="!showRunsHistorySlowLogs && !showRunsHistoryTemplateOptimizer"></span>

  </ng-container>

</div>
