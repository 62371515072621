import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {
  NotificationsHistoryRequestBody,
  NotificationsHistorySummaryResponse,
  NotificationsHistoryTableResponse,
  TimeRange
} from '../notifications-history.interface';
import isEqual from 'lodash-es/isEqual';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationsHistoryService {

  private selectedMultiCluster$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);

  constructor(public httpClient: HttpClient, private router: Router) {
  }

  getSelectedMultiClusterValue() {
    return this.selectedMultiCluster$.value;
  }

  getSelectedMultiCluster() {
    return this.selectedMultiCluster$.asObservable();
  }

  setSelectedMultiCluster(clusterIds: string[], addToUrl = true) {
    if (addToUrl) {
      this.router.navigate(
        [],
        {
          queryParams: {
            clusters: clusterIds.join(',')
          },
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }

    if (!isEqual(this.getSelectedMultiClusterValue(), clusterIds)) {
      this.selectedMultiCluster$.next(clusterIds);
    }
  }

  getNotificationsSummary(clusterIds, eventTypes, notificationStatuses, endpointIds, timeRange: TimeRange): Observable<NotificationsHistorySummaryResponse> {
    const body: NotificationsHistoryRequestBody = {
      clusterIds: clusterIds,
      eventTypes: eventTypes,
      notificationStatuses: notificationStatuses,
      endpointIds: endpointIds,
      timeRange: timeRange
    };

    return this.httpClient.post<NotificationsHistorySummaryResponse>(`api/notifier/notification-view/report`, body).pipe(share());
  }

  getNotificationsHistory(clusterIds, eventTypes, notificationStatuses, endpointIds, timeRange, size, offset, sortBy?, sortOrder?): Observable<NotificationsHistoryTableResponse> {
    const body: NotificationsHistoryRequestBody = {
      clusterIds: clusterIds,
      eventTypes: eventTypes,
      notificationStatuses: notificationStatuses,
      endpointIds: endpointIds,
      timeRange: timeRange,
      size: size,
      offset: offset,
      sortBy: sortBy,
      sortOrder: sortOrder
    };

    return this.httpClient.post<NotificationsHistoryTableResponse>(`api/notifier/notification-view/history`, body).pipe(share());
  }

}

// @ts-ignore
const MOCK_DATA_SUMMARY = {
  'summary': {
    'notificationsSent': 10,
    'notificationsMuted': 10,
    'mostNotifiedDay': {
      'date': '01-01-2023',
      'numberOfNotifications': 55
    },
    'mostNotifiedEndpoint': {
      'endpointId': 'EndpointId',
      'endpointName': 'Endpoint1',
      'numberOfNotifications': 533
    },
    'mostNotifiedCluster': {
      'clusterId': 'Vq9UvxkjRqGp5HX909sF5g',
      'clusterAlias': 'Obs-logs',
      'numberOfNotifications': 145
    },
    'activeEndpoints': 10,
    'totalEndpoints': 12,
    'activeFilters': 10,
    'totalFilters': 12
  },
  'topNotifications': [
    {
      'eventType': 'Status Red',
      'notificationsSent': 10,
      'notificationsFailed': 1,
      'numberOfClusters': 3
    },
    {
      'eventType': 'Status Yellow',
      'notificationsSent': 10,
      'notificationsFailed': 1,
      'numberOfClusters': 3
    }
  ],
  'notificationsOverTime': {
    'timeStamps': [1698840000000, 1698926400000],
    'clustersNotifications': [
      {
        'clusterId': 'dc8cjZ-EQZCvcJ2bLf5zLw',
        'data': {
          '1699401600000': 0,
          '1699833600000': 6,
          '1699660800000': 0,
          '1699747200000': 0,
          '1699488000000': 2,
          '1699920000000': 13,
          '1699574400000': 1
        }
      },
      {
        'clusterId': 'eAM3owtVTkGzFyURL2_cSg',
        'data': {
          '1699401600000': 0,
          '1699833600000': 6,
          '1699660800000': 11,
          '1699747200000': 8,
          '1699488000000': 8,
          '1699920000000': 9,
          '1699574400000': 21
        }
      }
    ]
  },
};
// @ts-ignore
const MOCK_DATA_HISTORY = {
  'historyItems': [
    {
      'notificationTime': 1698840000000,
      'eventType': 'Some indices have no replicas.',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Yellow',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'NO_ENDPOINT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Snapshot and restore operations cannot be performed on the cluster',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'MUTED',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Using wildcards could cause all indices to accidentally be deleted',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENDING',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Slow search performance was detected on the following node',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'CANCELED',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Yellow',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'NOT_AVAILABLE',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Red',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'NOT_SENT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Yellow',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'FAILED',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Red',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Yellow',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Red',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENT',
      'endpointName': 'Endpoint 1'
    },
    {
      'notificationTime': 1698840000000,
      'eventType': 'Status Yellow',
      'eventOpenTime': 1698840000000,
      'clusterName': 'opster-es',
      'clusterId': '123qwerty456',
      'notificationStatus': 'SENT',
      'endpointName': 'Endpoint 1'
    },
  ],
  'totalItems': 12
};
