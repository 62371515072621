<div class="sidebar-panel">
  <p class="panel-title">
    <mat-icon svgIcon="clock"></mat-icon>
    <span>Event duration</span>
  </p>
  <div class="panel-content" *ngIf="graph">
    <div class="time">
      <div>Opened at:</div>
      <div style="display: flex">
        <div class="date-item">{{openedAt | date: 'd MMM y'}}</div>
        <div  class="date-item">{{openedAt | date:  'hh:mm a'}}</div>
      </div>

    </div>
    <div class="time">
      <div>Closed at:</div>
      <div style="display: flex">
        <div class="date-item">{{(closedAt | date: 'd MMM y') || 'N/A'}}
        </div>
        <div class="date-item" >{{(closedAt | date:  'hh:mm a') || 'N/A'}}</div>
      </div>
    </div>

    <div class="duration">
     <span>Duration:</span><span>{{duration}}</span>
    </div>
  </div>


  <div class="panel-content" *ngIf="!graph">
    <div class="time no-graph">
      <div class="item-line">
        <div>Opened at:</div>
        <div style="display: flex; margin-left: 20px">
          <div class="date-item">{{openedAt | date: 'd MMM y'}}</div>
          <div  class="date-item">{{openedAt | date:  'hh:mm a'}}</div>
        </div>

      </div>

      <div class="border"></div>

      <div class="item-line">
        <div>Closed at:</div>
        <div style="display: flex; margin-left: 20px">
          <div class="date-item">{{(closedAt | date: 'd MMM y') || 'N/A'}}
          </div>
          <div class="date-item" >{{(closedAt | date:  'hh:mm a') || 'N/A'}}</div>
        </div>
      </div>
    </div>

    <div class="duration center">
      <span>Duration:</span><span>{{duration}}</span>
    </div>
  </div>

</div>
