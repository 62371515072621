<div class="multiple-accounts">
  <h3>Choose an account</h3>
  <p>You are logged in as <b style="font-weight: 600">{{email}}</b></p>
<!--  <p style="opacity: 0.5;">select account connected to your user</p>-->
  <div *ngFor="let account of accounts; let i = index; trackBy: PerformanceUtils.trackByIndex;">
    <div class="account" (click)="selectAccount(account)" [mat-dialog-close]="true">
      <mat-icon class="user-svg" [svgIcon]="'user_circle'"></mat-icon>
      {{account?.name || 'Account_' + account?.id}}
    </div>
  </div>
</div>

