import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Cluster } from '../../../../../store/global/cluster.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import get from 'lodash-es/get';
import { SettingsServiceService } from '../../../../../features/settings/service/settings-service.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first, Subject } from 'rxjs';
import { GlobalService } from '../../../../../store/global/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EventType, MuteSetting } from '../../../../../features/settings/settings.interface';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfidenceDialogComponent } from '../confidence-dialog/confidence-dialog.component';
import { DialogSize } from '../dialog.enum';
import { TranslateService } from '@ngx-translate/core';
import { SegmentTrackService } from '../../../../services/segment/segment.service';
import { ToastService } from '../../../../../store/toast/toast.service';
import includes from 'lodash-es/includes';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mute-event-dialog',
  templateUrl: './mute-event-dialog.component.html',
  styleUrls: ['./mute-event-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MuteEventDialogComponent implements OnInit {
  public dialogButton;
  public eventType: EventType;
  public page;
  public clusterName;
  public settings;
  public clusterId;
  public eventId;
  public muteSetting;
  public pattern = '*';
  public paramsTouched = false;
  public clustersMatching: Cluster[] = [];
  public allClusterList: Cluster[];
  public dialogButtonConfirm = {
    ok: {
      label: 'Close',
    },
    cancel: {
      label: ''
    }
  };
  public editMode = false;
  public isRecurring = false;
  public startTime;
  public isDefaultSetting = false;
  public listboxLoading = false;

  patternChanged$ = new Subject<string>();

  public dateItems = [
    {value: 60, viewValue: '1 Hour'},
    {value: 720, viewValue: '12 Hours'},
    {value: 1440, viewValue: '24 Hours'},
    {value: 2880, viewValue: '2 Days'},
    {value: 10080, viewValue: '7 Days'},
  ];
  public selectedDateMute = 1440;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private cdRef: ChangeDetectorRef,
              private globalService: GlobalService,
              private dialog: MatDialog,
              private segmentTrackService: SegmentTrackService,
              private dialogRef: MatDialogRef<MuteEventDialogComponent>,
              private toastService: ToastService,
              private translateService: TranslateService,
              private settingsServiceService: SettingsServiceService) {
  }

  ngOnInit(): void {
    this.dialogButton = get(this.data, 'dialogButtonConfig');
    this.muteSetting = get(this.data, 'muteSetting');
    this.eventId = get(this.muteSetting, 'id');
    this.eventType = get(this.data, 'type');
    this.page = get(this.data, 'page');

    this.segmentTrackService.setAnalyticsTrackToServer('opened mute config dialog', {
      page: this.page,
      email: this.segmentTrackService.getTrackEmail,
      eventType: get(this.eventType, 'type')
    });

    if (this.muteSetting) {
      this.isDefaultSetting = get(this.muteSetting, 'defaultSetting');
      this.pattern = get(this.muteSetting, 'clusterPattern') || '*';
      this.selectedDateMute = get(this.muteSetting, 'durationInMinutes');
      this.isRecurring = get(this.muteSetting, 'recurring');
      this.startTime = get(this.muteSetting, 'startTime');
      this.checkRegex(this.pattern);
      this.editMode = true;
      this.cdRef.markForCheck();
    }

    this.patternChanged$
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe(pattern => {
        this.paramsTouched = true;
        this.checkRegex(pattern);
      });

    this.getClusters();
  }

  onCreate(body: any) {
    this.settingsServiceService.createMuteSetting(body)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: () => {
          // this.openDialogConfirmation(this.dialogButtonConfirm, this.translateService.instant('settings.mute_settings.createSuccess'), 'Success');
          this.toastService.queueSnackBar(null, this.translateService.instant('settings.mute_settings.createSuccess'), 'success');

          this.segmentTrackService.setAnalyticsTrackToServer('created mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            eventType: get(body, 'eventType'),
            recurring: get(body, 'recurring'),
            duration: get(body, 'durationInMinutes'),
            pattern: get(body, 'clusterPattern').replace(/\s*\*\s*/g, ' * '),
            status: 'success'
          });
        }, error: (err: HttpErrorResponse) => {
          this.toastService.queueSnackBar(null, 'Failed to created mute config', 'error', 'export_error');

          this.segmentTrackService.setAnalyticsTrackToServer('created mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            eventType: get(body, 'eventType'),
            recurring: get(body, 'recurring'),
            duration: get(body, 'durationInMinutes'),
            pattern: get(body, 'clusterPattern').replace(/\s*\*\s*/g, ' * '),
            status: get(err, 'status')
          });
        }
      }).add(() => {
      this.dialogRef.close(true);
    });
  }

  onUpdate(body: MuteSetting, id: number) {
    this.settingsServiceService.editMuteSetting(body, id)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: () => {
          // this.openDialogConfirmation(this.dialogButtonConfirm, this.translateService.instant('settings.mute_settings.editSuccess'), 'Success');
          this.toastService.queueSnackBar(null, this.translateService.instant('settings.mute_settings.editSuccess'), 'success');

          this.segmentTrackService.setAnalyticsTrackToServer('updated mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            eventType: get(this.eventType, 'type'),
            recurring: get(body, 'recurring'),
            duration: get(body, 'durationInMinutes'),
            pattern: get(body, 'clusterPattern').replace(/\s*\*\s*/g, ' * '),
            default: this.isDefaultSetting,
            status: 'success'
          });
        }, error: (err: HttpErrorResponse) => {
          this.toastService.queueSnackBar(null, 'Failed to update mute config', 'error', 'export_error');

          this.segmentTrackService.setAnalyticsTrackToServer('updated mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            eventType: get(this.eventType, 'type'),
            recurring: get(body, 'recurring'),
            duration: get(body, 'durationInMinutes'),
            pattern: get(body, 'clusterPattern').replace(/\s*\*\s*/g, ' * '),
            default: this.isDefaultSetting,
            status: get(err, 'status')
          });
        }
      }).add(() => {
      this.dialogRef.close(true);
    });
  }

  onDelete(id) {
    this.settingsServiceService.deleteMuteSetting(id)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: () => {
          // this.openDialogConfirmation(this.dialogButtonConfirm, this.translateService.instant('settings.mute_settings.deleteSuccess'), 'Success');
          this.toastService.queueSnackBar(null, this.translateService.instant('settings.mute_settings.deleteSuccess'), 'success');

          this.segmentTrackService.setAnalyticsTrackToServer('deleted mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            default: this.isDefaultSetting,
            status: 'success'
          });
        },
        error: (err: HttpErrorResponse) => {
          this.toastService.queueSnackBar(null, 'Failed to delete mute config', 'error', 'export_error');

          this.segmentTrackService.setAnalyticsTrackToServer('deleted mute config', {
            page: this.page,
            email: this.segmentTrackService.getTrackEmail,
            default: this.isDefaultSetting,
            status: get(err, 'status')
          });
        }
      }).add(() => {
      this.dialogRef.close(true);
    });
  }

  getClusters() {
    this.globalService.getClusterList()
      .pipe(first(), untilDestroyed(this))
      .subscribe(list => {
        this.allClusterList = list;
        if (!this.editMode) {
          this.checkRegex(this.pattern);
        }
        this.cdRef.markForCheck();
      });
  }

  onPatternChange(pattern) {
    this.clustersMatching = [];
    this.listboxLoading = true;
    this.patternChanged$.next(pattern);
  }

  checkRegex(val) {
    this.settingsServiceService.filterByClusterPattern(val)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: res => {
          try {
            this.clustersMatching = this.filterClusters(this.allClusterList, res);
            this.cdRef.markForCheck();
          } catch (e) {
            console.log(e);
          }
        },
        error: () => {
          this.toastService.queueSnackBar(null, 'Failed to filter by cluster pattern', 'error', 'export_error');

        },
        complete: () => {
          this.listboxLoading = false;
        }
      });
    this.cdRef.detectChanges();
  }

  filterClusters(arr, clusterAliases) {
    return arr.filter(obj => {
      return get(obj, 'clusterAlias') && includes(clusterAliases, get(obj, 'clusterAlias'));
    });
  }

  onRecurringChange(val) {
    this.paramsTouched = true;
    this.isRecurring = get(val, 'checked');
    this.cdRef.markForCheck();
  }

  durationChange() {
    this.paramsTouched = true;
  }

  onCreateClick() {
    const body: MuteSetting = {
      eventType: this.eventType?.type,
      clusterPattern: this.pattern,
      durationInMinutes: this.selectedDateMute,
      recurring: this.isRecurring
    };

    this.onCreate(body);
  }

  onDeleteClick() {
    const msg = this.translateService.instant('settings.mute_settings.beforeDelete');
    const dialogButtonConfig = {
      ok: {
        label: 'Delete',
        action: () => {
          return true;
        }
      },
      cancel: {
        label: 'Cancel',
        action: () => {
        }
      },
    };

    const dialogRef = this.dialog.open(ConfidenceDialogComponent, {
      disableClose: true,
      width: DialogSize.small,
      data: {dialogButtonConfig: dialogButtonConfig, message: msg, title: 'Delete'},
      id: 'confidence-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.onDelete(this.eventId);
        }
      });
  }

  onUpdateClick() {
    const body = {
      clusterPattern: this.pattern,
      durationInMinutes: this.selectedDateMute,
      recurring: this.isRecurring
    };

    this.onUpdate(body, this.eventId);
  }

}
