<div class="panel-container" [style.background]="background">

  <app-toolbar class="panel-header" [withHeaderSub]="withHeaderSub">
    <ng-content select="[header]"></ng-content>
    <div *ngIf="loadingShow && loading" class="wrapper-spinner">
      <mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
    </div>
    <ng-content select="[header-sub]"></ng-content>
  </app-toolbar>

  <mat-card appearance="outlined" class="panel-content">
    <mat-card-content>
      <ng-content select="[body]"></ng-content>
    </mat-card-content>
  </mat-card>

</div>

