<h1 mat-dialog-title> {{'settings.mute_event.title' | translate}}
  <mat-icon mat-dialog-close>close</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="mute-event">
    <div>
      <p> {{'settings.mute_event.sub_title' | translate}} <br>
        <b>‘{{eventType?.name}}’</b> below:</p>
    </div>
    <div class="mute-section">
      <div class="default-msg" *ngIf="isDefaultSetting">{{'settings.mute_event.defaultSetting' | translate}}</div>
      <br>
      <div class="field-form space">
        <p-dropdown [styleClass]="'opster-dropdown xsm-width'" [options]="dateItems" [(ngModel)]="selectedDateMute"
                    optionValue="value" optionLabel="viewValue"
                    (ngModelChange)="durationChange()">
        </p-dropdown>

        <div class="field-checkbox">
          <p-checkbox class="recurring" [(ngModel)]="isRecurring"
                      (onChange)="onRecurringChange($event)"
                      binary="true" id="recurring"></p-checkbox>
          <label for="recurring">Recurring
            <mat-icon svgIcon="info" class="info-icon"
                      [pTooltip]="'Recurring mute notifications will extend suspension after the next successful notification.'"></mat-icon>
          </label>
        </div>
      </div>

      <br>
      <p-divider></p-divider>
      <p>Select clusters to mute the event on:</p>

      <div class="cluster-pattern">

        <div class="cluster-pattern-input">
          <label for="pattern">Pattern:</label>
          <input pInputText id="pattern" [(ngModel)]="pattern" (ngModelChange)="onPatternChange($event)">
          <small>(default: *)</small>

          <span class="cluster-pattern-example">{{'settings.mute_event.patternExample' | translate}}</span>
        </div>

        <div>
          <div class="text title">Matching Clusters:</div>
          <ng-container *ngIf="!listboxLoading; else loadingSpinner">
            <p-listbox [styleClass]="'customize-listbox'" [options]="clustersMatching" optionDisabled="inactive"
                       optionLabel="clusterName"
                       [emptyMessage]="'* The Clusters list cannot be empty'"
                       [style]="{ 'height': '15rem'}">
              <ng-template let-cluster pTemplate="item">
              <span style="display: inline-block;">
              {{cluster?.clusterAlias}}<br>
                <span style="font-size: 12px;opacity: 0.6;white-space: nowrap">({{cluster?.cluster}})</span>
              </span>
              </ng-template>
            </p-listbox>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="spinner-wrapper">
              <loader-box [strokeWidth]="2" [diameter]="50"></loader-box>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

  </div>
</div>
<div mat-dialog-actions [class.space-between]="editMode">

  <div *ngIf="!editMode">
    <button mat-stroked-button class="button-opster yellow dialog-action medium-btn"
            (click)="onCreateClick()"
            [disabled]="!clustersMatching?.length">
      <mat-icon svgIcon="save_new_icon"></mat-icon>
      {{'settings.save'| translate}}
    </button>
  </div>

  <div *ngIf="editMode">
    <button mat-stroked-button class="button-opster no-color dialog-action secondary gray"
            (click)="onDeleteClick()">
      <mat-icon svgIcon="trash" style="transform: translateY(-1px)"></mat-icon>
      {{'settings.delete'| translate}}
    </button>
  </div>

  <div *ngIf="editMode">
    <button mat-stroked-button class="button-opster yellow dialog-action medium-btn"
            (click)="onUpdateClick()"
            [disabled]="!clustersMatching?.length || !paramsTouched ||
            (pattern === muteSetting?.clusterPattern && isRecurring === muteSetting?.recurring && selectedDateMute === muteSetting?.durationInMinutes)">
      <mat-icon svgIcon="save_new_icon"></mat-icon>
      {{'settings.update'| translate}}
    </button>
  </div>

</div>
