import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';

// Module
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/layout.module';
import { ThemeModule } from './store/theme/theme.module';
import { ToastOpsterModule } from './shared/components/toast/toast-opster.module';

// material
import { MatIconModule } from '@angular/material/icon';

// component
import { AppComponent } from './app.component';
import { PageErrorComponent } from './core/page-error/page-error.component';

// services
import { IconElementService } from './shared/services/icon-element/icon-element.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppHttpInterceptor } from './shared/interceptors/app-http-interceptor';
import { SegmentTrackService } from './shared/services/segment/segment.service';

// all
import { SegmentModule } from 'ngx-segment-analytics';
import { environment } from '../environments/environment';
import { SegmentEnvKeys } from './segment-env';
import { GlobalErrorHandler } from './shared/interceptors/global-error-handler';
import { WebWorker } from './core/workers/web-worker';

// Google
import { GoogleInitOptions, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { UpdateService } from './store/update/update.service';
import {  MarkdownModule } from 'ngx-markdown';
import { LoaderModule } from './shared/components/loader/loader.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';


declare global {
  interface Window {
    Sentry: any;
    Buffer: any;
  }

}

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false, // default is true
  scopes: 'https://www.googleapis.com/auth/calendar.readonly'
};

export function appInit(appConfigService: IconElementService) {
  return () => {
    appConfigService.init();
    // scriptsService.loadScript();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    PageErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppLayoutModule,
    ThemeModule,
    MarkdownModule.forRoot({
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SegmentModule.forRoot({
      apiKey: SegmentEnvKeys.env_prod,
      loadOnInitialization: false
    }),
    MatIconModule,
    HammerModule,
    ToastOpsterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LoaderModule,
    LottieModule.forRoot({player: playerFactory})
  ],
  providers: [DatePipe,
    WebWorker,
    {
      provide: APP_INITIALIZER,
      useFactory: initFunction,
      deps: [SegmentTrackService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [IconElementService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    [{provide: ErrorHandler, useClass: GlobalErrorHandler}],
    UpdateService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '831962807300-7bdqa5mifcbf3pgoerfoj6bobvs292pq.apps.googleusercontent.com',
              googleLoginOptions
            )
          },
        ],
        onError: (err) => {
          window.alert(err);
        }
      } as SocialAuthServiceConfig,
    },
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initFunction(segmentTrackService: SegmentTrackService) {
  return () => segmentTrackService.init();
}

export function playerFactory() {
  return player;
}
