<div class="app" [ngClass]="{'darkTheme': (isDarkTheme$ | async)}" appTheme>

  <div class="app-top-menu">
    <app-top-menu [currentUrl]="currentUrl" [isClearTopMenu]="true"></app-top-menu>
  </div>

  <div class="app-content">
    <router-outlet></router-outlet>
  </div>

</div>
