<div class="panel-box" [ngClass]="className" [style.min-width]="minWidth" >
  <div class="header">
    <ng-content select="[title]"></ng-content>
  </div>
  <mat-card appearance="outlined" class="content" [class.promo]="promo">
    <mat-card-content>
      <ng-content select="[body]"></ng-content>
    </mat-card-content>
  </mat-card>
</div>
