import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService } from '../../../store/theme/theme.service';
import { PerformanceUtils } from '../../services/utils/functional-utils';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() withItem = false;
  @Input() small = false;
  @Input() table = false;
  @Input() cardSkeleton = false;
  @Input() cardEventsSkeleton = false;
  @Input() widgetSkeleton = false;
  @Input() hideBorder = false;
  public products = [1, 2, 3];

  public isDarkTheme$: Observable<boolean>;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.isDarkTheme$ = this.themeService.getActiveTheme();

  }

}
