import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objNgFor'
})
export class ObjNgForPipe implements PipeTransform {

  transform(value: any): any {
    return Object.keys(value);
  }

}

@NgModule({
  declarations: [ObjNgForPipe],
  imports: [

  ],
  exports: [ObjNgForPipe]
})
export class ObjNgForPipeModule {}
