<h1
  mat-dialog-title> {{title}}
  <mat-icon mat-dialog-close>close</mat-icon>
</h1>
<div mat-dialog-content class="failed-upload">
  <div style="text-align: center;">
    <mat-icon [svgIcon]="'error_big'" class="error-big-img"></mat-icon>
  </div>

  <div *ngIf="type === EnumToolsType.SLOWLOG">
    <p><b>Your slow logs couldn’t be analyzed{{errors ? ' because: ' : ''}}</b><br> {{errors ? errors : ''}}</p>
    <p>Our support team will review the file you uploaded and get back to you to help make the necessary changes.
      <br>
      In the meantime, you’re welcome to read the <a class="link"
        target="_blank"
        href="https://opster.com/elasticsearch-glossary/elasticsearch-slow-logs-documentation/">instructions</a> and try
      again after correcting your file.</p>

  </div>

  <div *ngIf="type === EnumToolsType.TEMPLATE_OPTIMIZER">
    {{errors}}
  </div>

</div>
<div mat-dialog-actions>

  <button [mat-dialog-close]="true"
          class="button-opster yellow no-space"
          (click)="onOk()">Run Again
  </button>
</div>
