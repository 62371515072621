import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { PreviousRunsSlowLogs, SlowLogReport, TopSuspect } from '../slow-logs.enum';
import isEqual from 'lodash-es/isEqual';
import map from 'lodash-es/map';

@Injectable({
  providedIn: 'root'
})
export class SlowLogsService {
  private history$: BehaviorSubject<PreviousRunsSlowLogs[]>;

  constructor(private http: HttpClient) {
    this.history$ = new BehaviorSubject<PreviousRunsSlowLogs[]>(null);
  }

  public setHistory() {
    const body = {limit: 1000, toolType: 'SLOWLOG'};
    this.http.post<PreviousRunsSlowLogs[]>(`api/analyzer/tools/history`, body).pipe(share()).subscribe(history => {
      if (history && this.history$.getValue() && isEqual(map(history, 'id'), map(this.history$.getValue(), 'id'))) {
        return;
      }
      this.history$.next(history || []);
    });
  }

  public getHistory() {
    return this.history$.asObservable();
  }

  public resetHistoryOnLogout() {
    this.history$.next(null);
  }

  public getToolsResult(queryId): Observable<any> {
    const body = {
      id: queryId,
    };
    return this.http.post(`api/analyzer/tools/result`, body).pipe(share()) as Observable<any>;
  }

  public slowLogAnalyze(path, distribution): Observable<SlowLogReport> {
    const body = {
      's3BucketPath': path,
      distribution
    };

    return this.http.post(`api/analyzer/slowlogs/analyze`, body)
      .pipe(share()) as Observable<SlowLogReport>;
  }

  public slowLogValidate(path, distribution): Observable<any> {
    const body = {
      's3BucketPath': path,
      distribution
    };

    return this.http.post(`api/analyzer/slowlogs/validate`, body, {responseType: 'text'})
      .pipe(share()) as Observable<any>;
  }

  public getQueries(ids, toolResultId) {
    const body = {
      ids: [ids],
      toolResultId
    };
    return this.http.post(`api/analyzer/slowlogs/queries`, body).pipe(share()) as Observable<any>;
  }

  public getSuspect(queryId, orderBy?, desc = true, from?, size?, patterns?): Observable<TopSuspect[]> {
    const body = {
      toolResultId: queryId,
      from,
      size,
      orderBy: orderBy,
      includePatterns: patterns,
      desc

    };
    return this.http.post(`api/analyzer/slowlogs/v2/suspects`, body)
      .pipe(share()) as Observable<TopSuspect[]>;
  }

  public getPatterns(toolResultId) {
    return this.http.get('api/analyzer/slowlogs/patterns/' + toolResultId)
      .pipe(share()) as Observable<string[]>;
  }


}
