import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MultiSelect} from 'primeng/multiselect';

import filter from 'lodash-es/filter';
import isEqual from 'lodash-es/isEqual';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import lowerCase from 'lodash-es/lowerCase';
import map from 'lodash-es/map';
import size from 'lodash-es/size';
import take from 'lodash-es/take';
import { FilterService } from 'primeng/api';
import { everyWordMatches } from './filter-service';

type FilterOptions<T extends string> = {
  [key in T]: (searchValue?: string, filterValue?: string) => boolean;
};
type CustomFilters = 'everyWordMatches';

const customFilters: FilterOptions<CustomFilters> = {
  everyWordMatches: everyWordMatches,
};

@Component({
  selector: 'input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputMultiSelectComponent implements OnInit, OnChanges {
  @Input() addedStyleClass;
  @Input() customAllName;
  @Input() disabled: boolean;
  @Input() disabledItemList?: Map<string, boolean>;
  @Input() filterMatchMode: MultiSelect['filterMatchMode'] | CustomFilters =
  'contains';
  @Input() isFillMultiselect = true;
  @Input() isGpt = false;
  @Input() isGroup: boolean;
  @Input() isGroupIcon: boolean;
  @Input() isSelectingGroup: boolean;
  @Input() listGrouping;
  @Input() nameItem: string;
  @Input() quick: boolean;
  @Input() selectedAllLabel = false;
  @Input() selectedGroups;
  @Input() selectedItems = [];
  @Input() selectionLimit = null;
  @Input() sizeOfItems = 0;
  @Input() tooltipPosition = 'top';

  @ViewChild('multiSelectRef') multiSelect: MultiSelect;

  public displayedSelectedItems = [];
  public displayedSelectedItemsCount = 0;

  @Output() selectionChange = new EventEmitter();
  @Output() groupSelectionChange = new EventEmitter();
  @Output() itemSelectionChange = new EventEmitter();
  @Output() loadItems = new EventEmitter();

  public tooltipText;
  public isClearAll;
  public styleClass;
  public isSmall = false;
  private readonly MAX_ITEMS_TOOLTIP = 10;

  public overlayVisible = false;

  constructor(private filterService: FilterService) {
    Object.entries(customFilters).forEach(([filterName, filterFn]) => {
      this.filterService.register(filterName, filterFn);
    });
  }

  ngOnInit(): void {
    this.styleClass = this.addedStyleClass ? 'p-multiSelect-group ' + this.addedStyleClass : this.isGpt ? 'p-multiSelect-group gpt' : 'p-multiSelect-group';
    this.isSmall = this.addedStyleClass.includes('small');
  }

  // @HostListener('document:click', ['$event.target'])
  // pageClicked() {
  //   if (!get(this.ref, 'nativeElement').contains(get(event, 'target') as HTMLElement) && this.overlayVisible) {
  //     this.multiSelect.hide();
  //   }
  // }

  changeNgModel() {
    this.displayedSelectedItemsCount = size(this.filterDisabled());
  }

  onPanelHide() {
    this.overlayVisible = false;
    this.selectionChange.emit(this.displayedSelectedItems);
  }

  onPanelShow() {
    this.overlayVisible = true;
    this.loadItems.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'selectedItems.currentValue') && size(this.selectedItems)) {
      this.displayedSelectedItems = [];

      this.displayedSelectedItems = this.selectedItems;
      this.displayedSelectedItemsCount = size(this.filterDisabled());

      if (isEqual(this.nameItem, 'Events')) {
        const groupedEvents = groupBy(this.displayedSelectedItems, 'severity');
        this.tooltipText = '<ul class="selectedItems-ul">' + map(Object.keys(groupedEvents), (key) => {
          const valueSize = size(groupedEvents[key]);
          return `<li>${key.charAt(0) + lowerCase(key.slice(1))}: ${valueSize} ${this.nameItem}</li>`;
        }).join('') + '</ul>';
      } else {
        this.buildDefaultTooltip();
      }
    } else {
      this.displayedSelectedItems = this.selectedItems;
      this.displayedSelectedItemsCount = size(this.filterDisabled());
    }
  }

  filterDisabled() {
    if (!this.disabledItemList) {
      return this.displayedSelectedItems;
    }
    return filter(this.displayedSelectedItems, x => !this.disabledItemList.get(get(x, 'id')));
  }

  buildDefaultTooltip() {
    const selectedLength = size(this.displayedSelectedItems);
    if (selectedLength > this.MAX_ITEMS_TOOLTIP) {
      const restItems = selectedLength - this.MAX_ITEMS_TOOLTIP;
      const selected = take([...this.displayedSelectedItems], this.MAX_ITEMS_TOOLTIP - 1);
      this.tooltipText = '<ul class="selectedItems-ul">' + map(selected, (item) => {
        return `<li>${item?.name}.</li>`;
      }).join('') + `<li>and ${restItems} more...</li>` + '</ul>';
    } else {
      this.tooltipText = '<ul class="selectedItems-ul">' + map(this.displayedSelectedItems, (item) => {
        return `<li>${item?.name}.</li>`;
      }).join('') + '</ul>';
    }
  }

  changeCheckBoxGroup(event, group) {
    this.groupSelectionChange.emit({event, group, selectedItems: this.displayedSelectedItems});
  }

  changeCheckBoxItem(event) {
    this.isClearAll = !size(get(event, 'value'));
    this.itemSelectionChange.emit(event);
  }

  clearAll() {
    this.displayedSelectedItems = [];
    this.changeCheckBoxItem({value: []});

    if (this.isGroup) {
      // @ts-ignore
      forEach(this.selectedGroups, (value, key, mapG) => mapG.set(key, false));
    }
  }
}
