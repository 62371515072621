import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import get from 'lodash-es/get';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventOverviewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() analyticsGraph;
  @Input() renderGraph;
  @Input() changeWidth;

  public loading = true;
  public height = '400px';

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'analyticsGraph.currentValue')) {
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loading = false;
      this.cdRef.detectChanges();
    }, 500);
  }


}
