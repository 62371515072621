import { Injectable, OnDestroy } from '@angular/core';
import { EnumToolsType } from '../../models/enum';
import { SlowLogStatus, SlowLogStatusData } from '../../../features/slow-logs/slow-logs.enum';
import { Subject } from 'rxjs';
import { AwsUploadService } from '../aws-upload/aws-upload.service';
import { SlowLogsService } from '../../../features/slow-logs/services/slow-logs.service';
// import {Md5} from 'ts-md5';
import { WebWorker } from '../../../core/workers/web-worker';
import { first } from 'rxjs/operators';
import { GlobalService } from '../../../store/global/global.service';
import { Router } from '@angular/router';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';


@Injectable({
  providedIn: 'root'
})
export class UploadFileManagerService implements OnDestroy {
  public analyzeSlowLogStatus: Subject<SlowLogStatusData> = new Subject<SlowLogStatusData>();
  public toolsType = EnumToolsType;
  public hash;

  private readonly GZ_TYPE = 'application/x-gzip';

  constructor(private awsUploadService: AwsUploadService,
              private slowLogService: SlowLogsService,
              private globalService: GlobalService,
              private webWorkerService: WebWorker,
              private router: Router,
  ) {
  }

  uploadFileSlowLog(slowLogFile, s3BucketPathNew, selectionDistribution) {
    const fileSize = get(slowLogFile, 'size');
    this.analyzeSlowLogStatus.next({status: SlowLogStatus.START, fileName: get(slowLogFile, 'name'), size: fileSize});

    const type = get(slowLogFile, 'type');
    if (type !== this.GZ_TYPE && typeof Worker !== 'undefined') {
      try {
        const worker = this.webWorkerService.initializing();
        const reader = new FileReader();
        reader.readAsArrayBuffer(slowLogFile);
        reader.onloadend = (evt) => {
          if (get(evt, 'target.readyState') === FileReader.DONE) {
            const arrayBuffer = get(evt, 'target.result');
            const slowLogFileName = get(slowLogFile, 'name');
            worker.postMessage({slowLogFileName, arrayBuffer});
          }
        };
        worker.onmessage = ({data}) => {
          const s3New = s3BucketPathNew.concat('.gz');
          this.uploadFileNext(data, s3New, selectionDistribution, fileSize, worker);
        };
      } catch (e) {
        this.uploadFileNext(slowLogFile, s3BucketPathNew, selectionDistribution, fileSize);
      }
    } else {
      this.uploadFileNext(slowLogFile, s3BucketPathNew, selectionDistribution, fileSize);
    }
  }

  private uploadFileNext(slowLogFile, s3BucketPathNew, selectionDistribution, fileSize, worker?) {
    if (worker) {
      this.webWorkerService.terminate();
    }

    this.awsUploadService.uploadFileSlowLog(slowLogFile, s3BucketPathNew);

    let progressPercentage = 1;

    while (progressPercentage < 100) {

        this.analyzeSlowLogStatus.next({
          status: SlowLogStatus.START,
          fileName: get(slowLogFile, 'name'),
          size: fileSize,
          progress: progressPercentage
        });

        progressPercentage = progressPercentage + Math.round(fileSize % 100);
    }

    if (progressPercentage >= 100) {
      this.analyzeSlowLogStatus.next({
        status: SlowLogStatus.ANALYZE,
        fileName: get(slowLogFile, 'name'),
        size: fileSize,
        progress: progressPercentage
      });
    }

    this.awsUploadService.getUploadFileSlowLog()
      .pipe(first())
      .subscribe(res => {
        if (res) {
          this.validateSlowLog(s3BucketPathNew, fileSize, selectionDistribution);
        }
      });
  }

  private validateSlowLog(s3BucketPathNew, fileSize, selectionDistribution) {
    this.slowLogService.slowLogValidate(s3BucketPathNew, selectionDistribution)
      .pipe(first())
      .subscribe({
        next: (result) => {

          if (!isEmpty(result)) {
            this.ifError(result);
            return;
          }

          this.analyzeSlowLog(s3BucketPathNew, fileSize, selectionDistribution);
        }, error: err => {
          this.ifError(get(err, 'error', null));

        }
      });
  }

  private analyzeSlowLog(s3BucketPathNew, fileSize, selectionDistribution) {
    this.slowLogService.slowLogAnalyze(s3BucketPathNew, selectionDistribution)
      .pipe(first())
      .subscribe({
        next: (result) => {
          if (get(result, 'message')) {
            this.analyzeSlowLogStatus
              .next({status: SlowLogStatus.FAILED, result: get(result, 'message'), size: fileSize});
            return;
          }
          this.analyzeSlowLogStatus.next({status: SlowLogStatus.COMPLETE, result, size: fileSize});
          this.globalService.setSelectedRunSlowLogs(get(result, 'id'));
          this.slowLogService.setHistory();
          this.router.navigate(['slowLogs/result'], {
            queryParams: {
              runSL: get(result, 'id')
            },
            queryParamsHandling: 'merge'
          }).then(() => {
          });
        }, error: err => {
          this.ifError(get(err, 'message', null));
        }
      });
  }

  ifError(err) {
    this.analyzeSlowLogStatus.next({status: SlowLogStatus.FAILED, result: err});
  }

  getAfterAnalyzeSlowLog() {
    return this.analyzeSlowLogStatus.asObservable();
  }

  ngOnDestroy() {
    this.analyzeSlowLogStatus.unsubscribe();
  }
}
