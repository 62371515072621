import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-affected-section',
  templateUrl: './affected-section.component.html',
  styleUrls: ['./affected-section.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AffectedSectionComponent implements OnInit {
  @Input() affectedSection;

  constructor() {
  }

  ngOnInit(): void {
  }



}
