import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { NotificationConfig } from './toast.interface';
import size from 'lodash-es/size';
import get from 'lodash-es/get';

import { MessageService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// import {NotificationsComponent} from '../../shared/components/notifications/notifications.component';

export interface SnackBarQueueItem {
  summary: string;
  message: string;
  beingDispatched: boolean;
  severity: string;
  configParams?: NotificationConfig;
  duration?: number;
  sticky?: boolean;
  key?: string;
}

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {

  private readonly snackBarQueue = new BehaviorSubject<SnackBarQueueItem[]>([]);
  private readonly snackBarQueue$ = this.snackBarQueue.asObservable();


  constructor(
    private messageService: MessageService
  ) {
    /* Dispatches all queued snack bars one by one */
    this.snackBarQueue$
      .pipe(
        filter((queue: any) => size(queue) > 0 && !get(queue[0], 'beingDispatched')),
        tap(() => {
          const updatedQueue: any = this.snackBarQueue.value;
          this.snackBarQueue.next(updatedQueue);
        }),
        map(queue => queue[0]),
        untilDestroyed(this))
      .subscribe(snackBarItem =>
        this.showSnackbar(snackBarItem.summary, snackBarItem.message, snackBarItem.severity, snackBarItem.duration));
  }

  public ngOnDestroy() {
    this.snackBarQueue.next([]);
    this.snackBarQueue.complete();
  }

  // @ts-ignore
  // tslint:disable-next-line:max-line-length
  public queueSnackBar(summary: string, message: string, severity, key?: string, duration?: number, sticky?: boolean, important?) {
    this.showSnackbar(summary, message, severity, duration, sticky, key);
    // if (important) {
    //   this.snackBarQueue.next([]);
    //   this.snackBarQueue.next(
    //     this.snackBarQueue.value.concat([{message, beingDispatched: false, isError, duration, sticky}]),
    //   );
    // }
    // if (size(this.snackBarQueue.value) < 1) {
    //   this.snackBarQueue.next(
    //     this.snackBarQueue.value.concat([{message, beingDispatched: false, isError, duration, sticky}]),
    //   );
    // }

  }

  public clearQueueSnackBar() {
    this.snackBarQueue.next([]);
  }

  // @ts-ignore
  // tslint:disable-next-line:max-line-length
  private showSnackbar(summary: string, message: string, severity: string, duration?: number, sticky?: boolean, key?: string) {

    this.messageService.clear();

    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
      life: duration,
      sticky: sticky
    });

    // this.removeDismissedSnackBar(
    //   this.matSnackBar.openFromComponent(NotificationsComponent, {
    //     data: {
    //       message,
    //       isError
    //     },
    //     duration: duration || 3000,
    //     horizontalPosition: 'right',
    //     verticalPosition: 'top',
    //     panelClass: isError ? 'snack-bar-error' : 'snack-bar-success'
    //   }).afterDismissed(),
    // );
    // this.triggerAction(configParams);
  }

  /* Remove dismissed snack bar from queue and triggers another one to appear */
  // private removeDismissedSnackBar(dismissed: Observable<MatSnackBarDismiss>) {
  //   dismissed
  //     .pipe(
  //       delay(1000),
  //       take(1))
  //     .subscribe(() => {
  //       const updatedQueue = this.snackBarQueue.value;
  //       if (get(updatedQueue[0], 'beingDispatched')) {
  //         updatedQueue.shift();
  //       }
  //       this.snackBarQueue.next(updatedQueue);
  //     });
  // }


}
