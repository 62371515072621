<div class="full-answer">
  <mat-icon class="opster-chat small" [svgIcon]="'ops_gpt'"></mat-icon>

  <div style="overflow: auto">
    <opster-markdowm [data]="answer"></opster-markdowm>
    <span *ngIf="withSpinner" style=" display: flex;justify-content: end;">
   </span>
  </div>

</div>


