import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { EmptyStateTrialComponent } from './empty-state-trial.component';
import { MatIconModule } from '@angular/material/icon';
import { ReviewsWizardModule } from '../reviews-wizard/reviews-wizard.module';
import { DividerModule } from 'primeng/divider';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import {LoaderBoxModule} from '../loader-box/loader-box.component';

@NgModule({
  declarations: [
    EmptyStateTrialComponent,
  ],
    imports: [
        CommonModule,
        MatIconModule,
        ReviewsWizardModule,
        DividerModule,
        RouterModule,
        MatButtonModule,
        LoaderBoxModule,
    ],
  providers: [],
  exports: [
    EmptyStateTrialComponent
  ]
})
export class EmptyStateTrialModule {
}
