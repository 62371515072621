import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {NOTIFICATION_STATUS} from '../../health.interface';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import map from 'lodash-es/map';
import {Router} from '@angular/router';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Input() filterEndpoints;
  @Input() status;

  @Output() emitSidebarHide: EventEmitter<void> = new EventEmitter<void>();

  filterEndpointsDisplay;
  public NotificationStatus = NOTIFICATION_STATUS;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  notificationsNav() {
    this.emitSidebarHide.next();
    this.router.navigate(['/settings/notifications']).then(() => {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'filterEndpoints')) {

      this.filterEndpointsDisplay = map(groupBy(this.filterEndpoints, 'settings.type'), (val, key) => {
        return {
          key: key,
          names: map(val, 'name').join('\n')
        };
      });

    }

  }
}
