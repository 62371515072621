import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, of, Subject, timer} from 'rxjs';
import {catchError, filter, first, map, share, shareReplay, switchMap, takeUntil, takeWhile} from 'rxjs/operators';
import {UserSub} from './auth.interface';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {GlobalService} from '../../store/global/global.service';
import {
  MultipleAccountsComponent
} from '../../shared/components/popups/dialog/multiple-accounts/multiple-accounts.component';
import {DialogSize} from '../../shared/components/popups/dialog/dialog.enum';
import {MatDialog} from '@angular/material/dialog';
import {SegmentTrackService} from '../../shared/services/segment/segment.service';
import {Router} from '@angular/router';
import {UpdateService} from '../../store/update/update.service';

import get from 'lodash-es/get';
import {DatePipe} from '@angular/common';
import isEqual from 'lodash-es/isEqual';
import set from 'lodash-es/set';
import includes from 'lodash-es/includes';
import has from 'lodash-es/has';

const GOOGLE_ERROR_IGNORE = ['popup_closed_by_user'];
const CHECK_INTERVAL = 1000 * 60 * 60 * 6;

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  private accountFromServer$: BehaviorSubject<any>;

  public account: Observable<any>;
  public isTrialEnding: BehaviorSubject<boolean>;

  // google
  private googleUser$: BehaviorSubject<any>;
  private opsterUser$: Subject<any>;
  private isInviteUser$: BehaviorSubject<boolean>;

  private destroy$: Subject<boolean>;

  constructor(private http: HttpClient,
              private socialAuthService: SocialAuthService,
              private dialog: MatDialog,
              private globalService: GlobalService,
              private segmentTrackService: SegmentTrackService,
              private router: Router,
              private updateService: UpdateService,
              private datePipe: DatePipe) {

    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')) || {});

    this.destroy$ = new Subject<boolean>();
    this.isTrialEnding = new BehaviorSubject<boolean>(false);
    this.googleUser$ = new BehaviorSubject<any>(null);
    this.accountFromServer$ = new BehaviorSubject<any>(null);
    this.isInviteUser$ = new BehaviorSubject<boolean>(false);
    this.opsterUser$ = new Subject<any>();

    this.onInit();
    this.buildLoginWithGoogle();
  }

  // testGoogleLogin() {
  //   setTimeout(() => {
  //     const user = {
  //       email: 'revital@opster.com',
  //       firstName: 'Revital ',
  //       id: '117309593175338908958',
  //       idToken: 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImM5YWZkYTM2ODJlYmYwOWViMzA1NWMxYzRiZDM5Yjc1MWZiZjgxOTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2ODI4NjY1MjgsImF1ZCI6IjgzMTk2MjgwNzMwMC03YmRxYTVtaWZjYmYzcGdvZXJmb2o2Ym9idnMyOTJwcS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExNzMwOTU5MzE3NTMzODkwODk1OCIsImhkIjoib3BzdGVyLmNvbSIsImVtYWlsIjoicmV2aXRhbEBvcHN0ZXIuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjgzMTk2MjgwNzMwMC03YmRxYTVtaWZjYmYzcGdvZXJmb2o2Ym9idnMyOTJwcS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsIm5hbWUiOiJSZXZpdGFsIEZyaWVkbWFuIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FHTm15eFpoMTJWdFM4dlJnY2p1elVvTEw4UmF1cDFjZEZiZDRCNlI4YmVhPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlJldml0YWwgIiwiZmFtaWx5X25hbWUiOiJGcmllZG1hbiIsImlhdCI6MTY4Mjg2NjgyOCwiZXhwIjoxNjgyODcwNDI4LCJqdGkiOiJmMjEyODFmYjM1MGMzMTc1ZThkNjNhZmJmMmQxMmIwZGU0YmQzNGRkIn0.cDwzAa26ZRdke_adenvca10x1uEFaJUlKG7JBwYvduqphYEwVoULK8IaL-FWORJ7r1gJnVobgbN5eMpS2ZLXRD5jTzYSqRZz51vP5rM6zorZgRzgvsUGEeSCkFedIxXwGt23CKUs-lIjaJD8PQyGwmPwJ6qCRA7uerDBVc4MPAU3vyDpqLxNykOmQMhoRsEF6CgH_DdcvmpOF9Tk3o9Apy3zDeap_-fbWvlwSOr2bX_JRl-E14Ls_x9x5SeQHPsu-DRAopT1XcQWlXHyn4YVx1Nz5de2g635W4Xv35Xc4SysRQn_F31TM3spYkfcgGmJCKviKnRnSYfm3f-ZnrA9MA',
  //       lastName: 'Friedman',
  //       name: 'Revital Friedman',
  //       photoUrl: 'https://lh3.googleusercontent.com/a/AGNmyxZh12VtS8vRgcjuzUoLL8Raup1cdFbd4B6R8bea=s96-c',
  //       provider: 'GOOGLE'
  //     };
  //
  //     this.googleAuthState(user);
  //   }, 4000);
  // }


  onInit() {

    this.getCurrentUser()
      .pipe(filter(res => res))
      .subscribe(user => {
        if (get(user, 'email')) {
          this.segmentTrackService.setTrackEmail = get(user, 'email');
          this.segmentTrackService.setTrackUserId = get(user, 'userId');

          this.segmentTrackService.analyticsIdentify(user);

          const companyName = get(user, 'company');
          this.segmentTrackService.analyticsGroup(companyName, {
            name: companyName
          });
        } else if (isEqual(user, null)) {
          this.segmentTrackService.analyticsReset();
        } else if (isEqual(user, {})) {
          this.segmentTrackService.analyticsIdentify();
        }
      });

  }

  private checkTrialEnded(account) {
    return isEqual(get(account, 'account.plan'), 'free')
      && get(account, 'account.trialEndDate')
      && (get(account, 'account.trialEndDate') < Date.now());
  }

  startInterval() {
    timer(0, CHECK_INTERVAL).pipe(
      switchMap(() => {
        return this.getAccount()
          .pipe(catchError(() => {
            return of(undefined);
          }));
      }),
      takeWhile(account => !this.checkTrialEnded(account), true))
      .subscribe(account => {
        if (this.checkTrialEnded(account)) {
          // this.segmentTrackService.setAnalyticsTrackToServer('Trial ended user redirected to end trial screen',
          this.segmentTrackService.setAnalyticsTrackToServer('Trial ended - user redirected to end trial screen',
            {email: this.segmentTrackService.getTrackEmail});
          this.router.navigate(['/dashboard']).then(() => {
          });
          this.isTrialEnding.next(true);
        }
      });
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  public setCurrentUser(account, user) {
    if (!account && !user) {
      return;
    }
    const userDetails = {
      id: get(account, 'id') || get(account, 'accountId'),
      email: get(account, 'email'),
      fullName: get(user, 'fullName'),
      company: get(account, 'name'),
      plan: get(account, 'plan'),
      previousPlan: get(account, 'previousPlan'),
      trialStartDate: get(account, 'trialStartDate'),
      isOktaUser: get(user, 'isOktaUser'),
      multiFactorAuthEnabled: get(account, 'multiFactorAuthEnabled'),
      userId: get(user, 'userId'),
      trialStartDateString: this.datePipe.transform(get(account, 'trialStartDate'), 'dd/MM/yyyy hh:mm:ss a'),
    };

    localStorage.setItem('user', JSON.stringify(userDetails));
    this.currentUserSubject.next(userDetails);
  }

  signIn(email: string, password: string) {
    const data = {
      email: email,
      password
    };

    return this.http.post<any>('api/login', data)
      .pipe(map((res) => {
        if (!get(res, 'multiFactorAuthEnabled') && !has(res, 'accounts')) {
          this.getAccountAndUser(email);
        }
        return res;
      }));
  }

  getAccountAndUser(email) {
    combineLatest([this.getAccount(), this.getUser()]).pipe(takeUntil(this.destroy$))
      .subscribe(([resAccount, resUser]) => {
        const account: any = get(resAccount, 'account');
        set(account, 'email', email);
        this.setCurrentUser(account, resUser);
      });
  }

  signUp(userSignUp: UserSub) {
    return this.http.post<any>('api/signup', userSignUp, {params: {skipOpster: 'true'}});
  }

  getAllAccountUser() {
    combineLatest([this.getAccount(), this.getUser()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([resAccount, resUser]) => {
        if (get(resAccount, 'account.trialEndDate') < Date.now()) {
          this.startInterval();
        }
        const account: any = get(resAccount, 'account');
        account.email = get(resUser, 'email');
        account.fullName = get(resUser, 'fullName');
        const isEnd = this.checkTrialEnded(resAccount);
        this.isTrialEnding.next(isEnd);
        this.setCurrentUser(account, resUser);
      });
  }

  // loginToAccount
  public loginToAccount(account, email, password): Observable<any> {
    const body = {
      email,
      accountId: get(account, 'id'),
      password
    };
    return this.http.post<any>(`api/login-to-account`, body).pipe(shareReplay(1));
  }

  // Free Trial
  public getTrialStatus(): Observable<boolean> {
    return this.isTrialEnding.asObservable();
  }

  // Account
  public getAccountFromServer() {
    this.http.get<any>(`api/accounts/v2`).pipe(shareReplay(1)).subscribe(res => {
      this.accountFromServer$.next(res);
    });
  }

  public getAccount(): Observable<any> {
    return this.accountFromServer$.asObservable();
  }

  // User
  public getUser(): Observable<any> {
    return this.http.get<any>(`api/users`).pipe(shareReplay(1));
  }

  logout() {
    return this.http.post<any>('api/logout', {})
      .pipe(map(() => {
        this.globalService.resetAll();
        this.removeUser();
        return;
      }));
  }

  recoverPassword(email) {
    const payload = {email};
    return this.http.put<any>('api/users/recover-password', payload);
  }

  mfaCode(body) {
    return this.http.post<any>('api/login-mfa', body, {params: {skipOpster: 'true'}})
      .pipe(map((res) => {
        this.getAccountAndUser(get(body, 'email'));
        return res;
      }));
  }

  resetPassword(body) {
    return this.http.put<any>('api/users/reset-password', body);
  }

  // invite
  public invite(body): Observable<any> {
    return this.http.post<any>(`api/users/invite`, body);
  }

  authorizationCode(iss) {
    return this.http.get<any>(`api/login/sso/okta/authorization-code?iss=${iss}`);
  }

  okta(state, code) {
    return this.http.get<any>(`api/login/sso/okta?state=${state}&code=${code}`);
  }

  sso(state) {
    return this.http.get<any>(`api/login/sso/saml?state=${state}`);
  }

  removeUser() {
    this.accountFromServer$.next(null);
    this.currentUserSubject.next(null);
    this.opsterUser$.next(null);
    this.googleUser$.next(null);
    this.destroy$.next(null);
    this.segmentTrackService.analyticsReset();

    localStorage.removeItem('user');
    localStorage.removeItem('account');
    localStorage.removeItem('clustersTimestamp');
    localStorage.removeItem('RefreshTime');

    this.socialAuthService.signOut(true).then(() => {
    });
  }

  // google

  buildLoginWithGoogle(inviteToken = null) {
    this.socialAuthService.authState.subscribe((r?: SocialUser) => {
      console.log('SocialUser', r);

      this.googleAuthState(r, inviteToken);
    }, err => {
      window.alert(err);
      this.globalService.setLoader(false);
      if (!includes(GOOGLE_ERROR_IGNORE, err)) {
        // this.segmentTrackService.setAnalyticsTrackToServer(`Auto ops failed to login with google account`,
        this.segmentTrackService.setAnalyticsTrackToServer(`Auto ops - failed to login with google account`,
          {error: get(err, 'error')});
      }

    });
  }

  googleAuthState(r, inviteToken = null) {
    if (!r || isEqual(this.googleUser$.value, r)) {
      return;
    }
    this.setGoogleUser(r);
    this.globalService.setLoader(true);

    this.loginWithGoogleOpster(false, inviteToken)
      .pipe(first()).subscribe(res => {
      if (has(res, 'newReg')) {
        if (this.getIsInviteUser()) {
          this.loginNewReg(inviteToken);
        } else {
          console.log('New account creation is not allowed.');
        }
      } else {
        if (has(res, 'accounts')) {
          this.openMultiAccounts(get(res, 'accounts'), get(this.googleUser$, 'value.email'), true);
        } else {
          this.setGoogleOpsterAnalytics(get(res, 'email'), false, !!inviteToken);
          this.globalService.setLoader(false);
          this.setOpsterUser(res, false);
        }
      }
    }, () => {
      this.globalService.setLoader(false);
    });
  }

  private loginNewReg(inviteToken) {
    this.loginWithGoogleOpster(true)
      .pipe(first()).subscribe(newRes => {
      this.setGoogleOpsterAnalytics(get(newRes, 'email'), false, !!inviteToken);
      this.setOpsterUser(newRes, true);

      this.segmentTrackService.analyticsTrack('Client Start Free Trial', {
        email: get(newRes, 'email'),
        company: '',
        page: 'AutoOps - Sign Up with google',
        plan: 'trial',
      });

      this.segmentTrackService.setAnalyticsTrackToServer('start free trial', {
        email: get(newRes, 'email'),
        company: '',
        page: 'AutoOps - Sign Up with google',
        plan: 'trial',
      });

      this.globalService.setLoader(false);
    }, () => {
      this.globalService.setLoader(false);
    });
  }

  private setGoogleOpsterAnalytics(res, isNewReg, isInvite = false) {
    this.setCurrentUser(res, res);

    this.segmentTrackService.setAnalyticsTrackToServer(`Auto ops ${isInvite ? 'invite' : ''} - ${isNewReg ? 'new user' : 'login'} with google account`,
      {email: get(res, 'email')});
  }

  // Opster User
  public setOpsterUser(user, isNewUser) {
    set(user, 'newUser', isNewUser);
    this.opsterUser$.next(user);
  }

  public getOpsterUser(): Observable<boolean> {
    return this.opsterUser$.asObservable();
  }

  // Google User
  public setGoogleUser(user) {
    this.googleUser$.next(user);
  }

  public getGoogleUser(): Observable<any> {
    return this.googleUser$.asObservable();
  }


  // InviteUser
  public setIsInviteUser(val) {
    this.isInviteUser$.next(val);
  }

  public getIsInviteUser() {
    return this.isInviteUser$.getValue();
  }

  public loginWithGoogle(): Promise<SocialUser> {
    return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  public loginWithGoogleOpster(confirmedRegCode = false, token?, accountId?): Observable<any> {
    const body = {
      idToken: get(this.googleUser$.value, 'idToken'),
      userId: this.segmentTrackService.getTrackUserId,
      confirmedRegCode
    };

    if (token) {
      set(body, 'token', token);
    }
    if (accountId) {
      set(body, 'accountId', accountId);
    }

    return this.http.post(`api/google`, body)
      .pipe(share()) as Observable<string[]>;
  }

  public openMultiAccounts(accounts, email, isGoogle = false, password?) {
    this.globalService.setLoader(false);
    const dialogButtonConfig = {
      ok: {
        label: 'yes',
        action: (res) => {
          this.globalService.setLoader(true);

          if (isGoogle) {
            this.loginToGoogleAccount(res);
          } else {
            this.loginToAccount(res, email, password);
          }
          // this.segmentTrackService.setAnalyticsTrackToServer(`Auto ops ${isGoogle ? 'google' : ''} multi accounts successfully`,
          this.segmentTrackService.setAnalyticsTrackToServer(`Auto ops - ${isGoogle ? 'google' : ''} multi accounts successfully`,
            {email, isGoogle});
        }
      },

    };

    this.dialog.open(MultipleAccountsComponent, {
      width: DialogSize.xSmall,
      data: {dialogButtonConfig: dialogButtonConfig, element: {accounts, email}},
    });
  }

  loginToGoogleAccount(account) {
    this.loginWithGoogleOpster(false, false, get(account, 'account.id'))
      .pipe(first()).subscribe(res => {
      this.setGoogleOpsterAnalytics(get(res, 'email'), false, false);
      this.setOpsterUser(res, false);
    });
  }

  multiFactorAuthEnabled(account, email) {

    if (get(account, 'multiFactorAuthEnabled')) {
      this.segmentTrackService.setAnalyticsTrackToServer('AutoOps - Login successfully with multi factor auth enabled', {email});
      // this.segmentTrackService.setAnalyticsTrackToServer('AutoOps Login successfully with multi factor auth enabled', {email});

      this.setCurrentUser(account, null);
      this.router.navigate(['/login/code']).then(() => {
      });

    } else {
      this.segmentTrackService.setAnalyticsTrackToServer('AutoOps -Login successfully', {email});
      // this.segmentTrackService.setAnalyticsTrackToServer('AutoOps Login successfully', {email});

      this.router.navigate(['/']).then(() => {
        if (this.updateService.hasUpdate) {
          window.location.reload();
        }
      });
    }
  }
}
