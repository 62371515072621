import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { ToastService } from '../../../store/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerComponent {
  @Input() answer;
  @Input() index;
  @Input() withSpinner = false;


  constructor(private toastService: ToastService,
              private translateService: TranslateService) {
  }

  onClickCopyIcon() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('success.cluster_copy_clipboard'), 'success', 'copy_clipboard');
  }

}
