<div class="main-chat" [class.isQuickOpsGpt]="isQuickOpsGpt">
  <div *ngIf="isQuickOpsGpt" style="display: flex;justify-content: space-between;  padding: 1rem">

    <div style="display: flex;align-items: center;">
      <span class="init-title-quick">OpsGPT</span>
      <span class="beta">BETA</span>
    </div>
    <mat-icon class="quick-close" *ngIf="isQuickOpsGpt" [svgIcon]="'close'" (click)="closeOpsGpt()"></mat-icon>
  </div>

  <p-splitter [style]="{ height: '100%' }" [panelSizes]="[90, 10]" [ngClass]="'splitter-chat'"
              layout="vertical">
    <ng-template pTemplate>

      <div *ngIf="currentChat?.length || chatLoading || answerAll; else INIT" class="current-chat" #sc
           (scroll)="onScroll()">
        <div class="current-chat-body">

          <div *ngFor="let chat of currentChat; trackBy: PerformanceUtils.trackByIndex" class="question-answer">

            <div class="question">
              <!--              <mat-icon class="chat_question" [svgIcon]="'chat_question'"></mat-icon>-->
              <div class="chat_question"> {{userShortName | uppercase}}</div>
              <span class="prompt">
                 {{chat?.prompt}}
              </span>
            </div>

            <div class="answer" [class.error]="chat?.error">
              <div class="feedback">
                <mat-icon class="copy-line-icon" [svgIcon]="'copy_light'"
                          (click)="copyToClipboard(chat?.answer)"
                          [cdkCopyToClipboard]="chat?.answer"></mat-icon>
              </div>

              <div class="answer-main">
                <answer [answer]="chat?.answer"></answer>
              </div>
            </div>

            <div class="feedback-section" [class.right]="isQuickOpsGpt" *ngIf="!chat?.error">
              <span *ngIf="!isQuickOpsGpt" class="helpful">Was this helpful?</span>
              <mat-icon class="feedback-icon like" [svgIcon]="'like'"
                        [ngClass]="{'green': chat?.score === true, 'disabled': chat?.score !== null && chat.hasOwnProperty('score')}"
                        (click)="setFeedback(chat?.messageId, true)"></mat-icon>
              <mat-icon class="feedback-icon dislike" [svgIcon]="'like'"
                        [ngClass]="{'red': chat?.score === false, 'disabled': chat?.score !== null && chat.hasOwnProperty('score')}"
                        (click)="setFeedback(chat?.messageId, false)"></mat-icon>
            </div>

            <!--            <div class="created-at">{{chat.createdAt | date: 'MMM d, y, h:mm a'}}</div>-->
          </div>

          <div *ngIf="answerAll" class="question-answer">
            <div class="question">
              <!--              <mat-icon class="chat_question" [svgIcon]="'chat_question'"></mat-icon>-->
              <div class="chat_question"> {{userShortName | uppercase}}</div>
              <opster-markdowm [data]="question"></opster-markdowm>

            </div>

            <div class="answer">

              <div class="answer-main">

                <answer [answer]="answerAll" [index]="index" [withSpinner]="true"></answer>
              </div>
            </div>

          </div>

          <div class="loading-card-question" *ngIf="chatLoading">
            <div class="question">
              <!--              <mat-icon class="chat_question" [svgIcon]="'chat_question'"></mat-icon>-->
              <div class="chat_question"> {{userShortName | uppercase}}</div>
              <span class="prompt">
                {{question}}
              </span>
            </div>

            <div class="loading-card">
              <ng-lottie [options]="options" [ngClass]="'gif-lottie'"></ng-lottie>
              <div style=" bottom: 10px;margin: 0 2rem; position: absolute">{{loadingSentence}}</div>

            </div>
          </div>

        </div>

      </div>

      <ng-template #INIT>
        <div class="main-chat-init" *ngIf="!loadingHistory; else skeletonLoader">
          <p>
            <mat-icon class="opster-chat" [svgIcon]="'ops_gpt'"></mat-icon>
          </p>
          <div class="chat-title">
            Opster’s AI-Powered Chat
            <!--            <span>-->
            <!--                <mat-icon class="chat_black" [svgIcon]="'chat_color'"></mat-icon>-->
            <!--            </span>-->
          </div>
          <div class="chat-sub-title" style="margin-top: 2rem" *ngIf="!isInstallation">
            OpsGPT can answer questions related to your clusters and <br *ngIf="!isQuickOpsGpt"> provide detailed
            guidance on search operations.
          </div>
          <div class="chat-sub-title" style="margin-top: 2rem" *ngIf="isInstallation">
            {{allClusters?.length ? 'Get answers and instructions on how to connect more clusters to AutoOps.' :
            'Get answers and instructions on how to connect AutoOps. Once connected, OpsGPT can answer questions related\n' +
            ' to your clusters.'}}

          </div>
          <div class="chat-sub-title" style="margin-top: 4rem">
            Check out some examples below:
          </div>
          <div class="chat-init-examples">
            <div
              *ngFor="let example of isInstallation ? exampleInitInstallation : exampleInit; trackBy: PerformanceUtils.trackByIndex"
              class="example-box" (click)="selectExample(example)">
              <div class="example-text">{{example}}</div>
              <!--              <div class="example-box-arrow">-->
              <!--                <mat-icon class="arrow-right" [svgIcon]="'arrow_right'"></mat-icon>-->
              <!--              </div>-->

            </div>
          </div>
        </div>

        <ng-template #skeletonLoader>
          <div class="loader-wrapper-chat">
            <app-skeleton-loader [withItem]="true"></app-skeleton-loader>
            <app-skeleton-loader [withItem]="true"></app-skeleton-loader>
          </div>
        </ng-template>

      </ng-template>
    </ng-template>
    <ng-template pTemplate>

      <div class="input-gpt-main">

        <div class="input-gpt" [ngClass]="{'after-first-time': afterFirstTime}">
          <div class="input-question-action" [ngClass]="{'installation': gptPayload?.isInstallation}">

            <div *ngIf="!gptPayload?.isInstallation">
              <input-multi-select
                [listGrouping]="multiClustersGroup"
                [sizeOfItems]="multiClustersGroup[0]?.items?.length"
                [selectedAllLabel]="true"
                [selectedItems]="selectedMultiCluster"
                [disabledItemList]="multiClustersDisabledList"
                [isGpt]="true"
                [customAllName]="'Clusters'"
                [isGroup]="true"
                [quick]="true"
                [disabled]="!!currentChat?.length || chatLoading || !!answerAll"
                [isSelectingGroup]="false"
                [nameItem]="'Related to'"
                (loadItems)="multiSelectPanelOpened()"
                (selectionChange)="selectionMultiClusterChange($event)">
              </input-multi-select>
            </div>


            <!--            <p-button *ngIf="conversationId && !isQuickOpsGpt" (click)="newChat()"-->
            <!--                      [styleClass]="'button-opster gray border-gray'">-->

            <!--              <mat-icon [svgIcon]="'plus_circle'"></mat-icon>-->
            <!--             <span >New chat</span>-->
            <!--            </p-button>-->

            <div style="display: flex;align-items: center;">
              <button *ngIf="afterFirstTime && !isQuickOpsGpt" (click)="newChat(afterFirstTime)" mat-stroked-button
                      class="button-opster small-btn content-bg">
                <mat-icon [svgIcon]="'new_chat'"></mat-icon>
                <span>New chat</span>
              </button>


              <mat-icon class="action-chat" *ngIf="afterFirstTime && isQuickOpsGpt"
                        (click)="newChat(afterFirstTime)"
                        [pTooltip]="'New Chat'" [tooltipPosition]="'left'"
                        [svgIcon]="'new_chat'"></mat-icon>


              <span *ngIf="afterFirstTime && isQuickOpsGpt && allClusters?.length"
                    class="small-spacer-border opacity"></span>

              <mat-icon class="action-chat" *ngIf=" isQuickOpsGpt && allClusters?.length"
                        (click)="openFullPage()"
                        [pTooltip]="'Full Page'"
                        [svgIcon]="'full_page_2'"></mat-icon>

              <!--              <mat-icon class="plus-circle" *ngIf="isQuickOpsGpt"-->
              <!--                        (click)="newChat()"-->
              <!--                        [svgIcon]="'chat_color'"></mat-icon>-->
            </div>


          </div>
          <div class="input-question">

            <div class="textarea-question">
              <textarea pInputTextarea [autoResize]="true" autofocus
                        [placeholder]="gptPayload?.isInstallation ? 'Ask a question related to AutoOps installation' : 'Ask your question'"
                        rows="3" #textAreaQuestion
                        [disabled]="chatLoading || !!answerAll"
                        [(ngModel)]="question"></textarea>
              <div class="send_question" [class.disabled]="chatLoading || !!answerAll">

                <mat-icon class="send-question-icon" (click)="checkUserPolicyAgreement(question)"
                          [svgIcon]="'send_question'" *ngIf="!chatLoading && !answerAll"></mat-icon>
                <mat-spinner *ngIf="chatLoading || !!answerAll" [strokeWidth]="1" [diameter]="15"></mat-spinner>

              </div>
            </div>

            <div class="question-text" [class.isQuickOpsGpt]="isQuickOpsGpt">
              Opster does not guarantee result accuracy
              and recommends verifying answers with Opster’s support.<br> See <a style="color: #9B9797"
                                                                                 href="https://opster.com/about/privacy-policy/"
                                                                                 aria-label="privacy-policy"
                                                                                 target="_blank">Privacy Policy</a>
              & <a style="color: #9B9797" href="https://opster.com/about/term-and-conditions/"
                   aria-label="privacy-policy"
                   target="_blank">Terms of Use</a>.
            </div>

          </div>
        </div>

      </div>

    </ng-template>
  </p-splitter>

</div>
