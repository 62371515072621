import {ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';
import {PerformanceUtils} from '../../../../services/utils/functional-utils';

@Component({
  selector: 'app-multiple-accounts',
  templateUrl: './multiple-accounts.component.html',
  styleUrls: ['./multiple-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleAccountsComponent implements OnInit {
  public email;
  public accounts;

  public dialogButton;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    const element = get(this.data, 'element');
    this.email = get(element, 'email');
    this.accounts = get(element, 'accounts');
    this.dialogButton = get(this.data, 'dialogButtonConfig');
  }

  selectAccount(account) {
    Promise.resolve(this.dialogButton.ok.action({account})).then(() => {
    });
  }

}
