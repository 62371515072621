import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnChanges {
  @Input() text = 'Loading';
  @Input() okta = false;

  constructor() {
  }

  ngOnInit() {
  }


  ngOnChanges() {
  }


}
