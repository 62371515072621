<div class="sidebar-panel description" [ngClass]="{'open': expandMore}">
  <p class="panel-title">
    <mat-icon svgIcon="file_check"></mat-icon>
    <span>Background and impact</span>
  </p>
  <div #description class="panel-content marked">
    <opster-markdowm [lineNumbers]="false" [clipboard]="false"  [data]="healthDescription">
    </opster-markdowm>
  </div>

  <div class="expand-more" *ngIf="ifExpandMore">
    <mat-icon class="expand-more-icon" [class.anim]="expandMore" (click)="expandMore = !expandMore">
      expand_more
    </mat-icon>

  </div>
</div>
