import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {EnumToolsType} from '../../../../models/enum';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';

@Component({
  selector: 'failed-upload',
  templateUrl: './failed-upload.component.html',
  styleUrls: ['./failed-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailedUploadComponent implements OnInit {
  public type: EnumToolsType;
  public errors;
  public title;
  public EnumToolsType = EnumToolsType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.errors = get(this.data, 'errors');
    this.type = get(this.data, 'type');

    this.title = isEqual(this.type, EnumToolsType.SLOWLOG) ? 'Slow Logs Failed'
      : isEqual(this.type, EnumToolsType.TEMPLATE_OPTIMIZER) ? 'Template Optimizer Failed' : '';
  }

  onOk() {
    if (isEqual(this.type, EnumToolsType.SLOWLOG)) {
      this.router.navigate(['/slowLogs/input'],
        {
          queryParams: {},
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }

    if (isEqual(this.type, EnumToolsType.TEMPLATE_OPTIMIZER)) {
      window.location.reload();
    }


  }

}
