import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';


@Injectable()
export class UpdateService {
  public hasUpdate = false;

  constructor(public updates: SwUpdate) {
    updates.checkForUpdate();
  }

  public checkForUpdates(): void {
    this.updates.checkForUpdate().then(() => {
      this.promptUser();
    });
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => {
      console.log('Has Updates');
      this.hasUpdate = true;
    });
  }

  //   if (updates.isEnabled) {
  //     this.authService.getCurrentUser().subscribe(user => {
  //       if (user) {
  //         interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
  //           .then(() => console.log('checking for updates')));
  //       }
  //     });
  //
  //   }
  // }
  //
  // public checkForUpdates(): void {
  //   this.updates.available.subscribe(() => this.promptUser());
  // }
  //
  // private promptUser(): void {
  //   this.updates.activateUpdate().then(() => {
  //
  //     this.messageService.add({key: 'new_version', sticky: true, severity: 'success', summary: 'A new version is available!'});
  //
  //   });
  // }
}
