import { Injectable } from '@angular/core';
import { AppRoutes } from '../../../app-routing-data';
import {  Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { share, shareReplay } from 'rxjs/operators';
import { NotificationFilterRequest } from '../../../shared/components/popups/dialog/dialog.interface';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ShippingIconLink } from '../shipping/shipping.interface';
import {MuteAnalysisBody, MuteSetting, NotificationMuteSetting} from '../settings.interface';
import { FormControl } from '@angular/forms';
import * as DOMPurify from 'dompurify';

@Injectable({
  providedIn: 'root'
})
export class SettingsServiceService {
  private linkClick$ = new Subject<ShippingIconLink>();

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  // getSettingsShippingMenuTabs(): ShippingIconLink[] {
  //   return [
  //     {
  //       link: this.getFeatureRoute(AppRoutes.docker),
  //       label: this.translateService.instant('settings.docker'),
  //       labelLink: this.translateService.instant('settings.docker'),
  //       icon: 'docker_logo',
  //       active: true
  //     },
  //     {
  //       label: this.translateService.instant('settings.kubernetes'),
  //       labelLink: this.translateService.instant('settings.kubernetes'),
  //       icon: 'kubernetes_logo',
  //       active: false,
  //       children: [
  //         {
  //           link: this.getFeatureRouteInParent(AppRoutes.kubernetes),
  //           label: this.translateService.instant('settings.yamlDeploymentLink'),
  //           labelLink: this.translateService.instant('settings.yamlDeployment'),
  //           icon: 'yaml_logo',
  //           active: true
  //         },
  //         {
  //           link: this.getFeatureRouteInParent(AppRoutes.helm),
  //           label: this.translateService.instant('settings.helmLink'),
  //           labelLink: this.translateService.instant('settings.helm'),
  //           icon: 'helm_logo',
  //           active: true
  //         }
  //       ]
  //     },
  //     {
  //       link: this.getFeatureRouteInParent(AppRoutes.rpmPackage),
  //       label: this.translateService.instant('settings.rpmPackage'),
  //       labelLink: this.translateService.instant('settings.rpmPackage'),
  //       icon: 'rpm_logo',
  //       active: true
  //     },
  //     {
  //       link: this.getFeatureRouteInParent(AppRoutes.debPackage),
  //       label: this.translateService.instant('settings.debPackage'),
  //       labelLink: this.translateService.instant('settings.debPackage'),
  //       icon: 'debian_logo',
  //       active: true
  //     },
  //     {
  //       link: this.getFeatureRouteInParent(AppRoutes.dockerSwarm),
  //       label: this.translateService.instant('settings.dockerSwarm'),
  //       labelLink: this.translateService.instant('settings.dockerSwarm'),
  //       icon: 'docker_swarm_logo',
  //       active: true
  //     },
  //     {
  //       link: this.getFeatureRouteInParent(AppRoutes.dockerCompose),
  //       label: this.translateService.instant('settings.dockerCompose'),
  //       labelLink: this.translateService.instant('settings.dockerCompose'),
  //       icon: 'docker_compose_logo',
  //       active: true
  //     },
  //     {
  //       link: this.getFeatureRouteInParent(AppRoutes.windows),
  //       label: this.translateService.instant('settings.windows'),
  //       labelLink: this.translateService.instant('settings.windows'),
  //       icon: 'windows_logo',
  //       active: true
  //     }
  //   ];
  // }

  getSettingsMenuTabs(disabled): MenuItem[] {
    return [
      {
        routerLink: AppRoutes.user,
        label: this.translateService.instant('settings.user'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right'
      },
      {
        routerLink: AppRoutes.account,
        label: this.translateService.instant('settings.account'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right'
      },
      {
        routerLink: AppRoutes.teamManagement,
        label: this.translateService.instant('settings.teamManagement'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right'
      },
      {
        routerLink: disabled ? '' : AppRoutes.tokens,
        label: this.translateService.instant('settings.tokens'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
        styleClass: disabled ? 'disabled-tab' : '',
        tooltipOptions: {
          tooltipLabel: disabled ? 'Connect a cluster first in order to use AutoOps' : ''
        }

      },
      {
        routerLink: disabled ? '' : AppRoutes.clusters,
        label: this.translateService.instant('settings.clusters'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
        styleClass: disabled ? 'disabled-tab' : '',
        tooltipOptions: {
          tooltipLabel: disabled ? 'Connect a cluster first in order to use AutoOps' : ''
        }

      },
      {
        routerLink: disabled ? '' : AppRoutes.notifications,
        label: this.translateService.instant('settings.notifications'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
        styleClass: disabled ? 'disabled-tab' : '',
        tooltipOptions: {
          tooltipLabel: disabled ? 'Connect a cluster first in order to use AutoOps' : ''
        }

      },
      {
        routerLink: disabled ? '' : AppRoutes.customizeEvents,
        label: this.translateService.instant('settings.customizeEvents'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
        styleClass: disabled ? 'disabled-tab' : '',
        tooltipOptions: {
          tooltipLabel: disabled ? 'Connect a cluster first in order to use AutoOps' : ''
        }

      },
      {
        routerLink: disabled ? '' : AppRoutes.dismissEvents,
        label: this.translateService.instant('settings.dismissEvents'),
        routerLinkActiveOptions: {exact: true},
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
        styleClass: disabled ? 'disabled-tab' : '',
        tooltipOptions: {
          tooltipLabel: disabled ? 'Connect a cluster first in order to use AutoOps' : ''
        }

      },
      {
        routerLink: AppRoutes.installation,
        label: this.translateService.instant('settings.shipping'),
        queryParamsHandling: 'merge',
        icon: 'pi pi-fw pi-chevron-right',
      },
      // {
      //   routerLink: AppRoutes.installation,
      //   label: this.translateService.instant('settings.shipping'),
      //   routerLinkActiveOptions: {exact: true},
      //   expanded: true,
      //   queryParamsHandling: 'merge',
      //   icon: 'pi pi-fw pi-chevron-right',
      //   items: [
      //     {
      //       routerLink: AppRoutes.docker,
      //       label: this.translateService.instant('settings.docker'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.kubernetes,
      //       label: this.translateService.instant('settings.yamlDeployment'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.helm,
      //       label: this.translateService.instant('settings.helm'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.rpmPackage,
      //       label: this.translateService.instant('settings.rpmPackage'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.debPackage,
      //       label: this.translateService.instant('settings.debPackage'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.dockerSwarm,
      //       label: this.translateService.instant('settings.dockerSwarm'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.dockerCompose,
      //       label: this.translateService.instant('settings.dockerCompose'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     },
      //     {
      //       routerLink: AppRoutes.windows,
      //       label: this.translateService.instant('settings.windows'),
      //       routerLinkActiveOptions: {exact: true},
      //       queryParamsHandling: 'merge',
      //       icon: 'pi pi-fw pi-chevron-right',
      //     }]
      // }

    ];
  }

  // Cluster enable/disable
  public enableDisableCluster(clusterId: string, action: 'enable' | 'disable'): Observable<any> {
    return this.httpClient.put<any>(`api/accounts/clusters/${clusterId}/${action}`, null).pipe(shareReplay(1));
  }

  // Tokens
  public createOpsterTokens(): Observable<any> {
    return this.httpClient.post<any>(`api/accounts/tokens`, null).pipe(shareReplay(1));
  }

  public deleteOpsterTokens(token): Observable<any> {
    return this.httpClient.delete<any>(`api/accounts/tokens/${token}`).pipe(shareReplay(1));
  }

  public getLink() {
    return this.linkClick$.asObservable();
  }

  public setLink(link: ShippingIconLink) {
    this.linkClick$.next(link);
  }

  public getFeatureRouteInParent(feature: string): Observable<string> {
    return of(`../${feature}`);
  }

  public getFeatureRoute(feature: string): Observable<string> {
    return of(`${feature}`);
  }

  // User
  public getUser(): Observable<any> {
    return this.httpClient.get<any>(`api/users`).pipe(shareReplay(1));
  }

  public setUser(user): Observable<any> {
    return this.httpClient.put<any>(`api/users`, user);
  }


  public updateAccount(account): Observable<any> {
    return this.httpClient.put<any>(`api/accounts`, account);
  }

  // fetcher
  public getFetcher(): Observable<any> {
    return this.httpClient.get<any>(`api/fetcher/es/settings`).pipe(shareReplay(1));
  }

  public setFetcher(body): Observable<any> {
    return this.httpClient.post<any>(`api/fetcher/es/settings`, body);
  }

  public deleteClusterFetcher(id): Observable<any> {
    return this.httpClient.delete<any>(`api/fetcher/es/settings/${id}`);
  }

  public editClusterFetcher(id, body): Observable<any> {
    return this.httpClient.put<any>(`api/fetcher/es/settings/${id}`, body);
  }

  // notifications Endpoints
  public getNotificationsEndpoints(): Observable<any> {
    return this.httpClient.get<any>(`api/notifier/endpoint`).pipe(share());
  }

  public addNotificationsEndpoints(body): Observable<any> {
    return this.httpClient.post<any>(`api/notifier/endpoint`, body);
  }

  public deleteNotificationsEndpoints(id): Observable<any> {
    return this.httpClient.delete<any>(`api/notifier/endpoint/${id}`);
  }

  public editNotificationsEndpoints(id, body): Observable<any> {
    return this.httpClient.put<any>(`api/notifier/endpoint/${id}`, body);
  }

  public validateEndpoints(body): Observable<any> {
    return this.httpClient.post<any>(`api/notifier/endpoint/test`, body, {params: {skipOpsterError: 'true'}});
  }

  // notifications Filters
  public getNotificationsFilters(): Observable<NotificationFilterRequest[]> {
    return this.httpClient.get<any>(`api/notifier/filters`).pipe(share());
  }

  public addNotificationsFilters(body): Observable<NotificationFilterRequest> {
    return this.httpClient.post<any>(`api/notifier/filters`, body);
  }

  public deleteNotificationsFilters(id): Observable<any> {
    return this.httpClient.delete<any>(`api/notifier/filters/${id}`);
  }

  public editNotificationsFilters(id, body): Observable<NotificationFilterRequest> {
    return this.httpClient.put<any>(`api/notifier/filters/${id}`, body);
  }

  // notifications Filters for analysis
  public getNotificationsFiltersAnalysis(body): Observable<any> {
    return this.httpClient.post<any>(`api/notifier/filters/analysis`, body).pipe(share());
  }

  public getNotificationsEndpointsAnalysis(body): Observable<any> {
    return this.httpClient.post<any>(`api/notifier/endpoint/analysis`, body).pipe(share());
  }

  // mute settings
  public createMuteSetting(body: MuteSetting): Observable<any> {
    return this.httpClient.post<any>(`api/notifier/mute`, body).pipe(share());
  }

  public editMuteSetting(body: MuteSetting, id: number): Observable<any> {
    return this.httpClient.put<any>(`api/notifier/mute/${id}`, body).pipe(share());
  }

  public deleteMuteSetting(id): Observable<any> {
    return this.httpClient.delete<any>(`api/notifier/mute/${id}`).pipe(share());
  }

  public getNotificationMuteSetting(id): Observable<NotificationMuteSetting> {
    return this.httpClient.get<any>(`api/notifier/mute/${id}`).pipe(share());
  }

  public getAllNotificationMuteSetting(body): Observable<NotificationMuteSetting[]> {
    return this.httpClient.post<any>(`api/notifier/mute/list`, body).pipe(share());
  }

  public getMuteAnalysis(body: MuteAnalysisBody): Observable<NotificationMuteSetting> {
    return this.httpClient.post<any>(`api/notifier/mute/analysis`, body).pipe(share());
  }

  public filterByClusterPattern(pattern) {
    return this.httpClient.post<any>(`api/accounts/cluster/filter`, {clusterPattern: pattern}).pipe(share());
  }

  // sso
  public putSamlApplication(body): Observable<any> {
    return this.httpClient.put<any>(`api/accounts/saml/application`, body).pipe(share());
  }

  public getSamlApplication(): Observable<any> {
    return this.httpClient.get<any>(`api/accounts/saml/application`).pipe(share());
  }

  public deleteSamlApplication(entityId): Observable<any> {
    const options = {
      body: {
        entityId: entityId
      },
    };
    return this.httpClient.delete<any>(`api/accounts/saml/application/`, options).pipe(share());
  }

  // google and okta
  public ssoFileUpload(body): Observable<any> {
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.httpClient.post<any>(`api/accounts/idp/file-upload`, body, {headers: headers}).pipe(share());
  }

  scriptValidator(control: FormControl) {
    // Use DOMPurify to sanitize and clean the input
    const sanitizedValue = DOMPurify.sanitize(control.value);

    // Check if the sanitized value is different from the original value
    if (sanitizedValue !== control.value) {
      // The input contained potentially harmful content, mark it as invalid
      control.setValue('');
      return { scriptInjection: true };
    }

    // If there are no issues with the input, return null (valid)
    return null;
  }


}
