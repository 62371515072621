export enum ICONS {
  user = 'user',
  lock = 'lock',
  email = 'email',
  devices = 'devices',
  essentials_login = 'essentials_login',
  gal_top = 'gal_top',
  gal_bottom = 'gal_bottom',
  opster_close = 'opster_close',
  opster_open = 'opster_open',
  refresh = 'refresh',
  alert_high_open = 'alert_high_open',
  alert_low_open = 'alert_low_open',
  alert_medium_open = 'alert_medium_open',
  collapse_snippet = 'collapse_snippet', // V
  expand_snippet = 'expand_snippet', // V
  copy = 'copy', // V
  index_icon = 'index_icon', // V
  node_icon = 'node_icon', // V
  homepage_icon = 'homepage_icon', // V
  menu_icon = 'menu_icon', // V
  success_notification = 'success_notification',
  success_notification_circle = 'success_notification_circle',
  logo = 'logo',
  login_account = 'login_account',
  login_password = 'login_password',
  no_connection = 'no_connection',
  slack = 'slack',
  pager_duty = 'pager_duty',
  webhook = 'webhook',
  empty_state_ghost = 'empty_state_ghost',
  opsgenie = 'opsgenie',
  empty_chart_top = 'empty_chart_top',
  empty_chart_line = 'empty_chart_line',
  empty_chart_pie = 'empty_chart_pie',
  empty_icon_speed = 'empty_icon_speed',
  space = 'space',
  space_dark = 'space_dark',
  nodata = 'nodata',
  no_data_dark = 'no_data_dark',
  save_new_icon = 'save_new_icon',
  call_calling = 'call_calling',
  log_out = 'log_out',
  file_check = 'file_check',
  notification = 'notification',
  sliders_horiz = 'sliders_horiz',
  edit = 'edit',
  trash = 'trash',
  processor = 'processor',
  wrench = 'wrench',
  activity_chart = 'activity_chart',
  clock = 'clock',
  book = 'book',
  shards = 'shards',
  nodes = 'nodes',
  docs = 'docs',
  refresh_btn = 'refresh_btn',
  star = 'star',
  gear = 'gear',
  check_circle = 'check_circle',
  moon = 'moon',
  sun = 'sun',
  chevron_right = 'chevron_right',
  search = 'search',
  new_release = 'new_release',
  info = 'info',
  victorops = 'victorops',
  trophy = 'trophy',
  presentation = 'presentation',
  template_optimizer = 'template_optimizer',
  alert_triangle = 'alert_triangle',
  alert_medium_white = 'alert_medium_white',
  check_yes = 'check_yes',
  copy_light = 'copy_light',
  copy_dark = 'copy_dark',
  template_optimizer_black = 'template_optimizer_black',
  arrow_down = 'arrow_down',
  arrow_up = 'arrow_up',
  arrow_right = 'arrow_right',
  user_circle = 'user_circle',
  sort_by = 'sort_by',
  shards_view = 'shards_view',
  pin_icon = 'pin_icon',
  msteams = 'msteams',
  error_big = 'error_big',
  alert_low_sidebar = 'alert_low_sidebar',
  alert_high_sidebar = 'alert_high_sidebar',
  alert_medium_sidebar = 'alert_medium_sidebar',
  copy_text = 'copy_text',
  search_dark = 'search_dark',
  slow_logs = 'slow_logs',
  progress_bar_upload = 'progress_bar_upload',
  progress_bar_error = 'progress_bar_error',
  metric_beat_empty_state = 'metric_beat_empty_state',
  zoom_out = 'zoom_out',
  opensearch_logo = 'opensearch_logo',
  elasticsearch_logo = 'elasticsearch_logo',
  copy_icon = 'copy_icon',
  opster_no_border = 'opster_no_border',
  cluster_view_icon = 'cluster_view_icon',
  no_search_result = 'no_search_result',
  google = 'google',
  okta = 'okta',
  azure = 'azure',
  plus = 'plus',
  more_horiz = 'more_horiz',
  jump_cloud = 'jump_cloud',
  AutoOps_logo = 'AutoOps_logo',
  replay = 'replay',
  lightning = 'lightning',
  one_login = 'one_login',
  link = 'link',
  play_next = 'play_next',
  play_previous = 'play_previous',
  play = 'play',
  pause = 'pause',
  docker_logo = 'docker_logo',
  debian_logo = 'debian_logo',
  docker_compose_logo = 'docker_compose_logo',
  docker_swarm_logo = 'docker_swarm_logo',
  helm_logo = 'helm_logo',
  kubernetes_logo = 'kubernetes_logo',
  openshift_logo = 'openshift_logo',
  rpm_logo = 'rpm_logo',
  yaml_logo = 'yaml_logo',
  windows_logo = 'windows_logo',
  phone = 'phone',
  expand = 'expand',
  collapse = 'collapse',
  close = 'close',
  gear_white = 'gear_white',
  appcast_logo = 'appcast_logo',
  bloomberg_logo = 'bloomberg_logo',
  bmc_logo = 'bmc_logo',
  checkpoint_logo = 'checkpoint_logo',
  coupa_logo = 'coupa_logo',
  frontiers_logo = 'frontiers_logo',
  ibm_logo = 'ibm_logo',
  paloalto_logo = 'paloalto_logo',
  paypal_logo = 'paypal_logo',
  presidio_logo = 'presidio_logo',
  redbrain_logo = 'redbrain_logo',
  cybereason_logo = 'cybereason_logo',
  inbox_in = 'inbox_in',
  newspaper = 'newspaper',
  checkbox_circle = 'checkbox_circle',
  bulb = 'bulb',
  arrow_perc = 'arrow_perc',
  export = 'export',
  mute_notification = 'mute_notification',
  dismiss = 'dismiss',
  plus_Icon = 'plus_Icon',
  linux_logo = 'linux_logo',
  chevron_right_black = 'chevron_right_black',
  tip = 'tip',
  calculator = 'calculator',
  cluster_cost = 'cluster_cost',
  play_tour = 'play_tour',
  cost_event = 'cost_event',
  bulb_idea = 'bulb_idea',
  marketing_bg_top = 'marketing_bg_top',
  marketing_bg_bottom = 'marketing_bg_bottom',
  marketing_sg = 'marketing_sg',
  cost_event_green = 'cost_event_green',
  chevron_down = 'chevron_down',
  trophy_cost = 'trophy_cost',
  speech_bubble = 'speech_bubble',
  chat = 'chat',
  send_question = 'send_question',
  like = 'like',
  opster_color = 'opster_color',
  plus_circle = 'plus_circle',
  chat_dark = 'chat_dark',
  full_page_2 = 'full_page_2',
  new_chat = 'new_chat',
  ops_gpt = 'ops_gpt',
  ops_gpt_light = 'ops_gpt_light',
  ops_gpt_dark = 'ops_gpt_dark',
  arrow_ask = 'arrow_ask',
  dashboard_icon = 'dashboard_icon',
  atom = 'atom',
  clipboard = 'clipboard',
  calendar_small = 'calendar_small',
  cluster_count = 'cluster_count',
  occurrences = 'occurrences',
  plant = 'plant',
  plant_dark = 'plant_dark',
  copy_icon_hover = 'copy_icon_hover',
  alert_high_red = 'alert_high_red',
  close_blue = 'close_blue',
  play_tour_black = 'play_tour_black',
  bell = 'bell',
  bell_notifications = 'bell_notifications',
  review = 'review',
  cost_event_icon = 'cost_event_icon',
  plus_color = 'plus_color',
  bell_spe = 'bell_spe',
  warning = 'warning',
  confirm_data_eui = 'confirm_data_eui',
}
