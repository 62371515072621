import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { GlobalService } from '../../../../store/global/global.service';
import { DatePicker } from '../../../../store/global/cluster.interface';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NodeViewService } from '../../../../features/node-view/service/node-view.service';
import { SegmentTrackService } from '../../../services/segment/segment.service';

import size from 'lodash-es/size';
import isEmpty from 'lodash-es/isEmpty';
import first from 'lodash-es/first';
import flatMap from 'lodash-es/flatMap';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import set from 'lodash-es/set';
import { PerformanceUtils } from '../../../services/utils/functional-utils';
import isEqual from 'lodash-es/isEqual';
import { PERCENTILES_LIST } from '../../../../features/node-view/categories';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'panel-line-crosshair-chart',
  templateUrl: './panel-line-crosshair-chart.component.html',
  styleUrls: ['./panel-line-crosshair-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelLineCrosshairChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data;
  @Input() loading: boolean;
  @Input() animationCharts;
  @Input() globalDatePicker;
  @Input() isFullScreen = false;
  @Input() expandIcon = true;
  @Input() height;
  @Input() yLineFullName = true;
  @Input() legend = true;
  @Input() selection = true;
  @Input() crosshair = true;
  @Input() selectedNodes;
  @Input() metricNameFromServe;
  @Input() cursor;
  @Input() isSidebar;
  @Input() clusterId;
  @Input() withPercentiles = false;


  public selectedForm = new FormControl();
  public selectList: string[];
  public selectedFormInitialValue = null;

  public selectedPercentilesForm = new FormControl('MAX');
  private localSelectedPercentiles;
  public selectPercentilesList = ['MAX', 'PERCENTILE_99', 'PERCENTILE_95', 'PERCENTILE_50'];
  public PercentilesList = PERCENTILES_LIST;

  public AllMetricsData;

  public numberFormat;
  public digits;

  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;


  constructor(private globalService: GlobalService,
              private translate: TranslateService,
              private segmentTrackService: SegmentTrackService,
              private nodeViewService: NodeViewService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.nodeViewService.getChartHeaderLoader()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.showChartHeaderLoader();
      });
    //
    // this.nodeViewService.getPercentiles()
    //   .pipe(untilDestroyed(this), rxFirst())
    //   .subscribe(percentiles => {
    //     this.percentilesList = percentiles;
    //
    //     this.selectedPercentilesForm.setValue(this.percentilesList[get(this.data, 'metricName')]);
    //
    //   });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'data.currentValue')) {
      this.AllMetricsData = get(changes, 'data.currentValue.metrics');
      this.numberFormat = get(changes, 'data.currentValue.numberFormat');

      this.digits = get(changes, 'data.currentValue.digits', 1);

      if (get(changes, 'data.currentValue.groups')
        && size(get(changes, 'data.currentValue.metrics'))) {
        this.selectList = this.AllMetricsData.map(x => get(x, 'metricName'));
        this.setFormInitialValue();
        set(this.data, 'metrics', this.getGroupsMetrics(get(this.selectedForm, 'value')));

      }

    }
  }

  setFormInitialValue() {
    if (get(this.selectedForm, 'touched')) {
      return;
    }

    this.selectedFormInitialValue = get(this.data, 'multi') ? [first(this.selectList)] : first(this.selectList);
    set(this.selectedForm, 'value', this.selectedFormInitialValue);
  }

  showChartHeaderLoader() {
    this.loading = true;
    this.ref.detectChanges();
  }

  showChartHeaderLoaderTimeout() {
    this.loading = true;
    this.ref.detectChanges();
    setTimeout(() => {
      this.loading = false;
      this.ref.detectChanges();
    }, 400);
  }

  onSelectionChange(event, metricName) {
    if (get(this.data, 'groups')) {
      set(this.selectedForm, 'value', event);
    }

    this.data = {
      categoryId: get(this.data, 'categoryId'),
      metrics: get(this.data, 'groups')
        ? this.getGroupsMetrics(event)
        : null,
      y: [],
      numberFormat: get(this.data, 'numberFormat'),
      metricName: metricName,
      multi: get(this.data, 'multi'),
      groups: get(this.data, 'groups'),
      yMax: get(this.data, 'yMax'),
      yMin: get(this.data, 'yMin'),
      yLimit: get(this.data, 'yLimit'),
      activity: get(this.data, 'activity'),
      newLine: get(this.data, 'newLine'),
    };
  }

  getGroupsMetrics(value) {
    return flatMap(this.AllMetricsData.map(item => {
      return get(item, 'metrics').filter(y => includes(value, get(y, 'metricName'))).map(x => {
        return {
          name: get(x, 'name') + '-' + this.translate.instant('chartSelect.' + get(x, 'metricName')),
          data: get(x, 'data'),
          nodeId: get(x, 'nodeId'),
        };
      });
    }));
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.onFullScreenExit();
  }

  onFullScreen(dataSeries) {
    let str = get(dataSeries, 'metricName');

    if (!isEmpty(get(dataSeries, 'y'))) {
      const yMetrics = get(dataSeries, 'y');
      const yMetricsNames = yMetrics.map(value => {
        return get(first(value), 'metricName');
      });

      str += ',' + yMetricsNames;
    }

    const fullScreenParams = {
      fullscreen: true,
      categoryId: get(dataSeries, 'categoryId'),
      metric: str
    };

    this.segmentTrackService.setAnalyticsTrackToServer('Clicked on full screen chart',
      {email: this.segmentTrackService.getTrackEmail});
    this.globalService.setFullScreen(fullScreenParams);
  }

  onSelectionChanged(range) {
    const data: DatePicker = {
      label: null,
      range: range
    };
    this.nodeViewService.setChartHeaderLoader();
    this.globalService.setGlobalDatePicker(data, true);
  }

  onFullScreenExit() {
    this.globalService.setFullScreen(null);
  }

  onSelectionPercentileChange(value: string, metricName: string) {
    if (value && metricName && !isEqual(value, this.localSelectedPercentiles)) {
      this.localSelectedPercentiles = value;
      // const percentile = {
      //   [metricName]: value
      // };

      this.segmentTrackService.setAnalyticsTrackToServer('Clicked on percentile dropdown',
        {email: this.segmentTrackService.getTrackEmail});
      this.nodeViewService.setPercentiles(metricName, value);
    }

  }

  ngOnDestroy(): void {
  }

}

