import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from './icon-element.consts';
import set from 'lodash-es/set';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';

@Injectable({
  providedIn: 'root'
})
export class IconElementService {

  private readonly images = [
    {
      src: 'https://opster.com/wp-content/uploads/2022/05/no_connection.svg',
      alt: 'no-connection'
    }
  ];

  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer) {
  }

  public init() {
    Object.values(ICONS).map(iconsKey => this.initIcon(iconsKey));
    this.initImages();
  }


  public initIcon(icon: ICONS): void {
    this.iconRegistry.addSvgIcon(icon,
      this.sanitizer.bypassSecurityTrustResourceUrl(
        `../../../../assets/img/svg/${icon}.svg`));
  }

  public initImages() {
    if (get(this.images, 'length')) {
      forEach(this.images, (image) => {
        const noConnectionImg = new Image();
        set(noConnectionImg, 'src', get(image, 'src'));
        set(noConnectionImg, 'alt', get(image, 'alt'));
      });
    }
  }
}
