import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {SlowLogStatus} from '../../../features/slow-logs/slow-logs.enum';
import {Subscription, timer} from 'rxjs';
import { ProgressBarModule } from 'primeng/progressbar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

import ceil from 'lodash-es/ceil';
import get from 'lodash-es/get';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileName;
  @Input() status;
  @Input() size;
  @Input() progress;
  @Output() closeProgressBar = new EventEmitter<any>();

  public slowLogStatus = SlowLogStatus;

  public value = 0;
  private timerSubs;

  constructor(private ref: ChangeDetectorRef) {
    this.timerSubs = new Subscription();
  }

  @HostListener('click', ['$event']) onClick() {
    this.close(false);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    const statusCurrentValue = get(changes, 'status.currentValue');
    const progressCurrentValue = get(changes, 'progress.currentValue');
    const progressPrevValue = get(changes, 'progress.previousValue');
    if (statusCurrentValue === this.slowLogStatus.START) {
      this.value = 1;
    } else if (statusCurrentValue === this.slowLogStatus.ANALYZE) {
      this.timerSubs = timer(15000, 20000)
        .subscribe(() => this.value = this.value < 99.9 ? ceil(this.value + 0.1, 2) : 99.9);
    } else if (progressCurrentValue !== progressPrevValue) {
      this.value = this.progress > this.value ? this.progress : this.value;
    } else {
      this.value = 100;
    }
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    if(this.timerSubs) {
      this.timerSubs.unsubscribe();
    }
  }

  close(onlyClose) {
    this.closeProgressBar.emit(onlyClose);
  }

}


@NgModule({
  declarations: [ProgressBarComponent],
  imports: [
    ProgressBarModule,
    MatIconModule,
    CommonModule,
  ],
  exports: [ProgressBarComponent]
})
export class ProgressBarOpsterModule {
}
