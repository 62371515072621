<div *ngIf="!table && !cardSkeleton && !widgetSkeleton && !cardEventsSkeleton" class="skeleton-loader"
     [ngClass]="{'with-item': withItem}">

  <div>
    <p-skeleton *ngIf="withItem" borderRadius="10px" size="40px" styleClass="p-mr-2 skeleton"></p-skeleton>
  </div>

  <div>
    <p-skeleton width="30%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
  </div>

</div>

<div *ngIf="table" class="skeleton-table">
  <div class="skeleton-table-header">
    <p-skeleton styleClass="p-mb-2 skeleton line"
                *ngFor="let _ of [].constructor(6); let i = index; trackBy: PerformanceUtils.trackByIndex"
                height="50px"></p-skeleton>
  </div>
  <div class="skeleton-table-body">
    <p-skeleton styleClass="p-mb-2 skeleton line"
                *ngFor="let _ of [].constructor(30); let i = index; trackBy: PerformanceUtils.trackByIndex"
                height="30px"></p-skeleton>
  </div>
</div>

<div *ngIf="cardSkeleton" class="skeleton-card">
  <div class="card-body"
       *ngFor="let _ of [].constructor(4); let i = index; trackBy: PerformanceUtils.trackByIndex">
    <p-skeleton width="30%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
  </div>
</div>


<div *ngIf="cardEventsSkeleton" class="skeleton-card">
  <div class="card-body event"
       *ngFor="let _ of [].constructor(3); let i = index; trackBy: PerformanceUtils.trackByIndex">
    <p-skeleton width="30%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
    <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
  </div>
</div>


<div *ngIf="widgetSkeleton" class="skeleton-widget" [class.no-border]="hideBorder">
  <p-skeleton width="80%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
  <p-skeleton width="60%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
  <p-skeleton width="60%" height="12px" styleClass="p-mb-2 skeleton line"></p-skeleton>
</div>



