import { Component, Input, NgModule, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { ToolbarModule } from '../toolbar/toolbar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  @Input() loadingShow = true;
  @Input() loading: boolean;
  @Input() withHeaderSub: boolean;
  @Input() background;

  constructor() {
  }

  ngOnInit() {
  }

}

@NgModule({
  declarations: [PanelComponent],
  imports: [
    MatProgressSpinnerModule,
    MatCardModule,
    ToolbarModule,
    CommonModule

  ],
  exports: [PanelComponent]
})
export class PanelModule {}
