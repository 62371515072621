import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { OpsterChatService } from './service/opster-chat.service';
import get from 'lodash-es/get';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'ops-gpt',
  templateUrl: './ops-gpt.component.html',
  styleUrls: ['./ops-gpt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpsGptComponent implements OnInit {
  public user;
  public conversationId;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private ref: ChangeDetectorRef,
              private opsterChatService: OpsterChatService) {
  }

  ngOnInit(): void {
    this.authService.getCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(r => {
        this.user = r;
        this.ref.markForCheck();
      });

    const params = get(this.activatedRoute, 'snapshot.queryParams');
    this.conversationId = params['conversationId'];

    if (this.conversationId) {
      this.opsterChatService.setConversationId(this.conversationId);
    }
  }

}
