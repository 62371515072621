import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  GET_RANGE,
  GET_RANGE_EVENTS,
  GET_RANGE_NOTIFICATIONS,
  GET_RANGE_NOW,
  GET_RANGE_SMALL
} from '../../../../store/global/helpers';
// @ts-ignore
import moment from 'moment';

import {GlobalService} from '../../../../store/global/global.service';
import {DatePicker} from '../../../../store/global/cluster.interface';
import {EnumArrowDirection, EnumDateTime, EnumTimesValues} from '../../../models/enum';
import {DatePipe} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {OverlayPanel} from 'primeng/overlaypanel';

import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import map from 'lodash-es/map';

const DEFAULT_CUSTOM_RANGE = 'Last 15 minutes';
const DEFAULT_CUSTOM_RANGE_SMALL = 'Last 7 days';
const DEFAULT_CUSTOM_RANGE_SINGLE = 'Now';
const DEFAULT_CUSTOM_RANGE_NOTIFICATIONS = 'Last 24 hours';

// const DEFAULT_CUSTOM_RANGE_EVENTS = 'Last 24 hours';

@Component({
  selector: 'time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeRangeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() timeType: EnumDateTime = EnumDateTime.TIME_RANGE;

  public ranges = [];
  private allRanges = [];
  public selectedDate;
  public isArrowsIconsDisabled = false;

  public customCalendar = false;

  public startDate;
  public endDate;
  public customLimitDate;

  public showArrowButtons = false;
  public showZoomOutButton = false;

  public EnumDateTime = EnumDateTime;
  public selectedDateDisplay;
  public selectedDateTooltip;

  public readonly Arrow_Direction = EnumArrowDirection;
  public readonly Times_Values = EnumTimesValues;
  public readonly Max_Seconds_Disabled = 30;

  private readonly Time_Single_Diff = 1;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(OverlayPanel) overlayPanel: OverlayPanel;

  constructor(private globalService: GlobalService,
              private cd: ChangeDetectorRef,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, 'timeType')) {

      this.destroy$.next(true);
      this.destroy$.unsubscribe();
      this.destroy$ = new Subject<boolean>();

      switch (this.timeType) {
        case this.EnumDateTime.TIME_RANGE:
          this.showArrowButtons = true;
          this.showZoomOutButton = true;
          this.getTimeRange();
          break;

        case this.EnumDateTime.TIME_SINGLE:
          this.showArrowButtons = true;
          this.showZoomOutButton = false;
          this.getTimeSingle();
          break;

        case this.EnumDateTime.TIME_RANGE_SMALL:
          this.showArrowButtons = false;
          this.showZoomOutButton = false;
          this.getTimeRangeSmall();
          break;

        case this.EnumDateTime.TIME_RANGE_EVENTS:
          this.showArrowButtons = false;
          this.showZoomOutButton = false;
          this.getTimeRangeEvent();
          break;

        case this.EnumDateTime.TIME_RANGE_NOTIFICATIONS:
          this.showArrowButtons = false;
          this.showZoomOutButton = false;
          this.getTimeRangeNotifications();
          break;

      }
    }

  }

  getTimeRangeSmall() {
    this.globalService.getGlobalDatePickerSmall()
      .pipe(takeUntil(this.destroy$)).subscribe(datePicker => {
      this.allRanges = GET_RANGE_SMALL(null);
      // @ts-ignore
      this.ranges = map(this.allRanges, (x, y) => {
        return {name: y, code: null};
      });

      const label = get(datePicker, 'label');
      this.selectedDate = {name: label, code: null};
      this.customCalendar = false;

      this.buildSelectedDate();
      // this.buildSelectedTooltip();

    });


  }

  getTimeRange() {
    this.globalService.getGlobalDatePicker()
      .pipe(takeUntil(this.destroy$)).subscribe(datePicker => {
      // Set ranges
      this.allRanges = GET_RANGE(null);
      // @ts-ignore
      this.ranges = map(this.allRanges, (x, y) => {
        return {name: y, code: null};
      });
      this.ranges.push({name: 'Custom range', code: 0});


      const label = get(datePicker, 'label');
      this.customCalendar = !label;

      if (this.customCalendar) {
        const date = get(datePicker, 'range');
        this.selectedDate = {name: 'Custom range', code: 0};
        this.startDate = moment(get(date, 'startDate')).toDate();
        this.endDate = moment(get(date, 'endDate')).toDate();

      } else {
        this.selectedDate = {name: label, code: null};
      }

      this.buildSelectedDate();
      // this.buildSelectedTooltip();

    });
  }

  getTimeSingle() {
    this.globalService.getSingleDatePicker()
      .pipe(takeUntil(this.destroy$)).subscribe(datePicker => {
      // Set ranges
      this.allRanges = GET_RANGE_NOW(null);
      // @ts-ignore
      this.ranges = map(this.allRanges, (x, y) => {
        return {name: y, code: null};
      });
      this.ranges.push({name: 'Custom range', code: 0});

      const label = get(datePicker, 'label');
      this.customCalendar = !label;

      if (this.customCalendar) {
        const date = get(datePicker, 'range');
        this.selectedDate = {name: 'Custom range', code: 0};
        this.endDate = moment(get(date, 'endDate')).toDate();

      } else {
        this.selectedDate = {name: label, code: null};
      }

      this.buildSelectedDate();
      // this.buildSelectedTooltip();

    });
  }

  getTimeRangeEvent() {
    this.globalService.getEventsDatePicker()
      .pipe(takeUntil(this.destroy$)).subscribe(datePicker => {
      // Set ranges
      this.allRanges = GET_RANGE_EVENTS(null);
      // @ts-ignore
      this.ranges = map(this.allRanges, (x, y) => {
        return {name: y, code: null};
      });

      const label = get(datePicker, 'label');
      this.customCalendar = !label;

      if (this.customCalendar) {
        const date = get(datePicker, 'range');
        this.selectedDate = {name: 'Custom range', code: 0};
        this.startDate = moment(get(date, 'startDate')).toDate();
        this.endDate = moment(get(date, 'endDate')).toDate();

      } else {
        this.selectedDate = {name: label, code: null};
      }

      this.buildSelectedDate();

    });
  }

  getTimeRangeNotifications() {
    this.globalService.getNotificationsDatePicker()
      .pipe(takeUntil(this.destroy$)).subscribe(datePicker => {
      // Set ranges
      this.allRanges = GET_RANGE_NOTIFICATIONS(null);
      // @ts-ignore
      this.ranges = map(this.allRanges, (x, y) => {
        return {name: y, code: null};
      });
      this.ranges.push({name: 'Custom range', code: 0});

      const label = get(datePicker, 'label');
      this.customCalendar = !label;
      this.customLimitDate = moment().subtract(2, 'months').toDate();

      if (this.customCalendar) {
        const date = get(datePicker, 'range');
        this.selectedDate = {name: 'Custom range', code: 0};
        this.startDate = moment(get(date, 'startDate')).toDate();
        this.endDate = moment(get(date, 'endDate')).toDate();

      } else {
        this.selectedDate = {name: label, code: null};
      }

      this.buildSelectedDate();

    });
  }

  onApply() {
    if (!this.selectedDate && !this.startDate && !this.endDate) {
      return;
    }

    this.buildSelectedDate();
    // this.buildSelectedTooltip();

    const isCustomCalendar = get(this.selectedDate, 'code') === 0;
    let data: DatePicker;

    const [startDate, endDate] = isCustomCalendar ? [this.startDate, this.endDate] :
      this.allRanges[get(this.selectedDate, 'name')];
    switch (this.timeType) {
      case this.EnumDateTime.TIME_RANGE:
        data = {
          label: isCustomCalendar ? null : get(this.selectedDate, 'name'),
          range: isCustomCalendar ? {startDate: this.startDate, endDate: this.endDate} : null
        };
        this.isArrowsIconsDisabled = moment(endDate)
          .diff(moment(startDate), 'seconds') < this.Max_Seconds_Disabled;
        this.globalService.setGlobalDatePicker(data, true);
        break;

      case this.EnumDateTime.TIME_SINGLE:
        data = {
          label: isCustomCalendar ? null : get(this.selectedDate, 'name'),
          range: isCustomCalendar ? {endDate: this.endDate} : null
        };
        this.isArrowsIconsDisabled = moment().diff(moment(endDate), 'seconds') < this.Max_Seconds_Disabled;
        this.globalService.setSingleDatePicker(data, true, !isEqual(get(data, 'label'), DEFAULT_CUSTOM_RANGE_SINGLE), isCustomCalendar);
        break;

      case this.EnumDateTime.TIME_RANGE_SMALL:
        data = {
          label: isCustomCalendar ? null : get(this.selectedDate, 'name'),
          range: isCustomCalendar ? {startDate: this.startDate, endDate: this.endDate} : null
        };
        this.globalService.setGlobalDatePickerSmall(data);
        break;

      case this.EnumDateTime.TIME_RANGE_EVENTS:
        data = {
          label: isCustomCalendar ? null : get(this.selectedDate, 'name'),
          range: isCustomCalendar ? {startDate: this.startDate, endDate: this.endDate} : null
        };
        this.isArrowsIconsDisabled = moment(endDate)
          .diff(moment(startDate), 'seconds') < this.Max_Seconds_Disabled;
        this.globalService.setEventsDatePicker(data, true);
        break;

      case this.EnumDateTime.TIME_RANGE_NOTIFICATIONS:
        data = {
          label: isCustomCalendar ? null : get(this.selectedDate, 'name'),
          range: isCustomCalendar ? {startDate: this.startDate, endDate: this.endDate} : null
        };
        this.isArrowsIconsDisabled = moment(endDate)
          .diff(moment(startDate), 'seconds') < this.Max_Seconds_Disabled;
        this.globalService.setNotificationsDatePicker(data, true);
        break;
    }

    this.overlayPanel.hide();
    this.cd.detectChanges();
  }

  getUpdatedRanges(value) {
    switch (this.timeType) {
      case this.EnumDateTime.TIME_RANGE:
        return GET_RANGE(value[0]);
      case this.EnumDateTime.TIME_SINGLE:
        return GET_RANGE_NOW(value[0]);
      case this.EnumDateTime.TIME_RANGE_SMALL:
        return GET_RANGE_SMALL(value[0]);
      case this.EnumDateTime.TIME_RANGE_EVENTS:
        return GET_RANGE_EVENTS(value[0]);
    }
  }

  onClickRangeArrow(buttonType) {
    const times = this.customCalendar ?
      [moment(this.startDate), moment(this.endDate)] :
      this.allRanges[get(this.selectedDate, 'name')];
    const [startDate, endDate] = times;

    const diffTimeInMinutes = this.timeType === this.EnumDateTime.TIME_RANGE ?
      endDate.diff(startDate, 'minutes') :
      moment().diff(endDate, 'minutes');
    const timeToUpdate = this.timeType === this.EnumDateTime.TIME_RANGE ? Math.abs(diffTimeInMinutes / 2) : this.Time_Single_Diff;

    switch (buttonType) {
      case this.Arrow_Direction.LEFT:
        this.onClickLeftArrow(startDate, endDate, timeToUpdate);
        break;
      case this.Arrow_Direction.RIGHT:
        this.onClickRightArrow(startDate, endDate, timeToUpdate);
        break;
      case this.Arrow_Direction.ZOOM_OUT:
        this.onClickZoomOut(startDate, endDate, timeToUpdate);
        break;
    }

    this.selectedDate = {name: 'Custom range', code: 0};
    this.onApply();
  }

  onClickLeftArrow(startDate, endDate, timeToUpdate) {
    const startDateSubs = startDate.subtract(timeToUpdate, 'minutes');
    const endDateSubs = endDate.subtract(timeToUpdate, 'minutes');
    this.startDate = startDateSubs.toDate();
    this.endDate = endDateSubs.toDate();
  }

  onClickRightArrow(startDate, endDate, timeToUpdate) {
    const startDateSubs = startDate.add(timeToUpdate, 'minutes');
    const endDateSubs = endDate.add(timeToUpdate, 'minutes');
    this.startDate = startDateSubs.toDate();
    this.endDate = endDateSubs.toDate();
  }

  onClickZoomOut(startDate, endDate, timeToUpdate) {
    const startDateSubs = startDate.subtract(timeToUpdate, 'minutes');
    const endDateSubs = endDate.add(timeToUpdate, 'minutes');
    this.startDate = startDateSubs.toDate();
    this.endDate = endDateSubs.toDate();
  }

  buildSelectedDate() {
    this.selectedDateDisplay = this.customCalendar ?
      (this.timeType === EnumDateTime.TIME_SINGLE ?
        this.datePipe.transform(this.endDate, 'd MMM y hh:mm a') :
        this.datePipe.transform(this.startDate, 'd MMM y hh:mm a') + '-' + this.datePipe.transform(this.endDate, 'd MMM y hh:mm a'))
      : get(this.selectedDate, 'name');

    this.cd.detectChanges();
  }

  buildSelectedTooltip() {
    const times = this.customCalendar ?
      {startDate: moment(this.startDate), endDate: moment(this.endDate)} :
      this.getUpdatedRanges([get(this.selectedDate, 'name')]);
    const {startDate, endDate} = times;

    this.selectedDateTooltip = this.timeType === EnumDateTime.TIME_SINGLE ?
      this.datePipe.transform(endDate, 'd MMM y hh:mm a') :
      `<div class="range-date">${this.datePipe.transform(startDate, 'd MMM y hh:mm a')}
      to
      ${this.datePipe.transform(endDate, 'd MMM y hh:mm a')}</div>`;
  }

  disabledTooltip() {
    this.selectedDateTooltip = null;
  }

  rangeSelected(val) {
    if (get(val, 'value.code') === 0) {
      this.customCalendar = true;
      let range;

      switch (this.timeType) {
        case this.EnumDateTime.TIME_RANGE:
          range = GET_RANGE(DEFAULT_CUSTOM_RANGE);
          this.startDate = get(range, 'startDate').toDate();
          break;

        case this.EnumDateTime.TIME_SINGLE:
          range = GET_RANGE_NOW(DEFAULT_CUSTOM_RANGE_SINGLE);
          break;

        case this.EnumDateTime.TIME_RANGE_SMALL:
          range = GET_RANGE_SMALL(DEFAULT_CUSTOM_RANGE_SMALL);
          this.startDate = get(range, 'startDate').toDate();
          break;

        case this.EnumDateTime.TIME_RANGE_NOTIFICATIONS:
          range = GET_RANGE_NOTIFICATIONS(DEFAULT_CUSTOM_RANGE_NOTIFICATIONS);
          this.startDate = get(range, 'startDate').toDate();
          break;
      }

      this.endDate = get(range, 'endDate').toDate();

    } else {
      this.customCalendar = false;
      this.onApply();
      this.overlayPanel.hide();
      this.cd.detectChanges();
    }

  }

  toggle(event) {
    this.overlayPanel.toggle(event);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }


}
