<p-dropdown #dropdown [options]=" items"
            [ngModel]="selectedItem"
            [optionLabel]="label"
            [styleClass]="styleClass + (disabled ? ' disabled' : '')"
            [filter]="type !== InputSelectTypes.REFRESH" [filterBy]="'filteredFields'"
            [dropdownIcon]="'arrow_down'"
            [emptyFilterMessage]="'No Results Found'"
            [placeholder]="placeholder"
            (onShow)="onPanelShow()"
            [group]=" isGroup"
            (onChange)="onSelectionChange($event)">

  <ng-template pTemplate="header">

    <div *ngIf="tabViewNames?.length">
      <p-tabView [styleClass]="'dropdown-tabView'" (onChange)="onChangeTabView($event)"
                 [activeIndex]="this.isSecondTabSelected ? 1: 0">
        <p-tabPanel *ngFor="let item of tabViewNames; trackBy: PerformanceUtils.trackByIndex" [header]="item">
        </p-tabPanel>
      </p-tabView>
    </div>

  </ng-template>

  <ng-template pTemplate="selectedItem">
    <div class="item-value">

      <div *ngIf="nameItem">
        <span>{{nameItem}}</span>
        <span class="small-spacer-border"></span>
      </div>
      <span class="selected-item"
            [pTooltip]="selectedItem?.tooltip"
            [showDelay]="500"
            [innerHTML]="selectedItem?.displayedName"></span>
    </div>
  </ng-template>

  <ng-template let-item pTemplate="item">
    <span class="item-list"
          [class.wrap-text]="type === InputSelectTypes.CLUSTERS"
          [pTooltip]="item?.tooltip"
          [showDelay]="500"
          [tooltipPosition]="tooltipPosition"
          [innerHTML]="item?.displayedName"> </span>
  </ng-template>

</p-dropdown>

