import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ChartCoreOpster} from '../chart/chart-core';
import {ChartTypesEnum} from '../chart/chart.enum';
import * as Highcharts from 'highcharts';
import {ChartEventsOptions, PointClickEventObject, SeriesEventsOptionsObject} from 'highcharts';
import {combineLatest, fromEvent, of, Subject} from 'rxjs';
import {ChartFormatterThis} from '../chart/chart.interface';
import {debounceTime, distinctUntilChanged, share, takeUntil} from 'rxjs/operators';
import {NumberFormatEnum} from '../../models/number-format.enum';
import {NodeViewService} from '../../../features/node-view/service/node-view.service';
import {IntersectionStatus} from '../../directives/from-intersection-observer';
import {GlobalService} from '../../../store/global/global.service';
import {isEmptyArray} from '../../services/utils/functional-utils';
import moment from 'moment';
import {NumberFormatPipe} from '../../pipe/number-format/short-number-suffix-pipe.pipe';

import nth from 'lodash-es/nth';
import set from 'lodash-es/set';
import map from 'lodash-es/map';
import last from 'lodash-es/last';
import isEqual from 'lodash-es/isEqual';
import includes from 'lodash-es/includes';
import first from 'lodash-es/first';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import concat from 'lodash-es/concat';
import cloneDeep from 'lodash-es/cloneDeep';
import assign from 'lodash-es/assign';
import size from 'lodash-es/size';

@Component({
  selector: 'line-crosshair-chart',
  templateUrl: '../chart/chart.component.html',
  styleUrls: ['./line-crosshair-chart.component.scss', '../chart/chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineCrosshairChartComponent extends ChartCoreOpster implements OnInit, OnChanges, OnDestroy {
  type = ChartTypesEnum.Spline;
  @Input() data: any = [];
  @Input() numberFormat = NumberFormatEnum.NUMBER;
  @Input() digits = 1;
  @Input() cursor = false;
  @Input() legend = true;
  @Input() legendRight = false;
  @Input() isFullScreen = false;
  @Input() selection = true;
  @Input() crosshair = true;
  @Input() yLineFullName = true;
  @Input() animationCharts;
  @Input() clusterId;
  @Input() tooltipOutside = false;

  @Output() selectionChanged = new EventEmitter();
  @Output() emitLoaderTimeout = new EventEmitter();

  public singleNode;
  public MetricsVisibilityMap;
  public isVisible: boolean;
  public dataChart: any = [];
  public dataChartMetrics: any = [];
  public currentHoveredSeriesNodeId;

  private destroy$: Subject<boolean> = new Subject<boolean>();


  public formatter = ((component) => {
    return function () {
      return component.tooltipFormatter(this);
    };
  })(this);


  constructor(public cdr: ChangeDetectorRef, public zone: NgZone, private numberFormatPipe: NumberFormatPipe,
              private nodeViewService: NodeViewService, private globalService: GlobalService
  ) {
    super(cdr, zone);
  }

  ngOnInit() {
    const displayedNode$ = this.nodeViewService.getDisplayedNodes();
    const visibleMetrics$ = this.nodeViewService.getMetricVisibility();

    combineLatest([displayedNode$, visibleMetrics$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([nodeId, metricMap]) => {
        this.isVisible = isEqual(metricMap.get(get(this.data, 'metricName')), IntersectionStatus.Visible);
        this.MetricsVisibilityMap = metricMap;

        if (!isEqual(this.singleNode, nodeId) && this.isVisible) {
          this.emitLoaderTimeout.next(true);
          this.singleNode = nodeId;
          this.toggleSingleNode();
          this.updateOptions();
          this.applyOnChange();
        }
      });

    this.globalService.getSelectedCluster()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clusterId => {
        if (!isEqual(this.clusterId, clusterId)) {
          this.nodeViewService.setDisplayedNodes(null);
        }
      });

    this.nodeViewService.getSelectedNodes()
      .subscribe(nodes => {
        if (!this.singleNode) {
          return;
        }

        const nodeInService = this.nodeViewService.getDisplayedNodesValue();
        if (!includes(nodes, nodeInService)) {
          this.nodeViewService.setDisplayedNodes(null);
        }
      });
  }

  ngOnChanges(changes): void {
    if (get(changes, 'data.currentValue')) {
      this.dataChart = assign(this.data);
      this.dataChartMetrics = assign(get(this.dataChart, 'metrics'));
      // this.createFixedColorPalette();
      this.maintainSingleNodeView();
      this.updateDatePicker();
      this.addPlotLines();
      this.updateOptions();
      this.applyOnChange();
    }
  }

  createFixedColorPalette() {
    if (isEmptyArray(this.dataChartMetrics)) {
      return;
    }
    const fixedColorCodes = [];

    this.dataChartMetrics.map(metric => {
      const stringHexNumber = (
        // tslint:disable-next-line:no-bitwise
        parseInt(
          parseInt(get(metric, 'nodeId'), 36)
            .toExponential()
            .slice(2, -5)
          , 10) & 0xFFFFFF
      ).toString(16).toUpperCase();
      fixedColorCodes.push('#' + ('000000' + stringHexNumber).slice(-6));
    });

    this.colorPalette = fixedColorCodes;
  }

  maintainSingleNodeView() {
    if (!this.singleNode) {
      return;
    }

    this.toggleSingleNode();
    this.updateOptions();
    this.applyOnChange();
  }

  syncronizeCrossHairs(chart) {
    fromEvent(chart.container, 'mousemove')
      .pipe(distinctUntilChanged(),
        debounceTime(50),
        takeUntil(this.destroy$),
        share())
      .subscribe(event => {
        forEach(Highcharts.charts.filter(item => item), (cht: any) => {
          const metric = get(cht, 'title.textStr');
          if (isEqual(this.MetricsVisibilityMap.get(metric), IntersectionStatus.NotVisible)) {
            return;
          }

          this.addSeriesLegend(cht, event);
        });
      });
  }

  syncronizeCrossHairsPoint(point) {
    const target = get(point, 'target');
    const chart = get(target, 'series.chart');
    const xAxis: any = first(chart.xAxis);

    let xAxis1;
    forEach(Highcharts.charts.filter(item => item), (cht: any) => {
      const metric = get(cht, 'title.textStr');
      if (isEqual(this.MetricsVisibilityMap.get(metric), IntersectionStatus.NotVisible)) {
        return;
      }

      xAxis1 = first(cht.xAxis);
      try {
        xAxis1.removePlotLine('myPlotLineId');

        xAxis1.addPlotLine({
          value: get(chart, 'plotLeft') + xAxis.translate(get(target, 'plotX'), true) + 1,
          width: 1,
          color: 'var(--crosshair-color)',
          id: 'myPlotLineId'
        });
      } catch (e) {

      }
    });
  }

  addSeriesLegend(cht, evt) {
    if (get(cht, 'series')) {
      forEach(get(cht, 'series'), series => {
        const point: any = series.searchPoint(evt, true);
        if (!point) {
          return;
        }
        const legend = get(series, 'legendItem');
        const element = get(legend, 'line.element');
        if (!element) {
          return;
        }

        const pointY = this.numberFormatPipe.transform(get(point, 'y'),
          cht.options.chartNumberFormat.numberFormat, cht.options.chartNumberFormat.digits);


        get(series, 'legendItem.label').attr('text', `${get(series, 'name')} ${pointY}`);

      });
    }
  }

  toggleSingleNode() {
    const metrics = forEach(this.dataChartMetrics, data =>
      set(data, 'visible', !this.singleNode
        ? true
        : this.singleNode === get(data, 'nodeId')));
    this.dataChartMetrics = cloneDeep(metrics);
  }

  addPlotLines() {
    if (!this.dataChartMetrics || !get(this.dataChart, 'y')) {
      return;
    }
    const yline = get(this.dataChart, 'y');
    const metricName = get(this.dataChart, 'metricName');


    const newPlotLines: any = map(yline, item => {
      return map(item,
        plotLine => {
          const allDataPlotLines = this.buildDataPlotLines(
            this.datePickerMin ? this.datePickerMin : first(first(plotLine.data)),
            this.datePickerMax ? this.datePickerMax : first(last(plotLine.data)),
            nth(first(get(plotLine, 'data')), 1), 20);

          return {
            type: 'line',
            name: (this.yLineFullName && metricName ? get(plotLine, 'metricNameText') + ' - ' : '') + get(plotLine, 'name'),
            dashStyle: 'longdash',
            showInLegend: this.legend,
            lineWidth: 1,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            data: allDataPlotLines
          };
        }
      );
    });

    const metrics = forEach(this.dataChartMetrics, data => set(data, 'dashStyle', 'Solid'));
    this.dataChartMetrics = concat(metrics, ...newPlotLines);
  }

  buildDataPlotLines(startDate, endDate, data, steps) {
    const diffStep = (endDate - startDate) / steps;
    const arraySteps = [];

    arraySteps.push({x: startDate, y: data});
    for (let i = 1; i <= steps - 1; i++) {
      arraySteps.push({x: startDate + (diffStep * i), y: data});
    }
    arraySteps.push({x: endDate, y: data});

    return arraySteps;
  }

  tooltipFormatter(chartThis: ChartFormatterThis) {
    const date = moment(get(chartThis, 'key')).format(this.tooltipDateFormat);
    const numberFormat = this.numberFormat;
    const digits = this.digits;
    const perc = this.numberFormatPipe.transform(get(chartThis, 'y'), numberFormat, digits);
    const name = get(get(chartThis, 'series'), 'name');
    this.currentHoveredSeriesNodeId = get(chartThis, 'series.userOptions.nodeId');
    const pointColor = get(get(chartThis, 'point'), 'color');
    if (numberFormat === NumberFormatEnum.PRECENT) {
      return `<span style="color:${pointColor}">${name}</span>:<br>
        ${perc}<br><br>
        ${date}`;
    } else {
      return `<span style="color:${pointColor}">${name}</span>:<br>
                ${perc}<br><br>
                ${date}`;
    }
  }

  handleClickEvent(nodeId, legendClicked = false) {
    this.zone.run(() => {
      if (this.singleNode === nodeId) {
        this.nodeViewService.setDisplayedNodes(null);
      } else if (this.currentHoveredSeriesNodeId === nodeId || legendClicked) {
        this.nodeViewService.setDisplayedNodes(nodeId);
      } else {
        this.nodeViewService.setDisplayedNodes(this.currentHoveredSeriesNodeId);
      }
    });
  }

  updateOptions() {
    const events: ChartEventsOptions = {};
    if (this.crosshair) {
      events.load = (event: any) => {
        const target = get(event, 'target');
        if (get(target, 'series')) {
          this.syncronizeCrossHairs(get(event, 'target'));
        }
      };


    }
    if (this.selection) {
      events.selection = event => {
        const range = {
          startDate: moment(get(first(event.xAxis), 'min')),
          endDate: moment(get(first(event.xAxis), 'max'))
        };
        this.nodeViewService.setChartHeaderLoader();
        this.selectionChanged.emit(range);

        return false;
      };
    }


    const eventsPlotOptions: SeriesEventsOptionsObject = {};
    eventsPlotOptions.legendItemClick =
      event => {
        const target = get(event, 'target');
        const nodeId = get(target, 'userOptions.nodeId');
        this.handleClickEvent(nodeId, true);
        return false;
      };

    // eventsPlotOptions.click =
    //   (event: SeriesClickEventObject) => {
    //     const target = get(event, 'point.series');
    //     const nodeId = get(target, 'userOptions.nodeId');
    //     this.nodeViewService.setChartHeaderLoader();
    //     this.handleClickEvent(nodeId);
    //     return false;
    //   };


    let plotLinesLimit = [];
    let legendTitle: Highcharts.LegendTitleOptions = {};
    if (get(this.dataChart, 'yLimit')) {
      plotLinesLimit = [{
        value: get(this.dataChart, 'yLimit.value'),
        color: '#EE6540',
        dashStyle: 'shortdash',
        zIndex: 1,
        width: 3,
      }];

      const text = get(this.dataChart, 'yLimit.text');
      const tooltip = get(this.dataChart, 'yLimit.tooltip');
      const color = get(this.dataChart, 'yLimit.color');

      legendTitle = {
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          color: 'var(--main-text)',
        },
        text: `<span style="display: flex; align-items: center; gap: 5px; transform: translateY(3px); cursor: default">
                <span style="width: 16px; border-top: dashed 3px ${color};"></span>
                <span style="opacity: 0.8;" >${text} - ${tooltip}</span>
               </span>`
      };
    }


    this.chartOptions = {
      // @ts-ignore
      chartNumberFormat: {numberFormat: this.numberFormat, digits: this.digits},
      chart: {
        zooming: {
          type: this.selection ? 'x' : null
        },
        animation: false,
        // height: this.isFullScreen ? 720 : this.height,
        events: events,
      },
      title: {
        style: {
          display: 'none'
        }
      },
      accessibility: {
        announceNewData: {
          enabled: true,
          minAnnounceInterval: 15000,
        }
      },
      tooltip: {
        formatter: this.formatter,
        outside: this.tooltipOutside,
        style: {
          fontSize: '13px',
        },
      },
      legend: {
        title: legendTitle,
        align: this.legendRight ? 'right' : 'center',
        useHTML: !this.legendRight,
        width: this.legendRight ? 'auto' : '100%',
        y: this.legendRight ? -25 : null,
        x: this.legendRight ? -30 : null,
        verticalAlign: this.legendRight ? 'top' : 'bottom',
        maxHeight: this.legendRight ? 400 : 150,
        margin: this.legendRight ? 42 : 12,
      },
      xAxis: {
        // height: this.isFullScreen ? null : (this.height - 50),
        type: 'datetime',
        min: this.datePickerMin,
        max: this.datePickerMax,
        labels: {
          style: {
            color: 'var(--chart-unit)',
            fontSize: '0.65rem',
          },
        },
      },
      // @ts-ignore
      yAxis: {
        // height: this.isFullScreen ? null : (this.height - 50),
        className: 'chart-yAxis',
        plotLines: plotLinesLimit,
        min: get(this.dataChart, 'yMin'),
        max: get(this.dataChart, 'yMax'),
        softMax: get(this.dataChart, 'ySoftMax'),
        // @ts-ignore
        tickPositioner: (min, max) => {
          if (min === max) {
            return min === 0 ?
              [min - 1, min - 0.5, min, min + 0.5, min + 1] :
              [min - (min * 0.1), min - (min * 0.05), min, min + (min * 0.05), min + (min * 0.1)];
          }
        },
        labels: {
          // @ts-ignore
          formatter: (event: any) => {
            const numberFormat = this.numberFormat;
            const digits = this.digits;

            return this.numberFormatPipe.transform(get(event, 'value'), numberFormat, digits);
          },
          style: {
            color: 'var(--chart-unit)',
            fontSize: '0.65rem',
          },
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        },
        series: {
          cursor: this.cursor ? 'pointer' : 'default',
          point: {
            events: {
              mouseOver: (event: any) => {
                of(event).pipe(
                  distinctUntilChanged(),
                  debounceTime(50),
                  takeUntil(this.destroy$),
                  share())
                  .subscribe(evt => {
                    this.syncronizeCrossHairsPoint(evt);
                  });
              },
              click: (event: PointClickEventObject) => {
                const target = get(event, 'point.series');
                const nodeId = get(target, 'userOptions.nodeId');
                this.handleClickEvent(nodeId);
              },
            }
          },
          events: eventsPlotOptions,
          allowPointSelect: false,
          animation: this.animationCharts,
          marker: {
            enabled: isEqual(size(get(first(this.dataChartMetrics), 'data')), 1),
            states: {
              select: {
                enabled: false
              }
            }
          },
          states: {
            inactive: {
              opacity: 0.2
            }
          },
        }
      },
      series: this.dataChartMetrics,
    };
  }

  ngOnDestroy() {
    if (this.highChartRef) {
      this.highChartRef.destroy();
      this.highChartRef = null;
    }

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}
