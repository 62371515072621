import { Directive, EventEmitter, HostBinding, HostListener, NgModule, Output } from '@angular/core';
import size from 'lodash-es/size';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (size(files) > 0) {
      this.fileDropped.emit(files);
    }
  }

}

@NgModule({
  declarations: [DndDirective],
  imports: [
  ],
  exports: [DndDirective]
})
export class DndDirectiveModule {
}
