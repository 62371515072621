// import 'hammerjs';
 import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

const SENTRY_DSN = 'https://e7bcbe2149de4fb3a4b41ee28a6bd655@o471786.ingest.sentry.io/5504258';
const SENTRY_RELEASE = 'prod-sentry';

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/autoops\.opster\.com/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


enableProdMode();


platformBrowserDynamic().bootstrapModule(AppModule, {ngZoneEventCoalescing: true})
  .catch(err => console.log(err));
