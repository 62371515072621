import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Nodes } from '../node-list/node-list.interface';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import split from 'lodash-es/split';
import map from 'lodash-es/map';
import keyBy from 'lodash-es/keyBy';
import join from 'lodash-es/join';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import compact from 'lodash-es/compact';

import { IntersectionStatus } from '../../../shared/directives/from-intersection-observer';
import groupBy from 'lodash-es/groupBy';
import valuesIn from 'lodash-es/valuesIn';
import flatten from 'lodash-es/flatten';
import sortBy from 'lodash-es/sortBy';
import take from 'lodash-es/take';

const LIMIT_NODES = 40;

@Injectable({
  providedIn: 'root'
})
export class NodeViewService {
  private selectedNodes$: BehaviorSubject<string>;
  private nodesList$: BehaviorSubject<Nodes[]>;
  private displayedNodes$: BehaviorSubject<string>;
  private headerLoader$: Subject<boolean>;
  private percentiles$: BehaviorSubject<any>;
  private readonly visibleMetricsMap;
  private visibleMetrics: BehaviorSubject<Map<string, IntersectionStatus>>;


  constructor(public httpClient: HttpClient, private router: Router) {
    this.selectedNodes$ = new BehaviorSubject<string>(null);
    this.headerLoader$ = new Subject<boolean>();
    this.percentiles$ = new BehaviorSubject<any>(null);
    this.nodesList$ = new BehaviorSubject<Nodes[]>(null);
    this.displayedNodes$ = new BehaviorSubject<string>(null);
    this.visibleMetricsMap = new Map<string, IntersectionStatus>([]);
    this.visibleMetrics = new BehaviorSubject<Map<string, IntersectionStatus>>(this.visibleMetricsMap);
  }

  private static selectedNodesFormat(nodes: any[]) {
    const valuesNodes = map(nodes, 'id');
    return join(valuesNodes, ',');
  }

  public nodesListFromServer(clusterId: string, searchNodes: string, from: number, to: number)
    : void {
    let url = `api/monitoring/clusters/${clusterId}/nodes?from=${from}&to=${to}`;
    url = searchNodes ? url + `&search=${searchNodes}` : url;
    this.httpClient.get<any>(url, {params: {skipOpsterError: true}}).pipe(first()).subscribe((data) => {

      const groupByNodes = flatten(valuesIn(map(groupBy(data, 'roles'), nodes => sortBy(nodes, 'name'))));
      this.nodesList$.next(groupByNodes);

      const selectedNodes = this.selectedNodes$.value;
      const limitGroupByNodes = selectedNodes ? groupByNodes : take(groupByNodes, LIMIT_NODES);
      const valuesNodes = keyBy(limitGroupByNodes, 'id');
      const newSelectedNodes = selectedNodes ? compact(map(split(selectedNodes, ','), x => valuesNodes[x])) : null;

      if (isEmpty(newSelectedNodes)) {
        this.setSelectedNodes(NodeViewService.selectedNodesFormat(limitGroupByNodes));
      }
    });
  }

  public getNodesList(): Observable<Nodes[]> {
    return this.nodesList$.asObservable();
  }

  // SelectedNodes
  public setSelectedNodes(nodesIds: string, addToUrl = true) {
    if (isEqual(this.selectedNodes$.value, nodesIds)) {
      return;
    }
    if (addToUrl) {
      if (nodesIds !== '') {
        this.router.navigate(
          [],
          {
            queryParams: {nodes: nodesIds},
            queryParamsHandling: 'merge'
          }).then(() => {
        });
      }

      this.selectedNodes$.next(nodesIds);
    }
  }

  public getSelectedNodes(): Observable<string> {
    return this.selectedNodes$.asObservable();
  }

  // Displayed Nodes
  public setDisplayedNodes(nodesId) {
    this.displayedNodes$.next(nodesId);
  }

  public getDisplayedNodes(): Observable<string> {
    return this.displayedNodes$.asObservable();
  }

  public getDisplayedNodesValue() {
    return this.displayedNodes$.value;
  }

  // Visible Nodes
  public setMetricVisibility(metricName: string, isVisible: IntersectionStatus) {
    this.visibleMetricsMap.set(metricName, isVisible);
    this.visibleMetrics.next(this.visibleMetricsMap);
  }

  public getMetricVisibility() {
    return this.visibleMetrics.asObservable();
  }

  public removeMetricFromVisibilityList(metricName) {
    this.visibleMetricsMap.delete(metricName);
  }

  // Header Loader
  public setChartHeaderLoader() {
    this.headerLoader$.next(true);
  }

  public getChartHeaderLoader() {
    return this.headerLoader$.asObservable();
  }

  // Percentiles
  public setPercentiles(metricName, value?) {
    if (metricName && value) {
      const mar = this.percentiles$.getValue() || {};
      mar[metricName] = value;
      this.percentiles$.next(mar);
    } else {
      this.percentiles$.next(null);

    }

  }

  public getPercentiles() {
    return this.percentiles$.asObservable();
  }

}
