<p-multiSelect #multiSelectRef [panelStyleClass]="'opster-multiSelect-group-panel'"
               [options]="listGrouping"
               [group]="isGroup"
               [appendTo]="isGpt ? multiSelectRef : null"
               [(ngModel)]="displayedSelectedItems"
               [maxSelectedLabels]="1"
               [selectionLimit]="selectionLimit"
               [disabled]="disabled"
               [emptyFilterMessage]="'No Results Found'"
               (onPanelHide)="onPanelHide()"
               (onChange)="changeCheckBoxItem($event)"
               (onPanelShow)="onPanelShow()"
               (ngModelChange)="changeNgModel()"
               [placeholder]="'placeholder'"
               [styleClass]="styleClass"
               [dropdownIcon]="'arrow_down'"
               scrollHeight="250px"
               [filterMatchMode]="filterMatchMode">
  <!--               [styleClass]="quick ? (isGpt ? 'p-multiSelect-group gpt' : 'p-multiSelect-group quick' ): 'p-multiSelect-group'"-->

  <ng-template pTemplate="selectedItems">
    <div class="item-value" *ngIf="listGrouping"
         [pTooltip]="displayedSelectedItemsCount ? tooltipText : 'No items selected'"
         [tooltipDisabled]="multiSelectRef?.overlayVisible"
         [tooltipPosition]="'bottom'"
         [appendTo]="'body'"
         [escape]="false"
         [showDelay]="500"
         [tooltipStyleClass]="'multiselect-tooltip'">
      <span *ngIf="!isSmall">{{nameItem | titlecase}}</span>
      <ng-container *ngIf="(nameItem === 'Events' && !isClearAll) || displayedSelectedItems?.length > 1; else itemName">

        <span class="small-spacer-border" *ngIf="!isSmall"></span>
        <span
          *ngIf="selectedAllLabel && sizeOfItems === displayedSelectedItems?.length; else notSelectedAllLabels">All
          <span *ngIf="isGpt">{{customAllName | lowercase}}</span>
          <span *ngIf="isSmall">{{nameItem | titlecase}}</span>
        </span>
        <ng-template #notSelectedAllLabels>
          <span>{{displayedSelectedItemsCount}}
            <span>{{!isGpt ? (nameItem | lowercase) + ' selected' : 'clusters'}}</span></span>
        </ng-template>

      </ng-container>
      <ng-template #itemName>
        <span *ngIf="!isSmall" class="small-spacer-border"></span>
        <div
          class="item-name">{{displayedSelectedItems && displayedSelectedItems?.length === 0 ? 'No items selected' : displayedSelectedItems[0]?.name}}</div>
      </ng-template>
    </div>
    <div *ngIf="listGrouping?.size && (!displayedSelectedItems || displayedSelectedItems?.length === 0)"
         class="item-placeholder">
      Select {{nameItem | titlecase}}
    </div>
    <!--    <div *ngIf="!listGrouping?.length && (selectedItems || selectedItems?.length === 0)" class="item-placeholder">-->
    <!--      No {{nameItem | titlecase}}-->
    <!--    </div>-->
  </ng-template>

  <ng-template let-group pTemplate="group">

    <div class="flex align-items-center" [pTooltip]="group?.label">
      <p-checkbox *ngIf="isSelectingGroup"
                  class="checkbox"
                  [ngModel]="selectedGroups.get(group.label)"
                  [binary]="true"
                  [disabled]="group?.disabled"
                  [inputId]="group?.label"
                  (onChange)="changeCheckBoxGroup($event, group)"></p-checkbox>
      <label class="label" [for]="group?.label">
        <mat-icon *ngIf="isGroupIcon" [svgIcon]="group?.icon"></mat-icon>
        {{group?.label}}
      </label>
    </div>
  </ng-template>

  <ng-template let-item pTemplate="item">
    <div class="flex align-items-center wrap-text">
      <span [pTooltip]="item?.label" [tooltipStyleClass]="'hovered-icon'"
      [tooltipPosition]="tooltipPosition">{{item?.label}}</span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="multiSelect-footer">
      <!--      <span (click)="selectAll()">Select All </span> | -->
      <span (click)="clearAll()"> Clear All</span>
    </div>
  </ng-template>

</p-multiSelect>
