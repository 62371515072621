import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import {User} from '../../core/auth/auth.interface';
import {GlobalService} from '../../store/global/global.service';
import {MatDialog} from '@angular/material/dialog';
import get from 'lodash-es/get';
import {SlowLogStatus} from '../../features/slow-logs/slow-logs.enum';
import {NavigationEnd, Router} from '@angular/router';
import {UploadFileManagerService} from '../../shared/services/upload-file-manager/upload-file-manager.service';
import {FailedUploadComponent} from '../../shared/components/popups/dialog/failed-upload/failed-upload.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter} from 'rxjs/operators';
import {EnumToolsType} from '../../shared/models/enum';
import isBoolean from 'lodash-es/isBoolean';
import {SegmentTrackService} from '../../shared/services/segment/segment.service';
import {SideBarInterface} from '../../store/global/side-bar.interface';
import merge from 'lodash-es/merge';
import {GptPayload} from '../../features/ops-gpt/ops-gpt.interface';
import {OpsterChatService} from '../../features/ops-gpt/service/opster-chat.service';
import {Cluster} from '../../store/global/cluster.interface';
import includes from 'lodash-es/includes';

declare const gtag: Function;

const SHOW_GPT = {
  'ops_gpt': false,
};

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent implements OnInit, OnChanges {
  @Input() currentUrl;

  public user: User;
  public clusterList: Cluster[];
  public isTrialEnding = false;
  public showTrialWelcomeDialog = false;
  public plan;
  public isMenuOpen = false;
  public gptPayload: GptPayload;

  public isAfterAnalyzeSlowLog = false;
  public analyzeSlowLogStatus;
  public analyzeSlowLogFileName;
  public analyzeSlowLogSize;
  public analyzeSlowLogProgress;
  public analyzeSlowLogResult;

  @ViewChild('appTopContent') appTopContent;

  public showGpt = true;
  public innerWidthBig;
  public displaySidebarWithGpt = true;

  public sideBarObject: SideBarInterface;

  constructor(private authService: AuthService,
              private globalService: GlobalService,
              private uploadFileManagerService: UploadFileManagerService,
              private segmentTrackService: SegmentTrackService,
              private dialog: MatDialog,
              // private dashboardService: DashboardService,
              private ref: ChangeDetectorRef,
              private opsterChatService: OpsterChatService,
              private router: Router) {
  }

  ngOnInit() {
    this.globalService.getClusterList()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
      this.clusterList = res;
    });

    this.authService.getAccountFromServer();
    this.globalService.setGlossary();
    this.authService.getTrialStatus()
      .pipe(untilDestroyed(this))
      .subscribe(status => {
        this.isTrialEnding = status;
        this.showTrialWelcomeDialog =
          ((this.clusterList && !this.clusterList?.length) || this.isTrialEnding) && !includes(this.currentUrl, 'settings');
        this.ref.markForCheck();

        // if (status) {
        // this.globalService.setSelectedRefreshTime('Off', false);
        // }
      });

    this.globalService.getSideBar()
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        if (res) {
          this.sideBarObject = merge(this.sideBarObject, res);
          this.ref.markForCheck();
        }
      });

    try {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
          page_path: get(event, 'urlAfterRedirects')
        });
      });
    } catch (e) {
      console.log(e);
    }

    this.getAfterAnalyzeSlowLogs();
    // this.updateService.checkForUpdates();
    this.authService.getAllAccountUser();
    this.authService.getCurrentUser()
      .pipe(filter(res => res),
        untilDestroyed(this))
      .subscribe(x => {
        this.user = x;
        // if (!isEmpty(this.user)) {
          // this.dashboardService.init();
        // }
        this.ref.markForCheck();
      });

    this.globalService.getQuickOpsGpt()
      .pipe(untilDestroyed(this)).subscribe((res: GptPayload) => {
      if (get(res, 'display')) {

        if (get(res, 'typesToInclude')) {
          this.segmentTrackService.setAnalyticsTrackToServer('Autoops Open OpsGPT side bar from event', {
            email: this.segmentTrackService.getTrackEmail,
            eventType: get(res, 'typesToInclude').join(', ')
          });

          this.opsterChatService.setConversationId(null);

          if (!get(this.gptPayload, 'display')) {
            this.displaySidebarWithGpt = false;
            this.ref.markForCheck();

            setTimeout(() => {
              this.displaySidebarWithGpt = true;
              this.ref.markForCheck();
            }, 200);
          }

        }

        this.innerWidthBig = window.innerWidth > 2000;
      }
      this.gptPayload = res;
      this.ref.markForCheck();


    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(get(changes, 'currentUrl'), 'currentValue')) {
      this.showGpt = isBoolean(SHOW_GPT[this.currentUrl]) ? SHOW_GPT[this.currentUrl] : true;
      if (!this.showGpt) {
        const gptPayload: GptPayload = {
          display: false
        };
        this.globalService.setQuickOpsGpt(gptPayload);
      }

      this.showTrialWelcomeDialog =
        ((this.clusterList && !this.clusterList?.length) || this.isTrialEnding) && ! includes(this.currentUrl, 'settings');

      this.ref.markForCheck();
    }
  }


  getAfterAnalyzeSlowLogs() {
    this.uploadFileManagerService.getAfterAnalyzeSlowLog()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isAfterAnalyzeSlowLog = true;
        this.analyzeSlowLogStatus = get(res, 'status');
        this.analyzeSlowLogResult = get(res, 'result');
        this.analyzeSlowLogProgress = get(res, 'progress');

        if (get(res, 'fileName')) {
          this.analyzeSlowLogFileName = get(res, 'fileName');
        }
        this.analyzeSlowLogSize = get(res, 'size');
        this.ref.detectChanges();


        if (get(res, 'result') && this.analyzeSlowLogStatus === SlowLogStatus.COMPLETE) {
          this.isAfterAnalyzeSlowLog = false;
          this.analyzeSlowLogResult
        } else if (this.analyzeSlowLogStatus === SlowLogStatus.FAILED) {
          this.onCloseProgressBar(false);
        }
        this.ref.markForCheck();

      });
  }

  onMenuOpen(event) {
    this.isMenuOpen = event;
    this.globalService.setMenuOpen(this.isMenuOpen);
  }

  onCloseProgressBar(onlyClose) {
    if (onlyClose) {
      this.isAfterAnalyzeSlowLog = false;
      return;
    }

    if (this.analyzeSlowLogStatus === SlowLogStatus.COMPLETE) {
      this.isAfterAnalyzeSlowLog = false;
      this.router.navigate(['/slowLogs/result'],
        {
          queryParams: {
            runSL: get(this.analyzeSlowLogResult, 'id')
          },
          queryParamsHandling: 'merge'
        }).then(() => {
      });
    }
    if (this.analyzeSlowLogStatus === SlowLogStatus.FAILED) {
      this.isAfterAnalyzeSlowLog = false;
      this.dialog.open(FailedUploadComponent, {
        width: '600px',
        autoFocus: false,
        data: {errors: this.analyzeSlowLogResult, type: EnumToolsType.SLOWLOG},
      });

    }

  }

  onSupplementaryInfo(val) {
    this.sideBarObject.onSupplementaryInfo(val);
  }

  onOnMenuOpen(val, severity) {
    this.sideBarObject.onOnMenuOpen(val, severity);
  }

  onOnCustomize() {
    this.sideBarObject.onOnCustomize();
  }

  onDismiss() {
    this.sideBarObject.onDismiss();
  }

  onSidebarHide() {
    this.sideBarObject.onSidebarHide();
    this.sideBarObject = null;

  }

  onOnMuteNotifications(val) {
    this.sideBarObject.onMuteNotifications(val);
  }
}
