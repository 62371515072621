<div class="bad-face">
  <div class="text">
    <h2>Whoops!!</h2>
    <h2>It's not you, it's us</h2>
  </div>
  <div>
    <mat-icon class="image" [svgIcon]="'empty_state_ghost'"></mat-icon>
  </div>
  <div class="text">
    <h2> {{reportError}}</h2>
    <a class="link" [href]="'/login'">Go back to login page</a>
  </div>
</div>
