import {NgModule} from '@angular/core';
import { NumberFormatPipe } from './short-number-suffix-pipe.pipe';

@NgModule({
  declarations: [
    NumberFormatPipe,
  ],
  imports: [

  ],
  providers: [NumberFormatPipe],
  exports: [
    NumberFormatPipe
  ]
})
export class NumberFormatModule {
}
