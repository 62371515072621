<div class="sidebar-panel field-names" *ngIf="arrFieldNames?.length">
  <p class="panel-title">
    <mat-icon svgIcon="processor"></mat-icon>
    <span>What was detected</span>
  </p>

  <div class="affected-section">
    <p> We found {{arrFieldNames?.length}} cases where it’s recommended you change the template </p>

    <div style="display: grid;grid-template-columns: auto 50px">
      <p-orderList [value]="arrFieldNames" [listStyle]="{'max-height':'20rem'}" filterBy="name"
                   filterPlaceholder="Filter by name" [dragdrop]="false" [selection]="selectionFieldNames"
                   (selectionChange)="selectionChange($event)">

        <ng-template let-fieldName pTemplate="item">
          <div class="field-name-item">
            <div style="padding-right: 10px">{{fieldName?.id +1}}</div>
            "
            <div class="field" [pTooltip]="fieldName?.name">{{fieldName?.name}} </div>
            "
          </div>


        </ng-template>


      </p-orderList>
      <div class="field-names-btns" >
        <div class="btn" (click)="changeFieldNames(false)" [ngClass]="{'disabled': selectionFieldNamesPosition === 0}">
          <mat-icon>expand_less</mat-icon>
        </div>
        <div class="btn" (click)="changeFieldNames(true)" [ngClass]="{'disabled': selectionFieldNamesPosition === (arrFieldNames?.length - 1)}">
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
    </div>
  </div>


</div>
