import {Injectable} from '@angular/core';

@Injectable()
export class WebWorker {

  private _worker: Worker;

  get worker(): Worker {
    return this._worker;
  }

  initializing(): Worker {
    this._worker = new Worker(new URL('./compress-file.worker', import.meta.url));
    return this.worker;
  }

  terminate() {
    this._worker.terminate();
  }
}
