<div class="sidebar-panel">
  <p class="panel-title">
    <mat-icon svgIcon="wrench"></mat-icon>
    <span>Recommendations</span>
  </p>
  <div class="panel-content">
    <p *ngIf="recommendations?.length > 1">The recommendations below are organized according to suggested order of
      execution:</p>

      <ul class="recommendation-box"
          *ngFor="let rec of recommendations; trackBy: PerformanceUtils.trackByIndex; let i = index;">

        <div class="rec-title">
          <mat-icon [svgIcon]="'lightning'"></mat-icon>
          <span>{{rec?.title}}</span>
        </div>

        <li [ngClass]="recommendations?.length > 1 ? 'list': 'not-list'">
          <p class="reco-description marked">

            <opster-markdowm [lineNumbers]="false" [clipboard]="false"  [data]="rec?.description">
            </opster-markdowm>

          </p>
          <!--      <p class="reco-description"><a (click)="show = !show">{{show ? '...see less': '...see More' }}</a></p>-->
        </li>

<!--        <div class="copy-line" *ngIf="rec?.command">-->
<!--            <span class="item-copy-line" [cdkCopyToClipboard]="rec?.command" (click)="copyToClipboard()"-->
<!--                  [pTooltip]="'Click to copy'">-->
<!--              <mat-icon class="copy-line-icon" [svgIcon]="'copy'"></mat-icon>-->
<!--              Copy-->
<!--            </span>-->
<!--        </div>-->

        <div *ngIf="rec?.command" class="rec-content">
          <code-viewer [data]="rec?.command"  [lineNumbers]="true"></code-viewer>
        </div>

      </ul>


  </div>

</div>
