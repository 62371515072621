// @ts-ignore
import moment from 'moment';


export const GET_RANGE = (rangeLabel) => {
  const ranges: any = {
    'Last 1 minute': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 5 minutes': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(5, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 15 minutes': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(15, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 30 minutes': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(30, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 1 hour': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 3 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(3, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 6 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(6, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 12 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(12, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 24 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(24, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 2 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(2, 'day'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 7 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(6, 'day').startOf('day'),
      moment(new Date, 'MM-DD-YYYY HH:mm').endOf('day')],
  };

  return rangeLabel ?
    (ranges[rangeLabel] ? {startDate: ranges[rangeLabel][0], endDate: ranges[rangeLabel][1]} : null)
    : ranges;
};

export const GET_RANGE_NOW = (rangeLabel) => {
  const ranges: any = {
    'Now': [moment(new Date, 'MM-DD-YYYY HH:mm'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    '1 minute ago': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'minute')],
    '5 minute ago': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(5, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm').subtract(5, 'minute')],
    '15 minute ago': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(15, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm').subtract(15, 'minute')],
    '30 minute ago': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(30, 'minute'),
      moment(new Date, 'MM-DD-YYYY HH:mm').subtract(30, 'minute')],
    '1 hour ago': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'hour'),
      moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'hour')]
  };

  return rangeLabel ?
    (ranges[rangeLabel] ? {startDate: ranges[rangeLabel][0], endDate: ranges[rangeLabel][1]} : null)
    : ranges;
};

export const GET_RANGE_SMALL = (rangeLabel) => {
  const ranges: any = {
    'Last 1 month': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 2 months': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(2, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 3 months': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(3, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 6 months': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(6, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 9 months': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(9, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 1 year': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'year'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 2 years': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(2, 'year'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
  };

  return rangeLabel ?
    (ranges[rangeLabel] ? {startDate: ranges[rangeLabel][0], endDate: ranges[rangeLabel][1]} : null)
    : ranges;
};

export const GET_RANGE_EVENTS = (rangeLabel) => {
  const ranges: any = {
    'Last 24 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(24, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 7 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(6, 'day'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 14 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(13, 'day'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 1 month': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
  };

  return rangeLabel ?
    (ranges[rangeLabel] ? {startDate: ranges[rangeLabel][0], endDate: ranges[rangeLabel][1]} : null)
    : ranges;
};

export const GET_RANGE_NOTIFICATIONS = (rangeLabel) => {
  const ranges: any = {
    'Last 24 hours': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(24, 'hour'), moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 7 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(6, 'day'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 14 days': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(13, 'day'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 1 month': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(1, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')],
    'Last 2 month': [moment(new Date, 'MM-DD-YYYY HH:mm').subtract(2, 'month'),
      moment(new Date, 'MM-DD-YYYY HH:mm')]
  };

  return rangeLabel ?
    (ranges[rangeLabel] ? {startDate: ranges[rangeLabel][0], endDate: ranges[rangeLabel][1]} : null)
    : ranges;
};

export const getChartColumnWidth = (input) => {
  return 1 / (input / 30);
};
