import { Directive, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { themes } from './themes.const';
import { ThemeService } from './theme.service';
import { Subscription } from 'rxjs';
import { SegmentTrackService } from '../../shared/services/segment/segment.service';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  private themeName;
  private themServiceSubscription: Subscription;

  constructor(private elementRef: ElementRef,
              @Inject(DOCUMENT) private document: any,
              private segmentTrackService: SegmentTrackService,
              private themService: ThemeService) {
  }

  ngOnInit() {
    this.themService.getActiveTheme()
      .pipe()
      .subscribe(isDarkTheme => {
        if (this.themeName) {
          this.segmentTrackService.analyticsTrack(`user change theme mode to ${isDarkTheme ? 'darkTheme' : 'lightTheme'}`,
            {email: this.segmentTrackService.getTrackEmail});
        }
        this.themeName = isDarkTheme ? 'darkTheme' : 'lightTheme';
        this.updateTheme(this.themeName);

      });
  }

  updateTheme(themeName) {
    const element = this.elementRef.nativeElement;
    const them = themes[themeName];


    for (const key of Object.keys(them)) {
      element.style.setProperty(key, them[key]);
      this.document.body.style.setProperty(key, them[key]);
    }
  }

  ngOnDestroy() {
    if (this.themServiceSubscription) {
      this.themServiceSubscription.unsubscribe();
    }
  }

}
