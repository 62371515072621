import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateOptimizerService {
  private history$: BehaviorSubject<any[]>;
  private selectedManualCluster$: BehaviorSubject<any[]>;
  private isSecondTabSelected: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.history$ = new BehaviorSubject<any>(null);
    this.selectedManualCluster$ = new BehaviorSubject<any>(null);
    this.isSecondTabSelected = new BehaviorSubject<any>(false);
  }

  // ManualClusters
  public getOnlyManualClusters(): Observable<any> {
    return this.http.get(`api/analyzer/template-optimizer/get-only-manual-clusters`).pipe(share()) as Observable<any>;
  }

  getSelectedManualClusters(): Observable<any> {
    return this.selectedManualCluster$.asObservable();
  }

  setSelectedManualCluster(val) {
    this.selectedManualCluster$.next(val);
  }

  getIsSecondTabSelected(): Observable<any> {
    return this.isSecondTabSelected.asObservable();
  }

  setIsSecondTabSelected(val: boolean) {
    this.isSecondTabSelected.next(val);
  }

  // End ManualClusters

  setAnalysesHistory(clusterId: string) {
    // const body = {limit: 1000, toolType: 'TEMPLATE_OPTIMIZER'};
    this.http.get<any>(`api/analyzer/tools/templates/history/${clusterId}`).pipe(share()).subscribe(history => {
      // @ts-ignore
      // const test: any = {templateToResultsMap: null};
      this.history$.next(history);
    });

  }

  getAnalysesHistory(): Observable<any> {
    return this.history$.asObservable();
  }

  public getToolsResult(queryId): Observable<any> {
    // const body = {
    //   id: queryId,
    // };
    return this.http.get(`api/analyzer/tools/templates/result/${queryId}`).pipe(share()) as Observable<any>;
  }

  public templateAnalyze(path, cluster): Observable<any> {
    const body = {
      s3BucketPath: path,
      cluster
    };


    return this.http.post(`api/analyzer/template-optimizer/manual`, body)
      .pipe(share()) as Observable<any>;
  }

  public resetHistoryOnLogout() {
    this.history$.next(null);
  }
}
