<div class="empty-state-trial">
  <div class="box-trial" *ngIf="isTrialEnding !== undefined && isTrialEnding !== null">
    <!--      trial start-->
    <div class="trial-start" *ngIf="!isTrialEnding">
      <h1 class="trial-start-header">
        Welcome to Opster AutoOps!
      </h1>
      <div class="left-box">
        <h2>Ready to get started?</h2>
        <div class="main-text">
          It takes <b>less than 5 minutes</b> to connect the agent. Then it starts pulling machine metrics and
          stats.
          AutoOps prevents & resolves issues, cuts down administration time and reduces hardware costs.
        </div>

        <div class="video_container" [class.loaded]="!loadingVideo">

          <div class="video_wrapper">
            <div style="    top: 0; left: 0;width: 100%;height: 100%;;position:relative;">

              <iframe width="100%" height="100%" frameBorder="0" src="https://player.vimeo.com/video/814314704?h=079c378930&dnt=1&app_id=122963&autoplay=1"
                      (load)="loaded()"></iframe>
            </div>

          </div>
        </div>


        <div class="loader-wrapper" [class.loaded]="!loadingVideo">
          <loader-box [strokeWidth]="2" [diameter]="50"></loader-box>
        </div>

      </div>

      <p-divider layout="vertical" class="divider"></p-divider>

      <div class="right-box">
        <reviews-wizard [autoplayInterval]="2000"></reviews-wizard>
      </div>

      <div class="footer">
        <button mat-stroked-button class="button-opster yellow metricbeat" routerLink="/settings/installation"
                (click)="connectMetricbeat()">
          <div class="btn-label">
            <mat-icon [svgIcon]="'empty_icon_speed'"></mat-icon>
            Connect your cluster
            <i class="pi pi-chevron-right"></i>

          </div>
        </button>

        <div>Your 14-day free trial will start once the cluster is connected.</div>
        <div>Not sure how to get started? <a (click)="clickTalkToExperts()"
                                             href="https://calendly.com/opster-team/meeting-opster" target="_blank">
          Talk to our experts
        </a> or <a (click)="clickLearnMore()" href="https://opster.com/elasticsearch-autoops/" target="_blank">learn
          more about AutoOps</a>.
        </div>
      </div>
    </div>

    <!--      trial end-->
    <div class="trial-end" *ngIf="isTrialEnding">
      <div class="trial-end-content">
        <div class="left-box">
          <h1 class="trial-end-header">Your 14-Day Free Trial <br>of Opster AutoOps has Ended!</h1>
          <h2>Get AutoOps with expert support</h2>
          <div class="main-text">
            Set up a quick call to see what’s included in Opster’s solutions & support services.<br> Get an accurate
            price
            quote for your system on the call.
          </div>

          <button mat-stroked-button class="button-opster yellow metricbeat"
                  (click)="openCalendar()">
            <div class="btn-label">
              Talk to an expert
              <i class="pi pi-chevron-right"></i>

            </div>
          </button>

          <div class="footer">
            <div>
              <div>We understand that sometimes time just gets away from you.</div>
              <div>If you would like an extension to evaluate if AutoOps is a good fit for you, please <a
                (click)="clickContactSales()"
                href="https://opster.com/form/contact/" target="_blank">contact us</a>.
              </div>
            </div>
          </div>
        </div>

        <p-divider layout="vertical"></p-divider>

        <div class="right-box">
          <div class="logos-box">
            <ng-container *ngFor="let logo of logos; trackBy: PerformanceUtils.trackByIndex">
              <div class="logo-box">
                <mat-icon [svgIcon]="logo"></mat-icon>
              </div>
            </ng-container>
          </div>
          <div class="footer-text">
        <span>
          Leading companies from around the world choose to run Elasticsearch & OpenSearch with Opster.
        </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
