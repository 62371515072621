import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Recommendation } from '../../health.interface';
import { ToastService } from '../../../../../store/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss', '../sidebar-panel-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationsComponent implements OnInit {
  @Input() recommendations: Recommendation[];
  @Input() type;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private toastService: ToastService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  copyToClipboard() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('recommendations.copy_clipboard'), 'success', 'copy_clipboard');
  }

}
