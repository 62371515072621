<markdown [lineNumbers]="lineNumbers" [clipboard]="clipboard" class="opster-markdown"
          [clipboardButtonTemplate]="buttonTemplate"
          [data]=" data ">
</markdown>

<ng-template #buttonTemplate>
  <div class="copy-line on-tab-view">
       <span class="item-copy-line" style="
    display: flex;
    align-items: center;
    gap: 5px;"
             (click)="copyToClipboard()"
             [tooltipStyleClass]="'hovered-icon'"
             [pTooltip]="'Click to copy'">
            <mat-icon class="copy-line-icon" [svgIcon]="'copy'"></mat-icon>
             Copy
        </span>
  </div>
</ng-template>
