import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import { TimeRangeComponent } from './time-range.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    TimeRangeComponent,
  ],
    imports: [
        CommonModule,
        CalendarModule,
        OverlayPanelModule,
        FormsModule,
        ListboxModule,
        MatIconModule,
        TooltipModule,
        MatButtonModule,
    ],
  providers: [],
  exports: [
    TimeRangeComponent
  ]
})
export class TimeRangeModule {
}
