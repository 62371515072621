import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {LineCrosshairChartComponent} from './line-crosshair-chart.component';
import {PanelLineCrosshairChartComponent} from './panel/panel-line-crosshair-chart.component';
import {LoaderBoxModule} from '../../components/loader-box/loader-box.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TooltipModule} from 'primeng/tooltip';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MultiSelectModule} from 'primeng/multiselect';
import {SharedModule} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
  declarations: [
    LineCrosshairChartComponent,
    PanelLineCrosshairChartComponent
  ],
  exports: [
    LineCrosshairChartComponent,
    PanelLineCrosshairChartComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LoaderBoxModule,
    MatFormFieldModule,
    TooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    SharedModule,
    DropdownModule,
  ],
  providers: []
})
export class LineCrosshairChartModule {
}
