<p-overlayPanel #overlayPanel [showCloseIcon]="false" [styleClass]="'opster-overlaypanel'" [appendTo]="dw"
                [dismissable]="true">
  <ng-template pTemplate>
    <div class="ranges">

      <div class="absolute-time-range" *ngIf="customCalendar">

        <p
          class="title-time-range">{{timeType !== EnumDateTime.TIME_SINGLE ? 'Absolute time range' : 'Select a specific time to view:'}}</p>
        <div *ngIf="timeType !==  EnumDateTime.TIME_SINGLE" class="calendar">
          <label class="calendar-name">From</label>
          <p-calendar [styleClass]="'opster-calendar'" [showTime]="true"
                      [hourFormat]="'12'"
                      [minDate]="customLimitDate"
                      [(ngModel)]="startDate" [showIcon]="true" inputId="icon" [maxDate]="endDate"></p-calendar>
        </div>
        <div class="calendar">
          <label *ngIf="timeType !==  EnumDateTime.TIME_SINGLE" class="calendar-name">To</label>
          <p-calendar [styleClass]="'opster-calendar'" [showTime]="true" [hourFormat]="'12'" [(ngModel)]="endDate"
                      [minDate]="startDate"
                      [showIcon]="true" inputId="icon"></p-calendar>
        </div>

        <div class="time-range-action">
          <button mat-stroked-button (click)="onApply();" class="button-opster yellow">
            Apply
          </button>
        </div>

      </div>

      <p-listbox [options]="ranges"
                 [(ngModel)]="selectedDate"
                 optionLabel="name"
                 [styleClass]="'ranges'"
                 (onChange)="rangeSelected($event);">
      </p-listbox>

    </div>

  </ng-template>
</p-overlayPanel>

<div class="time-range-ranges">

  <mat-icon *ngIf="showZoomOutButton"
            [pTooltip]="'Zoom Out'" [tooltipPosition]="'bottom'"
            [showDelay]="500"
            (click)="onClickRangeArrow(Arrow_Direction.ZOOM_OUT)"
            class="icon-zoomout zoom-out" [svgIcon]="'zoom_out'"></mat-icon>

  <mat-icon *ngIf="showArrowButtons &&
  (timeType === EnumDateTime.TIME_SINGLE && selectedDate?.name !== Times_Values.NOW ||
  timeType === EnumDateTime.TIME_RANGE)"
            [ngClass]="{'disabled': isArrowsIconsDisabled}"
            class="icon-arrows left" (click)="onClickRangeArrow(Arrow_Direction.LEFT)">chevron_left
  </mat-icon>

  <div #dw class="dropdown-wrapper">
    <div #dp class="dropdown-ranges" [ngClass]="{'with-arrows': showArrowButtons &&
  (timeType === EnumDateTime.TIME_SINGLE && selectedDate?.name !== Times_Values.NOW ||
  timeType === EnumDateTime.TIME_RANGE)}" (click)="toggle($event)">
    <span class="selected-date" [pTooltip]="selectedDateTooltip" [tooltipPosition]="'bottom'"
          [escape]="false" [tooltipStyleClass]="'selected-date-tooltip'" [showDelay]="500"
          [tooltipDisabled]="!selectedDateTooltip"
          (mouseenter)="buildSelectedTooltip()" (mouseleave)="disabledTooltip()">{{selectedDateDisplay}}</span>

      <mat-icon class="arrow-dropdown">arrow_drop_down</mat-icon>
    </div>
  </div>

  <mat-icon *ngIf="showArrowButtons &&
  (timeType === EnumDateTime.TIME_SINGLE && selectedDate?.name !== Times_Values.NOW ||
  timeType === EnumDateTime.TIME_RANGE)"
            [ngClass]="{'disabled': isArrowsIconsDisabled}"
            class="icon-arrows right" (click)="onClickRangeArrow(Arrow_Direction.RIGHT)">chevron_right
  </mat-icon>
</div>



